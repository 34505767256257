<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" left-arrow title="保证金" @click-left="back" />
        </van-sticky>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        
        <div class="container">
            <div class="box1" style="color: #000;">
                保证金额度<span style="font-size: 25px;color: #475AD5;margin-left: 10px;"><span style="font-size: 18px;">¥</span>{{ brand_info.securityDepositLimit }}</span>
            </div>
            <div class="box1" style="color: #000;margin: 10px 0;justify-content: space-between;">
               <div class="flex">
                余额<span style="font-size: 25px;color: #475AD5;margin-left: 10px;"><span style="font-size: 18px;">¥</span>{{ brand_info.securityDepositBalance }}</span>
               </div>
               <div class="flex" style="color: #B10808;">
                待补缴<span style="font-size: 25px;margin-left: 10px;"><span style="font-size: 18px;">¥</span>{{brand_info.paidBackAmount}}</span>
               </div>
            </div>
            <van-button class="btn" @click="showmode = true">缴纳保证金</van-button>
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="暂无内容"
                @load="onLoad"
                :immediate-check="false"
            >
                <div class="box" v-for="item in shiplist" style="margin: 10px 0;">
                    <div style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
                        <div>
                        <div style="max-width: 200px;">
                            <div v-if="item.status == 0">未付款</div>
                            <div v-if="item.status == 1">已付款</div>
                            <div v-if="item.status == 2">已取消</div>
                            <div v-if="item.status == 3">扣除</div>
                            <div v-if="item.status == 4">提现中</div>
                            <div v-if="item.status == 5">提现</div>
                            <div v-if="item.status == 6">提现失败</div>
                            <div>{{item.remark}}</div>
                        </div>
                        <div style="margin-top: 10px;">{{ item.createTime}}</div>
                    </div>
                    <div style="font-size: 25px;color: #475AD5;"><span>{{ item.type == 0 ? '+' : '-' }}</span>{{item.amount}}</div>
                    </div>
                    <div v-if="item.status == 0" class="paycss" style="margin-top: 10px;" @click="topay(item)">去支付</div>
                </div>
            </van-list>
            
        </div>

        </van-pull-refresh>
        <van-popup round v-model="showmode" position="bottom" >
                <div class="wuliu_box" style="height: 350px;">
                    <div style="font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;">支付</div>
                    <div>
                        <!-- <div style="color: #475AD5;font-size: 30px;">¥500</div>
                        <div style="color:rgba(55,38,27,0.5);">输入缴纳金额</div> -->
                        <van-field class="input_fild" type="number" autocomplete="off" v-model="price_need" placeholder="请输入缴纳金额"
                         maxlength="10"/> 
                    </div>
                    <div>
                        <div style="margin-top: 20px;">支付方式</div> 
                        <van-radio-group v-model="paytype" direction="horizontal" style="margin-top: 20px;">
                            <van-radio name="0">微信</van-radio>
                            <van-radio name="1">支付宝</van-radio>
                        </van-radio-group>
                    </div>
                    <van-button class="btn" @click="payfn">确认支付</van-button>
                </div>
        </van-popup>
        <div style="color: rgba(55,38,27,0.5);position: relative;bottom: 20px;left: 0;font-size: 15px;padding: 0 10px;box-sizing: border-box;margin-top: 20px;">
            <div>连续3个自然日保证金余额 < 保证金额度，暂停发布商品到橱窗的功能</div>
            <div>连续7个自然日保证金余额 < 保证金应缴金额，暂停发布商品到橱窗的功能，商品橱窗下架</div>
        </div>
    </div>
</template>
<script>
import { searchSecurityDepositRecord,createSecurityDepositRecordOrder,brand} from '@/api/goods';
import { getRePayOrderInfo} from '@/api/myorder';
import { Toast } from 'vant';

export default {
    name: 'work',
    data() {
        return {
            isLoading: false,
            mar_top:'',
            shiplist:[],
            bradnid:'',
            paytype:'0',
            showmode:false,
            price_need:'',
            brand_info:{},

            loading: false,
            finished: false,
            page: 1,
            pageSize: 5,
        }
    },
    methods: {
        onLoad() {       
            this.page++
            setTimeout(() => {
                this.getlist()
            }, 1000);
        },
        onRefresh() {
            setTimeout(() => {
                Toast('刷新成功');
                    this.isLoading = false
                    this.page = 1
                    this.shiplist = []
                    this.finished = false
                    this.getlist();  
                    this.getnewbrand();  
                }, 1000);
        },
        //支付
        async payfn(){
            if(this.price_need == '') {
                Toast('金额不能为空')
            } else {
                const res = await createSecurityDepositRecordOrder({
                    brandId:this.bradnid,
                    amount:this.price_need,
                    payWay:this.paytype
                })
                console.log("保证金充值",res);
                var paydata = {}
                if(this.paytype == '0') { //微信支付
                    var a = {
                        payChannel:'wechat',
                        timeStamp:JSON.parse(res.data.orderStr).timeStamp,
                        sign:JSON.parse(res.data.orderStr).sign,
                        nonceStr:JSON.parse(res.data.orderStr).nonceStr,
                        appId:JSON.parse(res.data.orderStr).appId,
                        packageValue:JSON.parse(res.data.orderStr).packageValue,
                        prepayId:JSON.parse(res.data.orderStr).prepayId,
                        partnerId:JSON.parse(res.data.orderStr).partnerId,
                    }
                    paydata = a
                } else { //支付宝支付
                    var a = {
                        payChannel:'alipay',
                        orderInfo:res.data.orderStr,
                    }
                    paydata = a
                }
                if(res.code == 200) {
                    this.showmode = false
                    var data = {
                    action:'Play',
                    req:paydata,
                    reqId:4,
                    respFunction:'bridgepayfn'
                }
                if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }   
                }
            }
        },
        //重新去支付
        async topay(e){
            console.log(e);
            const res = await getRePayOrderInfo({
                orderNo:e.recordOrderNo,
                type:0
            })
            console.log("重新支付",res);
            /* console.log(JSON.parse(res.data.orderStr)); */
            var paydata = {}
            if(res.data.payWay == 0) { //微信支付
                var a = {
                        payChannel:'wechat',
                        timeStamp:JSON.parse(res.data.orderStr).timeStamp,
                        sign:JSON.parse(res.data.orderStr).sign,
                        nonceStr:JSON.parse(res.data.orderStr).nonceStr,
                        appId:JSON.parse(res.data.orderStr).appId,
                        packageValue:JSON.parse(res.data.orderStr).packageValue,
                        prepayId:JSON.parse(res.data.orderStr).prepayId,
                        partnerId:JSON.parse(res.data.orderStr).partnerId,
                    }
                    paydata = a
            } else { //支付宝支付
                var a = {
                        payChannel:'alipay',
                        orderInfo:res.data.orderStr,
                    }
                    paydata = a
            }
            var data = {
                action:'Play',
                req:paydata,
                reqId:4,
                respFunction:'bridgepayfn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
            
        },
        back() {
            this.$router.go(-1);
        },
        //保证金明细
        async getlist(){
            const res = await searchSecurityDepositRecord({ 
                brandId:this.bradnid,
                pageNum:this.page,
                pageSize:this.pageSize
            });
            console.log("查询保证金明细",res);
            this.shiplist = [...this.shiplist , ...res.data.records] 
                this.loading = false
                if (res.data.records.length === 0 || this.shiplist.length == res.data.total) {
                    this.finished = true
                }
        },
        //最新品牌信息
        async getnewbrand(){
            const res = await brand(this.bradnid)
            console.log("查询最新品牌信息",res); 
            /* res.data.securityDepositBalance = res.data.securityDepositBalance  == 0.00 ? 0: res.data.securityDepositBalance
            res.data.securityDepositLimit = res.data.securityDepositLimit+0.00 */
            this.brand_info = res.data
        },
        //支付bridge回调
        bridgepayfn(e){
            console.log("支付   ",e);
            /* if(e.code == 200) { */
                /* Toast('操作成功') */
                this.page = 1
                this.shiplist = []
                this.finished = false
                setTimeout(() => {
                     this.getlist();
                     this.getnewbrand(); 
                }, 300);
            /* } */
        },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log(this.$route.query);
        this.bradnid = this.$route.query.id
        this.page = 1
        this.shiplist = []
        this.finished = false
        await this.getlist();  
        await this.getnewbrand();  
        window.bridgepayfn = this.bridgepayfn    
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 

.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
/deep/ .van-step--horizontal .van-step__circle-container {
    background-color: rgba(0, 0, 0, 0);
}
.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-size: 14px;
    
    .box {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        .flex {
            display: flex;
            align-items: center;
        }
    }
    .box1 {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/deep/.van-step__circle  {
    width: 10px;
    height: 10px;
    background: rgba(97, 76, 47, 0.15); 
}
.wuliu_box {
    height: 300px;
    padding: 20px;
    box-sizing: border-box; 
    background-color: #E9D4BC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
}
.btn {
        width: 100%;
        border-radius: 5px;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        margin: 10px 0;
    }
    .paycss {
        width: 100px;
        height: 35px;
        border-radius: 8px;
        line-height: 35px;
        text-align: center;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        
    }
    .input_fild {
        margin-top: 10px;
        padding:10px 15px;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15);
        border-radius: 8px;
        color: #000;
    }
    :deep(.van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
