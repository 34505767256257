<template>
    <div class="page" >
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="入驻信息" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
                    <van-field readonly class="input_fild" label="品牌主体" label-width="120px"  :value="info.type == 1 ? '个人' :info.type == 2 ? '个体工商户' : '企业/公司'" />
                    <van-field readonly  class="input_fild" label="经营类目" label-width="120px">
                        <template #input>
                            <el-cascader
                            class="iptql"
                                disabled
                                v-model="leimu_val"
                                :options="options"
                                :props="props"
                                :show-all-levels="false"
                                clearable>
                            </el-cascader>
                        </template>
                    </van-field>
                    <van-field readonly class="input_fild" v-if="info.type != 3" label="姓名" label-width="120px" :value="info.name" /> 
                    <van-field readonly class="input_fild" v-else label="公司/企业名称" label-width="120px" :value="info.name" />
                    <van-fiel readonlyd class="input_fild" label="证件类型" label-width="120px" :value="info.idType == 0 ? '身份证' :info.idType == 1 ? '个体工商户营业执照' : '营业执照' "/>
                    <van-field readonly class="input_fild" label="证件号" label-width="120px" :value="info.idNumber" />
                    <van-field readonly class="input_fild" label="证件照片" label-width="120px" >
                    <template #input>
                        <van-image v-for="item in info.idUrl" style="margin-right: 8px;" width="50" height="50" :src="item"
                                    @click="previewImage(info.idUrl)" />
                                </template>
                    </van-field> 
                    <van-field readonly class="input_fild" v-if="info.businessLicense" label="营业执照" label-width="120px" >
                        <template #input>
                        <van-image  style="margin-right: 8px;" width="50" height="50" :src="info.businessLicense"
                                    @click="previewImage(info.businessLicense)"  />
                                </template>
                    </van-field>
                    <van-field readonly v-if="info.operateName" class="input_fild" label="运营联系人" label-width="120px" :value="info.operateName" />
                    <van-field readonly v-if="info.operatePhone" class="input_fild" label="运营手机号" label-width="120px" :value="info.operatePhone" />
                    <van-field readonly class="input_fild" label="支付宝账号" label-width="120px" :value="info.alipayNo" />
                    <van-field readonly class="input_fild" label="微信账号" label-width="120px" :value="info.wechatNo" />
                    <van-field readonly class="input_fild" label="是否参与拍卖" label-width="120px" :value="info.auctionFlag == true ? '是': '否'" />
                    <van-field readonly class="input_fild" label="拍卖许可证" label-width="120px" v-if="info.auctionLicense">
                        <template #input>
                        <van-image  style="margin-right: 8px;" width="50" height="50" :src="info.auctionLicense"
                                    />
                                </template>
                    </van-field>
                    <van-field readonly class="input_fild" label="是否参与文物拍卖"  label-width="120px" :value="info.culturalAuctionFlag == true ? '是': '否'" />
                    <van-field readonly class="input_fild" label="拍卖许可证" label-width="120px" v-if="info.culturalAuctionLicense">
                        <template #input>
                        <van-image  style="margin-right: 8px;" width="50" height="50" :src="info.culturalAuctionLicense"
                                    />
                                </template>
                    </van-field>
                    <van-field readonly class="input_fild" label="品牌名" label-width="120px" :value="info.brand" />
                    <van-field readonly class="input_fild" label="品牌介绍" autosize type="textarea" label-width="120px" :value="info.brandDesc" />
                    <van-field readonly class="input_fild" label="品牌历史" autosize type="textarea" label-width="120px" :value="info.brandHistory" />
                    
                    <van-field readonly class="input_fild" label="品牌logo" label-width="120px" >
                        <template #input>
                        <van-image fit="cover" style="margin-right: 8px;" width="50" height="50" :src="info.brandLogo"
                                    @click="previewImage(info.brandLogo)" />
                                </template>
                    </van-field>
                    <!-- <van-field class="input_fild" label="品牌分类" label-width="120px" :value="info.brandCategory" /> -->
                    <van-field readonly class="input_fild" label="宣传图片" label-width="120px">
                        <template #input>
                        <van-image fit="cover" v-for="item in info.publicityPicture" style="margin-right: 8px;" width="50" height="50" :src="item"
                                    @click="previewImage(info.publicityPicture)" />
                                </template>
                    </van-field>
                    <van-field readonly class="input_fild" label="宣传视频" label-width="120px">
                        <template #input>
                        <!-- <van-image style="margin-right: 8px;" width="50" height="50" :src="info.publicityVideo"
                                    @click="previewImage('publicityVideo')" /> -->
                        <video :poster="info.videoResponse.image.url" @click="playVideo" :src="info.videoResponse.videoUrl" style="width: 60px;height: 60px;"></video>
                                </template>
                    </van-field>
        </div> 
    </div>
</template>
<script>
import { fetchEnterInfo } from '@/api/enter';
import { findGoodCategoryList } from '@/api/goods';
import { ImagePreview } from 'vant';
export default {
    name: 'work',
    data() {
        return {
            mar_top:'',
            imgUrl:require('/src/assets/pic_active.png'),
            imgUrls:require('/src/assets/pic_fail.png'),
            enterStatus:0,
            watchshow:false,
            approveRemark:'',
            info:{},
            leimu_val:[],
            props: { multiple: true,value:'id',label:'text' },
            options:[]
        }
    },
    methods: {
        previewImage(key) {
            if(Array.isArray(key)) {
            ImagePreview(key);

            } else {
                ImagePreview([key]);
            }
            console.log(key);
        },
        playVideo(){
            this.$el.querySelector('video').play()
        },
        
        back() {
            this.$router.go(-1);
        },
       
        async getEnterInfo() {
            // 获取入驻信息
            const res = await fetchEnterInfo({ });
            console.log("获取入驻信息111222",res); 
            if (res.data) {
                res.data.idUrl = res.data.idUrl.split('#####'),
                res.data.publicityPicture = res.data.publicityPicture.split('#####'),
                this.leimu_val = JSON.parse(res.data.businessCategory)
                this.info = res.data
            } 
        },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        const result = await findGoodCategoryList({})
        console.log("三级分类",result);
        this.options = result.data
        await this.getEnterInfo();
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 15px;
    .box {
        padding:40px 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 27px 0;
        position: relative;
        .title {
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translate(-50%,0);
            padding: 10px 30px;
            box-sizing: border-box;
            background-color: #876235;
            color: #E9D4BC ;
            border-radius: 8px;
            font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;
        }
        .t {
            color: #37261B ;
            font-weight: 600;
            margin-left: 5px;
        }
        .des {
            margin: 10px 0;
            padding: 10px;
            box-sizing: border-box;
            margin-left: 8px;
            border-left: 1px dashed #876235;
            font-size: 13px;
            color: rgba(55,38,27,0.33);
        }
        .b {
            padding: 3px 5px;
            box-sizing: border-box;
            background-color: rgba(71,90,213,0.1);
            color: #475AD5;
            margin-left: 5px;
            font-size: 12px;
        }
    }
    .box1 {
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .watch {
        text-decoration: underline;
        text-align: center;
        color: rgba(55,38,27,0.66);
    }
    .flex {
        display: flex;
        align-items: center;
        color: rgba(55,38,27,0.66);
    }
    .same {
        
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        font-size: 18px;
    }
    .a {
        background-color: #876235 ;
        color: #fff;
    }
    .b {
        background-color: rgba(174,133,81,0.15);
        color: #000;
    }
    .input_fild {
    padding:10px 15px;
    box-sizing: border-box;
    background: rgba(174,133,81,0.15);
    color: #000;
}
.btn {
        width: 100%;
        border-radius: 5px;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        margin-top: 20px;
        border: 1px solid #876235;
    }
}
.van-cell::after {
    border-bottom: 1px solid #E9D4BC;
}
.iptql /deep/ .el-input__inner{
        background: rgba(174,133,81,0.15);  /* 背景色 */
        border-color: rgba(174,133,81,0.15); /* 边框色 */
        color: 000 /* 文字颜色 */ 
    }
    /deep/ .iptql .el-input__inner::placeholder,
    /deep/ .el-date-picker .el-input__inner::placeholder,
    /deep/ .el-input .el-input__inner::placeholder {
        color:#A8937C;
    }
    /deep/ .el-input.is-disabled .el-input__inner {
        background-color: rgba(0, 0, 0, 0);
        border: none;
    }
    /deep/ .el-cascader__tags .el-tag {
        background-color: rgba(174, 133, 81, 0.15);
        border: none;
        color: #000;
    }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
