import Vue from 'vue'
// 在这里引入你所需的组件
import {
    Button,
    Swipe,
    SwipeItem,
    Image as VanImage,
    Popup,
    Form,
    Field,
    Uploader,
    RadioGroup,
    Radio,
    CellGroup,
    Toast
} from 'vant'

// 按需引入UI组件
Vue.use(Button)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(VanImage)
Vue.use(Popup)
Vue.use(Form);
Vue.use(Field);
Vue.use(Uploader);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(CellGroup);
Vue.use(Toast);
