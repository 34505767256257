<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" left-arrow title="商家入驻协议" @click-left="back" />
        </van-sticky>
        <div class="container" style="ont-size: 15px;padding: 0 10px;box-sizing: border-box;margin-top: 20px;">
        </div>
        <div style="color: rgba(55,38,27,0.5);position: relative;bottom: 20px;left: 0;font-size: 15px;padding: 10px 20px;box-sizing: border-box;margin-top: 20px;">
            <div>柴门小童第三方商家收费及促销一般规则</div>
            
            <div>生效、执行时间：2025年01月23日</div>
                <div>1、关于信息服务费等收费。您作为第三方商家使用柴门小童，应按每月10元的标准，向柴门小童支付信息服务费。如果您一次性支付年度费用，则全年信息服务费优惠至80元。柴门小童费用规则另有特别约定者，按其约定执行。
                    如您是柴门小童第一批参与柴门小童“分段计时”促销活动的商家，柴门小童免除您3个月的信息服务费及其它一切适用于第三方商家的费用。该项免除仅限一次。分段计时指特定商品在指定时间段内在线人数达到指定数量时降至事先预设的价格（可以为固定价格，也可以为原价格的一定比例）的促销方式。
                        如您作为第三方商家，凡向柴门小童成功引进5个第三方商家的，柴门小童将免除您3个月的信息服务费及其它一切适用于第三方商家的费用。
                            如您作为第三方商家，绑定柴门小童指定的银行卡，柴门小童将免除您半年信息服务费。</div>
                                <div>2、柴门小童第三方商家保证金。您作为第三方商家，应在上架商品前向柴门小童支付首笔保证金，金额为100元。随后，按柴门小童指定的30日内的营业额，根据柴门小童相关规则，计算您应支付的保证金总额度并支付剩余保证金。如您出现虚假宣传、发布违禁商品或出售三无产品的，柴门小童有权扣除保证金。保证金于您退出平台时结算，若有剩余，将无息归还给您。在您入驻柴门小童期间，如保证金出现不足额的，您应立即补足。连续3个自然日未初足的，柴门小童有权暂停发布商品到橱窗的功能。连续6个自然日未补足的，商品橱窗下架，柴门小童有权暂停提供服务。
如柴门小童规则无特别约定，保证金适用如下规则：
如您出现虚假宣传：
情节轻微的，每出现一次违规，扣除保证金100元；
情节一般的，每出现一次违规，扣除保证金200元；
情节严重的，每出现一次违规，扣除保证金2000元。
如您发布违禁商品：
情节一般时，每出现一次违规，扣除保证金2000元；
情节严重时，每出现一次违规，扣除保证金5000元。
如您出售三无产品：
三次以内违规，扣除保证金5000元，并需要提供整改报告。
上述保证金规则具体认定标准，由柴门小童确定并公布，您同意遵照执行。</div>
<div>3、柴门小童第三方商家分段计时促销活动。第三方商家如果参与柴门小童分段计时活动，如为普通商品秒杀和分段计时，应按每订单交易金额5元的标准向柴门小童支付信息服务费。如为降价促销，每订单交易按您扣除运费后的营业额的1%外加5元的标准向柴门小童支付信息服务费。
    如非柴门小童组织的秒杀等促销活动，与柴门小童无关，您无需支付上述柴门小童促销活动的信息服务费。</div>
<div>4、柴门小童如监管相关交易款项，则每月5日（法定节假日顺延）与您结算上月的交易款。具体规则由柴门小童确定并公布，您同意遵照执行。</div>
<div>5、反竞价。如果用户以确定价格发布某项需求，您作为第三方商家响应的，柴门小童将为您与用户匹配交易。匹配成功后，除非发布需求的用户取消交易，您不得擅自取消交易或要求用户取消交易。此等交易中您必须保证相关产品与服务的质量，不得降低。</div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'enter',
    data() {
        return {
            mar_top:""
        }
    },
    methods: {
        back() {
        this.$router.go(-1)
    },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 

.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
/deep/ .van-step--horizontal .van-step__circle-container {
    background-color: rgba(0, 0, 0, 0);
}
.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-size: 14px;
    
    .box {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        .flex {
            display: flex;
            align-items: center;
        }
    }
    .box1 {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/deep/.van-step__circle  {
    width: 10px;
    height: 10px;
    background: rgba(97, 76, 47, 0.15); 
}
.wuliu_box {
    height: 300px;
    padding: 20px;
    box-sizing: border-box; 
    background-color: #E9D4BC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
}
.btn {
        width: 100%;
        border-radius: 5px;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        margin: 10px 0;
    }
    .paycss {
        width: 100px;
        height: 35px;
        border-radius: 8px;
        line-height: 35px;
        text-align: center;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        
    }
    .input_fild {
        margin-top: 10px;
        padding:10px 15px;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15);
        border-radius: 8px;
        color: #000;
    }
    :deep(.van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
