import request from "@/utils/request";

// 店铺查询订单列表
export const queryBrandOrderList = (data) => {
    return request({
        url: `/ssm-trade/api/trade/queryBrandOrderList`,
        method: "POST",
        data
    })
}
// 查询订单下面的退款信息
export const queryRefundListByOrder = (data) => {
    return request({
        url: `/ssm-trade/api/trade/queryRefundListByOrder`,
        method: "POST",
        data
    })
}
// 店铺取消订单
export const brandCancelOrder = (data) => {
    return request({
        url: `/ssm-trade/api/trade/brandCancelOrder`,
        method: "POST",
        data
    })
}
// 商家审核退款申请
export const approveBrandRefund = (data) => {
    return request({
        url: `/ssm-trade/api/trade/approveBrandRefund`,
        method: "POST",
        data
    })
}
// 商家审核退款
export const approveRefundPay = (data) => {
    return request({
        url: `/ssm-trade/api/trade/approveRefundPay`,
        method: "POST",
        data
    })
}
// 商家确认收到退款商品
export const confirmReceiveRefundOrder = (data) => {
    return request({
        url: `/ssm-trade/api/trade/confirmReceiveRefundOrder`,
        method: "POST",
        data
    })
}
// 订单发货
export const deliverGoodsOrder = (data) => {
    return request({
        url: `/ssm-trade/api/trade/deliverGoodsOrder`,
        method: "POST",
        data
    })
}
// 检查物流单号和物流公司是否匹配
export const matchComAndNum = (data) => {
    return request({
        url: `/ssm-trade/api/trade/logistics/matchComAndNum`,
        method: "POST",
        data
    })
}
// 查询物流信息
export const query = (data) => {
    return request({
        url: `/ssm-trade/api/trade/logistics/query`,
        method: "POST",
        data
    })
}
//商家上传发票
export const uploadInvoice = (data) => {
    return request({
        url: `/ssm-trade/api/trade/uploadInvoice`,
        method: "POST",
        data
    })
}
// 开发票
export const addInvoice = (data) => {
    return request({
        url: `/ssm-share/api/share/invoice/addInvoice`,
        method: "POST",
        data
    })
}
//查询开票信息
export const queryInvoiceInfo = (targetId) => {
    return request({
        url: `/ssm-share/api/share/invoice/queryInvoiceInfo?targetId=${targetId}`,
        method: "GET"
    })
}
