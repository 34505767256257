import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.css';  //引入vant样式
import VConsole from 'vconsole';
import '@/plugins/vant' //按需引入vant组件的文件

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//fild禁止输入空格及表情包
import { Field } from "vant";
/* Vue.use(Vant); */
Vue.component(Field.name, {
  extends: Field,
  props: {
    formatter: {
      type: Function,
      default: function (value) {
        value = value.replace(/\s+/g, "");
        value = value.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, '');
        return value
      },
    },
  },
});

//判断安卓ios
Vue.prototype.$Version = function() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
            return 'iOS';
        } else if (userAgent.match(/Android/i)) {
            return 'Android';
        } else {
            return 'unknown';
        }
  };

//不是测试环境的话就有调试工具
const isDebugMode = process.env.NODE_ENV !== 'production';
if (isDebugMode) {
    new VConsole(); // 初始化vconsole
}
//移动适配
import 'amfe-flexible';
import Spread from "@/components/Spread";
import OpenTag from "@/components/OpenTag";
import {callNative,exposeH5Method} from "@/utils/JSBridge"; //引入JSBridge逻辑
import '@/utils/WebViewJavascriptBridge'

Vue.config.ignoredElements = ['wx-open-launch-app']
Vue.config.productionTip = false
Vue.component('Spread', Spread)  //定义成全局组件
Vue.component('OpenTag', OpenTag)  //定义成全局组件
Vue.use(ElementUI);
new Vue({
    router, render: h => h(App)
}).$mount('#app')
