// let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
// //这是必须要写的，用来创建一些设置
// function setupWebViewJavascriptBridge(callback) {

//   //Android使用
//   if (isAndroid) {
//     if (window.WebViewJavascriptBridge) {
//       callback(WebViewJavascriptBridge)
//     } else {
//       document.addEventListener(
//         'WebViewJavascriptBridgeReady',
//         function () {
//           callback(WebViewJavascriptBridge)
//         },
//         false
//       );
//     }
//     sessionStorage.phoneType = 'android'
//   }


//   if (window.WVJBCallbacks) {
//     return window.WVJBCallbacks.push(callback);
//   }
//   window.WVJBCallbacks = [callback];
//   var WVJBIframe = document.createElement('iframe');
//   WVJBIframe.style.display = 'none';
//   WVJBIframe.src = 'https://__bridge_loaded__';
//   document.documentElement.appendChild(WVJBIframe);
//   setTimeout(function () {
//     document.documentElement.removeChild(WVJBIframe)
//   }, 0);
//   sessionStorage.phoneType = 'ios'
// }
// //注册回调函数，第一次连接时调用 初始化函数(android需要初始化,ios不用)
// setupWebViewJavascriptBridge(function (bridge) {  
//   if (isAndroid) {
//     //初始化
//     bridge.init(function (message, responseCallback) {
//       var data = {
//         'Javascript Responds': 'Wee!'
//       };
//       responseCallback(data);
//     })
//   }
// })

// export default {
//   // js调APP方法 （参数分别为:app提供的方法名  传给app的数据  回调）
//   callHandler(name, data, callback) {
//     console.log('callHandlerName',name)
//     setupWebViewJavascriptBridge(function (bridge) {
//         console.log('setupWebViewJavascriptBridge',bridge)
//       bridge.callHandler(name, data, callback)
//     })
//   },
//   // APP调js方法 （参数分别为:js提供的方法名  回调）
//   registerHandler(name, callback) {
//     setupWebViewJavascriptBridge(function (bridge) {
//       bridge.registerHandler(name, function (data, responseCallback) {
//         callback(data, responseCallback)
//       })
//     })
//   }
// }


// 这里根据移动端原生的 userAgent 来判断当前是 Android 还是 ios
const u = navigator.userAgent;
// Android终端
const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
// IOS 终端
const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); 

/**
 * 配合 IOS 使用时的初始化方法
 */
const iosFunction = (callback) => {
    if (window.WebViewJavascriptBridge) { return callback(window.WebViewJavascriptBridge) }
    if (window.WVJBCallbacks) { return window.WVJBCallbacks.push(callback) }
    window.WVJBCallbacks = [callback];
    var WVJBIframe = document.createElement('iframe');
    WVJBIframe.style.display = 'none';
    WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
    document.documentElement.appendChild(WVJBIframe);
    setTimeout(function(){
         document.documentElement.removeChild(WVJBIframe);
    }, 0);
}

/**
 * 配合 Android 使用时的初始化方法
 */
const androidFunction = (callback) => {
    if (window.WebViewJavascriptBridge) {
        callback(window.WebViewJavascriptBridge);
    } else {
        document.addEventListener('WebViewJavascriptBridgeReady', function () {
            callback(window.WebViewJavascriptBridge);
        }, false)
    }
}

window.setupWebViewJavascriptBridge = isAndroid ? androidFunction : iosFunction;
console.log('show1')
isAndroid && window.setupWebViewJavascriptBridge(function (bridge) {
    console.log('show2',JSON.stringify(bridge))
    // 注册 H5 界面的默认接收函数（与安卓交互时，安卓端可以不调用函数名，直接 send 数据过来，就能够在这里接收到数据）
    bridge.init(function (msg, responseCallback) {
        alert(msg);
        responseCallback("JS 返回给原生的消息内容");
    })
});