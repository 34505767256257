<template>
    <div class="page">
        <van-sticky >
            <van-nav-bar style="background-color: #E9D4BC;" title="" left-arrow />
        </van-sticky>
        <div class="container">
            <van-button style="margin: 10px 0;" @click="iosImage" type="info">上传图片</van-button>
            <van-button @click="iosVideo" type="info">上传视频</van-button>
            <van-button style="margin: 10px 0;" @click="iosWxPay" type="info">微信支付</van-button>
            <van-button  @click="iosZfbPay" type="info">支付宝支付</van-button>
            <van-button @click="shiming" style="margin: 10px 0;" type="info">身份校验</van-button>
            <van-button @click="ShareFun" type="info">分享</van-button>
            <van-button @click="choseAddress" type="info" style="margin: 10px 0;">选择地址</van-button>
            <van-button @click="lognFun" type="info" style="margin: 10px 0;">登陆</van-button>
            <van-button @click="getUserInfo" type="info" style="margin: 10px 0;">获取用户认证信息</van-button>
            <van-button @click="beforenter" type="info">跳转品牌服务</van-button>
            <van-button @click="towork" style="margin: 10px 0;" type="info">跳转工作台</van-button>
        </div> 
    </div>
</template>
<script>
import wx from 'weixin-js-sdk';
export default {
    name: 'invoice',
    data() {
        return {
            imgUrl:require('/src/assets/pic_img.png'),
            version:''
        }
    },
    async mounted() {
        /* this.$Version() = this.getMobileOperatingSystem() */
       /* var data = {
                action:'UserInfo',
                req:{},
                reqId:5,
                respFunction:'UserInfofn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            } */
        window.iosUploadImage = this.iosUploadImage
        window.iosUploadVideo = this.iosUploadVideo
        window.iosWxPayFun = this.iosWxPayFun
        window.iosZfbFun = this.iosZfbFun 
        window.AuthenticationFuns = this.AuthenticationFuns
        window.ShareFn = this.ShareFn
        window.UserInfofn = this.UserInfofn
        window.chooseAddress = this.chooseAddress
        window.loginfun = this.loginfun
        window.getuserfun = this.getuserfun
       
      
    },
    methods: {
        beforenter(){
            this.$router.push({ name: 'beforenter'});
        },
        towork(){
            this.$router.push({ name: 'work'});
        },
        //ios 上传图片
        iosImage(){
            var data = {
                action:'GetImage',
                req:{img_num:2},
                reqId:1,
                respFunction:'iosUploadImage'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        iosUploadImage(e){
            console.log("上传图片",e);
        },
        //上传视频
        iosVideo(){
            var data = {
                action:'GetVideo',
                req:{ minute:180},
                reqId:3,
                respFunction:'iosUploadVideo'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        iosUploadVideo(e){
            console.log("上传视频",e);
        },
        //微信支付
        iosWxPay(){
            var a = {
                    payChannel:'wechat',
                    timeStamp:"1736933980",
                    sign:"cjikd40CO0zZlXbge+UowysXUmyfLw2Bv0Qv9LOHuK4npe3yL5cx9NcFj4zcKzgjvxuAGfVDdBX00gxWSFSSIzwXLDMaqAi2EY/gmjZEgZWsNC2ioxapRyPzGKNjal0iJntqxEyHHdEyxzbJNlFwru1yoSCT37a5IfcEvB+IZEdrJiww6qPwLeB/SCUtBtkNTtFNkQb7GvmpqhlZJzSWSCBdzDUm275/GHGf0kGZ/BirmVZsYn01WIMVDLJpLl3kampgdeB7hNIJUDInh6evV6iMkQNhWF7DZvz6uunslrCdkwDpOJikb/0ifFaGpvbc6dZsI98SS1W7cn6wm+hrDQ==",
                    nonceStr:"c9826b2f62c84a3398d1203f27bce18d",
                    appId:"wx0a439cc190c512c5",
                    packageValue:"Sign=WXPay",
                    prepayId:"wx151739407168940af93fc767d6d06a0001",
                    partnerId:"1678261269",
                }
            var data = {
                action:'Play',
                req:a,
                reqId:4,
                respFunction:'iosWxPayFun'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        iosWxPayFun(e){
            console.log("微信支付",e);
        },
        //ios支付宝支付
        iosZfbPay(){
            var a = {
                payChannel:'alipay',
                orderInfo:"alipay_root_cert_sn=687b59193f3f462dd5336e5abf83c5d8_02941eef3187dddf3d3b83462e1dfcf6&alipay_sdk=alipay-sdk-java-4.39.113.ALL&app_cert_sn=b248fc5b6d571aead55ee392077a6b93&app_id=2021004147628069&biz_content=%7B%22out_trade_no%22%3A%221879823278489784320%22%2C%22subject%22%3A%22111%E7%AD%89%E5%95%86%E5%93%81%22%2C%22time_expire%22%3A%222025-01-16+18%3A59%3A29%22%2C%22total_amount%22%3A%2212.00%22%7D&charset=UTF-8&format=json&method=alipay.trade.app.pay&notify_url=https%3A%2F%2Ftest-api.serenetone.com%2Fssm-pay%2Fapi%2Fpay%2Fnotify%2Fali%2Fv2&sign=Ay9rhWoaZP3iAA5FxDLxsDMo7dWYBJhnwhxKRfHRnDSAaJYTS%2BaZ7cX3Un2saHhZqeNJXZfxiu69cQkEvtLXmpkPWWAL6rS3TRKzlT2BIAlJ%2FhXuCu2d0NbmIMLpIaX9mhEtAJ%2BFz15IeK8uKoM3C3lMw%2BY7IW2Cj1ay8uyVcRPNbpMn7cMo7JPY0B%2FA650k9KF2sDpeCu98%2FNS%2BXo75xjiAJU%2FTeY2K%2FHcWh5GJXPfRLLHrmi0jcG3nrZsP9AQe%2B472FYdhQ9G7b7sJABhOoihTPH0WRuzXmMtlncrDCysl14xvw2nAQFC6cXThdYZjgemFvwvKOsmS3eDoiW3EMw%3D%3D&sign_type=RSA2&timestamp=2025-01-16+17%3A29%3A29&version=1.0",
                }
            var data = {
                action:'Play',
                req:a,
                reqId:4,
                respFunction:'iosZfbFun'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        iosZfbFun(e){
            console.log("支付宝支付",e);
        },
        //实名认证
        shiming(){
            var a = {
                name:"武少威",
                idCard:"410928199803156336",
            }
            var data = {
                action:'Authentication',
                req:a,
                reqId:5,
                respFunction:'AuthenticationFuns'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        AuthenticationFuns(e){
            console.log("身份校验",e);
            
        },
        //分享
        ShareFun(){
            var data = {
                action:'Share',
                req:{},
                reqId:6,
                respFunction:'ShareFn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        ShareFn(e){
            console.log("分享",e);
        },
        //选择地址
        choseAddress(){
            var data = {
                action:'SelectAddress',
                req:{},
                reqId:7,
                respFunction:'chooseAddress'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        chooseAddress(e){
            console.log("选择地址",e);
        },
        //登陆
        lognFun(){
            var data = {
                action:'Login',
                req:{},
                reqId:7,
                respFunction:'loginfun'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        loginfun(e){
            console.log("登陆",e);
        },
        //获取用户认证信息
        getUserInfo(){
            var data = {
                action:'UserAuthInfo',
                req:{
                    name:'张三',
                    idCard:'1234567890'
                },
                reqId:7,
                respFunction:'getuserfun'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        getuserfun(e){
            console.log("获取用户认证信息",e);
        },

        UserInfofn(e){
            console.log("获取用户信息22",e);
            localStorage.setItem('token',e.data.token)
        },


        
       
        //登陆
        /* loginFun(){
            var data = {
                action:'Login',
                req:{},
                reqId:7,
                respFunction:'loginfn'
            }
            window.ssmAndroid.ssmMessage(JSON.stringify(data))
        },
        uploadimg(e){
            console.log("上传图片",e);
        },
        uploadimgs(e){
            console.log("上传图片",e);
            
        },
        uploadvideo(e){
            console.log("上传视频",e);
        },
        payfn(e){
            console.log("支付   ",e);
        },
        authentication(e){
            console.log("实名认证",e);
        },
        sharefn(e){
            console.log("分享",e);
        },
        loginfn(e){
            console.log("登陆",e);
        }, */



        getMobileOperatingSystem() {
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            
                if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
                    return 'iOS';
                } else if (userAgent.match(/Android/i)) {
                    return 'Android';
                } else {
                    return 'unknown';
                }
            }
    },
    
    
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 15px;
    .flex{
        display: flex;
        align-items: center;
    }
}
</style>
