<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" left-arrow title="商家入驻协议" @click-left="back" />
        </van-sticky>
        <div class="container" style="ont-size: 15px;padding: 0 10px;box-sizing: border-box;margin-top: 20px;">
        </div>
        <div style="color: rgba(55,38,27,0.5);position: relative;bottom: 20px;left: 0;font-size: 15px;padding: 10px 20px;box-sizing: border-box;margin-top: 20px;">
            <div>拍卖机构入驻协议</div>

<div>版本号：V1.0 </div>
<div>发布日期：2025年1月1日</div>
<div>施行日期：2025年1月1日</div>


<div>为规范入驻本平台的拍卖机构(以下简称“卖家”或“您”)的行为，明确卖家义务，营造规范、有序的拍卖活动交易秩序，保障用户的合法权益，根据相关法律、法规及柴门小童平台相关规则，制订本协议。</div>

<div>本协议由上海声声曼网络科技有限公司（以下简称“柴门小童”）与您签署。</div>

<div>1.协议生效和适用范围</div>
<div>1.1.您通过网络页面点击确认或以其他方式选择接受本协议，即表示您与柴门小童已达成协议并同意接受本协议的全部约定内容。</div>
<div>1.2.本协议内容包括协议正文、附件以及所有柴门小童已经发布的或将来可能发布的各类规则、操作流程。</div>
<div>2.入驻</div>
<div>2.1.您应当符合拍卖活动的准入条件，按照入驻流程申请入驻拍卖活动。</div>
<div>2.2.柴门小童对您在入驻环节填写或提交的入驻材料、入驻信息进行备案，但柴门小童保留抽查、要求您补充提交、及时更新入驻材料及入驻信息的权利；如柴门小童发现您入驻资质造假的，柴门小童有权立即终止本协议并根据拍卖活动规则对您采取措施。</div>
<div>3.拍卖商品、规则发布</div>
<div>3.1.您应当根据拍品发布规则及流程发布拍品及/或专场。如柴门小童发现您提交的拍品资质造假，柴门小童有权立即终止本协议并根据拍卖活动规则对您采取措施。</div>
<div>3.2.就您发布的专场及/或拍品，柴门小童有权根据实际情况决定是否予以排期，亦有权取消或调整排期时间。</div>
<div>3.3.您应当依照法律、法规及平台规则，发布和公布拍卖公告，并公示拍卖规则、拍卖商品/标的主要情况、竞买人条件以及相关拍卖规则。</div>
<div>3.4.您有义务对竞买人资格进行审核，仅允许符合条件的竞买人参与竞拍。</div>
<div>3.5.遵守本协议附件相关约定。</div>
<div>4.费用规定</div>
<div>4.1.作为您使用柴门小童提供的拍卖活动技术服务的对价，您应当按照如下约定向乙方支付费用：</div>
<div>4.1.1.交易技术服务费：如您发布的拍品交易成功（即拍品订单状态显示为“交易成功”）的，您同意向乙方支付交易技术服务费（“交易技术服务费”的计算基准为您通过拍卖活动交易成功的拍品成交价总额），交易技术服务费的收费标准以拍卖活动收费规则的规定为准。</div>
<div>4.1.2.上拍技术服务费：如您的拍品出现流拍、您主动关闭交易订单或买家确认收货前的交易退款（含部分退款）成功的，您均同意向乙方支付上拍技术服务费，上拍技术服务费的收费标准以拍卖活动收费规则的规定为准。</div>
<div>4.2.柴门小童按照协议约定向您收取交易技术服务费、上拍技术服务费的行为并不意味着柴门小童对您的入驻资质、拍品资质、拍品、交易或您自行上传的其他信息的真实性、准确性、合法性、有效性承担任何明示或暗示的保证或担保等责任，也不意味着柴门小童成为您与用户之间就拍品竞拍、交易的参与方，因您的行为导致的任何投诉、纠纷、争议、赔偿等您应以自己的名义独立承担所有法律责任。</div>
<div>4.3.拍品成交款、流拍、交易成功、订单关闭、退款成功等情况以柴门小童系统记录的数据及柴门小童的判断为准。如出现协议中止、终止或解除等情形的，均不影响乙方已经收取的技术服务费，各方同意乙方已经收取的技术服务费在任何情况下都不予退回。</div>
<div>4.4.柴门小童有权为您指定向用户收取拍卖成交款的唯一收款和支付通道，您不得以任何方式转移或者掩盖实际的拍品成交金额，达到逃避支付服务费的目的。</div>
<div>4.5.双方应于一个自然月结束后核对上月服务费金额，并于核对后的5个工作日内由乙方向您出具相应服务费发票。</div>
<div>5.保证金</div>
<div>5.1.您应当缴纳经营保证金及上拍技术服务费保证金，保证金的缴纳标准、时限、补缴、解冻、管理使用等以拍卖活动保证金规则的规定为准。前述保证金应缴纳于柴门小童指定您使用的收款和支付通道内，且冻结于您该通道的账户内。</div>
<div>6.违约责任</div>
<div>6.1.您明确理解并同意，如因您违反有关法律法规或者本协议之规定，使柴门小童遭受任何损失、受到用户、任何第三方的索赔或任何行政管理部门的处罚，您应对柴门小童进行全额赔偿，包括合理的律师费用。</div>
<div>6.2.除本协议另有约定外，任何一方违反其于本协议项下的陈述、承诺、保证或义务，而使另一方遭受任何诉讼、纠纷、索赔、处罚等的，违约方应负责解决，使另一方发生任何费用、额外责任或遭受经济损失的，应当负责赔偿。如一方发生违约行为，守约方可以书面通知方式要求违约方在指定的时限内停止违约行为，要求其消除影响。如违约方未能按时停止违约行为，则守约方有权立即终止本协议。如因柴门小童自身原因造成您的损失，柴门小童向您承担的最大总体责任和赔偿限额不应超过在本协议项下柴门小童已向您收取的技术服务费总额。</div>
<div>7.协议终止及转让</div>
<div>7.1.本协议在符合以下任意一项情形时终止：</div>
<div>7.1.1.自然终止：如您丧失拍卖经营资质，则本协议将同时终止；</div>
<div>7.1.2.通知解除：柴门小童可提前15天以书面通知的方式终止本协议而无须承担违约责任；</div>
<div>7.1.3.单方解除权：出现下列情况之一的，柴门小童有权立刻终止本协议，且按有关规则对您进行处罚：</div>
<div>（1）您违反本协议中的任何承诺或保证，包括但不限于本协议项下的任何约定；</div>
<div>（2）您违反柴门小童网的相关条款、协议、规则、通告等相关规定，而被柴门小童网终止提供服务的；</div>
<div>（3）您被柴门小童清退出拍卖活动的。</div>
<div>7.1.4.如非因柴门小童的原因，您未能按本协议之规定，按时全额支付有关技术服务费用，且在柴门小童规定的时限内仍未支付，柴门小童有权部分或全部中止或终止本协议；</div>
<div>7.1.5.本协议规定的其它协议终止条件发生或实现，导致本协议终止。</div>
<div>7.2.协议终止后有关事项的处理：</div>
<div>7.2.1.无论本协议因何原因终止，在协议终止前的行为所导致的任何责任，协议各方必须完全且独立地承担；</div>
<div>7.2.2.协议终止后，柴门小童有权保留您的注册数据及以前的交易行为记录。如您在协议终止前在柴门小童交易平台上存在违法行为或违反协议的行为，柴门小童仍可行使本协议所约定的权利；</div>
<div>7.2.3.协议终止之前：</div>
<div>（1）您已经发布至拍卖活动的拍品尚未达成交易的，柴门小童有权在本协议终止时同时删除此项拍品的相关信息；</div>
<div>（2）您已经与用户就拍品达成交易的，柴门小童可以不删除该项交易，但柴门小童有权在协议终止的同时将协议终止的情况通知该交易中的用户。</div>
<div>7.3.柴门小童有权将本协议项下的全部权利和义务转让予第三方，而无须征得您的同意，届时柴门小童将通过页面通知或公告等方式向您发出转让通知。</div>
<div>8.其他约定</div>
<div>8.1.柴门小童将在现有技术的基础上尽最大努力提供相应的安全措施以保障与拍卖有关的技术服务安全和正常运行。但由于可能存在的计算机病毒、网络通讯故障、浏览器兼容问题、系统维护等方面的因素以及可能发生的不可抗力事件，本平台在此明确声明对技术服务不作任何明示或暗示的保证，包括但不限于：对服务的可适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。对此您予以理解并不应要求柴门小童承担责任。</div>
<div>8.2.本协议适用中华人民共和国大陆地区法律。</div>
<div>8.3.您与竞拍人之间产生的以及因之而引起的争议，应由您自费与之解决，与柴门小童无关。您进一步同意保证柴门小童不会因您的拍卖活动而遭受任何损失，也不会承担任何法律责任。否则，您同意对柴门小童所受损失全额补偿。</div>
<div>8.4.因柴门小童与您就本协议的签订、履行或解释发生争议，双方应努力友好协商解决。如协商不成，柴门小童和您同意由柴门小童住所地法院管辖审理双方的纠纷或争议。</div>


<div>附件：拍卖商品上架规则</div>
<div>1.    商品：指您提供用于拍卖的产品、商品、物品、拍品或财产，应属于合法取得，不得侵犯任何第三方合法权益，包含产品附件、部件、随机文件资料、赠品、礼品、奖品、试用品和相关服务等。</div>
<div>2.    您不得拍卖法律、法规禁止流通的商品，所有权有争议的商品及/或处分权有限制或存有争议的商品。</div>
<div>3.    您作为专业拍卖机构，应审核其拍卖的商品，且均以您自身名义进行商品信息上传、展示、咨询答复、拍卖、发票开具、物流配送服务及售后服务提供等；您销售及服务出现争议、纠纷、国家机关机构调查时，由您以拍卖者身份处理。“柴门小童”不参与您拍卖业务中，除依据本协议相关约定外，也不直接介入您与其他人间的争议和纠纷。</div>
<div>4.    您应充分披露、如实描述所发布商品的情况，不得作虚假陈述。在拍卖程序结束之前，不得下架。</div>
<div>5.    如因您销售的商品、发布的信息或提供的售后服务问题而引发第三人（包括但不限于客户、您经营行为的受侵害者等）对“柴门小童”及/或“柴门小童平台”的投诉、诉讼以及引发的国家监管部门调查的，由您处理，柴门小童有权将您的主体身份资料、注册资料、联系方式、纳税有关的信息及交易信息等资料提供给投诉方或其它相关部门，您应承担因此而产生的全部法律责任，如因此而给“柴门小童”及/或“柴门小童平台”造成损失的，“柴门小童”有权要求您赔偿“柴门小童”及“柴门小童平台”的全部损失，柴门小童有权从商家的保证金、拍卖款中直接扣除。</div>
<div>6.    柴门小童以普通非专业人员的知识水平标准对相关内容进行判别，可以认为这些商品、内容或行为具有违法或不当性质的，柴门小童有权根据不同情况选择删除相关信息、对商家店铺采取限制性措施或停止对该商家提供服务。</div>
<div>7.    您不得做出任何违反中国国家法律、法规及政策的行为，且可能给柴门小童带来重大损害（包括商誉的损害），否则视为您严重违约，甲方有权采取商品立即下架、暂停向您提供服务、暂时关闭您后台管理账户、通知网银在线冻结商家柴门小童钱包账户、终止合作等措施，且甲方无需承担任何责任，您因此给甲方造成损失的，您应承担赔偿责任。</div>
<div>您尤其保证遵守《反恐怖主义法》、《网络安全法》、《广告法》、《互联网信息服务管理办法》、《计算机信息网络国际联网安全保护管理办法》、《枪支管理法》、《民用爆炸物品安全管理条例》、《危险化学品安全管理条例》和《互联网危险物品信息发布管理规定》等法律法规和政策文件的规定，遵守柴门小童平台相应的规则，不会在在柴门小童平台发布上述危险物品信息、交易上述物品。，否则视为您严重违约，甲方有权采取商品立即下架、暂停向您提供服务、暂时关闭您后台管理账户、通知网银在线冻结商家柴门小童钱包账户、终止合作等措施，且甲方无需承担任何责任，您因此给甲方造成损失的，您应承担赔偿责任。</div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'enter',
    data() {
        return {
            mar_top:""
        }
    },
    methods: {
        back() {
        this.$router.go(-1)
    },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 

.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
/deep/ .van-step--horizontal .van-step__circle-container {
    background-color: rgba(0, 0, 0, 0);
}
.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-size: 14px;
    
    .box {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        .flex {
            display: flex;
            align-items: center;
        }
    }
    .box1 {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/deep/.van-step__circle  {
    width: 10px;
    height: 10px;
    background: rgba(97, 76, 47, 0.15); 
}
.wuliu_box {
    height: 300px;
    padding: 20px;
    box-sizing: border-box; 
    background-color: #E9D4BC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
}
.btn {
        width: 100%;
        border-radius: 5px;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        margin: 10px 0;
    }
    .paycss {
        width: 100px;
        height: 35px;
        border-radius: 8px;
        line-height: 35px;
        text-align: center;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        
    }
    .input_fild {
        margin-top: 10px;
        padding:10px 15px;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15);
        border-radius: 8px;
        color: #000;
    }
    :deep(.van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
