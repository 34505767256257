<template>
    <div class="page" >
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="订单详情" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <div class="box">
                <div class="flex" style="justify-content: space-between;">
                            <div class="flex"></div>
                           <div style="color: #475AD5;" v-if="info.status == 0">待付款</div>
                           <div style="color: rgba(55,38,27,0.33);" v-if="info.status == 1">交易关闭</div>
                           <div style="color: #475AD5;" v-if="info.status == 2">待发货</div>
                           <div style="color: #475AD5;" v-if="info.status == 3">支付失败</div>
                           <div style="color: #475AD5;" v-if="info.status == 8">待收货</div>
                           <div style="color: #475AD5;" v-if="info.status == 10">交易成功</div>
                           <div style="color: #475AD5;" v-if="info.status == 11">待开票</div>
                           <div style="color: #475AD5;" v-if="info.status == 12">已开票</div>
                       </div>
                <div style="height: 1px;width: 100%;background-color:#DFC09C;margin: 10px 0;"></div>
                <div v-for="items in info.orderItemList" class="flex" style="flex-direction: column;">
                    <div class="flex" style="justify-content: space-between;">
                        <div class="flex">
                        <img :src="items.coverImage" class="imgs">
                        <div class="goodsn">
                            <div style="font-size: 15px;" class="t">{{items.goodsName}}</div>
                            <div style="font-size: 14px;color: rgba(55,38,27,0.33);display: flex;margin-right: 10px;">
                                <div  v-for="guige in items.specificationItemNameList">{{guige}}</div>
                            </div>
                        </div>
                        </div>
                        <div style="height: 60px;display: flex;flex-direction: column;justify-content: space-around;">
                            <div style="font-size: 18px;color: #475AD5;">¥{{ items.amount }}</div>
                            <div style="font-size: 14px;color: rgba(55,38,27,0.33);" >x{{items.num}}</div>
                        </div>
                    </div>
                    <div style="height: 1px;width: 100%;background-color:#DFC09C;margin: 10px 0;"></div>
                </div>
                <!-- 未支付显示 -->
                <div style="font-size: 12px;color: #B10808;" >
                    <template v-if="info.status == 0">
                        <div class="flex">
                            <span style="margin-right: 10px;">剩余支付时间</span>
                            <van-count-down  @finish="finshfun" style="color: #B10808;" :time="info.residuePayMilliSecond" />
                        </div>
                    </template>
                </div> 
            </div>
            <div class="flex bt" style="margin: 10px 0;">
                <div>订单号</div>
                <div style="margin-left: 30px;">{{info.bizOrderNo}}</div>
            </div>
            <div class="flex bt" style="margin: 10px 0;">
                <div>商品总价</div>
                <div style="margin-left: 30px;">¥{{info.amount}}</div>
            </div>
            <div class="flex bt" style="margin: 10px 0;">
                <div>运费</div>
                <div style="margin-left: 30px;">¥{{info.expressAmount}}</div>
            </div>

            <div style="height: 1px;width: 100%;background-color: rgba(223,192,156,0.66);margin: 10px 0;"></div>

            <div class="flex bt" style="margin: 10px 0;">
                <div>创建时间</div>
                <div style="margin-left: 30px;">{{info.createTime}}</div>
            </div>
            <div v-if="info.cancelTime" class="flex bt" style="margin: 10px 0;">
                <div>取消时间</div>
                <div style="margin-left: 30px;">{{info.cancelTime}}</div>
            </div>
            <div v-if="info.payWay" class="flex bt" style="margin: 10px 0;">
                <div>付款方式</div>
                <div style="margin-left: 30px;">{{info.payWay == 0 ? '微信' :info.payWay == 1 ? '支付宝' : ''}}</div>
            </div>
            <div v-if="info.payTime" class="flex bt" style="margin: 10px 0;">
                <div>付款时间</div>
                <div style="margin-left: 30px;">{{info.payTime}}</div>
            </div>
            <div v-if="info.deliveryTime" class="flex bt" style="margin: 10px 0;">
                <div>发货时间</div>
                <div style="margin-left: 30px;">{{info.deliveryTime}}</div>
            </div>
            <div class="flex bt" style="margin: 10px 0;">
                <div>更新时间</div>
                <div style="margin-left: 30px;">{{info.updateTime}}</div>
            </div>
            <div class="flex p" style="justify-content: space-between;">
                <div v-if="info.status == 0 " class="same b" @click="cancelfun">取消</div>
                <!-- <div v-if="info.status == 0 " class="same a" @click="payfun">支付</div> -->
                <div v-if="info.status == 1 " style="width: 100%;" class="same b" @click="deletefun">删除</div>
                <div v-if="info.status == 2 " style="width: 100%;" class="same a" @click="showwuliu = true">填写物流单号</div>
                <div v-if="info.status == 8 " style="width: 100%;" class="same a" @click="watchwuliu">查看物流</div>
                <div v-if="info.status == 10 "  class="same a" @click="watchwuliu">查看物流</div>
                <div v-if="info.status == 11 " style="width: 100%;"  class="same a" @click="fp = true">上传发票</div>
                <div v-if="info.status == 12 " style="width: 100%;"  class="same a" @click="previewImage(info.invoice.invoiceUrl)">查看发票</div>
            </div>
            <!-- 填写物流单号弹窗 -->
            <van-popup round v-model="showwuliu" position="bottom">
                <div class="wuliu_box">
                    <div style="font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;">填写物流单号</div> 
                    <div class="flex" style="margin: 20px 0 ;font-size: 16px;color: #000;">
                        <div>物流公司<span style="margin-left: 4px;color: #B20A09;">*</span></div>
                        <el-select class="el_selects" @change="changefun" v-model="value" filterable placeholder="请选择快递">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex" style="margin: 20px 0 ;font-size: 16px;color: #000;">
                        <div>快递单号<span style="margin-left: 4px;color: #B20A09;">*</span></div>
                        <el-input  style="width: 60%;margin-left: 20px;" class="iptql" v-model="wuliunum" placeholder="请输入内容"></el-input>
                    </div>
                    <div class="wuliu_btn" @click="determinewuliu">确认</div>
                </div>
            </van-popup>
            <!-- 填写上传发票弹窗 -->
            <van-popup round v-model="fp" position="bottom">
                <div class="wuliu_box">
                    <div style="font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;">上传发票</div> 
                    <div class="flex" style="margin: 20px 0 ;font-size: 16px;color: #000;">
                        <van-image width="76" height="76" :src="imgUrl" @click="upload_fp"/>
                        <van-image v-if="fp_pic" width="76" height="76" :src="fp_pic" style="margin-left: 5px;"/>
                    </div>
                    <div class="wuliu_btn" @click="fpup">确认</div>
                </div>
            </van-popup>
        </div> 
    </div>
</template>
<script>
import { Toast ,ImagePreview,Dialog} from 'vant';
import {matchComAndNum,deliverGoodsOrder,uploadInvoice } from '@/api/order';
import {userCancelOrder,deleteOrder,queryOrderInfoById} from '@/api/myorder';
export default {
    name: 'order',
    data() {
        return {
            mar_top:'',
            options: [
                {value:'yuantong',label:'圆通速递'},
                {value:'yunda',label:'韵达快递'},
                {value:'zhongtong',label:'中通快递'},
                {value:'jtexpress',label:'极兔速递'},
                {value:'shentong',label:'申通快递'},
                {value:'youzhengguonei',label:'邮政快递包裹'},
                {value:'shunfeng',label:'顺丰速运'},
                {value:'jd',label:'京东物流'},
                {value:'ems',label:'EMS'},
                {value:'youzhengdsbk',label:'邮政电商标快'},
                {value:'youzhengbk',label:'邮政标准快递'},
                {value:'debangkuaidi',label:'德邦快递'},
                {value:'danniao',label:'菜鸟速递'},
                {value:'xinfengwuliu',label:'信丰物流'},
                {value:'debangwuliu',label:'德邦物流'},
                {value:'zhongtongkuaiyun',label:'中通快运'},
                {value:'rrs',label:'日日顺物流'},
                {value:'jingdongkuaiyun',label:'京东快运'},
                {value:'shunfengkuaiyun',label:'顺丰快运'},
                {value:'kuayue',label:'跨越速运'},
                {value:'annengwuliu',label:'安能快运'},
                {value:'baishiwuliu',label:'百世快运'},
                {value:'sxjdfreight',label:'顺心捷达'},
                {value:'jinguangsudikuaijian',label:'京广速递'},
                {value:'yimidida',label:'壹米滴答'},
                {value:'yundakuaiyun',label:'韵达快运'},
                {value:'subida',label:'速必达'},
                {value:'usps',label:'USPS'},
                {value:'youshuwuliu',label:'优速'},
                {value:'annto',label:'安得物流'},
                {value:'pingandatengfei',label:'平安达腾飞'},
                {value:'zhongtongguoji',label:'中通国际'},
                {value:'jiayunmeiwuliu',label:'加运美'},
                {value:'cainiaodajian',label:'菜鸟大件'},
                {value:'dhl',label:'DHL-中国件'},
                {value:'savor',label:'海信物流'},
                {value:'ups',label:'UPS'},
                {value:'zhaijisong',label:'宅急送'},
                {value:'upsen',label:'UPS-全球件'},
                {value:'ztky',label:'中铁快运'},
                {value:'suning',label:'苏宁物流'},
                {value:'suteng',label:'速腾快递'},
                {value:'fedexcn',label:'Fedex-国际件-中文'},
                {value:'ewe',label:'EWE全球快递'},
                {value:'stosolution',label:'申通国际'},
                {value:'huitongkuaidi',label:'百世快递'},
                {value:'shunfenglengyun',label:'顺丰冷链'},
                {value:'fedex',label:'FedEx-国际件'},
                {value:'huisenky',label:'汇森速运'},
                {value:'exfresh',label:'安鲜达'},
                {value:'disifang',label:'递四方'},
                {value:'quantong56',label:'全通速递'},
                {value:'youzhengguoji',label:'国际包裹'},
                {value:'flextock',label:'Flextock'},
                {value:'dhlen',label:'DHL-全球件'},
                {value:'zhongtiewuliu',label:'中铁飞豹'},
                {value:'longjun',label:'龙俊物流'},
                {value:'emswuliu',label:'EMS物流'},
                {value:'chinapost',label:'中国邮政（CHINA POST）'},
                {value:'ane66',label:'安能快递'},
                {value:'yuxinwuliu',label:'宇鑫物流'},
                {value:'shpost',label:'同城快寄'},
                {value:'emsbg',label:'EMS包裹'},
                {value:'yuantongguoji',label:'圆通国际'},
                {value:'emsguoji',label:'EMS-国际件'},
                {value:'ttsucha',label:'天天速查'},
                {value:'wooolink',label:'上海领链物流'},
            ],
            info:{},
            wuliulist:[],
            company_name:'',
            showwuliu:false,
            wuliunum:'',
            wuliu_name:"",
            value:"",
            showmore:false,
            did:"",
            fp:false,
            imgUrl:require('/src/assets/pic_img.png'),
            fp_pic:'',
        }
    },
    methods: {
        //查看发票
        previewImage(e){
            ImagePreview([e])
        },
        // 取消订单
        cancelfun(){
            Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: '是否取消此订单',
                })
                .then(async() => {
                    const res =  await userCancelOrder({id:this.info.id})
                    console.log("取消订单",res);
                    Toast('操作成功')
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 300);
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 支付
        payfun(){},
        // 删除
        deletefun(){
            Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: '是否删除此订单',
                })
                .then( async() => {
                    const res =  await deleteOrder({orderId:this.info.id})
                    console.log("删除",res);
                        Toast('操作成功')
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 300);
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 申请退款
        applyfun(){
            this.$router.replace({ name: 'apply' ,params:{info:this.info,isfirst:1}})
        },
        changefun(e){
            console.log(e);
            for (let i = 0; i < this.options.length; i++) {
                if(this.options[i].value == e) {
                    this.wuliu_name = this.options[i].label
                }
            }
            console.log("物流公司名称",this.wuliu_name);
        },
        // 填写物流单号弹窗确定
        async determinewuliu(){
            console.log(this.value , this.wuliunum);
            if(this.value && this.wuliunum) {
                console.log("物流公司编码",this.value);
                console.log("物流单号",this.wuliunum);
                const res = await matchComAndNum({ 
                    com: this.value,
                    num: this.wuliunum,
                });
                console.log("检查物流单号和物流公司是否匹配",res);
                if(res.data) {
                    const res1 = await deliverGoodsOrder({ 
                        id: this.info.id,
                        logisticsNumber: this.wuliunum,
                        logisticsCompany: this.value
                    });
                    console.log("订单发货",res1);
                    this.showwuliu = false
                    Toast('操作成功')
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 300);

                } else {
                    Toast('物流单号和快递公司不匹配')
                }
            } else {
                Toast('请填写完整信息')
            }

        },
        // 跳转物流
        watchwuliu(){
            if(this.info.logisticsCompany == null || this.info.logisticsNumber == null) {
                Toast('暂无物流信息')
            } else {
                // this.$router.push({ name: 'logistics' ,params:{info:e,issales:3}});
                this.$router.replace({ name: 'wuliu' ,params:{info:this.info}});
            }
        },
        //上传发票
        upload_fp(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'upload_fpfun'
            }
            window.ssmAndroid.ssmMessage(JSON.stringify(data))
         },
         upload_fpfun(e){
            this.fp_pic = e.data[0]
         },
        // 上传发票确认
        async fpup(){
            if(this.fp_pic == '') {
                Toast('发票图片不能为空')
            } else {
            const res = await uploadInvoice({
                id:this.info.invoiceId,
                invoiceUrl:this.fp_pic
            })
                if(res.code == 200) {
                    Toast('操作成功')
                    this.fp = false
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 300);
                }
            }
        },
        async finshfun(){
            await this.getdetail()
        },
        back() {
            this.$router.go(-1);
        },
         // 查询物流信息
         async getinfo() {
            const res = await query({ 
                bizOrderNo: this.bizOrderNo,
            });
            this.wuliulist = res.data.dataList.reverse()
            console.log("查询物流信息",this.wuliulist);

        },
        async getdetail(){
            const res = await queryOrderInfoById({ 
            orderId: this.did,
            });
            console.log("订单详情",res);
            
        this.info = res.data
        },
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    
    async mounted() {
        window.upload_fpfun = this.upload_fpfun
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log("this.$route.params",this.$route.params.info);
        this.did = this.$route.params.info.id
        await this.getdetail()
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.bt {
    justify-content: space-between;
}
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .box {
        padding: 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 7px 0;
    }
    .flex {
        display: flex;
        align-items: center;
        color: rgba(55,38,27,0.66);
        .img {
           width: 24px;
           height: 24px;
           border-radius: 12px; 
        }
        .imgs {
            width: 60px;
            height: 60px;
            border-radius: 8px; 
        }
        .goodsn {
            width: 190px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .t {
            // width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .p {
        width: 94%;
        position: fixed;
        left:50%;
        bottom: 50px;
        transform: translate(-50%,0);
    }
    .same {
        
        width: 48%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        font-size: 18px;
    }
    .a {
        background-color: #876235 ;
        color: #fff;
    }
    .b {
        background-color: rgba(174,133,81,0.15);
        color: #000;
    }
        .cancel {
            border: 1px solid rgba(55,38,27,0.33);
            color: rgba(55,38,27,0.5);
        }
        .sub {
            background: #876235;
            color: #fff;
        }
        .recive {
            background-color: #475AD5;
            color: #fff;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tosat {
            width: 300px;
            height: 180px;
            border-radius: 8px;
            background-color: #E9D4BC;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }
    .res {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        height: 52px;
        border-radius: 8px;
        background-color: rgba(174,133,81,0.15);
        margin-top: 10px;
    }
    .ipt {
        border: none;
        background: rgba(0, 0, 0, 0);
    }
    .te {
        border-radius: 8px;
        margin: 10px 0;
        background-color: rgba(174,133,81,0.15);
    }
    .p {
        width: 94%;
        position: fixed;
        left:50%;
        bottom: 50px;
        transform: translate(-50%,0);
    }
    .same {
        
        width: 48%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        font-size: 18px;
    }
    .a {
        background-color: #876235 ;
        color: #fff;
    }
    .b {
        background-color: rgba(174,133,81,0.15);
        color: #000;
    }
    .wuliu_box {
        height: 300px;
        padding: 20px;
        box-sizing: border-box; 
        background-color: #E9D4BC;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
        .el_selects {
        width: 60%;
        margin-left: 20px;
    }
    .el_selects /deep/ .el-input__inner {
        background: rgba(174,133,81,0.15);  /* 背景色 */
        border-color: rgba(174,133,81,0.15); /* 边框色 */
        color: 000 /* 文字颜色 */ 
        /* 添加其他需要修改的样式 */
    }

    /deep/ .el-select .el-input__inner::placeholder,
    /deep/ .el-date-picker .el-input__inner::placeholder,
    /deep/ .el-input .el-input__inner::placeholder {
        color:#A8937C;
    }
    .iptql /deep/ .el-input__inner{
        background: rgba(174,133,81,0.15);  /* 背景色 */
        border-color: rgba(174,133,81,0.15); /* 边框色 */
        color: 000 /* 文字颜色 */ 
    }
    .wuliu_btn {
        width: 100%;
        height: 48px;
        border-radius: 12px;
        font-size: 18px;
        background-color: #876235 ;
        color: #fff;
        text-align: center;
        line-height: 48px;
        margin: 20px auto;
    }
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
