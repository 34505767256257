<template>
  <div class="labelText">
    <canvas ref="labelText"></canvas>
    <div class="text">立即加入</div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {
    var canvas = this.$refs.labelText;
    canvas.width = 62;
    canvas.height = 24;
    var ctx = canvas.getContext("2d");
    // 开启抗锯齿效果
    ctx.imageSmoothingEnabled = true;
    ctx.webkitImageSmoothingEnabled = true;
    ctx.mozImageSmoothingEnabled = true;
    ctx.msImageSmoothingEnabled = true;
    ctx.oImageSmoothingEnabled = true;
    let realWidth = 62
    let realHeight = 24
    let rightTopRadius = 6
    let rightBottomRadius = 6
    let leftBottomRadius = 6
    let leftTopRadius = 6
    var path = new Path2D();
    path.moveTo(leftTopRadius, 0);
    path.lineTo(realWidth - rightTopRadius, 0);
    path.quadraticCurveTo(realWidth - rightTopRadius / 2, 0, realWidth - rightTopRadius / 2, rightTopRadius / 2);
    path.quadraticCurveTo(realWidth, rightTopRadius / 2, realWidth, rightTopRadius);
    path.lineTo(realWidth, realHeight - rightTopRadius);
    path.quadraticCurveTo(realWidth, realHeight - rightTopRadius / 2, realWidth - rightBottomRadius / 2, realHeight - rightTopRadius / 2);
    path.quadraticCurveTo(realWidth - rightBottomRadius / 2, realHeight, realWidth - rightBottomRadius, realHeight);
    path.lineTo(leftBottomRadius, realHeight);
    path.quadraticCurveTo(leftBottomRadius / 2,
        realHeight,
        leftBottomRadius / 2,
        realHeight - leftBottomRadius / 2);
    path.quadraticCurveTo(0, realHeight - leftBottomRadius / 2, 0, realHeight - leftBottomRadius);
    path.lineTo(0, leftTopRadius);
    path.quadraticCurveTo(0, leftBottomRadius / 2, leftBottomRadius / 2, leftBottomRadius / 2);
    path.quadraticCurveTo(leftBottomRadius / 2, 0, leftBottomRadius, 0);
    path.closePath();
    ctx.strokeStyle = "#384594"
    ctx.stroke(path);
    ctx.fillStyle = "#384594";
    ctx.fill(path);
  }
}

</script>
<style lang="less" scoped>
.labelText {
  position: relative;

  .text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    color: #fff;
    text-align: center;
    line-height: 22px;
    font-size: 12px;
    font-weight: 400;
  }
}
</style>
