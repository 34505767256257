import request from "@/utils/request";

// 首次提交入驻信息审核
export const submitApproveFirst = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/enter/submitApproveFirst`,
        method: "POST",
        data
    })
}
// 修改入驻信息审核
export const updateAndSubmit = (data) => {
    return request({
        url: `ssm-merchants//api/merchants/enter/updateAndSubmit`,
        method: "POST",
        data
    })
}
// 查询我的入驻信息
export const fetchEnterInfo = (data) => {
    return request({
        url: "ssm-merchants/api/merchants/enter/findByUser",
        method: "POST",
        data
    })
}
//更新实名认证信息
export const updateFaceRecognition = (data) => {
    return request({
        url: "ssm-merchants/api/merchants/enter/updateFaceRecognition",
        method: "POST",
        data
    })
}
//姓名身份证校验
export const otherId2Verify = (data) => {
    return request({
        url: "ssm-app/api/app/aliIdVerify/otherId2Verify",
        method: "POST",
        data
    })
}