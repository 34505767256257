<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" left-arrow title="商家入驻协议" @click-left="back" />
        </van-sticky>
        <div class="container" style="ont-size: 15px;padding: 0 10px;box-sizing: border-box;margin-top: 20px;">
        </div>
        <div style="color: rgba(55,38,27,0.5);position: relative;bottom: 20px;left: 0;font-size: 15px;padding: 10px 20px;box-sizing: border-box;margin-top: 20px;">
            <div>柴门小童交易规则</div>

<div>版本号：V1.0</div>
<div>发布日期：2025年1月1日</div>
<div>施行日期：2025年1月1日</div>

<div>以下订单规则、发票规则和评价管理规则适用于在柴门小童平台下单购买商品及购买后的发票开具与评价活动。消费者（或称“您”）若在柴门小童平台购买商品，应同意遵守本规则。消费者在柴门小童平台购买商品，视为接受本规则并受之约束。</div>

<div>本规则中，柴门小童或柴门小童平台，指由上海声声曼电子商务有限公司运营的电子商务平台。</div>

<div>本规则于2025年1月1日起施行。由柴门小童平台根据实际执行情况不时调整并公布。新公布的版本将适用其指定范围的交易。</div>

<div>订单规则</div>
<div>1. 下单规则</div>
<div>（1）已下单的商品请在柴门小童提示的时间内点击付款，如您在柴门小童提示的时间内未进行付款的，订单将会自动关闭；如相关商品柴门小童另有规定的，以具体规定为准。</div>
<div>（2）当出现订单信息填写不完整、商品无货、无法配送、地址信息不匹配等情形时，订单有可能无法提交成功。</div>
<div>2. 订单查询</div>
<div>生成订单后，您可随时登录柴门小童，打开“我的订单”或相关订单查询页面，查询订单信息。</div>
<div>3. 修改订单</div>
<div>（1）有品配送的商品，在您选购的订单发货之前，您可在我的订单-待收货订单中修改订单收货地址，您仅有一次修改订单收货地址的机会；由商家自行配送的商品，您无法自行修改收货地址/联系方式，若您需要更改，请在订单发货前联系商家客服。如柴门小童订单规则另有规定的，以具体规定为准。</div>
<div>（2）如您需要修改订单的，请按照柴门小童页面提示填写信息，填写完毕点击确认提交后，经系统确认的，视为您的订单修改完成。</div>
<div>（3）如您的商品订单页面没有显示修改订单按钮，说明您的订单无法进行修改。当您点击修改按钮时，系统需要一定的时间锁定您的订单，如在此期间您的订单已进入发货流程，您有可能无法对订单进行修改。</div>
<div>4. 取消订单</div>
<div>（1）订单发货之前，您可以在订单页面点击取消订单按钮，经系统确认后，订单取消。订单取消后如您需要再次购买订单商品的，请您重新下单。</div>
<div>（2）订单取消后，您已经支付的款项将退还至您的付款账户，退款过程中如银行或第三方支付机构向您收取任何手续费或其他费用，应由您自行承担。如您全部、部分使用优惠券购买柴门小童商品，优惠券将无法退还，具体商品另有规定的除外。</div>
<div>（3）如您的商品订单页面没有取消订单的按钮，说明您的订单已经进入了发货流程，您的订单将无法取消。</div>
<div>5. 订单拒收</div>
<div>订单商品到达您指定的收货地址后，如您拒绝签收或无法联系到您本人进行签收，视为订单拒收。自营商品订单拒收的，柴门小童将会取消该订单并退还您已经支付的款项，第三方商家商品订单拒收的，需要申请退货，请您按照柴门小童的流程办理。</div>
<div>6. 订单异常</div>
<div>如您的订单出现订单状态长时间没有变化、无法查询到订单信息、收到的商品与订单不符等情况，请您联系柴门小童客服进行处理。</div>

<div>发票规则</div>
<div>7. 自营商品发票规则</div>
<div>（1）开具的发票为电子发票，均为真实有效的合法发票，与传统纸质发票具有同等法律效力，可作为用户维权、保修的有效凭据。</div>
<div>（2）开具发票时，您应按照系统及相关法律要求，填写各类发票信息（如单位名称和纳税人识别号等信息），否则将无法为您开具发票。</div>
<div>（3）发票的金额为您实际支付的商品金额，不包含优惠券、礼品券、抵扣券或其他柴门小童给与您的优惠部分的价格。</div>
<div>（4）您的电子发票开具完成后，您可以登录个人账户，在订单页面查看和下载电子发票。</div>
<div>（5）如您需要退货，您应提供商家对您开具的发票，如开具的增值税专用发票丢失的，您需按照相关法律法规要求提供有关文件，否则可能无法为您办理退货。如您开具电子发票后申请退货，原电子发票会通过系统自动冲红（电子发票显示无效），如您只是申请订单中部分商品退货，商家将对未发生退货部分的商品重新开具发票。换货无需退回发票，原发票继续有效。</div>
<div>8. 第三方商家商品发票规则</div>
<div>（1）开具发票形式以第三方商家说明为准。</div>
<div>（2）您有如下方式可申请开具发票：</div>
<div>(i)您在下单流程中申请开具发票，并提供开票所需的抬头、纳税人识别号及国家规定开具发票需要的其他信息，如开具的是电子发票的，还需留下联系电话、电子邮箱，以便与您沟通联系；</div>
<div>(ii)您可收货后在柴门小童页面在线或电话联系商家客服开具发票；</div>
<div>（3）您的电子发票开具完成后，您可以登录个人账户，在订单页面查看和下载电子发票。</div>

<div>评价管理规则</div>
<div>9.适用范围</div>
<div>本规则适用于柴门小童平台的所有商家和消费者。</div>
<div>10.定义</div>
<div>10.1评价：指消费者在订单交易完成后，交易双方可以对交易的订单进行的评价，评价也包括柴门小童商家对消费者评价的回复。</div>
<div>10.2商品评价：指消费者针对订单商品给出的评分、评价和晒单等内容，包括对商品本身以及柴门小童或第三方商家提供的服务。</div>
<div>10.3恶意评价：指消费者或商家使用侮辱性、诽谤性语言或者明显违背事实的内容进行的评价或者其他违反法律规定的评价。</div>
<div>11.评价管理及要求</div>
<div>11.1评价原则：消费者与商家发布评价应当客观、真实、合法，且与交易的商品或服务具有关联性，不得利用评价侵害相关方合法权益或获取不正当利益，不得虚构评价破坏有品平台信用评价体系。</div>
<div>11.2评价时间：消费者可对确认收货之日起60天内的商品订单进行评价。如消费者超过60天未作出评价，则系统将在前端显示“该用户未及时做出评价，系统默认评价”。系统默认评价不会影响商家店铺的综合评分和满意度。</div>
<div>11.3评价注意事项：</div>
<div>11.3.1同一订单消费者晒图最多可上传6张图片。</div>
<div>11.3.2消费者对于商品订单的评价一经作出则无法修改。</div>
<div>11.4追加评论：消费者可在确认收货之日起180天内追加评论。追加评论的机会仅有一次，可在原评论下追加，也可在商家的回复下追加。追加评论的内容不得修改，也不会影响商家店铺的综合评分和满意度。系统默认评价后，消费者亦可以发表追评。</div>
<div>11.5评价及回复内容要求：</div>
<div>商家或消费者发布的评价内容、晒图和回复中不得出现法律法规、平台规则不允许的内容，包括但不限于：</div>
<div>（1）危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、损害国家荣誉和利益的；煽动民族仇恨或歧视、破坏民族团结的；破坏国家宗教政策，宣扬邪教和封建迷信的；散布谣言，扰乱社会秩序，破坏社会稳定的；散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；侮辱或者诽谤他人，侵害他人合法权益（包括但不限于知识产权）、泄露他人个人信息；含有病毒或其他恶意扣费代码的网页或软件内容的；</div>
<div>（2）利用评价展示非柴门小童的其他商品、商家信息或广告宣传信息的，包括但不仅限于店铺、商品链接、联系信息等；</div>
<div>（3）评价图片、视频与评价商品无关或图片、视频不清晰的；</div>
<div>（4）未经他人同意，使用第三人拥有著作权的作品或使用他人图片并编辑后发布的；</div>
<div>（5）评价与所购买的商品或服务无关的，包含但不限于随机中文、乱码、符号、图片、段子、歌词、诗歌、新闻等与商品无关的内容；</div>
<div>（6）恶意购买人的评价；</div>
<div>（7）其他涉嫌违法违规或侵犯柴门小童及第三方合法权益的情形。</div>
<div>11.6违规评价处理：</div>
<div>11.6.1如果消费者或商家违反本规则规定进行恶意评价的，商家或消费者向柴门小童发起投诉，柴门小童将根据投诉人提供的证明材料进行审核，经柴门小童判定为恶意评价的，柴门小童有权屏蔽或隐藏该评价。</div>
<div>11.6.2柴门小童有权隐藏违规交易产生的评价，包括但不限于发布违禁信息、骗取他人财物、虚假交易等违规行为所涉及的订单对应的评价。柴门小童有权对排查到的评价进行屏蔽、隐藏等处理。</div>
<div>12.评价侵权处理</div>
<div>12.1 因消费者或商家发布的评论包含的图片、文字或其他任何信息，导致任何第三方将柴门小童起诉至法院、投诉或举报至相关部门，或对柴门小童提起仲裁的，柴门小童有权按照法律规定，根据人民法院、行政机关、仲裁机关的要求公布涉案消费者、商家的相关信息。</div>
<div>12.2 因消费者或商家违反本规则规定，导致柴门小童遭受损失的，柴门小童有权要求商家或消费者赔偿因此给柴门小童造成的损失，包括但不限于柴门小童因此需要赔偿的损失、行政机关的罚款、律师费、公证费以及其他相关费用。</div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'enter',
    data() {
        return {
            mar_top:""
        }
    },
    methods: {
        back() {
        this.$router.go(-1)
    },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 

.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
/deep/ .van-step--horizontal .van-step__circle-container {
    background-color: rgba(0, 0, 0, 0);
}
.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-size: 14px;
    
    .box {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        .flex {
            display: flex;
            align-items: center;
        }
    }
    .box1 {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/deep/.van-step__circle  {
    width: 10px;
    height: 10px;
    background: rgba(97, 76, 47, 0.15); 
}
.wuliu_box {
    height: 300px;
    padding: 20px;
    box-sizing: border-box; 
    background-color: #E9D4BC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
}
.btn {
        width: 100%;
        border-radius: 5px;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        margin: 10px 0;
    }
    .paycss {
        width: 100px;
        height: 35px;
        border-radius: 8px;
        line-height: 35px;
        text-align: center;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        
    }
    .input_fild {
        margin-top: 10px;
        padding:10px 15px;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15);
        border-radius: 8px;
        color: #000;
    }
    :deep(.van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
