<template>
    <div>
        <van-sticky>
            <van-nav-bar left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <span class="row-item">
                <div class="row-item-name">企业/公司身份</div>
            </span>
            <van-form ref="enterForm" validate-first label-width="120">
                <van-field v-model="companyDesc" name="companyDesc" label="公司/企业名称" placeholder="请输入公司/企业名称" maxlength="10"
                :rules="[{ required: true, message: '请输入公司/企业名称' }]" />
                <van-field v-model="name" name="name" label="姓名" placeholder="请输入姓名" maxlength="10"
                    :rules="[{ required: true, message: '请输入姓名' }]" />

                <!-- <van-field v-model="idTypeName" name="idType" label="证件类型" readonly /> -->

                <!-- <van-field name="idUrl" label="营业执照照片">
                    <template #input>
                        <template v-if="idUrl.length > 0">
                            <van-image v-for="item in idUrl" style="margin-right: 8px;" width="50" height="50"
                                :src="item" @click="previewImage('idUrl')" />
                        </template>
                        <van-button icon="plus" size="mini" />
                    </template>
                </van-field> -->

                <!-- <van-field v-model="idType" name="idType" label="证件类型" placeholder="请输入证件类型" maxlength="10"
                    :rules="[{ required: true, message: '请输入证件类型' }]" /> -->
                    <!-- <van-field v-model="idType"  label="证件类型" readonly  aria-disabled  maxlength="10" />
                <van-popup v-model="showPicker" position="bottom">
                        <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
                    </van-popup> -->
                    <van-field v-model="idType"  label="法人证件类型" readonly  aria-disabled  maxlength="10" />

                    <van-field name="idUrl" label="法人证件图" :rules="[{ required: true, message: '上传法人证件图' }]">
                    <template #input>
                        <template v-if="idUrl.length > 0">
                            <van-image v-for="item in idUrl" style="margin-right: 8px;" width="50" height="50" :src="item"
                                @click="previewImage('idUrl')" />
                        </template>
                        <van-button icon="plus" size="mini" />
                    </template>
                </van-field>

                <van-field name="idNumber" label="证件号" placeholder="请输入法人证件号" readonly clickable :value="idNumber"
                    maxlength="18" @touchstart.native.stop="idNumberShow = true"
                    :rules="[{ required: true, message: '请输入法人证件号' }]" />

                <!-- <van-field name="idUrl" label="证件照片">
                    <template #input>
                        <template v-if="idUrl.length > 0">
                            <van-image v-for="item in idUrl" style="margin-right: 8px;" width="50" height="50"
                                :src="item" @click="previewImage('idUrl')" />
                        </template>
                        <van-button icon="plus" size="mini" />
                    </template>
                </van-field> -->
                <van-field name="yingyepic" label="营业执照照片">
                    <template #input>
                        <template v-if="businessLicense.length > 0">
                            <van-image v-for="item in businessLicense" style="margin-right: 8px;" width="50" height="50"
                                :src="item" @click="previewImage('businessLicense')" />
                        </template>
                        <van-button icon="plus" size="mini" />
                    </template>
                </van-field>

                <van-field name="auctionFlag" label="是否参与拍卖">
                    <template #input>
                        <van-switch v-model="auctionFlag" size="20" />
                    </template>
                </van-field>

                <van-field name="auctionLicense" label="拍卖许可证">
                    <template #input>
                        <template v-if="auctionLicense.length > 0">
                            <van-image v-for="item in auctionLicense" style="margin-right: 8px;" width="50" height="50"
                                :src="item" @click="previewImage('auctionLicense')" />
                        </template>
                        <van-button icon="plus" size="mini" />
                    </template>
                </van-field>

                <van-field name="culturalAuctionFlag" label="是否文物拍卖">
                    <template #input>
                        <van-switch v-model="culturalAuctionFlag" size="20" />
                    </template>
                </van-field>

                <van-field name="culturalAuctionLicense" label="文物拍卖许可证">
                    <template #input>
                        <template v-if="culturalAuctionLicense.length > 0">
                            <van-image v-for="item in culturalAuctionLicense" style="margin-right: 8px;" width="50"
                                height="50" :src="item" @click="previewImage('culturalAuctionLicense')" />
                        </template>
                        <van-button icon="plus" size="mini" />
                    </template>
                </van-field>

                <van-field v-model="alipayNo" name="alipayNo" label="支付宝账号" placeholder="请输入支付宝账号" maxlength="18"
                    :rules="[{ required: true, message: '请输入支付宝账号' }]" />
                    <van-field v-model="wxNo"  name="wxNo" label="微信账号" placeholder="请输入微信账号" maxlength="18"
                    :rules="[{ required: true, message: '请输入支付宝微信账号' }]" />
                <van-field v-model="operateName" name="operateName" label="运营联系人" placeholder="请输入运营联系人" maxlength="10"
                    :rules="[{ required: true, message: '请输入运营联系人' }]" />

                <van-field v-model="operatePhone" name="operatePhone" label="运营联系人手机号" placeholder="请输入运营联系人手机号"
                    maxlength="11" :rules="[{ required: true, message: '请输入运营联系人手机号' }]" />

                <!-- 品牌 -->
                <van-field v-model="brand" name="brand" label="品牌名" placeholder="请输入品牌名"
                    :rules="[{ required: true, message: '请输入品牌名' }]" />

                <van-field v-model="brandDesc" name="brandDesc" label="品牌介绍" placeholder="请输入品牌介绍"
                    :rules="[{ required: true, message: '请输入品牌介绍' }]" />

                <van-field name="brandLogo" label="品牌logo">
                    <template #input>
                        <template v-if="brandLogo.length > 0">
                            <van-image v-for="item in brandLogo" style="margin-right: 8px;" width="50" height="50"
                                :src="item" @click="previewImage('brandLogo')" />
                        </template>
                        <van-button icon="plus" size="mini" />
                    </template>
                </van-field>

                <van-field name="brandCategory" label="品牌分类" :rules="[{ required: true, message: '请输入姓名' }]">
                    <template #input>
                        <van-checkbox-group v-model="brandCategory" direction="vertical">
                            <van-checkbox style="margin: 4px 0;" name="汉服" shape="square">汉服</van-checkbox>
                            <van-checkbox style="margin: 4px 0;" name="手工艺" shape="square">手工艺</van-checkbox>
                            <van-checkbox style="margin: 4px 0;" name="非遗" shape="square">非遗</van-checkbox>
                            <van-checkbox style="margin: 4px 0;" name="茶艺" shape="square">茶艺</van-checkbox>
                            <van-checkbox style="margin: 4px 0;" name="字画" shape="square">字画</van-checkbox>
                        </van-checkbox-group>
                    </template>
                </van-field>

                <van-field name="publicityPicture" label="宣传图片">
                    <template #input>
                        <template v-if="publicityPicture.length > 0">
                            <van-image v-for="item in publicityPicture" style="margin-right: 8px;" width="50"
                                height="50" :src="item" @click="previewImage('publicityPicture')" />
                        </template>
                        <van-button icon="plus" size="mini" />
                    </template>
                </van-field>

                <van-field name="publicityVideo" label="宣传视频">
                    <template #input>
                        <template v-if="publicityVideo.length > 0">
                            <van-image v-for="item in publicityVideo" style="margin-right: 8px;" width="50" height="50"
                                :src="item" @click="previewImage('publicityVideo')" />
                        </template>
                        <van-button icon="plus" size="mini" />
                    </template>
                </van-field>
            </van-form>

            <van-number-keyboard v-model="idNumber" :show="idNumberShow" extra-key="X" close-button-text="完成"
                @blur="idNumberShow = false" />

                <template v-if="shop_id == ''">
                <div style="margin-top: 16px;">
                    <van-button class="btn" @click="submit">提交审核</van-button>
                </div>
            </template>
            <template v-else>
                <div style="margin-top: 16px;">
                    <van-button class="btn" @click="submit1">提交审核</van-button>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { submitApproveFirst } from "@/api/enter"
import { ImagePreview } from 'vant';
export default {
    name: 'enter',
    data() {
        return {
            type: 1,
            leimu:'',
            // operateName: '汲国平',
            // operatePhone: '12066923529',
            // name: '箕佳琪',
            // idType: 1,
            // idTypeName: '个体工商户营业执照',
            // idNumber: '34',
            // idUrl: ['https://lustrous-turret.net/'],
            // alipayNo: 'ut adipisicing',
            // brand: 'sint in veniam"',
            // brandDesc: 'sint in veniam"111',
            // brandLogo: ["https://avatars.githubusercontent.com/u/40204397"],
            // brandCategory: ['1', '2'],
            // publicityPicture: ["https://loremflickr.com/400/400?lock=8376818798816731"],
            // publicityVideo: [],
            // auctionFlag: false,
            // auctionLicense: [],
            // culturalAuctionFlag: false,
            // culturalAuctionLicense: [],
            companyDesc:'',
            operateName: '',
            operatePhone: '',
            name: '',
            idType: '身份证',
            idTypeName: '个体工商户营业执照',
            idNumber: '',
            businessLicense:['https://img01.yzcdn.cn/vant/cat.jpeg'],
            idUrl: ['https://img01.yzcdn.cn/vant/cat.jpeg','https://img01.yzcdn.cn/vant/cat.jpeg'],
            alipayNo: '',
            brand: '',
            brandDesc: '',
            brandLogo: ["https://img01.yzcdn.cn/vant/cat.jpeg"],
            brandCategory: [],
            publicityPicture: ["https://img01.yzcdn.cn/vant/cat.jpeg", "https://img01.yzcdn.cn/vant/cat.jpeg"],
            publicityVideo: ["https://img01.yzcdn.cn/vant/cat.jpeg"],
            auctionFlag: false,
            auctionLicense:["https://img01.yzcdn.cn/vant/cat.jpeg"],
            culturalAuctionFlag: false,
            culturalAuctionLicense: ["https://img01.yzcdn.cn/vant/cat.jpeg"],
            idNumberShow: false,
            columns: ['身份证', '个体工商户营业执照', '营业执照'],
            showPicker:false,
            lx:'身份证',
            wxNo:'',
            shop_id:''
        }
    },
    methods: {
        onConfirm(e){
            console.log(e);
            this.lx = e
            this.showPicker = false
        },
        async submit() {
            if (this.brandCategory.length < 2) {
                this.$toast('至少选择两项品牌分类');
                return false;
            }
            var data = {
                type: this.type,
                businessCategory:this.leimu,
                operateName: this.operateName,
                operatePhone: this.operatePhone,
                companyDesc:this.companyDesc,
                name: this.name,
                auctionLicense:this.auctionLicense.join(','),
                culturalAuctionLicense:this.culturalAuctionLicense.join(','),
                idType: 0,
                businessLicense:this.businessLicense.join(','),
                idNumber: this.idNumber,
                idUrl: this.idUrl.join(','),
                alipayNo: this.alipayNo,
                auctionFlag:this.auctionFlag?1:0,
                culturalAuctionFlag:this.culturalAuctionFlag?1:0,
                wechatNo:this.wxNo,
                brand: this.brand,
                brandDesc: this.brandDesc,
                brandLogo: this.brandLogo.join(','),
                brandCategory: this.brandCategory.join(','),
                publicityPicture: this.publicityPicture.join(','),
                publicityVideo: this.publicityVideo.join(','),
            }
            console.log(this.$data);
                const res = await submitApproveFirst(data);
            console.log("个人身份提交",res);
            if (res.data) {
                this.$toast('提交成功');
                // this.$router.push({ name: 'enterresult' });
                setTimeout(() => {
                    this.$router.go(-1);
                }, 1000);
            } else {
                this.$toast('提交失败');
                return false;
            }
            // this.$refs.enterForm.validate((valid) => {
            //     if (valid) {
            //         this.$toast('提交成功');
            //         this.$router.push({ name: 'enterresult' });
            //     } else {
            //         this.$toast('提交失败');
            //         return false;
            //     }
            // });
        },
        async submit1() {
            if (this.brandCategory.length < 2) {
                this.$toast('至少选择两项品牌分类');
                return false;
            }
            var data = {
                id:this.shop_id,
                type: this.type,
                businessCategory:this.leimu,
                operateName: this.operateName,
                companyDesc:this.companyDesc,
                operatePhone: this.operatePhone,
                auctionLicense:this.auctionLicense.join(','),
                culturalAuctionLicense:this.culturalAuctionLicense.join(','),
                name: this.name,
                idType: 0,
                idNumber: this.idNumber,
                businessLicense:this.businessLicense.join(','),
                idUrl: this.idUrl.join(','),
                alipayNo: this.alipayNo,
                auctionFlag:this.auctionFlag?1:0,
                culturalAuctionFlag:this.culturalAuctionFlag?1:0,
                wechatNo:this.wxNo,
                brand: this.brand,
                brandDesc: this.brandDesc,
                brandLogo: this.brandLogo.join(','),
                brandCategory: this.brandCategory.join(','),
                publicityPicture: this.publicityPicture.join(','),
                publicityVideo: this.publicityVideo.join(','),
            }
            console.log(this.$data);
                const res = await updateAndSubmit(data);
            console.log("公司身份提交",res);
            if (res.data) {
                this.$toast('提交成功');
                // this.$router.push({ name: 'enterresult' });
                setTimeout(() => {
                    this.$router.go(-1);
                }, 1000);
            } else {
                this.$toast('提交失败');
                return false;
            }
            // this.$refs.enterForm.validate((valid) => {
            //     if (valid) {
            //         this.$toast('提交成功');
            //         this.$router.push({ name: 'enterresult' });
            //     } else {
            //         this.$toast('提交失败');
            //         return false;
            //     }
            // });
        },
        back() {
            this.$router.go(-1);
        },
        next() {
            if (this.data.type === 1) {
                this.$router.push({ name: 'personalenter' });
            } else if (this.data.type === 2) {
                this.$router.push({ name: 'individualenter' });
            } else {
                this.$router.push({ name: 'companyenter' });
            }
        },
        previewImage(key) {
            const value = this[key];
            if (value?.length > 0) {
                ImagePreview(value);
            };
        }
    },
    mounted() { 
        console.log("this.$route.parmas",this.$route.params);
        this.type = this.$route.params.type
        this.leimu = this.$route.params.leimu
        this.shop_id = this.$route.params.id
    }
}
</script>
<style lang="less" scoped>
.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-size: 16px;

    .label {
        font-size: 14px;
    }

    .row {
        margin: 10px 0;
        display: flex;
    }

    .category-right {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
    }

    .tip {
        font-size: 10px;
        color: rgba(163, 159, 159, 0.97);
    }

    .underline {
        text-decoration: underline;
    }

    .row-item {
        margin: 8px;
        background-color: #f7f8fa;
        display: inline-flex;
        border-radius: 5px;
        width: 100px;

        .row-item-name {
            font-size: 12px;
            padding: 8px;
            width: 100px;
            text-align: center;
        }
    }

    .btn {
        width: 100%;
        border-radius: 5px;
        background-color: rgba(174, 133, 81, 0.97);
        color: rgba(255, 255, 255, 1);
    }

    .active {
        background-color: #94A1F2 !important;
        color: white;
    }
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
