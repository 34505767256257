<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" left-arrow @click-left="back" :title="top_text"/>
        </van-sticky>
        <div class="container">
            <van-form>
                <div style="margin-top: 20px;">商品名<span style="color: #B10808;">*</span></div>
                <van-field class="input_fild"  autocomplete="off" v-model="good_name" placeholder="请输入" maxlength="28" :rules="[{ required: true, message: '请输入商品名' }]"/> 
                <div class="flex" style="margin-top: 20px;">
                    <div>商品类型</div> 
                    <van-radio-group v-model="radio" direction="horizontal" style="margin-left: 40px;">
                        <van-radio name="1">全新</van-radio>
                        <van-radio name="2">老物件</van-radio>
                    </van-radio-group>
                </div>
                <template v-if="radio == 2">
                    <div style="margin-top: 20px;">几成新 </div>
                    <van-field readonly clickable  :value="newvalue" class="input_fild"
                    right-icon="arrow-down" @click="newshowPicker = true" placeholder="请选择" />
                    <van-popup v-model="newshowPicker" position="bottom">
                        <van-picker show-toolbar :columns="newlist" @confirm="onConfirmnew" value-key="val" @cancel="newshowPicker = false" />
                    </van-popup>
                </template>
                <div style="margin-top: 20px;">分类<span style="color: #B10808;">*</span> </div>
                <van-field readonly clickable  :value="resultstr" class="input_fild"
                right-icon="arrow-down" @click="cateshowPicker1 = true" placeholder="请选择" :rules="[{ required: true, message: '请选择分类' }]"  />
                <van-popup v-model="cateshowPicker1" round position="bottom" style="padding: 20px;box-sizing: border-box;">
                    <div style="margin-bottom: 20px;">选择分类(多选)</div>
                    <van-checkbox-group v-model="result_arr">
                        <van-checkbox checked-color="#475AD5" style="margin: 10px 0;" v-for="item in resultlist" :name="item">{{item}}</van-checkbox>
                    </van-checkbox-group>
                    <div class="row">
                        <div @click="lxfun" class="btns" >确认</div>
                    </div>
                </van-popup>
                <div style="margin-top: 20px;">品类<span style="color: #B10808;">*</span></div>
                <van-field readonly clickable  :value="pltext" class="input_fild" 
                :rules="[{ required: true, message: '请选择品类' }]" 
                right-icon="arrow-down" @click="cateshowPicker = true" placeholder="请选择" />
                <van-popup style="padding: 20px 0;box-sizing: border-box;" v-model="cateshowPicker" round position="bottom">
                <div style="margin-bottom: 20px;margin-left: 10px;">选择品类(单选)</div>
                <van-tree-select
                    :items="options"
                    :active-id.sync="activeId"
                    :main-active-index.sync="activeIndex"
                    @click-item="optionsfn"
                />
                </van-popup>
                <div style="margin-top: 20px;">售卖模式<span style="color: #B10808;">*</span></div>
                <div style="display: flex;margin-top: 10px;">
                    <div @click="salesFun(item.id)" v-for="item in sales" :key="item.id" :class="`type_items ${item.id == goods.sales ? 'active' : ''}`">{{ item.value }}</div>
                </div>
                <template v-if="goods.sales!= 4&&goods.sales!= 1">
                    <div style="margin-top: 20px;">开始时间<span style="color: #B10808;">*</span></div>
                    <van-field style="width: 100%;margin-top: 10px;"  readonly clickable class="input_fild" :value="cxstarttime" :rules="[{ required: true, message: '请选择开始时间' }]" right-icon="arrow-down" @click="showPickerstart = true" />
                        <van-popup v-model="showPickerstart" position="bottom">
                            <van-datetime-picker
                                type="datetime"
                                @cancel="showPickerstart = false"
                                title="选择开始时间"
                                @confirm="onConfirmstart"
                                :formatter="formatter"
                            />
                        </van-popup>
                    <div style="margin-top: 20px;">结束时间<span style="color: #B10808;">*</span></div>
                    <van-field style="width: 100%;margin-top: 10px;"   readonly clickable class="input_fild" :value="cxendtime" :rules="[{ required: true, message: '请选择结束时间' }]" right-icon="arrow-down" @click="showPickerend = true" />
                    <van-popup v-model="showPickerend" position="bottom">
                        <van-datetime-picker
                            type="datetime"
                            @cancel="showPickerend = false"
                            title="选择结束时间"
                            @confirm="onConfirmend"
                            :formatter="formatter"
                        />
                    </van-popup>
                </template>
                <!-- 秒杀 -->
                <template v-if="goods.sales == 0">
                    <div style="margin-top: 20px;">商品价格<span style="color: #B10808;">*</span></div>
                    <van-field class="input_fild"  @input="validateInput" autocomplete="off" type="number" v-model="ssmPrice" placeholder="请输入" maxlength="10"  :rules="[{ required: true, message: '请输入商品价格' }]"/> 
                </template>
                <!-- 分段计时 -->
                <template v-if="goods.sales == 1"> 
                    <div style="margin-top: 20px;">商品价格<span style="color: #B10808;">*</span></div>
                    <van-field class="input_fild"  autocomplete="off" type="number" v-model="ssmPrice" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入商品价格' }]"/> 
                    <div style="margin-top: 20px;">开始时间<span style="color: #B10808;">*</span></div>
                    <van-field style="width: 100%;margin-top: 10px;"  readonly clickable class="input_fild" :value="cxstarttime" :rules="[{ required: true, message: '请选择开始时间' }]" right-icon="arrow-down" @click="showPickerstart = true" />
                        <van-popup v-model="showPickerstart" position="bottom">
                            <van-datetime-picker
                                type="datetime"
                                @cancel="showPickerstart = false"
                                title="选择开始时间"
                                @confirm="onConfirmstart"
                                :formatter="formatter"
                            />
                        </van-popup>
                    <div style="margin-top: 20px;">结束时间<span style="color: #B10808;">*</span></div>
                    <van-field style="width: 100%;margin-top: 10px;"   readonly clickable class="input_fild" :value="cxendtime" :rules="[{ required: true, message: '请选择结束时间' }]" right-icon="arrow-down" @click="showPickerend = true" />
                    <van-popup v-model="showPickerend" position="bottom">
                        <van-datetime-picker
                            type="datetime"
                            @cancel="showPickerend = false"
                            title="选择结束时间"
                            @confirm="onConfirmend"
                            :formatter="formatter"
                        />
                    </van-popup>
                    <div class="flex" style="margin-top: 20px;">
                        <div>人数到达</div>
                        <van-field class="input_fild" style="width: 200px;margin: 0 5px;"  autocomplete="off" type="number" v-model="fd_num" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入' }]"/>
                        <div>人</div>
                    </div>
                    <div class="flex" style="margin-top: 20px;">
                        <div>降价</div>
                        <van-field readonly clickable  :value="selectval" class="input_fild"
                        right-icon="arrow-down" style="width: 120px;margin: 0 5px;" @click="isshowdown = true" placeholder="请选择" />
                        <van-popup v-model="isshowdown" position="bottom">
                            <van-picker show-toolbar :columns="selectlist" @confirm="selectvalue" value-key="val" @cancel="isshowdown = false" />
                        </van-popup>
                        <van-field v-if="selectid == 1" class="input_fild" style="width: 150px;margin: 0 5px;"  autocomplete="off" type="number" v-model="fd_mon" placeholder="请输入元" maxlength="10" :rules="[{ required: true, message: '请输入' }]"/>
                        <van-field v-if="selectid == 2" class="input_fild" style="width: 150px;margin: 0 5px;"  autocomplete="off" type="number" v-model="fd_per" placeholder="请输入百分比" maxlength="10" :rules="[{ required: true, message: '请输入' }]"/>
                    </div>
                    <template v-if="fd_numlist >= 2">
                        <div style="margin-top: 20px;">开始时间<span style="color: #B10808;">*</span></div>
                    <van-field style="width: 100%;margin-top: 10px;"  readonly clickable class="input_fild" :value="cxstarttime2" :rules="[{ required: true, message: '请选择开始时间' }]" right-icon="arrow-down" @click="showPickerstart2 = true" />
                        <van-popup v-model="showPickerstart2" position="bottom">
                            <van-datetime-picker
                                type="datetime"
                                @cancel="showPickerstart2 = false"
                                title="选择开始时间"
                                @confirm="onConfirmstart2"
                                :formatter="formatter"
                            />
                        </van-popup>
                    <div style="margin-top: 20px;">结束时间<span style="color: #B10808;">*</span></div>
                    <van-field style="width: 100%;margin-top: 10px;"   readonly clickable class="input_fild" :value="cxendtime2" :rules="[{ required: true, message: '请选择结束时间' }]" right-icon="arrow-down" @click="showPickerend2 = true" />
                    <van-popup v-model="showPickerend2" position="bottom">
                        <van-datetime-picker
                            type="datetime"
                            @cancel="showPickerend2 = false"
                            title="选择结束时间"
                            @confirm="onConfirmend2"
                            :formatter="formatter"
                        />
                    </van-popup>
                    <div class="flex" style="margin-top: 20px;">
                        <div>人数到达</div>
                        <van-field class="input_fild" style="width: 200px;margin: 0 5px;"  autocomplete="off" type="number" v-model="fd_num2" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入' }]"/>
                        <div>人</div>
                    </div>
                    <div class="flex" style="margin-top: 20px;">
                        <div>降价</div>
                        <van-field readonly clickable  :value="selectval2" class="input_fild"
                        right-icon="arrow-down" style="width: 120px;margin: 0 5px;" @click="isshowdown2 = true" placeholder="请选择" />
                        <van-popup v-model="isshowdown2" position="bottom">
                            <van-picker show-toolbar :columns="selectlist" @confirm="selectvalue2" value-key="val" @cancel="isshowdown2 = false" />
                        </van-popup>
                        <van-field v-if="selectid2 == 1" class="input_fild" style="width: 150px;margin: 0 5px;"  autocomplete="off" type="number" v-model="fd_mon2" placeholder="请输入元" maxlength="10" :rules="[{ required: true, message: '请输入' }]"/>
                        <van-field v-if="selectid2 == 2" class="input_fild" style="width: 150px;margin: 0 5px;"  autocomplete="off" type="number" v-model="fd_per2" placeholder="请输入百分比" maxlength="10" :rules="[{ required: true, message: '请输入' }]"/>
                    </div>
                    </template>
                    <template v-if="fd_numlist >= 3">
                        <div style="margin-top: 20px;">开始时间<span style="color: #B10808;">*</span></div>
                    <van-field style="width: 100%;margin-top: 10px;"  readonly clickable class="input_fild" :value="cxstarttime3" :rules="[{ required: true, message: '请选择开始时间' }]" right-icon="arrow-down" @click="showPickerstart3 = true" />
                        <van-popup v-model="showPickerstart3" position="bottom">
                            <van-datetime-picker
                                type="datetime"
                                @cancel="showPickerstart3 = false"
                                title="选择开始时间"
                                @confirm="onConfirmstart3"
                                :formatter="formatter"
                            />
                        </van-popup>
                    <div style="margin-top: 20px;">结束时间<span style="color: #B10808;">*</span></div>
                    <van-field style="width: 100%;margin-top: 10px;"   readonly clickable class="input_fild" :value="cxendtime3" :rules="[{ required: true, message: '请选择结束时间' }]" right-icon="arrow-down" @click="showPickerend3 = true" />
                    <van-popup v-model="showPickerend3" position="bottom">
                        <van-datetime-picker
                            type="datetime"
                            @cancel="showPickerend3 = false"
                            title="选择结束时间"
                            @confirm="onConfirmend3"
                            :formatter="formatter"
                        />
                    </van-popup>
                    <div class="flex" style="margin-top: 20px;">
                        <div>人数到达</div>
                        <van-field class="input_fild" style="width: 200px;margin: 0 5px;"  autocomplete="off" type="number" v-model="fd_num3" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入' }]"/>
                        <div>人</div>
                    </div>
                    <div class="flex" style="margin-top: 20px;">
                        <div>降价</div>
                        <van-field readonly clickable  :value="selectval3" class="input_fild"
                        right-icon="arrow-down" style="width: 120px;margin: 0 5px;" @click="isshowdown3 = true" placeholder="请选择" />
                        <van-popup v-model="isshowdown3" position="bottom">
                            <van-picker show-toolbar :columns="selectlist" @confirm="selectvalue3" value-key="val" @cancel="isshowdown3 = false" />
                        </van-popup>
                        <van-field v-if="selectid3 == 1" class="input_fild" style="width: 150px;margin: 0 5px;"  autocomplete="off" type="number" v-model="fd_mon3" placeholder="请输入元" maxlength="10" :rules="[{ required: true, message: '请输入' }]"/>
                        <van-field v-if="selectid3 == 2" class="input_fild" style="width: 150px;margin: 0 5px;"  autocomplete="off" type="number" v-model="fd_per3" placeholder="请输入百分比" maxlength="10" :rules="[{ required: true, message: '请输入' }]"/>
                    </div>
                    </template>
                    <div v-if="fd_numlist <3" class="guige" style="margin-top: 20px;" @click="fn_numadd ">添加时间段</div>
                </template>
                <!-- 减价售卖 -->
                <template v-if="goods.sales == 2"> 
                        <div style="margin-top: 20px;">全国价格<span style="color: #B10808;">*</span></div>
                        <van-field class="input_fild" input-pattern="\d*\.?\d*"  autocomplete="off" type="number" v-model="mon_con" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入全国价格' }]"/>
                        <div style="margin-top: 20px;">柴门价格<span style="color: #B10808;">*</span></div>
                        <van-field class="input_fild" input-pattern="\d*\.?\d*"  autocomplete="off" type="number" v-model="ssmPrice" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入柴门价格' }]"/>
                        <div style="margin-top: 20px;">保留价格<span style="color: #B10808;">*</span></div>
                        <van-field class="input_fild" input-pattern="\d*\.?\d*"  autocomplete="off" type="number" v-model="minprice" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入保留价格' }]"/>
                        <van-field readonly clickable  :value="everyval" class="input_fild"
                        right-icon="arrow-down" style="width: 100%;" @click="everypicker = true" placeholder="请选择" />
                        <van-popup v-model="everypicker" position="bottom">
                            <van-picker value-key="val" show-toolbar :columns="everylist" @confirm="everyfn" @cancel="everypicker = false" />
                        </van-popup>
                        <div class="flex" style="margin-top: 20px;">
                        <div>降价</div>
                        <van-field readonly clickable  :value="heshivalue" class="input_fild"
                        right-icon="arrow-down" style="width: 120px;margin: 0 5px;" @click="heshidown = true" placeholder="请选择" />
                        <van-popup v-model="heshidown" position="bottom">
                            <van-picker show-toolbar :columns="selectlist" @confirm="heshifun" value-key="val" @cancel="heshidown = false" />
                        </van-popup>
                        <van-field v-if="heshiid == 1" class="input_fild" style="width: 150px;margin: 0 5px;"  autocomplete="off" type="number" v-model="heshi_y" placeholder="请输入元" maxlength="10" :rules="[{ required: true, message: '请输入' }]"/>
                        <van-field v-if="heshiid == 2" class="input_fild" style="width: 150px;margin: 0 5px;"  autocomplete="off" type="number" v-model="heshi_b" placeholder="请输入百分比" maxlength="10" :rules="[{ required: true, message: '请输入' }]"/>
                       <!--  <div>降价</div>
                        <van-field class="input_fild" style="width: 200px;margin: 0 5px;"  autocomplete="off" type="number" v-model="mon_down" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入降价价格' }]"/> -->
                        </div>
                        <div class="flex" style="margin-top: 20px;">
                        <div>独特性</div> 
                        <van-radio-group v-model="isonly" direction="horizontal" style="margin-left: 40px;">
                            <van-radio name="1">独一无二</van-radio>
                            <van-radio name="0">多件</van-radio>
                        </van-radio-group>
                    </div>
                </template>
                <!-- 拍卖 -->
                <template v-if="goods.sales == 3"> 
                    <div style="margin-top: 20px;">起始价<span style="color: #B10808;">*</span></div>
                    <van-field class="input_fild"  autocomplete="off" type="number" v-model="start_mon" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入起始价' }]"/>
                    <div style="margin-top: 20px;">保证金<span style="color: #B10808;">*</span></div>
                    <van-field class="input_fild"  autocomplete="off" type="number" v-model="promise_mon" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入保证金' }]"/>
                </template>
                <!-- 薅羊毛 -->
                <template v-if="goods.sales == 4">
                    <div style="margin-top: 20px;">商品价格<span style="color: #B10808;">*</span></div>
                    <van-field class="input_fild" input-pattern="\d*\.?\d*"  autocomplete="off" type="number" v-model="ssmPrice" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入商品价格' }]"/> 
                </template>
                <!-- 默认规格 -->
                <div v-for="item in default_guige" class="flex" style="margin: 10px 0;">
                    <div class="title">{{item.name}}：</div>
                    <div class="flex" style="flex-wrap: wrap;width: 275px;margin-bottom: 10px;">
                        <div @click="selectGuige(items)" v-for="items in item.itemList"  :class="`sc ${kucunclass.indexOf(items.id) != -1 ? 'active' : ''}`"  style="margin-top: 5px;margin-right: 10px;">
                            {{ items.name }} 
                            <van-image width="20" height="20" class="close" :src="require('/src/assets/pic_close_g.png')" @click.stop="sizeFun(items)"/>
                        </div>
                        <van-image style="background: rgba(174,133,81,0.15);" width="28" height="28" :src="require('/src/assets/pic_add_g.png')"  @click.stop="addzigg(item)"/>
                        <!-- <van-button @click.stop="addzigg(item)" style="" icon="plus" size="mini" /> -->
                    </div>
                </div>
                <!-- 设置库存 -->
                <div v-if="this.result.length >=2" class="flex">
                    <div class="title">设置库存</div>
                </div>
                <div v-for="item in result" class="flex" style="margin: 10px 0;justify-content: space-between;">
                    <div>{{item.name}}：</div>
                        <div style="display: flex;align-items: center;">
                            <!-- <div @click="item.inventory > 1 ? item.inventory-- : false">—</div>
                                <input maxlength="10" type="number" v-model="item.inventory" style="width: 76px;height: 31px;text-align: center;border: none;background-color: #E9D4BC;">
                            <div @click="item.inventory++">+</div> -->
                            <van-field class="input_fild" style="max-width: 100px;margin-top: 0;"  autocomplete="off" type="number" v-model="item.inventory" placeholder="请输入库存" maxlength="10"/> 
                        </div>
    
                </div>
    
                <div v-for="item in goods.customize" class="flex" style="margin-bottom: 10px;">
                    <div class="title">{{item.name}}：</div>
                    <div class="title" v-for="items in item.value">{{items}}</div>
                </div>
    
                <div v-if="default_guige.length < 3" class="flex" style="margin: 10px 0;">
                    <div class="guige" @click="zdyFun">自定义规格</div>
                </div>
                <div class="guige_box" v-if="showguige">
                    <div class="flex">
                        <div class="title">规格名称：</div>
                        <input v-model="guige.name" :disabled="guige_name ? false : true" type="text" style="width: 80px;height: 40px;border: none;background-color: #876235;border-radius: 4px;color: #E9D4BC;text-align: center;">
                    </div>
                    <div class="flex" style="margin-top: 20px;align-items: start;">
                        <div class="title">规格选项：</div>
                        <div style="display: flex;align-items: center;flex-wrap: wrap;width: 240px;">
                            <input v-for="(item,index) in guige.value"  @input="iptfun(index,$event)" :value="item" type="text" style="width: 70px;height: 40px;margin-right: 5px;margin-top: 4px;border: none;background-color: #876235;border-radius: 4px;color: #E9D4BC;text-align: center;">
                            <!-- <div @click="addFun" ><van-button icon="plus" size="mini" /></div> -->
                            <van-image style="background: rgba(174,133,81,0.15);" width="28" height="28" :src="require('/src/assets/pic_add_g.png')"  @click="addFun"/>
                        </div>
                    </div>
                    <div class="flex" style="justify-content: space-around;margin-top: 10px;">
                        <div @click="showguige = false" style="color: #887C7C;">取消</div>
                        <div @click="addFun1">保存</div>
                    </div>
                </div>
                <div class="flex" style="margin-top: 20px;">
                    <div>发货时间</div>
                    <van-radio-group v-model="send_time" direction="horizontal" style="margin-left: 40px;max-width: 200px;">
                        <van-radio name="24小时">24小时</van-radio>
                        <van-radio name="48小时">48小时</van-radio>
                        <van-radio style="margin-top: 5px;" name="自定义">自定义</van-radio>
                    </van-radio-group>
                </div>
                <template v-if="send_time == '自定义'">
                    <div style="margin-top: 20px;">发货时间<span style="color: #B10808;">*</span></div>
                    <van-field class="input_fild"  autocomplete="off" v-model="zidingyi_time" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入自定义时间' }]"/> 
                </template>
                <div class="flex" style="margin-top: 20px;">
                    <div>运费类型</div>
                    <van-radio-group v-model="yunfei" direction="horizontal" style="margin-left: 40px;">
                        <van-radio name="0">免运费</van-radio>
                        <van-radio name="1">动态运费</van-radio>
                    </van-radio-group>
                </div>
                <div class="flex" style="margin-top: 20px;">
                    <div>是否支持七天无理由退货</div>
                    <van-radio-group v-model="isseven" direction="horizontal" style="margin-left: 40px;">
                        <van-radio name="1">是</van-radio>
                        <van-radio name="0">否</van-radio>
                    </van-radio-group>
                </div>
                <div class="flex" style="align-items: self-start;margin-top: 20px;margin-bottom: 10px;">
                    <div>封面图(16:9) <span style="color: #B10808;">*</span></div>
                </div>
                <van-image width="140" height="78" fit="cover" :src="imgUrl" @click="coverfun"/>
                <div class="flex" style="align-items: self-start;margin-top: 20px;margin-bottom: 10px;">
                    <div>详情图(4:3) <span style="color: #B10808;">*</span></div>
                </div>
                <van-image width="105" height="70"  :src="imgUrl1" @click="shopdetailfun"/>
                <van-image width="105" height="70"  :src="imgUrl2" style="margin: 0 5px;" @click="shopdetailfuns"/>
                <van-image width="105" height="70"  :src="imgUrl3" @click="shopdetailfunss"/>
                <div class="flex" style="align-items: self-start;margin-top: 20px;margin-bottom: 10px;">
                    <div class="title">视频 <span style="color: #B10808;">*</span></div>
                    <!-- <video v-if="videoinfo.videoUrl"  @click="playVideo" :src="videoinfo.videoUrl" style="width: 80px;height: 80px;margin-left: 5px;"></video> -->
                </div>
                <van-image width="76" height="76" :src="videoinfo.image.url" @click="videofun"/>
                <div class="flex" style="align-items: self-start;margin-top: 20px;margin-bottom: 10px;">
                    <div>分享图(1:1) <span style="color: #B10808;">*</span></div>
                </div>
                <van-image width="111" height="111" fit="cover" :src="imgUrl4" @click="sharefun"/>
            </van-form>
            <div class="flex" style="justify-content: center;color: rgba(190,186,186,1);font-size: 14px;font-family: PingFangSC-regular;">
                最多只能上架一款促销商品
            </div> 
            <!-- <div class="flex" style="justify-content: space-between;">
                <van-button block @click="save" class="bot_btn">保存</van-button>
                <van-button block @click="submitFun" class="bot_btns">发布</van-button>
            </div> -->
            <div style="height: 90px;"></div>
        </div> 
        <div  class="bottom_fixed">
            <div class="asd">
                <div class="bot" style="color: #37261B;font-weight: 500;user-select: none;" @click="save">保存</div>
                <div class="bot" style="background: #876235;color: #FFFFFF;user-select: none;" @click="submitFun">发布</div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import { findList ,addSpecification,addSpecificationItem,saveGoodsInfo,queryGoodsInfo,updateGoodsInfo,queryGoodsCategoryByBrand,brandCategoryList,deleteSpecificationItem} from '@/api/goods';
export default {
    name: 'goods',
    data() {
        return {
            mar_top:'',
            top_text:'',
            brand_id:'',//品牌ID
            ssmPrice:'',//普通商品价格 柴门价格
            good_name:"",//商品名
            resultlist:[],//所有分类数组
            result_arr:[],//选择商品分类数组
            resultstr:'',//选择商品分类字符串
            cateshowPicker1:false,//分类弹窗
            options:[],//所有品类数组
            pltext:'',//选择品类字符串
            activeId: '',//选择品类id
            activeIndex: 0,
            cateshowPicker:false,
            radio:'1',//1全新 2 老物件,
            newid:'9',
            newvalue:'九成新',//几成新
            newshowPicker:false,//几成新弹窗
            newlist:[
                {id:1,val:'一成新'},
                {id:2,val:'二成新'},
                {id:3,val:'三成新'},
                {id:4,val:'四成新'},
                {id:5,val:'五成新'},
                {id:6,val:'六成新'},
                {id:7,val:'七成新'},
                {id:8,val:'八成新'},
                {id:9,val:'九成新'}

            ],
            imgUrl:require('/src/assets/pic_goods_cover.png'),
            imgUrl1:require('/src/assets/pic_goods_z.png'),
            imgUrl2:require('/src/assets/pic_goods_c.png'),
            imgUrl3:require('/src/assets/pic_goods_f.png'),
            imgUrl4:require('/src/assets/pic_goods_share.png'),
            videoinfo:{
                image:{
                    url:require('/src/assets/pic_video.png'),
                }
            },
            send_time:'24小时',//发货时间 
            zidingyi_time:'',
            yunfei:'0',//运费 0 免运费 1 动态运费
            isseven:'1',//是否支持七天无理由
            isshowdown:false,
            isshowdown2:false,
            isshowdown3:false,
            selectlist:[
                {id:1,val:'元'},
                {id:2,val:'百分比'}
            ],
            selectid:'1',
            selectval:'元',
            selectid2:'1',
            selectval2:'元',
            selectid3:'1',
            selectval3:'元',
            everypicker:false,//每小时 半小时
            heshivalue:'元',
            everylist:[
                {id:0,val:'每小时'},
                {id:1,val:'每半小时'}
            ],
            everyval:'每小时',
            heshiid:'1',
            everyid:'0',
            heshidown:false,
            isonly:'0',//是否独一无二
            cxstarttime:'',//促销开始时间
            cxendtime:'',//促销结束时间
            fd_mon:'',//分段降价元
            fd_per:'',//分段百分比
            fd_num:'',//分段达到多少人
            cxstarttime2:'',//促销开始时间2
            cxendtime2:'',//促销结束时间2
            fd_mon2:'',//分段降价元2
            fd_per2:'',//分段百分比2
            fd_num2:'',//分段达到多少人2
            cxstarttime3:'',//促销开始时间3
            cxendtime3:'',//促销结束时间3
            fd_mon3:'',//分段降价元3
            fd_per3:'',//分段百分比3
            fd_num3:'',//分段达到多少人3
            mon_down:'',//减价售卖降价
            heshi_y:'',
            heshi_b:'',
            mon_con:'',//减价售卖全国价,
            minprice:'',//减价售卖保留价
            start_mon:'',//拍卖起拍价,
            promise_mon:'',//拍卖保证金
            fd_numlist:1,//几个分段计时
           
        resultnum:0,
        kucun :[],
        result:[],
        sentid:'',
        kucunclass:[],
            default_guige:[],
            goods_id:'',
            goods:{
                starttime:'',
                endtime:'',
                name:'',
                type:1,
                businessCategory: "一成新",
                businessCategoryid:1,
                goodsclass:[],
                goodsclassvalue:[],
                cate:1,
                sales:0,
                inventory:2,
                ship:0,
                shipval:'免运费',
                des:'',
                imageUrl: '',
                customize:[],
                size:['13厘米','15厘米','18厘米'],
                color:['红色','蓝色'],
                sendtime:'',
                times:'每小时'
            },
            times:[
                {id:0,value:'每小时'},
                {id:1,value:'半小时'}
            ],
            sales:[
                {id:0,value:'秒杀'},
                {id:1,value:'分段计时'},
                {id:2,value:'减价售卖'},
                {id:3,value:'拍卖'},
                {id:4,value:'薅羊毛'}
            ],
            guige:{
                name:"",
                value:[]
            },
            showguige:false,
            showPicker: false,
            showPickership: false,
            showPickership1: false,
            showPickersolt:false,
            showPickersolt1:false,
            showPickersolt2:false,
            showPickersolt3:false,
            showPickerstime:false,
            columns: [
                {id:1,val:'一成新'},
                {id:2,val:'二成新'},
                {id:3,val:'三成新'}
            ],
            type:[
                    {id:1,value:'全新'},
                    {id:2,value:'老物件'},
                ],
            shiplist:[
                {id:0,value:'免运费'},
                {id:1,value:'动态运费'},
            ],  
            goodsclass:[
                    {id:1,value:'汉服'},
                    {id:2,value:'手工艺'},
                    {id:3,value:'非遗'},
                    {id:4,value:'茶道'},
                    {id:5,value:'字画'},
                    {id:6,value:'乐器'},
                    {id:7,value:'其他'}
                ],
            cate:[
                {id:1,value:'唐朝'},
                {id:2,value:'秦朝'},
            ],
            guige_name:'',
            guige_zi_id:'',
            kucun:[],
            ziid:[],
            inid:'',
            showPickerstart:false,
            showPickerend:false,
            showPickerstart2:false,
            showPickerend2:false,
            showPickerstart3:false,
            showPickerend3:false,
            // 库存信息数据
            skuSpecification: [
                {
                    "itemIdList": [
                        1,
                        4
                    ],
                    "price": 568.45,
                    "inventory": 59
                },
                {
                    "itemIdList": [
                        2,
                        4
                    ],
                    "price": 812.19,
                    "inventory": 26
                }
            ],
            goodsPriceInfo: {
                price: '',
                countryPrice: '',
                ssmPrice: '',
                secKillPrice: '',
                startPrice: '',
                startTime: "",
                endTime: "",
                salePrice: '',
                reduceCyclePrice: '',
                reduceCycleType: '',
                goodsPiecewiseList: [
                    {
                        price: '',
                        inventory: '',
                        startTime: "",
                        endTime: ""
                    },
                    {
                        price: '',
                        inventory: '',
                        startTime: "",
                        endTime: ""
                    }
                ]
            },
            
        }
    },
    methods: {
        validateInput(val){
            if (val === '' || val > 0) {
                this.ssmPrice = val;
            } else {
                // 如果是负数，可以重置为空或上一个有效值
                this.ssmPrice = this.value; // 或者 this.value = '0'; // 如果想重置为0
            }
        },
        //分段计时 添加分段
        fn_numadd(){
            this.fd_numlist ++ 
        },
         //分类确认
         lxfun(){
            console.log(this.result_arr);
            if(this.result_arr.length < 1) {
                Toast('至少选择一种类型')
            } else {
                this.cateshowPicker1 = false
                this.resultstr = this.result_arr.join(',')
            }
        },
        //品类选择
        optionsfn(e){
            console.log("品类选择",e);
            this.activeId = e.id,
            this.pltext = e.text
            this.cateshowPicker = false
        },
        //几成新确定
        onConfirmnew(e){
            console.log("几成新",e);
            this.newid = e.id,
            this.newvalue = e.val
            this.newshowPicker = false
        },
        //选择百分比/元
        selectvalue(e){
            this.selectid = e.id
            this.selectval = e.val
            this.isshowdown = false
        },
        selectvalue2(e){
            this.selectid2 = e.id
            this.selectval2 = e.val
            this.isshowdown2 = false
        },
        selectvalue3(e){
            this.selectid3 = e.id
            this.selectval3 = e.val
            this.isshowdown3 = false
        },
        heshifun(e){
            this.heshiid = e.id
            this.heshivalue = e.val
            this.heshidown = false
        },
        //选择每小时每半小时
        everyfn(e){
            this.everyid = e.id
            this.everyval = e.val
            this.everypicker = false
        },
        formatDatetime(date) {
            var year = date.getFullYear();
            var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            var second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            
            return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
        },
        // arr 目标对象数组
        // filed 分组字段
        classifyArrayGroupBySameFieldAlpha(arr, filed) {
            let temObj = {}
            for (let i = 0; i < arr.length; i++) {
                let item = arr[i]
                if (!temObj[item[filed]]) {
                    temObj[item[filed]] = [item]
                } else {
                    temObj[item[filed]].push(item)
                }
            }
            let resArr = []
            Object.keys(temObj).forEach(key => {
                resArr.push({
                    key: key,
                    data: temObj[key],
                })
            })
            return resArr
        },

        // 子规格选中
        selectGuige(e){
            console.log('this.result',this.result,this.resultnum);
            console.log("eeee",e);
            if(this.kucunclass.indexOf(e.id) == -1) {
                    this.kucun.push(e)
                    this.kucunclass.push(e.id)
            } else {
                this.kucun=this.kucun.filter(item=>item.id != e.id)
                this.kucunclass=this.kucunclass.filter(item=>item != e.id)
                for (let i = 0; i < this.result.length; i++) {
                        this.result = this.result.filter(item=>item.itemIdList.indexOf(e.id) == -1)
                }
            }
            const res = this.classifyArrayGroupBySameFieldAlpha(this.kucun,'specificationId')
            console.log("kucun",this.kucun);
            console.log("res111",res);
            console.log("kucunclass",this.kucunclass);
            if(res.length == 1) {
                let result = this.result;
                console.log("单个规格",result);
                
            for (let i = 0; i < res[0].data.length; i++) {
                        let combined = {name:'',itemIdList:'',inventory:1};
                        combined.name = res[0].data[i].name
                        combined.itemIdList = res[0].data[i].id 
                        var a = result.some(item => item.name === combined.name)
                        if(!a) {
                            result.push(combined)
                        } 
                }
                /* result[0].itemIdList = result[0].itemIdList.split(",") */
                /* result[0].itemIdList = result[0].itemIdList.map(num => +num) */
                /* for (let i = 0; i < result.length; i++) {
                    if(!Array.isArray(result[i].itemIdList)) {
                        result[i].itemIdList = result[i].itemIdList.split(",")
                        result[i].itemIdList = result[i].itemIdList.map(num => +num)
                    }
                } */
            this.result = result
            console.log("result",result);
            }else if(res.length == 2) {
            /* let result = this.result; */
            let result = [];
            for (let i = 0; i < res[0].data.length; i++) {
                for (let j = 0; j < res[1].data.length; j++) {
                        let combined = {name:'',itemIdList:'',inventory:1};
                        combined.name = res[0].data[i].name + '/' + res[1].data[j].name
                        combined.itemIdList = res[0].data[i].id + ',' + res[1].data[j].id
                        var a = result.some(item => item.name === combined.name)
                        if(!a) {
                            result.push(combined)
                        } 
                    }
                }
                for (let i = 0; i < result.length; i++) {
                    if(!Array.isArray(result[i].itemIdList)) {
                        result[i].itemIdList = result[i].itemIdList.split(",")
                        result[i].itemIdList = result[i].itemIdList.map(num => +num)
                    }
                }
            this.result = result
            console.log("result",result);
            } else if(res.length == 3) {
            console.log(res,'三种规格');
            let result = [];
            for (let i = 0; i < res[0].data.length; i++) {
                for (let j = 0; j < res[1].data.length; j++) {
                    for (let k = 0; k < res[2].data.length; k++) {
                        let combined = {name:'',itemIdList:'',inventory:1};
                        combined.name = res[0].data[i].name + '/' + res[1].data[j].name+ '/' + res[2].data[k].name
                        combined.itemIdList = res[0].data[i].id + ',' + res[1].data[j].id+ ',' + res[2].data[k].id
                        result.push(combined)
                    }
                }
            }
            console.log("result333",result);
            for (let i = 0; i < result.length; i++) {
                result[i].itemIdList = result[i].itemIdList.split(",")
                result[i].itemIdList = result[i].itemIdList.map(num => +num)
            }
            this.result = result
            console.log("result",result);
            }
            return
            if(res.length == 1) {
                let result = this.result;
            for (let i = 0; i < res[0].data.length; i++) {
                /* for (let j = 0; j < res[1].data.length; j++) { */
                        let combined = {name:'',itemIdList:'',inventory:1};
                        combined.name = res[0].data[i].name
                        combined.itemIdList = res[0].data[i].id
                        var a = result.some(item => item.name === combined.name)
                        if(!a) {
                            result.push(combined)
                        } 
                    /* } */
                }
                result[0].itemIdList = result[0].itemIdList.split(",")
                result[0].itemIdList = result[0].itemIdList.map(num => +num)
                /* for (let i = 0; i < result.length; i++) {
                    if(!Array.isArray(result[i].itemIdList)) {
                        result[i].itemIdList = result[i].itemIdList.split(",")
                        result[i].itemIdList = result[i].itemIdList.map(num => +num)
                    }
                } */
            this.result = result
            console.log("result",result);
            }
        },
        // 新增子规格
        addzigg(e){
            console.log("新增zigg",e);
            this.showguige = true
            this.guige = {name:e.name,value:[]}
            this.guige_name = false
            this.guige_zi_id = e.id
            
        },
        // 新增规格
        zdyFun(){
            this.showguige = true
            this.guige = {name:"",value:[]}
            this.guige_name = true
            console.log(this.guige);
        },
        addFun(){
            this.guige.value.push('')
        },
        containsEmptyString(arr) {
            return arr.some(item => item === "");
        },
        async addFun1(){
            console.log(this.guige);
            if(this.guige_name) {
                if(this.guige.name == '' || this.containsEmptyString(this.guige.value)) {
                    Toast('请输入内容')
                } else {
                    console.log("自定义");
                    var res = await addSpecification({
                        name:this.guige.name,
                        subNameList:this.guige.value
                    });
                    console.log("新增规格",res);
                    for (let i = 0; i < res.data.subItemList.length; i++) {
                        res.data.subItemList[i].specificationId = res.data.id
                    }
                    console.log("新增规格111",res);
                    
                    var data = {
                        id:res.data.id,
                        name:res.data.name,
                        itemList:res.data.subItemList
                    }
                    this.default_guige.push(data)
                }

            } else {
                console.log("no自定义",this.guige_zi_id,this.guige);
                console.log(this.default_guige);
                if(this.guige_zi_id != 1 && this.guige_zi_id != 2) {
                    if(this.containsEmptyString(this.guige.value)) {
                        Toast('请输入内容')
                    } else {
                        var res = await addSpecificationItem({
                            specificationId:this.guige_zi_id,
                            nameList:this.guige.value
                        })
                        console.log("新增自定义子规格",res);
                        for (let i = 0; i < res.data.length; i++) {
                            res.data[i].specificationId = this.guige_zi_id
                            for (let j = 0; j < this.default_guige.length; j++) {
                                if(this.default_guige[j].id == this.guige_zi_id) {
                                    this.default_guige[j].itemList.push(res.data[i])
                                }
                            }
                        }
                        console.log("新增自定义子规格",res);
                    }
                } else {
                    var res = await addSpecificationItem({
                        specificationId:this.guige_zi_id,
                        nameList:this.guige.value
                    })
                    console.log("新增子规格",res);
                    await this.getggList();
                }
            }
            this.showguige = false
            
        },
        async sizeFun(e){
            console.log(e);
            console.log(this.goods.size);
            console.log("this.default_guige",this.default_guige);
            const res = await deleteSpecificationItem({itemId:e.id})
            console.log("删除子规格",res);
            if(res.code == 200) {
                for (let i = 0; i < this.default_guige.length; i++) {
                    if(e.specificationId == this.default_guige[i].id) {
                        for (let j = 0; j < this.default_guige[i].itemList.length; j++) {
                            this.default_guige[i].itemList = this.default_guige[i].itemList.filter(item=>{
                                return item.id != e.id
                            })
                        }
                    }                
                }
            }
            return
            
            return
            this.goods.size = this.goods.size.filter(item => {
                return item != e
            })
        },
        colorFun(e){
            console.log(e);
            this.goods.color = this.goods.color.filter(item => {
                return item != e
            })
        },
        iptfun(index,e){
            console.log(index,e);
            this.guige.value[index] = e.target.value
        },
        addsize(){
            this.showguige = true
            this.guige = {name:"尺寸",value:this.goods.size}
            this.guige_name = false
        },
        addcolor(){
            this.showguige = true
            this.goods.color = this.goods.color.filter(item=> {
                  return item != ''
                }
            )
            this.guige = {name:"颜色",value:this.goods.color}
            this.guige_name = false
        },
        shop_price(e){
            this.goodsPriceInfo.price = parseFloat(e.target.value)
        },
        guo_price(e){
            this.goodsPriceInfo.countryPrice = parseFloat(e.target.value)
        },
        chai_price(e){
            this.goodsPriceInfo.ssmPrice = parseFloat(e.target.value)
        },
        miao_price(e){
            this.goodsPriceInfo.secKillPrice = parseFloat(e.target.value)
        },
        heshi_price(e){
            this.goodsPriceInfo.salePrice = parseFloat(e.target.value)
        },
        jiang_price(e){
            this.goodsPriceInfo.reduceCyclePrice = parseFloat(e.target.value)
        },
        baoliu_price(e){
            this.goodsPriceInfo.minPrice = parseFloat(e.target.value)
        },
        qipai_price(e){
            this.goodsPriceInfo.startPrice = parseFloat(e.target.value)
        },
        baozheng_price(e){
            this.goodsPriceInfo.securityDeposit = parseFloat(e.target.value)
        },
        senrfun(e){
            this.goods.sendtime = e.target.value
        },
        // 类型
        typeFun(e){
            console.log(e);
            this.goods.type = e
        },
        // 售卖模式
        salesFun(e){
            console.log(e);
            this.goods.sales = e
        },
        // 运费
        ship(e){
            console.log(e);
            this.goods.ship = e
        },
        //促销开始时间
        onConfirmstart(e){
            console.log("开始时间",e);
            var date = new Date(e);
            console.log(this.formatDatetime(date));  // "2014/2/1 00:00:00"
            this.cxstarttime = this.formatDatetime(date)
            this.showPickerstart = false
        },
        onConfirmstart2(e){
            console.log("开始时间2",e);
            var date = new Date(e);
            console.log(this.formatDatetime(date));  // "2014/2/1 00:00:00"
            this.cxstarttime2 = this.formatDatetime(date)
            this.showPickerstart2 = false
        },
        onConfirmstart3(e){
            console.log("开始时间3",e);
            var date = new Date(e);
            console.log(this.formatDatetime(date));  // "2014/2/1 00:00:00"
            this.cxstarttime3 = this.formatDatetime(date)
            this.showPickerstart3 = false
        },
        //促销结束时间
        onConfirmend(e){
            console.log("结束时间",e);
            var date = new Date(e);
            console.log(this.formatDatetime(date));  // "2014/2/1 00:00:00"
            this.cxendtime = this.formatDatetime(date)
            this.showPickerend = false
        },
        onConfirmend2(e){
            console.log("结束时间2",e);
            var date = new Date(e);
            console.log(this.formatDatetime(date));  // "2014/2/1 00:00:00"
            this.cxendtime2 = this.formatDatetime(date)
            this.showPickerend2 = false
        },
        onConfirmend3(e){
            console.log("结束时间3",e);
            var date = new Date(e);
            console.log(this.formatDatetime(date));  // "2014/2/1 00:00:00"
            this.cxendtime3 = this.formatDatetime(date)
            this.showPickerend3 = false
        },
        formatter(type, val) {
        if (type === 'year') {
            return `${val}年`;
                } else if (type === 'month') {
                    return `${val}月`;
                }else if(type === 'day'){
                    return `${val}日`;
                }else if(type === 'hour'){
                    return `${val}时`;
                }else if(type === 'minute'){
                    return `${val}分`;
                }else if(type === 'second'){
                    return `${val}秒`;
                }
                return val;
        },
        // 减价售卖每小时
        onConfirm1(e){
            console.log(e);
            this.goods.times = e.value
            this.goodsPriceInfo.reduceCycleType = e.id
            this.showPickerstime = false
        },
        // 分类
        classFun(e){
            console.log(e);
            console.log(this.goods.goodsclassvalue);
            
            if (this.goods.goodsclassvalue.indexOf(e.value) != -1) {
            this.goods.goodsclass.splice(this.goods.goodsclass.indexOf(e.id), 1); //取消
            this.goods.goodsclassvalue = this.goods.goodsclassvalue.filter(item=>item!=e.value)
            } else {
            this.goods.goodsclass.push(e.id);//选中添加到数组里
            this.goods.goodsclassvalue.push(e.value);//选中添加到数组里
            }
            },
        // 品类
        cateFun(e){
            this.goods.cate = e.value
        },
        back() {
            this.$router.go(-1);
        },
        handleAvatarSuccess(res, file) {
            console.log("res",res);
        },
        //查询字段是否为空
        allFieldsNotEmpty(obj){
            return Object.keys(obj).every(key => {
                const value = obj[key];
                return value !== null && value !== undefined && value !== '';
            });
        },
        //   发布
        async submitFun(){
            var pricedate = {}
            if(this.goods.sales == 0) {
                var miaosha  = {
                    ssmPrice: this.ssmPrice,
                    startTime:this.cxstarttime,
                    endTime:this.cxendtime
                }
                pricedate = miaosha
            }else if(this.goods.sales ==1) {
                var fenduan = {
                    ssmPrice: this.ssmPrice,
                }
                pricedate = fenduan
                var arr = []
                var fd = {}
                fd.peopleNums = Number(this.fd_num)
                fd.reducePrice = this.selectid == 1 ? Number(this.fd_mon) : ''
                fd.reducePriceRatio = this.selectid == 1 ? '': Number(this.fd_per)
                fd.startTime = this.cxstarttime,
                fd.endTime = this.cxendtime
                arr.push(fd)
                if(this.fd_numlist == 2) {
                    var fds = {}
                    fds.peopleNums = Number(this.fd_num2)
                    fds.reducePrice = this.selectid2 == 1 ? Number(this.fd_mon2) : ''
                    fds.reducePriceRatio = this.selectid2 == 1 ? '': Number(this.fd_per2)
                    fds.startTime = this.cxstarttime2,
                    fds.endTime = this.cxendtime2
                    arr.push(fds)
                } else if(this.fd_numlist == 3) {
                    var fds = {}
                    fds.peopleNums = Number(this.fd_num2)
                    fds.reducePrice = this.selectid2 == 1 ? Number(this.fd_mon2) : ''
                    fds.reducePriceRatio = this.selectid2 == 1 ? '': Number(this.fd_per2)
                    fds.startTime = this.cxstarttime2,
                    fds.endTime = this.cxendtime2
                    var fdss = {}
                    fdss.peopleNums = Number(this.fd_num3)
                    fdss.reducePrice = this.selectid3 == 1 ? Number(this.fd_mon3) : ''
                    fdss.reducePriceRatio = this.selectid3 == 3 ? '': Number(this.fd_per3)
                    fdss.startTime = this.cxstarttime3,
                    fdss.endTime = this.cxendtime3
                    arr.push(fds,fdss)
                }
                console.log("分段list",arr);
                pricedate.goodsPiecewiseList = arr
            } else if (this.goods.sales ==2) {
                var heshi = {
                    countryPrice: this.mon_con,
                    ssmPrice: this.ssmPrice,
                    reduceCycleType: Number(this.everyid),
                    reduceCyclePrice: this.heshiid == 1 ? this.heshi_y:'100',
                    reduceCyclePriceRatio: this.heshiid == 2 ? this.heshi_b:'100',
                    endTime: this.cxendtime,
                    startTime: this.cxstarttime,
                    minPrice:this.minprice
                }
                console.log(heshi);
                pricedate = heshi
            } else if (this.goods.sales ==3) {
                var paimai = {
                    startPrice: this.start_mon,
                    startTime: this.cxstarttime,
                    endTime: this.cxendtime,
                    securityDeposit:this.promise_mon
                }
                pricedate = paimai
            }else if(this.goods.sales ==4) {
                var miaosha  = {
                    ssmPrice: this.ssmPrice,
                }
                pricedate = miaosha
            }
            // 秒杀商品参数
            var v_info = {
                url:this.videoinfo.videoCoverUrl
            }
            console.log(this.allFieldsNotEmpty(pricedate),'--------------');
            /* if(this.allFieldsNotEmpty(pricedate)) { */
                if(this.result.length == 0) {
                    Toast('请选择商品规格')
                     } else if(this.goods.sales ==3 && this.result.length >1 ) {
                        Toast('拍卖商品规格只能选择一种')
                    }else {
                    var data = {
                        brandId:this.brand_id,
                        name:this.good_name,
                        category:this.resultstr,
                        videoId:this.videoinfo.videoId,
                        videoImage:v_info,
                        coverImage:this.imgUrl,
                        shareImage:this.imgUrl4,
                        url:this.imgUrl1+'#####' +this.imgUrl2+'#####' +this.imgUrl3 ,
                        status:1,
                        type:this.goods.sales,
                        brandCategory:this.pltext,
                        newType:this.radio == '1' ? 0 : this.newid,
                        skuSpecification:this.result,
                        goodsPriceInfo:pricedate,
                        deliveryTime:this.send_time == '自定义' ? this.zidingyi_time : this.send_time,
                        freightType:Number(this.yunfei),
                        sevenNoReason:Number(this.isseven)
                    }
                    var data1 = {
                        id:this.goods_id,
                        name:this.good_name,
                        category:this.resultstr,
                        videoId:this.videoinfo.videoId,
                        videoImage:v_info,
                        coverImage:this.imgUrl,
                        shareImage:this.imgUrl4,
                        url:this.imgUrl1+'#####' +this.imgUrl2+'#####' +this.imgUrl3 ,
                        status:0,
                        type:this.goods.sales,
                        brandCategory:this.pltext,
                        newType:this.radio == '1' ? 0 : this.newid,
                        skuSpecification:this.result,
                        goodsPriceInfo:pricedate,
                        deliveryTime:this.send_time == '自定义' ? this.zidingyi_time : this.send_time,
                        freightType:Number(this.yunfei),
                        sevenNoReason:Number(this.isseven)
                    }
                    if(this.goods_id == undefined || this.goods_id == '') {
                        
                        /* if(this.allFieldsNotEmpty(data)) {
                            
                            if(this.imgUrl.indexOf('pic_img') !== -1 || this.imgUrl1.indexOf('pic_img') !== -1 || this.imgUrl2.indexOf('pic_img') !== -1 || this.imgUrl3.indexOf('pic_img') !== -1 || this.imgUrl4.indexOf('pic_img') !== -1 || this.imgUrl4.indexOf('pic_img') !== -1|| this.videoinfo.videoCoverUrl.indexOf('pic_video') !== -1){
                                    Toast('请上传完整图片或视频')
                                } else { */
                                    var res = await saveGoodsInfo(data);
                                    console.log("发布商品112233",res);
                                    if(res.code == 200) {
                                        Toast('保存成功'),
                                        setTimeout(() => {
                                            this.$router.go(-1);
                                        }, 300);
                                    } else {
                                        Toast(res.msg)
                                    }
                          /*       }
                        } else {
                            Toast('请补充商品信息')
                        } */
                    } else {
                        if(this.allFieldsNotEmpty(data1)) {
                            if(this.imgUrl.indexOf('pic_img') !== -1 || this.imgUrl1.indexOf('pic_img') !== -1 || this.imgUrl2.indexOf('pic_img') !== -1 || this.imgUrl3.indexOf('pic_img') !== -1 || this.imgUrl4.indexOf('pic_img') !== -1 || this.imgUrl4.indexOf('pic_img') !== -1|| this.videoinfo.videoCoverUrl.indexOf('pic_video') !== -1){
                                    Toast('请上传完整图片或视频')
                                } else {
                                    var res = await updateGoodsInfo(data1);
                                    console.log("编辑商品",res);
                                    if(res.code == 200) {
                                    Toast('保存成功'),
                                    setTimeout(() => {
                                        this.$router.go(-1);
                                    }, 300);
                                    } else {
                                        Toast(res.msg)
                                    }
                                }
                            
                        } else {
                            Toast('请补充商品信息')
                        }
                    }
                   
                }
           /*  } else {
                Toast('请补充完整商品信息')
            } */
        },
        //   保存商品
        async save(){
            var pricedate = {}
            if(this.goods.sales == 0) {
                var miaosha  = {
                    ssmPrice: this.ssmPrice,
                    startTime:this.cxstarttime,
                    endTime:this.cxendtime
                }
                pricedate = miaosha
            }else if(this.goods.sales ==1) {
                var fenduan = {
                    ssmPrice: this.ssmPrice,
                }
                pricedate = fenduan
                var arr = []
                var fd = {}
                fd.peopleNums = Number(this.fd_num)
                fd.reducePrice = this.selectid == 1 ? Number(this.fd_mon) : ''
                fd.reducePriceRatio = this.selectid == 1 ? '': Number(this.fd_per)
                fd.startTime = this.cxstarttime,
                fd.endTime = this.cxendtime
                arr.push(fd)
                if(this.fd_numlist == 2) {
                    var fds = {}
                    fds.peopleNums = Number(this.fd_num2)
                    fds.reducePrice = this.selectid2 == 1 ? Number(this.fd_mon2) : ''
                    fds.reducePriceRatio = this.selectid2 == 1 ? '': Number(this.fd_per2)
                    fds.startTime = this.cxstarttime2,
                    fds.endTime = this.cxendtime2
                    arr.push(fds)
                } else if(this.fd_numlist == 3) {
                    var fds = {}
                    fds.peopleNums = Number(this.fd_num2)
                    fds.reducePrice = this.selectid2 == 1 ? Number(this.fd_mon2) : ''
                    fds.reducePriceRatio = this.selectid2 == 1 ? '': Number(this.fd_per2)
                    fds.startTime = this.cxstarttime2,
                    fds.endTime = this.cxendtime2
                    var fdss = {}
                    fdss.peopleNums = Number(this.fd_num3)
                    fdss.reducePrice = this.selectid3 == 1 ? Number(this.fd_mon3) : ''
                    fdss.reducePriceRatio = this.selectid3 == 3 ? '': Number(this.fd_per3)
                    fdss.startTime = this.cxstarttime3,
                    fdss.endTime = this.cxendtime3
                    arr.push(fds,fdss)
                }
                console.log("分段list",arr);
                pricedate.goodsPiecewiseList = arr
            } else if (this.goods.sales ==2) {
                var heshi = {
                    countryPrice: this.mon_con,
                    ssmPrice: this.ssmPrice,
                    reduceCycleType: Number(this.everyid),
                    reduceCyclePrice: this.heshiid == 1 ? this.heshi_y:'100',
                    reduceCyclePriceRatio: this.heshiid == 2 ? this.heshi_b:'100',
                    endTime: this.cxendtime,
                    startTime: this.cxstarttime,
                    minPrice:this.minprice
                }
                console.log(heshi);
                pricedate = heshi
            } else if (this.goods.sales ==3) {
                var paimai = {
                    startPrice: this.start_mon,
                    startTime: this.cxstarttime,
                    endTime: this.cxendtime,
                    securityDeposit:this.promise_mon
                }
                pricedate = paimai
            }else if(this.goods.sales ==4) {
                var miaosha  = {
                    ssmPrice: this.ssmPrice,
                }
                pricedate = miaosha
            }
            var v_info = {
                url:this.videoinfo.image.url
            }
            if(this.allFieldsNotEmpty(pricedate)) {
                if(this.result.length == 0) {
                    Toast('请选择商品规格')
                    } else if(this.goods.sales ==3 && this.result.length >1 ) {
                        Toast('拍卖商品规格只能选择一种')
                    }else {
                    var data = {
                        brandId:this.brand_id,
                        name:this.good_name,
                        category:this.resultstr,
                        videoId:this.videoinfo.videoId,
                        videoImage:v_info,
                        coverImage:this.imgUrl,
                        shareImage:this.imgUrl4,
                        url:this.imgUrl1+'#####' +this.imgUrl2+'#####' +this.imgUrl3 ,
                        status:0,
                        type:this.goods.sales,
                        brandCategory:this.pltext,
                        newType:this.radio == '1' ? 0 : this.newid,
                        skuSpecification:this.result,
                        goodsPriceInfo:pricedate,
                        deliveryTime:this.send_time == '自定义' ? this.zidingyi_time : this.send_time,
                        freightType:Number(this.yunfei),
                        sevenNoReason:Number(this.isseven)
                    }
                    var data1 = {
                        id:this.goods_id,
                        name:this.good_name,
                        category:this.resultstr,
                        videoId:this.videoinfo.videoId,
                        videoImage:v_info,
                        coverImage:this.imgUrl,
                        shareImage:this.imgUrl4,
                        url:this.imgUrl1+'#####' +this.imgUrl2+'#####' +this.imgUrl3 ,
                        status:0,
                        type:this.goods.sales,
                        brandCategory:this.pltext,
                        newType:this.radio == '1' ? 0 : this.newid,
                        skuSpecification:this.result,
                        goodsPriceInfo:pricedate,
                        deliveryTime:this.send_time == '自定义' ? this.zidingyi_time : this.send_time,
                        freightType:Number(this.yunfei),
                        sevenNoReason:Number(this.isseven)
                    }
                    
                    if(this.goods_id == undefined || this.goods_id == '') {
                        if(this.goods.sales == 2) {
                            data.uniqueFlag = Number(this.isonly)
                        }
                        console.log("新增data",data);
                        
                        if(this.allFieldsNotEmpty(data)) {
                            if(this.imgUrl.indexOf('pic_img') !== -1 || this.imgUrl1.indexOf('pic_img') !== -1 || this.imgUrl2.indexOf('pic_img') !== -1 || this.imgUrl3.indexOf('pic_img') !== -1 || this.imgUrl4.indexOf('pic_img') !== -1 || this.videoinfo.image.url.indexOf('pic_video') !== -1){
                                    Toast('请上传完整图片或视频')
                                } else {
                                    var res = await saveGoodsInfo(data);
                                    console.log("保存促销商品",res);
                                    if(res.code == 200) {
                                        Toast('保存成功'),
                                        setTimeout(() => {
                                            this.$router.go(-1);
                                        }, 300);
                                    } else {
                                        Toast(res.msg)
                                    }
                                }
                            
                        } else {
                            Toast('请补充商品信息')
                        }
                    } else {
                        if(this.goods.sales == 2) {
                            data1.uniqueFlag = Number(this.isonly)
                        }
                        
                        console.log("编辑data",data1);
                        if(this.allFieldsNotEmpty(data1)) {
                                if(this.imgUrl.indexOf('pic_img') !== -1 || this.imgUrl1.indexOf('pic_img') !== -1 || this.imgUrl2.indexOf('pic_img') !== -1 || this.imgUrl3.indexOf('pic_img') !== -1 || this.imgUrl4.indexOf('pic_img') !== -1 || this.videoinfo.image.url.indexOf('pic_video') !== -1){
                                     Toast('请上传完整图片或视频')
                                    } else {
                                        var res = await updateGoodsInfo(data1);
                                        console.log("编辑促销商品",res);
                                        if(res.code == 200) {
                                            Toast('保存成功'),
                                            setTimeout(() => {
                                                this.$router.go(-1);
                                            }, 300);
                                        } else {
                                            Toast(res.msg)
                                        }
                                    }
                        } else {
                            Toast('请补充商品信息')
                        }
                    }
                   
                }
            } else {
                Toast('请补充完整商品信息')
            }
        },
        // 查询品牌下面的商品列表
        async getggList() {
                const res = await findList({});
                console.log("查询规格列表",res);
                if(res.data) {
                    this.default_guige = res.data
                }
        },
        //封面图
        coverfun(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'coverfn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        coverfn(e){
            this.imgUrl = e.data[0]
        },
        //详情图
        shopdetailfun(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'shopdetailfn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        shopdetailfn(e){
            this.imgUrl1 = e.data[0]
        },
        shopdetailfuns(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'shopdetailfns'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        shopdetailfns(e){
            this.imgUrl2 = e.data[0]
        },
        shopdetailfunss(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'shopdetailfnss'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        shopdetailfnss(e){
            this.imgUrl3 = e.data[0]
        },
        //分享图
        sharefun(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'sharefn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        sharefn(e){
            this.imgUrl4 = e.data[0]
        },
        //视频
        videofun(){
            var data = {
                action:'GetVideo',
                req:{ minute:180},
                reqId:3,
                respFunction:'videofn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        videofn(e){
            console.log('宣传视频',e);
            this.videoinfo.image.url = e.data.videoCoverUrl
            this.videoinfo.videoId = e.data.videoId
        },
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    computed:{
        isObjectEmpty() {
            for (let key in this.$route.params) {
                if (this.$route.params.hasOwnProperty(key)) {
                return false;
                }
            }
            return true;
        }
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        this.brand_id = this.$route.query.brand_id
        this.top_text = this.$route.params.good_id ? '编辑商品' : '添加新商品'
        await this.getggList();
        console.log(111,this.$route.query);
        // 查询商品详情
        if(!this.isObjectEmpty) {
            let goodsclass = []
            let kucun = []
            let result = [];
            this.goods_id = this.$route.params.good_id
            const res = await queryGoodsInfo(this.$route.params.good_id);
                console.log("查询商品信息",res);

                this.imgUrl1 = res.data.url.split('#####')[0],
                this.imgUrl2 = res.data.url.split('#####')[1],
                this.imgUrl3 = res.data.url.split('#####')[2],
                this.imgUrl = res.data.coverImage,
                this.imgUrl4 = res.data.shareImage,
                this.videoinfo = res.data.videoResponse,
                
                this.good_name = res.data.name,
                this.result_arr = res.data.category.split(','),
                this.resultstr = res.data.category
                this.pltext = res.data.brandCategory,
                this.radio = res.data.newType == 0 ? '1' : '2'
                this.newid = res.data.newType == 0 ? '' : res.data.newType
                this.newvalue = res.data.newType == 0 ? '' : this.newlist[res.data.newType - 1].val
                this.imgUrl = res.data.coverImage,
                this.yunfei = res.data.freightType.toString()
                this.goods.sales = res.data.type
                this.send_time = res.data.deliveryTime
                this.zidingyi_time = res.data.deliveryTime == '自定义' ? '' : res.data.deliveryTime
                this.result = res.data.skuSpecification
                this.isseven = res.data.sevenNoReason.toString()
                if(res.data.type == 0) {
                    this.ssmPrice = res.data.goodsPriceInfo.ssmPrice
                    this.cxstarttime = res.data.goodsPriceInfo.startTime
                    this.cxendtime = res.data.goodsPriceInfo.endTime
                }else if(res.data.type == 1) {
                        this.fd_numlist = res.data.goodsPriceInfo.goodsPiecewiseList.length
                        this.ssmPrice = res.data.goodsPriceInfo.ssmPrice
                        this.cxstarttime = res.data.goodsPriceInfo.goodsPiecewiseList[0].startTime
                        this.cxendtime = res.data.goodsPriceInfo.goodsPiecewiseList[0].endTime
                        this.selectid = res.data.goodsPriceInfo.goodsPiecewiseList[0].reducePrice ? '1' :'2'
                        this.selectval = res.data.goodsPriceInfo.goodsPiecewiseList[0].reducePrice ? '元' :'百分比'
                        this.fd_mon = res.data.goodsPriceInfo.goodsPiecewiseList[0].reducePrice ? res.data.goodsPriceInfo.goodsPiecewiseList[0].reducePrice : ''
                        this.fd_per = res.data.goodsPriceInfo.goodsPiecewiseList[0].reducePriceRatio ? res.data.goodsPriceInfo.goodsPiecewiseList[0].reducePriceRatio : ''
                        this.fd_num = res.data.goodsPriceInfo.goodsPiecewiseList[0].peopleNums
                    if(res.data.goodsPriceInfo.goodsPiecewiseList.length == 2) {
                        this.cxstarttime2 = res.data.goodsPriceInfo.goodsPiecewiseList[1].startTime
                        this.cxendtime2 = res.data.goodsPriceInfo.goodsPiecewiseList[1].endTime
                        this.selectid2 = res.data.goodsPriceInfo.goodsPiecewiseList[1].reducePrice ? '1' :'2'
                        this.selectval2 = res.data.goodsPriceInfo.goodsPiecewiseList[1].reducePrice ? '元' :'百分比'
                        this.fd_mon2 = res.data.goodsPriceInfo.goodsPiecewiseList[1].reducePrice ? res.data.goodsPriceInfo.goodsPiecewiseList[1].reducePrice : ''
                        this.fd_per2 = res.data.goodsPriceInfo.goodsPiecewiseList[1].reducePriceRatio ? res.data.goodsPriceInfo.goodsPiecewiseList[1].reducePriceRatio : ''
                        this.fd_num2 = res.data.goodsPriceInfo.goodsPiecewiseList[1].peopleNums
                    } else if(res.data.goodsPriceInfo.goodsPiecewiseList.length == 2){
                        this.cxstarttime3 = res.data.goodsPriceInfo.goodsPiecewiseList[2].startTime
                        this.cxendtime3 = res.data.goodsPriceInfo.goodsPiecewiseList[2].endTime
                        this.selectid3 = res.data.goodsPriceInfo.goodsPiecewiseList[2].reducePrice ? '1' :'2'
                        this.selectval3 = res.data.goodsPriceInfo.goodsPiecewiseList[2].reducePrice ? '元' :'百分比'
                        this.fd_mon3 = res.data.goodsPriceInfo.goodsPiecewiseList[2].reducePrice ? res.data.goodsPriceInfo.goodsPiecewiseList[2].reducePrice : ''
                        this.fd_per3 = res.data.goodsPriceInfo.goodsPiecewiseList[2].reducePriceRatio ? res.data.goodsPriceInfo.goodsPiecewiseList[2].reducePriceRatio : ''
                        this.fd_num3 = res.data.goodsPriceInfo.goodsPiecewiseList[2].peopleNums
                    }
                }else if(res.data.type == 2) {
                    this.ssmPrice = res.data.goodsPriceInfo.ssmPrice
                    this.cxstarttime = res.data.goodsPriceInfo.startTime
                    this.cxendtime = res.data.goodsPriceInfo.endTime
                    this.isonly = res.data.uniqueFlag.toString
                    this.heshi_y = res.data.goodsPriceInfo.reduceCyclePrice == '' ? '' : res.data.goodsPriceInfo.reduceCyclePrice
                    this.heshi_b = res.data.goodsPriceInfo.reduceCyclePriceRatio == '' ? '' : res.data.goodsPriceInfo.reduceCyclePriceRatio
                    this.heshiid = res.data.goodsPriceInfo.reduceCyclePrice == '' ? '2' : '1'
                    this.heshivalue = res.data.goodsPriceInfo.reduceCyclePrice == '' ? '百分比' : '元'
                    this.mon_con = res.data.goodsPriceInfo.countryPrice
                    this.everyid = res.data.goodsPriceInfo.reduceCycleType.toString()
                    this.everyval = res.data.goodsPriceInfo.reduceCycleType == 0 ? '每小时' :'每半小时'
                    this.minprice = res.data.goodsPriceInfo.minPrice
                } else if(res.data.type == 3) {
                    this.start_mon = res.data.goodsPriceInfo.startPrice
                    this.cxstarttime = res.data.goodsPriceInfo.startTime
                    this.cxendtime = res.data.goodsPriceInfo.endTime
                    this.promise_mon = res.data.goodsPriceInfo.securityDeposit
                }
                
                for (let i = 0; i < res.data.skuSpecification.length; i++) {
                    for (let j = 0; j < res.data.skuSpecification[i].items.length; j++) {
                        res.data.skuSpecification[i].name = res.data.skuSpecification[i].items.map(item => item.name).join('/'); 
                        res.data.skuSpecification[i].itemIdList = res.data.skuSpecification[i].items.map(item => item.itemId).join(','); 
                        res.data.skuSpecification[i].itemIdList = res.data.skuSpecification[i].itemIdList.split(',')
                        res.data.skuSpecification[i].itemIdList = res.data.skuSpecification[i].itemIdList.map(num => +num)
                    }
                    
                }
                for (let i = 0; i < res.data.skuSpecification.length; i++) {
                    for (let j = 0; j < res.data.skuSpecification[i].itemIdList.length; j++) {
                        goodsclass.push(res.data.skuSpecification[i].itemIdList[j])
                    }
                }
                this.kucunclass = Array.from(new Set(goodsclass))

                for (let i = 0; i < this.default_guige.length; i++) {
                    for (let j = 0; j < this.default_guige[i].itemList.length; j++) {
                        for (let k = 0; k < this.kucunclass.length; k++) {
                            if(this.default_guige[i].itemList[j].id == this.kucunclass[k])
                            kucun.push(this.default_guige[i].itemList[j])
                        }
                    }
                }

                this.kucun = kucun
                console.log("查询选中子规格对象",kucun);
                console.log("查询选中子规格",this.kucunclass);

                if(res.data.specificationInfoItemListResponses.length == 3) {
                    var zdy = {
                        id:res.data.specificationInfoItemListResponses[2].specificationId,
                        name:res.data.specificationInfoItemListResponses[2].specificationName,
                        itemList:res.data.specificationInfoItemListResponses[2].specificationItemList
                    }
                    this.default_guige.push(zdy)
                }
                this.result = res.data.skuSpecification
                this.resultnum = res.data.skuSpecification.length 


                console.log(this.goods);
                
        }
        //分类查询
        const res4 = await queryGoodsCategoryByBrand({brandId:this.brand_id})
        console.log("查询一级分类",res4);
        this.resultlist = res4.data
        //品类查询
        const res3 = await brandCategoryList({})
        console.log("品类2级",res3);
        this.options = res3.data

        window.coverfn = this.coverfn
        window.shopdetailfn = this.shopdetailfn
        window.shopdetailfns = this.shopdetailfns
        window.shopdetailfnss = this.shopdetailfnss
        window.sharefn = this.sharefn
        window.videofn = this.videofn
    }
}
</script>
<style lang="less" scoped>
.bottom_fixed {
    width: 100%;
    height: 90px;
    background: #E9D4BC;
    border-top: 1px solid rgba(223,192,156,0.66);
    padding: 0 15px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    .asd {
        display: flex;
        align-items: center;
        justify-content: space-between; 
        margin-top: 7px;
    }
    .bot {
        width: 49%;
        height: 48px;
        line-height: 48px;
        border-radius: 8px;
        background: rgba(174,133,81,0.15);
        color: #37261B;
        font-size: 18px;
        text-align: center;
        font-family: PingFangSC-regular;
    }
}
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .flex {
        display: flex;
        align-items: center;
        margin:2px 0;
        .send {
            border: 1px solid rgba(187,187,187,1);
            padding: 2px 4px;
            box-sizing: border-box;
        }
        .acsend {
            color: #fff;
            background-color: rgba(240,154,25,1);
            border: 1px solid rgba(240,154,25,1);
        }
        .sc {
            font-size: 14px;
            padding: 10px 15px;
            min-width: 50px;
            text-align: center;
            box-sizing: border-box;
            border-radius: 8px;
            background: rgba(174,133,81,0.15);
            color:rgba(55,38,27,0.5);
            margin-right: 4px;
            position: relative;
            .close {
                position: absolute;
                top: -5px;
                right: -5px;
                /* width: 16px;
                height: 16px;
                line-height: 12px;
                text-align: center;
                border-radius: 8px;
                border: 1px solid #A39F9F;
                color: #A39F9F;
                font-size: 12px; */
            }
        }
        .title {
            width: 80px;
        }
        .lx {
            width: 136px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background-color: rgba(255,255,255,1);
            color: rgba(16,16,16,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            border: 1px solid rgba(187,187,187,1);
        }
        .ipt {
            width: 275px;
            height: 28px;
            background-color: rgba(255,255,255,1);
            border: 1px solid rgba(187,187,187,1);
            padding-left: 10px;
            box-sizing: border-box;
        }
        .type_item {
            width: 44px;
            height: 23px;
            line-height: 23px;
            border-radius: 5px;
            background-color: rgba(247,241,241,1);
            color: rgba(136,124,124,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            margin-right: 4px;
            margin-top: 2px;
        }
        .vid {
            width: 92px;
            height: 34px;
            line-height: 34px;
            background-color: rgba(255,255,255,1);
            color: rgba(159,158,158,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            border: 1px solid rgba(187,187,187,1);
        }
        .save {
            width: 53px;
            height: 33px;
            line-height: 33px;
            border-radius: 5px;
            background-color: rgba(163,159,159,0.97);
            color: rgba(255,255,255,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
        }
        .sent {
            width: 280px;
            height: 33px;
            line-height: 33px;
            border-radius: 5px;
            background-color: rgba(174,133,81,0.97);
            color: rgba(255,255,255,1);
            font-size: 14px;
            margin-left: 6px;
            text-align: center;
            font-family: PingFangSC-regular;
        }
    }
    .guige_box {
        width: 100%;
        padding: 30px 20px;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15);
        border-radius: 8px;
        margin-bottom: 10px;
    }
    .active {
        background-color: #876235 !important;
        color: #E9D4BC !important;
    }
    .guige {
        width: 80px;
        height: 33px;
        line-height: 33px;
        border-radius: 8px;
        background-color: #876235;
        color: rgba(247,241,241,1);
        font-size: 14px;
        text-align: center;
        font-family: PingFangSC-regular;
    }
    .every {
        padding: 0 5px;
        box-sizing: border-box;
        height: 30px;
        border-radius: 6px;
        background-color: rgba(255,255,255,1);
        color: rgba(16,16,16,1);
        font-size: 14px;
        font-family: PingFangSC-regular;
        border: 1px solid rgba(187,187,187,1);
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}
.same {
        width: 48%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-size: 18px;
    }
    .a {
        background-color: #876235 ;
        color: #fff;
    }
    .b {
        background-color: rgba(174,133,81,0.15);
        color: #000;
    }
    .input_fild {
   margin-top: 10px;
   padding:10px 15px;
   box-sizing: border-box;
   background: rgba(174,133,81,0.15);
   border-radius: 8px;
   color: #000;
}
:deep(.van-field__control::-webkit-input-placeholder) {
   color:rgba(55,38,27,0.5);
   font-size: 14px;
}
.btns {
       width: 100%;
       height: 40px;
       border-radius: 5px;
       background-color: #876235;
       line-height: 40px;
       text-align: center;
       color: rgba(255, 255, 255, 1);
       margin-top: 20px;
   }
   .van-sidebar-item--select, .van-sidebar-item--select:active{
        color:#475AD5;
    }
    .van-tree-select__item--active {
        color:#475AD5;
    }
    .van-sidebar-item--select::before {
        background-color: #475AD5;
    }
    .type_items {
        width: 100px;
        height: 30px;
        line-height: 30px;
        border-radius: 5px;
        background: rgba(174,133,81,0.15);
        color: rgba(136,124,124,1);
        font-size: 14px;
        text-align: center;
        font-family: PingFangSC-regular;
        margin-right: 4px;
        margin-top: 2px;
    }
    .bot_btn{
        width: 48%;
        height: 48px;
        border-radius: 5px;
        background-color: rgba(174,133,81,0.15);
        border: none;
        color: #000;
        margin-top: 20px;
    }
    .bot_btns{
        width: 48%;
        height: 48px;
        border-radius: 5px;
        background-color: #876235;
        border: none;
        color: #fff;
        margin-top: 20px;
    }
    .van-tree-select__item {
        font-weight: normal;
    }
    .van-sidebar-item--select, .van-sidebar-item--select:active{
        color:#475AD5;
    }
    .van-tree-select__item--active {
        color:#475AD5;
    }
    .van-sidebar-item--select::before {
        background-color: #475AD5;
    }
    .van-popup {
        background-color: #E9D4BC;
    }
    .van-sidebar-item--select, .van-sidebar-item--select:active {
        background-color: #E9D4BC;
    }
    .van-sidebar-item {
        background-color: #E9D4BC;
    }
    .van-tree-select__nav{
        background-color: rgba(174,133,81,0.15);
    }
    .van-tree-select__content{
        background-color: #E9D4BC;
    }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
    .van-cell::after {
        border-bottom: 1px solid #E9D4BC !important;
    }
</style>
