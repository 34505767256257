import wx from 'weixin-js-sdk'
import wxApi from '@/api/integralTask'
import config from "@/config"
import {tools} from "@/utils/index";

/**
 * @description: 获取微信签名
 * @param {Function} success 成功回调函数
 * @param {Array} jsApiList JSSDK Api列表
 * @return: void
//  */

export default async ({ jsApiList = ['chooseImage'], openTagList = ['wx-open-launch-app'], success }) => {
    const { data } = await wxApi({ url: location.href.split('#')[0] })
    // console.log(data,"123123")
    const { timestamp, nonceStr, signature } = data
    wx.config({
        // debug: config.env !== 'production',
        // debug: true,
        appId: config.appId,
        timestamp,
        nonceStr,
        signature,
        jsApiList,
        openTagList
    })
    wx.ready(() => {
        success && typeof success === 'function' && success()
    })
}


