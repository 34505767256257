<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" left-arrow title="商品收益" @click-left="back" />
        </van-sticky>
        <div class="container">
            <div class="box1" style="color: #000;">
                待提现余额<span style="font-size: 25px;color: #475AD5;margin-left: 10px;"><span style="font-size: 18px;">¥</span>0</span>
            </div>
            <div class="box1" style="color: #000;margin: 10px 0;">
                拍卖保证金收入<span style="font-size: 25px;color: #475AD5;margin-left: 10px;"><span style="font-size: 18px;">¥</span>0</span>
            </div>
            <div class="box1" style="color: #000;">
                结算后余额<span style="font-size: 25px;color: #475AD5;margin-left: 10px;"><span style="font-size: 18px;">¥</span>0</span>
            </div>
            <div class="box1" style="color: #000; margin: 10px 0;">
                总成交额<span style="font-size: 25px;color: #475AD5;margin-left: 10px;"><span style="font-size: 18px;">¥</span>0</span>
            </div>
            <div class="box1" style="color: #000;">
                当月成交额<span style="font-size: 25px;color: #475AD5;margin-left: 10px;"><span style="font-size: 18px;">¥</span>0</span>
            </div>
        </div>
        <div style="color: rgba(55,38,27,0.5);position: relative;bottom: 20px;left: 0;font-size: 15px;padding: 0 10px;box-sizing: border-box;margin-top: 20px;">
            <div>连续3个自然日保证金余额 < 保证金额度，暂停发布商品到橱窗的功能</div>
            <div>连续7个自然日保证金余额 < 保证金应缴金额，暂停发布商品到橱窗的功能，商品橱窗下架</div>
        </div>
    </div>
</template>
<script>
import { } from '@/api/goods';

export default {
    name: 'work',
    data() {
        return {
            mar_top:'',
        }
    },
    methods: {
        back() {
            this.$router.go(-1);
        },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log(this.$route.query);
         
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 

.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
/deep/ .van-step--horizontal .van-step__circle-container {
    background-color: rgba(0, 0, 0, 0);
}
.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-size: 14px;
    
    .box {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        .flex {
            display: flex;
            align-items: center;
        }
    }
    .box1 {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/deep/.van-step__circle  {
    width: 10px;
    height: 10px;
    background: rgba(97, 76, 47, 0.15); 
}
.wuliu_box {
    height: 300px;
    padding: 20px;
    box-sizing: border-box; 
    background-color: #E9D4BC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
}
.btn {
        width: 100%;
        border-radius: 5px;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        margin: 10px 0;
    }
    .paycss {
        width: 100px;
        height: 35px;
        border-radius: 8px;
        line-height: 35px;
        text-align: center;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        
    }
    .input_fild {
        margin-top: 10px;
        padding:10px 15px;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15);
        border-radius: 8px;
        color: #000;
    }
    :deep(.van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
