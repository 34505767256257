<template>
    <div class="page" >
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="薅羊毛" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container"> 
            <van-form @submit="onSubmit">   
                    <div style="margin-top: 20px;">类型 </div>
                    <van-field readonly clickable  :value="resultstr" class="input_fild"
                    right-icon="arrow-down" @click="cateshowPicker1 = true" placeholder="请选择" :rules="[{ required: true, message: '请选择类型' }]"  />
                    <van-popup v-model="cateshowPicker1" round position="bottom" style="padding: 20px;box-sizing: border-box;">
                        <div style="margin-bottom: 20px;">选择类型(多选)</div>
                        <van-checkbox-group v-model="result">
                            <van-checkbox checked-color="#475AD5" style="margin: 10px 0;" v-for="item in resultlist" :name="item">{{item}}</van-checkbox>
                        </van-checkbox-group>
                        <div class="row">
                            <div @click="lxfun" class="btns" >确认</div>
                        </div>
                    </van-popup>
                    <div style="margin-top: 20px;">品类 </div>
                    <van-field readonly clickable  :value="pltext" class="input_fild" 
                    :rules="[{ required: true, message: '请选择品类' }]" 
                    right-icon="arrow-down" @click="cateshowPicker = true" placeholder="请选择" />
                    <van-popup style="padding: 20px 0;box-sizing: border-box;" v-model="cateshowPicker" round position="bottom">
                        <div style="margin-bottom: 20px;margin-left: 10px;">选择品类(单选)</div>
                        <van-tree-select
                            :items="options"
                            :active-id.sync="activeId"
                            :main-active-index.sync="activeIndex"
                            @click-item="optionsfn"
                        />
                    </van-popup>
                    <div style="margin-top: 20px;">品牌</div>
                    <van-field class="input_fild"  autocomplete="off" v-model="brand" placeholder="请输入" maxlength="10"/> 
                    <div class="flex" style="margin-top: 20px;">
                        <div>成色</div>
                        <van-radio-group v-model="radio" direction="horizontal" style="margin-left: 40px;">
                            <van-radio name="1">全新</van-radio>
                            <van-radio name="2">老物件</van-radio>
                        </van-radio-group>
                    </div>
                    <template v-if="radio == 2">
                        <div style="margin-top: 20px;">几成新 </div>
                        <van-field readonly clickable  :value="newvalue" class="input_fild"
                        right-icon="arrow-down" @click="newshowPicker = true" placeholder="请选择" />
                        <van-popup v-model="newshowPicker" position="bottom">
                            <van-picker show-toolbar :columns="newlist" @confirm="onConfirmnew" value-key="val" @cancel="newshowPicker = false" />
                        </van-popup>
                    </template>
                    <div style="margin-top: 20px;">描述</div>
                    <van-field class="input_fild"  autocomplete="off" v-model="des" placeholder="请输入" maxlength="38"/> 
                        <div style="margin-top: 20px;">心里价格</div>
                    <van-field class="input_fild"  autocomplete="off" type="number" v-model="heartprice" placeholder="请输入" maxlength="10"/> 
                    <div class="row">
                        <van-button  native-type="submit" class="btn" >确认</van-button>
                    </div>
            </van-form>
        </div>
        <van-overlay :show="show" @click="show = false">
            <div class="wrapper" @click.stop>
                <div class="block">
                    <div>为您匹配了心仪的好礼物，请收下</div>
                    <div style="display: flex;align-items: center;margin: 20px 0;">
                        <van-image width="28" height="28" src="https://img01.yzcdn.cn/vant/cat.jpeg" round/>
                        <div>品牌名字</div>
                    </div>
                    <van-image fit="cover" @click="previewImage(info.coverImage)" width="200" height="200" :src="info.coverImage" />
                    <div style="display: flex;margin: 10px 0;color:rgba(55,38,27,0.5);">
                        <div>规格:</div>
                        <div>
                            <div v-for="item in guige">{{ item }}</div>
                        </div>
                    </div>
                    <div style="font-size: 28px;color: #475AD5;">¥400</div>
                    <div class="btns" @click="shoufn">收入囊中</div>
                    <div class="close" @click="show = false">x</div>
                </div>
               
            </div>
        </van-overlay>
    </div>
</template>
<script>
import { brandCategoryList,querySystemCategoryList,queryReverseBiddingGoods } from '@/api/goods';
import { ImagePreview, Toast } from 'vant';
export default {
   name: 'auction',
   data() {
       return {
        mar_top:'',
        info:{},
        guige:[],
        resultlist:[],
        result:[],
        resultstr:'',
        show:false,
        brand:'',
        type:'',
        columns: ['身份证'],
        showPicker:false,
        newshowPicker:false,
        cateshowPicker:false,
        cateshowPicker1:false,
        radio:'1',
        newvalue:'',
        newid:'',
        newlist:[
            {id:9,val:'九成新'},
            {id:8,val:'八成新'},
            {id:7,val:'七成新'},
            {id:6,val:'六成新'},
            {id:5,val:'五成新'},
            {id:4,val:'四成新'},
            {id:3,val:'三成新'},
            {id:2,val:'二成新'},
            {id:1,val:'一成新'}
        ],
        heartprice:'',
        des:'',
        cascaderValue: '',
        // 选项列表，children 代表子选项，支持多级嵌套
        options: [],
        pltext:"",
        activeId: '',
        activeIndex: 0,
        items:[{
          text: '分类1',
          id: 1,
          children: [
            { text: '分类1-1', id: 11, children: [] },
            {
              text: '分类1-2',
              id: 12,
              children: [
                { text: '分类1-2-1', id: 121, children: [] }
              ]
            }
          ]
        },
        {
          text: '分类2',
          id: 2,
          children: [
            { text: '分类2-1', id: 21, children: [] },
            { text: '分类2-2', id: 22, children: [] }
          ]
        }]
       }
   },
   methods: {
        //类型确认
        lxfun(){
            console.log(this.result);
            /* if(this.result.length < 2) {
                Toast('至少选择两种类型')
            } else {
            } */
            this.cateshowPicker1 = false
            this.resultstr = this.result.join(',')
            
        },
        //品类选择
        optionsfn(e){
            console.log("品类选择",e);
            this.activeId = e.id,
            this.pltext = e.text
            this.cateshowPicker = false
        },
        onConfirmnew(e){
            console.log("几成新",e);
            this.newid = e.id,
            this.newvalue = e.val
            this.newshowPicker = false
        },
        //确认
        async onSubmit(){
            console.log(111);
            const res = await queryReverseBiddingGoods({
                category:this.resultstr,
                brandCategory:this.activeId,
                price:this.heartprice,
                brand:this.des,
                newType:this.radio == 1 ? 0 : this.newid
            })
            console.log("查询心仪商品",res);
            if(res.data.length >= 1) {
                this.show = true
                this.info = res.data[0]
                console.log(guige);
                var guige = []
                for (let i = 0; i < res.data[0].skuSpecification.length; i++) {
                    const names = res.data[0].skuSpecification[i].items.map(item => item.name);  
                    guige.push(names)           
                }
                for (let i = 0; i < guige.length; i++) {
                    guige[i] = guige[i].join('/')
                }
                console.log("规格",guige);
                this.guige = guige
                
            } else {
                Toast('暂无合适商品')
            }
        },
        //收入囊中
        shoufn(){
            this.show = false
        },
       showPickerssfun(){
           this.showPickerss = true
           console.log(this.brandCategorys);
           
       },
       flfun(){
           this.showPickerss = false
           console.log(this.brandCategorys,111);
           this.brandCategoryss = this.brandCategorys.join(',')
           console.log(this.brandCategoryss,222);

       },
       previewImage(e){
            ImagePreview([e]);
       },
       back(){
        var data = {
                    action:'ClosePage',
                    req:{},
                    reqId:12,
                    respFunction:'closefn'
                }
            if(this.$Version() == 'iOS') {
                console.log("ios关闭当前页面");
                
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                console.log("Android关闭当前页面");
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
       },
       closefn(e){
            console.log("返回"),e;
        },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
        //获取二级分类
        async getTwo(){
            const res = await brandCategoryList({})
            console.log("品类2级111",res);
            this.options = res.data
        },
        //分类多选
        async getamount(){
            const res2 = await querySystemCategoryList({})
            console.log("分类多选222",res2);
            this.resultlist = res2.data
        },
   },
   //商品分类 1级 多选   品类 2级 单选 类目 三级 多选
   async mounted() {
        window.closefn = this.closefn
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
            action:'StatusBarInfo',
            req:{},
            reqId:13,
            respFunction:'marginFun'
        }
        if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
        } else {
            window.ssmAndroid.ssmMessage(JSON.stringify(data))
    }
       setTimeout(() => {
        this.getTwo()
        this.getamount()
       }, 300);
   }
}
</script>
<style lang="less" scoped>
.van-step__line {
 display: none;
}
.van-hairline--bottom:after {
 border-bottom-width: 0; 
} 
.page {
   background-color: #E9D4BC;
   &::before {
     content: ' ';
     position: fixed;
     z-index: -1;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     background: #E9D4BC;
     background-size: 100% auto;
   }
}
.container {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   overflow: auto;
   padding: 16px;
   font-size: 16px;
   .flex {
       display: flex;
   }
   .label {
       font-size: 14px;
   }
   .pop {
       padding: 20px;
       padding-bottom: 40px;
       box-sizing: border-box;
       border-top-left-radius: 8px;
       border-top-right-radius: 8px;
   }
   .row {
       margin: 10px 0;
       display: flex;
   }

   .category-right {
       display: flex;
       justify-content: space-around;
       align-items: center;
       height: 100%;
   }

   .tip {
       font-size: 10px;
       color: rgba(163, 159, 159, 0.97);
   }

   .underline {
       text-decoration: underline;
   }

   .row-item {
       margin: 8px;
       background-color: #E1C9AD;
       display: inline-flex;
       border-radius: 5px;

       .row-item-name {
           font-size: 12px;
           padding: 8px;
           min-width: 90px;
           text-align: center;
       }
   }

   .btn {
       width: 100%;
       height: 48px;
       border-radius: 12px;
       background-color: #876235;
       color: rgba(255, 255, 255, 1);
       margin-top: 20px;
   }

   .active {
       background-color: #876235 !important;
       color: white;
   }

   /deep/ .van-hairline--top-bottom::after,
   .van-hairline-unset--top-bottom::after {
       border-width: 1px;
   }

   .info-container {
       display: flex;
       flex-direction: column;
       align-items: center;
       // border: 1pt solid rgba(236,234,234,1);

       div {
           // margin: 10px 0;
       }

       span {
           font-size: 12px;
       }
       .result-tip {
           color: rgba(163, 159, 159, 0.97);
       }
   }
}
/deep/ .van-step--horizontal .van-step__circle-container {
   background-color: rgba(0, 0, 0, 0);
}
.input_fild {
   margin-top: 10px;
   padding:10px 15px;
   box-sizing: border-box;
   background: rgba(174,133,81,0.15);
   border-radius: 8px;
   color: #000;
}
:deep(.van-field__control::-webkit-input-placeholder) {
   color:rgba(55,38,27,0.5);
   font-size: 14px;
}
.te {
       border-radius: 8px;
       margin: 10px 0;
       background-color: rgba(174,133,81,0.15);
   }
   .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 300px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #E9D4BC;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    position: relative;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
  }
  .btns {
       width: 100%;
       height: 40px;
       border-radius: 5px;
       background-color: #876235;
       line-height: 40px;
       text-align: center;
       color: rgba(255, 255, 255, 1);
       margin-top: 20px;
   }
   .van-sidebar-item--select, .van-sidebar-item--select:active{
        color:#475AD5;
    }
    .van-tree-select__item--active {
        color:#475AD5;
    }
    .van-sidebar-item--select::before {
        background-color: #475AD5;
    }
    .van-tree-select__item {
        font-weight: normal;
    }
   .van-sidebar-item--select, .van-sidebar-item--select:active{
        color:#475AD5;
    }
    .van-tree-select__item--active {
        color:#475AD5;
    }
    .van-sidebar-item--select::before {
        background-color: #475AD5;
    }
    .van-popup {
        background-color: #E9D4BC;
    }
    .van-sidebar-item--select, .van-sidebar-item--select:active {
        background-color: #E9D4BC;
    }
    .van-sidebar-item {
        background-color: #E9D4BC;
    }
    .van-tree-select__nav{
        background-color: rgba(174,133,81,0.15);
    }
    .van-tree-select__content{
        background-color: #E9D4BC;
    }
     //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
    .van-cell::after {
    border-bottom: 1px solid #E9D4BC !important;
}
</style>
