<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="订单" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <!-- 顶部导航 -->
            <van-tabs @click="navigationFun" v-model="active" color="#000000" background="#E9D4BC" line-width="20px"  title-inactive-color="rgba(55,38,27,0.66)">
                <van-tab v-for="item in top_navigation" :title="item.val">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="暂无内容"
                        @load="onLoad"
                        :immediate-check="false"
                    >
                    <div class="box" v-for="(item,index) in list" :key="item.id" @click="detail(item)">
                       <div class="flex" style="justify-content: space-between;">
                           <div class="flex">
                           </div>
                           <div style="color: #475AD5;" v-if="item.status == 0">待用户付款</div>
                           <div style="color: rgba(55,38,27,0.33);" v-if="item.status == 1">交易关闭</div>
                           <div style="color: #475AD5;" v-if="item.status == 2">待发货</div>
                           <div style="color: #475AD5;" v-if="item.status == 3">支付失败</div>
                           <div style="color: #475AD5;" v-if="item.status == 4">退款</div>
                           <div style="color: #475AD5;" v-if="item.status == 5">退款成功</div>
                           <div style="color: #475AD5;" v-if="item.status == 6">退款失败</div>
                           <div style="color: #475AD5;" v-if="item.status == 8">待收货</div>
                           <div style="color: #475AD5;" v-if="item.status == 10">交易成功</div>
                           <div style="color: #475AD5;" v-if="item.status == 11">待开票</div>
                           <div style="color: #475AD5;" v-if="item.status == 12">已开票</div>

                       </div>
                       <div style="height: 1px;width: 100%;background-color:#DFC09C;margin: 10px 0;"></div>
                       <div class="flex" style="flex-direction: column;" v-for="items in item.orderItemList" :key="items.id">
                        <div class="flex" style="justify-content: space-between;">
                                <div class="flex">
                                <img :src="items.coverImage" class="imgs">
                                <div class="goodsn">
                                    <div style="font-size: 15px;" class="t">{{items.goodsName}}</div>
                                    <div style="font-size: 14px;color: rgba(55,38,27,0.33);display: flex;margin-right: 10px;">
                                        <div  v-for="guige in items.specificationItemNameList">{{guige}}</div>
                                    </div>
                                </div>
                                </div>
                                <div style="height: 60px;display: flex;flex-direction: column;justify-content: space-around;">
                                    <div style="font-size: 18px;color: #475AD5;">¥{{ items.amount }}</div>
                                    <div style="font-size: 14px;color: rgba(55,38,27,0.33);" >x{{items.num}}</div>
                                </div>
                           </div>
                           <div style="height: 1px;width: 100%;background-color:#DFC09C;margin: 10px 0;"></div>
                       </div>
                       <div class="flex" style="justify-content: space-between;margin-top: 10px;">
                           <!-- <div style="font-size: 12px;color: #B10808;">
                            <template v-if="item.status == 0">
                                <div class="flex">
                                    <span style="margin-right: 10px;">剩余支付时间</span>
                                    <van-count-down @finish="finishfun" style="color: #B10808;" :time="item.residuePayMilliSecond" />
                                </div>
                            </template>
                            <div @click="toLogisitics(item)" v-if="item.status == 4 && active != 4 || item.status == 8 && active != 4" style="font-size: 12px;color: rgba(55,38,27,0.5);text-decoration: underline;">查看物流信息</div>
                           </div> -->
                           <div style="font-size: 12px;color: #B10808;" >
                                <template v-if="item.status == 0">
                                    <div class="flex">
                                        <span style="margin-right: 10px;">剩余支付时间</span>
                                        <van-count-down @finish="finishfun" style="color: #B10808;" :time="item.residuePayMilliSecond" />
                                    </div>
                                </template>
                            </div> 
                           <div class="flex">
                               <div v-if="item.status == 0 " class="same cancel" @click.stop="ordercancel(item.id)">取消</div>
                               <div v-if="item.status == 1" class="same cancel"  @click.stop="deleteorders(item)">删除</div>
                               <div v-if="item.status == 2 " class="same recive" @click.stop="ordernum(item.id)">填写物流单号</div>
                               <div v-if="item.status == 4 " class="same recive" @click.stop="toLogisitics(item)">查看物流</div>
                               <div v-if="item.status == 8 " class="same recive" @click.stop="toLogisitics(item)">查看物流</div>
                               <div v-if="item.status == 10 " class="same recive" @click.stop="toLogisitics(item)">查看物流</div>
                               <div v-if="item.status == 11 " class="same recive" style="margin-left: 5px;" @click.stop="fp = true">上传发票</div>
                               <div v-if="item.status == 12" @click.stop="previewImage(item.invoice.invoiceUrl)" class="same recive">查看发票</div>
                               <!-- <div v-if=" item.status == 4" class="same recive" @click="detail(item)" style="margin-left: 5px;">售后详情</div> -->



                               <!-- <div v-if="item.status == 2 " class="same recive" @click="ordernum(item.id)">填写物流单号</div>
                               <div v-if="item.status == 0 " class="same cancel" @click="ordercancel(item.id)">取消</div>
                               <div v-if=" active == 4" class="same cancel" @click="detail(item)">售后详情</div> -->

                               <!-- 取消弹窗 -->
                               <!-- <van-overlay :show="showpaycancel">
                                    <div class="wrapper">
                                        <div class="tosat">
                                            <div>取消理由</div>
                                            <van-radio-group v-model="radio">
                                                <van-radio name="跟客户协商一致取消">跟客户协商一致取消</van-radio>
                                                <van-radio style="margin-top: 20px;" name="顾客要求取消">顾客要求取消</van-radio>
                                            </van-radio-group>
                                            <div class="flex" style="width: 200px;display: flex;justify-content: space-between;color: #887C7C;">
                                                <span @click="showpaycancel = false">取消</span>
                                                <span @click="determine">确定</span>
                                            </div>
                                        </div>
                                    </div>
                                </van-overlay> -->
                                <!-- 填写物流单号弹窗 -->
                                <!-- <van-overlay :show="showwuliu" overlay-style="background-color: rgba(0, 0, 0, 0.5);">
                                    <div class="wrapper">
                                        <div class="tosat">
                                            <div>填入物流单号</div>
                                                <div class="flex">
                                                    <div style="margin-right: 20px;">快递公司</div>
                                                    <el-select style="width: 160px;" @change="changefun" v-model="value" filterable placeholder="请选择">
                                                        <el-option
                                                        v-for="item in options"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                                <div class="flex">
                                                    <div style="margin-right: 20px;">快递单号</div>
                                                    <el-input style="width: 160px;" v-model="wuliunum" placeholder="请输入快递单号"></el-input>
                                                </div>
                                            <div class="flex" style="width: 200px;display: flex;justify-content: space-between;color: #887C7C;">
                                                <span @click="showwuliu = false">取消</span>
                                                <span @click="determinewuliu">确定</span>
                                            </div>
                                        </div>
                                    </div>
                                </van-overlay> -->
                                
                                <!-- !-- 不同意退款弹窗 -->
                                <!-- <van-overlay :show="unagreeshow">
                                    <div class="wrapper">
                                        <div class="tosat">
                                            <div>不同意理由</div>
                                            <input type="text" class="lx" v-model="refuextext">
                                            <div class="flex" style="width: 200px;display: flex;justify-content: space-between;color: #887C7C;">
                                                <span @click="unagreeshow = false">取消</span>
                                                <span @click="unagreefun">确定</span>
                                            </div>
                                        </div>
                                    </div>
                                </van-overlay> -->
                               <!-- <div v-if="item.status == 0" class="same sub" style="margin-left: 5px;">提醒</div> -->

                               <!-- <template v-if="item.status == 4 && active != 4">
                                    <div class="same cancel" @click="unagreeshowfun(item.id)">不同意</div>
                                    <div class="same sub" @click="agreeshowfun(item.id)" style="margin-left: 5px;">同意</div>
                               </template> -->
                           </div>
                       </div>
                       
                    </div>
                    </van-list>
                </van-tab>
            </van-tabs>
            <div style="height: 80px;"></div>
            <!-- 填写物流单号弹窗 -->
            <van-popup round v-model="showwuliu" position="bottom">
                <div class="wuliu_box">
                    <div style="font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;">填写物流单号</div> 
                    <div class="flex" style="margin: 20px 0 ;font-size: 16px;color: #000;">
                        <div>物流公司<span style="margin-left: 4px;color: #B20A09;">*</span></div>
                        <el-select class="el_selects" @change="changefun" v-model="value" filterable placeholder="请选择快递">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex" style="margin: 20px 0 ;font-size: 16px;color: #000;">
                        <div>快递单号<span style="margin-left: 4px;color: #B20A09;">*</span></div>
                        <el-input  style="width: 60%;margin-left: 20px;" class="iptql" v-model="wuliunum" placeholder="请输入内容"></el-input>
                    </div>
                    <div class="wuliu_btn" @click="determinewuliu">确认</div>
                </div>
            </van-popup>
            <!-- 填写上传发票弹窗 -->
            <van-popup round v-model="fp" position="bottom">
                <div class="wuliu_box">
                    <div style="font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;">上传发票</div> 
                    <div class="flex" style="margin: 20px 0 ;font-size: 16px;color: #000;">
                        <van-image width="76" height="76" :src="imgUrl" @click="upload_fp"/>
                        <van-image v-if="fp_pic" width="76" height="76" :src="fp_pic" style="margin-left: 5px;"/>
                    </div>
                    <div class="wuliu_btn" @click="fpup">确认</div>
                </div>
            </van-popup>
        </div> 
    </div>
</template>
<script>
import { Toast ,Dialog,ImagePreview} from 'vant';
import { queryBrandOrderList,brandCancelOrder,approveBrandRefund,matchComAndNum,deliverGoodsOrder,uploadInvoice} from '@/api/order';
import {deleteOrder} from '@/api/myorder';
export default {
    name: 'order',
    data() {
        return {
            mar_top:'',
            brand_id:'',
            list :[
            ],
            loading: false,
            finished: false,
            page: 1,
            pageSize: 10,
           top_navigation:[
                {id:1,val:'全部'},
                {id:2,val:'待付款'},
                {id:3,val:'待发货'},
                {id:4,val:'待收货'},
                {id:5,val:'退款/售后'},
                {id:6,val:'待开票'},
           ],
           columns:[
            {id:1,val:'申通快递'},
            {id:2,val:'圆通快递'}
           ],
           columnsname:"",
           list: [],
           active: 0,
           radio:'跟客户协商一致取消',
           showpaycancel:false,
           showwuliu:false,
           showPickerend:false,
           unagreeshow:false,
           refuesshow:false,
           order_id:'',
           refuextext:'',
           options: [
            {value:'yuantong',label:'圆通速递'},
            {value:'yunda',label:'韵达快递'},
            {value:'zhongtong',label:'中通快递'},
            {value:'jtexpress',label:'极兔速递'},
            {value:'shentong',label:'申通快递'},
            {value:'youzhengguonei',label:'邮政快递包裹'},
            {value:'shunfeng',label:'顺丰速运'},
            {value:'jd',label:'京东物流'},
            {value:'ems',label:'EMS'},
            {value:'youzhengdsbk',label:'邮政电商标快'},
            {value:'youzhengbk',label:'邮政标准快递'},
            {value:'debangkuaidi',label:'德邦快递'},
            {value:'danniao',label:'菜鸟速递'},
            {value:'xinfengwuliu',label:'信丰物流'},
            {value:'debangwuliu',label:'德邦物流'},
            {value:'zhongtongkuaiyun',label:'中通快运'},
            {value:'rrs',label:'日日顺物流'},
            {value:'jingdongkuaiyun',label:'京东快运'},
            {value:'shunfengkuaiyun',label:'顺丰快运'},
            {value:'kuayue',label:'跨越速运'},
            {value:'annengwuliu',label:'安能快运'},
            {value:'baishiwuliu',label:'百世快运'},
            {value:'sxjdfreight',label:'顺心捷达'},
            {value:'jinguangsudikuaijian',label:'京广速递'},
            {value:'yimidida',label:'壹米滴答'},
            {value:'yundakuaiyun',label:'韵达快运'},
            {value:'subida',label:'速必达'},
            {value:'usps',label:'USPS'},
            {value:'youshuwuliu',label:'优速'},
            {value:'annto',label:'安得物流'},
            {value:'pingandatengfei',label:'平安达腾飞'},
            {value:'zhongtongguoji',label:'中通国际'},
            {value:'jiayunmeiwuliu',label:'加运美'},
            {value:'cainiaodajian',label:'菜鸟大件'},
            {value:'dhl',label:'DHL-中国件'},
            {value:'savor',label:'海信物流'},
            {value:'ups',label:'UPS'},
            {value:'zhaijisong',label:'宅急送'},
            {value:'upsen',label:'UPS-全球件'},
            {value:'ztky',label:'中铁快运'},
            {value:'suning',label:'苏宁物流'},
            {value:'suteng',label:'速腾快递'},
            {value:'fedexcn',label:'Fedex-国际件-中文'},
            {value:'ewe',label:'EWE全球快递'},
            {value:'stosolution',label:'申通国际'},
            {value:'huitongkuaidi',label:'百世快递'},
            {value:'shunfenglengyun',label:'顺丰冷链'},
            {value:'fedex',label:'FedEx-国际件'},
            {value:'huisenky',label:'汇森速运'},
            {value:'exfresh',label:'安鲜达'},
            {value:'disifang',label:'递四方'},
            {value:'quantong56',label:'全通速递'},
            {value:'youzhengguoji',label:'国际包裹'},
            {value:'flextock',label:'Flextock'},
            {value:'dhlen',label:'DHL-全球件'},
            {value:'zhongtiewuliu',label:'中铁飞豹'},
            {value:'longjun',label:'龙俊物流'},
            {value:'emswuliu',label:'EMS物流'},
            {value:'chinapost',label:'中国邮政（CHINA POST）'},
            {value:'ane66',label:'安能快递'},
            {value:'yuxinwuliu',label:'宇鑫物流'},
            {value:'shpost',label:'同城快寄'},
            {value:'emsbg',label:'EMS包裹'},
            {value:'yuantongguoji',label:'圆通国际'},
            {value:'emsguoji',label:'EMS-国际件'},
            {value:'ttsucha',label:'天天速查'},
            {value:'wooolink',label:'上海领链物流'},
           ],
            value: '',
            wuliunum:'',
            wuliu_name:'',
            fp:false,
            imgUrl:require('/src/assets/pic_img.png'),
            fp_pic:'',
        }
    },
    methods: {
        onLoad() {       
            this.page++
            setTimeout(() => {
                this.getOrderList()
            }, 1000);
        },
         //上传发票
         upload_fp(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'upload_fpfun'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
         },
         upload_fpfun(e){
            this.fp_pic = e.data[0]
         },
        //查看发票
        previewImage(e){
            ImagePreview([e]);
        },
        // 上传发票确认
        async fpup(){
            if(this.fp_pic == '') {
                Toast('发票图片不能为空')
            } else {
                const res = await uploadInvoice({
                    id:this.info.invoiceId,
                    invoiceUrl:this.fp_pic
                })
                if(res.code == 200) {
                    Toast('操作成功')
                    this.fp = false
                    this.finished = false
                    this.page = 1
                    this.list = []
                    this.getOrderList()
                }
            }
        },
        async navigationFun(e,w){
            console.log("顶部导航标签点击",e,w);
            this.finished = false
            this.page = 1
            this.list = []
            await this.getOrderList()
        },
        // 倒计时结束
        finishfun(){
            console.log("倒计时结束");
            this.finished = false
            this.page = 1
            this.list = []
            this.getOrderList()
        },
        // 点击填写物流单号
        ordernum(e){
            console.log(e);
            this.order_id = e
            this.showwuliu = true
            this.value = '',
            this.wuliunum = ''
        },
        // 点击取消订单
        ordercancel(e){
            console.log(e);
            this.order_id = e
            this.showpaycancel = true
        },
        // 付款取消弹窗确定
        async determine(){
            console.log(this.order_id,this.radio); 
            this.showpaycancel = false
            var data = {
                id:this.order_id,
                reason:this.radio
            }
            var res = await brandCancelOrder(data);
            console.log("店铺取消订单",res);
            this.finished = false
            this.page = 1
            this.list = []
            this.getOrderList()
        },
        // 填写物流单号弹窗确定
        async determinewuliu(){
            if(this.value && this.wuliunum) {
                console.log("物流公司编码",this.value);
                console.log("物流单号",this.wuliunum);
                const res = await matchComAndNum({ 
                    com: this.value,
                    num: this.wuliunum,
                });
                console.log("检查物流单号和物流公司是否匹配",res);
                if(res.data) {
                    const res1 = await deliverGoodsOrder({ 
                        id: this.order_id,
                        logisticsNumber: this.wuliunum,
                        logisticsCompany: this.value
                    });
                    console.log("订单发货",res1);
                    this.showwuliu = false
                    this.finished = false
                    this.page = 1
                    this.list = []
                    this.getOrderList()

                } else {
                    Toast('物流单号和快递公司不匹配')
                }
            } else {
                Toast('请填写完整信息')
            }

        },
        changefun(e){
            console.log(e);
            for (let i = 0; i < this.options.length; i++) {
                if(this.options[i].value == e) {
                    this.wuliu_name = this.options[i].label
                }
            }
            console.log("物流公司名称",this.wuliu_name);
        },
        // 用户退款不同意
        unagreeshowfun(e){
            this.order_id = e
            this.unagreeshow = true
        },
        // 售后详情
        detail(e){
            console.log(e);
            if(e.status == 4 ||e.status == 5 ||e.status == 6 ) {
                this.$router.push({name:'aftersales',params:{info:e}})
            } else {
                this.$router.push({ name: 'orderdetail' ,params:{info:e}})
            }
            
        },
        // 不同意退款弹窗确定
        async unagreefun(){
            console.log(this.order_id,this.radio); 
            this.unagreeshow = false
            var data = {
                id:this.order_id,
                remark:this.radio,
                status:0
            }
            var res = await approveBrandRefund(data);
            console.log("商家审核退款申请拒绝",res);
            this.finished = false
            this.page = 1
            this.list = []
            this.getOrderList()
        },
        async agreeshowfun(e){
            var data = {
                id:this.order_id,
                remark:this.refuextext,
                status:1
            }
            var res = await approveBrandRefund(data);
            console.log("商家审核退款申请同意",res);
            this.finished = false
            this.page = 1
            this.list = []
            this.getOrderList()
        },
        // 同意退款

        // 拒绝退款
        refuesfun(){
            this.refuesshow = false
        },
        // 删除
        deleteorders(e){
            console.log(e);
             Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: '是否删除此订单',
                })
                .then( async() => {
                    const res =  await deleteOrder({orderId:e.id})
                    console.log("删除",res);
                        Toast('操作成功')
                        setTimeout(() => {
                            this.finished = false
                            this.page = 1
                            this.list = []
                            this.getOrderList();
                        }, 300);
                })
                .catch(() => {
                    // on cancel
                });
        },
        back() {
            this.$router.go(-1);
        },
        // 跳转物流信息页面
        toLogisitics(e){
            if(e.logisticsCompany == null || e.logisticsNumber == null) {
                Toast('暂无物流信息')
            } else {
                // this.$router.push({ name: 'logistics' ,params:{info:e,issales:3}});
                this.$router.push({ name: 'wuliu' ,params:{info:e}});
            }
        },
        // 查询订单列表
        async getOrderList() {
            const res = await queryBrandOrderList({ 
                brandId: this.brand_id,
                /* brandId: 2, */
                sysOperatorId: 0,
                sysOperator: "string",
                pageNum: this.page,
                pageSize: this.pageSize,
                orderByIdDesc: true,
                type:this.active
            });
            console.log("查询品牌下面的商品列表",res);
            if(res.data) {
                this.list = [...this.list , ...res.data.records] 
                this.loading = false
                if (res.data.records.length === 0 || this.list.length == res.data.total) {
                    this.finished = true
                }
            }
        },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    
    async mounted() {
        window.upload_fpfun = this.upload_fpfun
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log("this.$route.query",this.$route.query);
        this.brand_id = this.$route.query.brand_id
        this.finished = false
        this.page = 1
        this.list = []
        await this.getOrderList();
    }
}
</script>
<style lang="less" scoped>

.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .box {
        padding: 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 7px 0;
    }
    .flex {
        display: flex;
        align-items: center;
        .img {
           width: 24px;
           height: 24px;
           border-radius: 12px; 
        }
        .imgs {
            width: 60px;
            height: 60px;
            border-radius: 8px; 
        }
        .goodsn {
            width: 190px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .t {
            // width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .same {
            padding: 5px 15px;
            box-sizing: border-box;
            border-radius: 8px;
        }
        .cancel {
            border: 1px solid rgba(55,38,27,0.33);
            color: rgba(55,38,27,0.5);
        }
        .sub {
            background: #876235;
            color: #fff;
        }
        .recive {
            background-color: #475AD5;
            color: #fff;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tosat {
            width: 300px;
            height: 180px;
            border-radius: 8px;
            background-color: #E9D4BC;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }
    .lx {
            width: 160px !important;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background-color: rgba(255,255,255,1);
            color: rgba(16,16,16,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            border: 1px solid rgba(187,187,187,1);
        }
        .wuliu_box {
        height: 300px;
        padding: 20px;
        box-sizing: border-box; 
        background-color: #E9D4BC;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
        .el_selects {
        width: 60%;
        margin-left: 20px;
    }
    .el_selects /deep/ .el-input__inner {
        background: rgba(174,133,81,0.15);  /* 背景色 */
        border-color: rgba(174,133,81,0.15); /* 边框色 */
        color: 000 /* 文字颜色 */ 
        /* 添加其他需要修改的样式 */
    }

    /deep/ .el-select .el-input__inner::placeholder,
    /deep/ .el-date-picker .el-input__inner::placeholder,
    /deep/ .el-input .el-input__inner::placeholder {
        color:#A8937C;
    }
    .iptql /deep/ .el-input__inner{
        background: rgba(174,133,81,0.15);  /* 背景色 */
        border-color: rgba(174,133,81,0.15); /* 边框色 */
        color: 000 /* 文字颜色 */ 
    }
    .wuliu_btn {
        width: 100%;
        height: 48px;
        border-radius: 12px;
        font-size: 18px;
        background-color: #876235 ;
        color: #fff;
        text-align: center;
        line-height: 48px;
        margin: 20px auto;
        
    }
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
