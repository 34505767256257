import { render, staticRenderFns } from "./Spread.vue?vue&type=template&id=4ae28690&scoped=true"
import script from "./Spread.vue?vue&type=script&lang=js"
export * from "./Spread.vue?vue&type=script&lang=js"
import style0 from "./Spread.vue?vue&type=style&index=0&id=4ae28690&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_webpack@5.89.0__lodash@4.17.21_vue-template-compiler@2.7.15_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ae28690",
  null
  
)

export default component.exports