<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="我的保证金" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <!-- 顶部导航 -->
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="暂无内容"
                @load="onLoad"
                :immediate-check="false"
            >
            <div class="box" v-for="item in list">
                <div class="flex" style="justify-content: space-between;">
                    <div>{{ item.goodsName }}</div>
                    <div v-if="item.status == 0" style="text-align: right;margin-bottom: 10px;color: #475AD5;">未付款</div>
                    <div v-if="item.status == 1" style="text-align: right;margin-bottom: 10px;color: #475AD5;">充值成功</div>
                    <div v-if="item.status == 2" style="text-align: right;margin-bottom: 10px;color: #475AD5;">退款</div>
                    <div v-if="item.status == 3" style="text-align: right;margin-bottom: 10px;color: #475AD5;">退款成功</div>
                    <div v-if="item.status == 4" style="text-align: right;margin-bottom: 10px;color: #475AD5;">退款失败</div>
                    <div v-if="item.status == 5" style="text-align: right;margin-bottom: 10px;color: #475AD5;">超时取消</div>
                </div>
                <div class="flex" style="justify-content: space-between;">
                    <div>充值金额</div>
                    <div style="color: #475AD5;font-size: 20px;">¥{{ item.amount }}</div>
                </div>
                <div style="margin-top: 20px;">{{ item.createTime }}</div>
            </div>
            </van-list>
        </div> 
    </div>
</template>
<script>
import {queryAuctionDepositRecord} from '@/api/auction';
export default {
    name: 'auction',
    data() {
        return {
            loading: false,
            finished: false,
            page: 1,
            pageSize: 20,
           list: [],
           goodsid: '',
           mar_top:'',
        }
    },
    methods: {
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
        onLoad() {       
            this.page++
            setTimeout(() => {
                this.getAuctionList()
            }, 1000);
        },

        back() {
            this.$router.go(-1);
        },
        //查询出价列表
        async getAuctionList(){
            const res = await queryAuctionDepositRecord({goodsId:this.goodsid});
            console.log("我的保证金记录",res);
            if(res.data) {
                this.list = [...this.list , ...res.data.records] 
                this.loading = false
                if (res.data.records.length === 0 || this.list.length == res.data.total) {
                    this.finished = true
                }
            }
        },
    },
    
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        this.page = 1
        this.list = []
        this.finished = false
        await this.getAuctionList();
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .box {
        padding: 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 7px 0;
    }
    .flex {
        display: flex;
        align-items: center;
        .img {
           width: 24px;
           height: 24px;
           border-radius: 12px; 
        }
        .imgs {
            width: 60px;
            height: 60px;
            border-radius: 8px; 
        }
        .goodsn {
            width: 190px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .t {
            // width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .same {
            padding: 5px 15px;
            box-sizing: border-box;
            border-radius: 8px;
        }
        .cancel {
            border: 1px solid rgba(55,38,27,0.33);
            color: rgba(55,38,27,0.5);
        }
        .sub {
            background: #876235;
            color: #fff;
        }
        .recive {
            background-color: #876235;
            color: #fff;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tosat {
            width: 300px;
            height: 180px;
            border-radius: 8px;
            background-color: #E9D4BC;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }
    .lx {
            width: 160px !important;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background-color: rgba(255,255,255,1);
            color: rgba(16,16,16,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            border: 1px solid rgba(187,187,187,1);
        }
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
