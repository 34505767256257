<template>
    <div class="page" >
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" :title="enterStatus == 2 ? '我的工作台':'品牌入驻'" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <!-- 未开通店铺 -->
            <template v-if="enterStatus == 0">
                <div class="box">
                    <div class="title">开通流程</div>
                    <div style="color: #876235;">01 <span class="t">选择品牌主题</span></div>
                    <div class="des">个人(无需营业执照)/个体工商户/企业/公司</div>
                    <div style="color: #876235;">02 <span class="t">提交资料</span></div>
                    <div class="des">上传营业执照，行业资质等入驻所需材料</div>
                    <div style="color: #876235;">03 <span class="t">填写品牌信息</span></div>
                    <div class="des">品牌名/介绍/LOGO/分类/宣传图</div>
                    <div style="color: #876235;display: flex;align-items: center;">04 <span class="t">材料审核</span> <div class="b">约三天</div> </div>
                    <div class="des">材料审核通过，即可开通相应功能</div>
                    <div style="color: #876235;">05 <span class="t">保证金</span></div>
                    <div class="des" style="border-left: none;">交付保证金</div>
                </div>
                <div class="same a" style="margin-top: 20px;" @click="nextfun">开通店铺</div>
                <div style="height: 100px;"></div>
                <div class="row xy" style="color: #906C41;font-size: 12px;display: flex;align-items: center;justify-content: center;">
                    <van-image v-if="!isactive" @click="isactive = true" fit="cover" width="18" height="18" :src="pic_noactive"/>
                    <van-image v-if="isactive" @click="isactive = false" fit="cover" width="18" height="18" :src="pic_active"/>
                    <div style="font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;margin-bottom: 1px;margin-left: 2px;">我已阅读并同意</div>
                    <div style="color:#475AD5 ;margin-bottom: 1px;" @click="toxylist" >《商家入驻协议》</div>
                </div>
            </template>
            <!-- 审核中 -->
             <template v-if="enterStatus == 1">
                 <template v-if="!watchshow">
                    <div class="box1">
                        <van-image
                            width="100"
                            height="100"
                            :src="imgUrl_ing"
                        />
                        <div style="margin-top: 20px;">您的资料正在审核中</div>
                    </div>
                    <div class="watch" @click="watchshow = true">查看申请信息</div>
                 </template>
                 <template v-else>
                    <van-field readonly  class="input_fild" label="品牌主体" label-width="120px"  :value="info.type == 1 ? '个人' :info.type == 2 ? '个体工商户' : '企业/公司'" />
                    <van-field readonly  class="input_fild" label="经营类目" label-width="120px">
                        <template #input>
                            <el-cascader
                            class="iptql"
                                disabled
                                v-model="leimu_val"
                                :options="options"
                                :props="props"
                                :show-all-levels="false"
                                clearable>
                            </el-cascader>
                        </template>
                    </van-field>
                    <van-field readonly  class="input_fild" v-if="info.type != 3" label="姓名" label-width="120px" :value="info.name" />
                    <van-field readonly  class="input_fild" v-else label="公司/企业名称" label-width="120px" :value="info.name" />
                    <van-field readonly  class="input_fild" label="证件类型" label-width="120px" :value="info.idType == 0 ? '身份证' :info.idType == 1 ? '个体工商户营业执照' : '营业执照' "/>
                    <van-field readonly  class="input_fild" label="证件号" label-width="120px" :value="info.idNumber" />
                    <van-field readonly  class="input_fild" label="证件照片" label-width="120px" >
                    <template #input>
                        <van-image fit="cover" v-for="item in info.idUrl" style="margin-right: 8px;" width="50" height="50" :src="item"
                                    @click="previewImage(info.idUrl)" />
                                </template>
                    </van-field> 
                    <van-field class="input_fild" v-if="info.businessLicense" label="营业执照" label-width="120px" >
                        <template #input>
                        <van-image  style="margin-right: 8px;" width="50" height="50" :src="info.businessLicense"
                                    @click="previewImage(info.businessLicense)"  />
                                </template>
                    </van-field>
                    <van-field readonly  v-if="info.operateName" class="input_fild" label="运营联系人" label-width="120px" :value="info.operateName" />
                    <van-field readonly  v-if="info.operatePhone" class="input_fild" label="运营手机号" label-width="120px" :value="info.operatePhone" />
                    <van-field readonly  class="input_fild" label="支付宝账号" label-width="120px" :value="info.alipayNo" />
                   <!--  <van-field readonly  class="input_fild" label="微信账号" label-width="120px" :value="info.wechatNo" /> -->
                    <van-field readonly  class="input_fild" label="是否参与拍卖" label-width="120px" :value="info.auctionFlag == true ? '是': '否'" />
                    <van-field readonly  class="input_fild" label="拍卖许可证" label-width="120px" v-if="info.auctionLicense">
                        <template #input>
                        <van-image fit="cover" @click="previewImage(info.auctionLicense)" style="margin-right: 8px;" width="50" height="50" :src="info.auctionLicense"/>
                                </template>
                    </van-field>
                    <van-field readonly  class="input_fild" label="是否参与文物拍卖"  label-width="120px" :value="info.culturalAuctionFlag == true ? '是': '否'" />
                    <van-field readonly  class="input_fild" label="文物拍卖许可证" label-width="120px" v-if="info.culturalAuctionLicense">
                        <template #input>
                        <van-image fit="cover" @click="previewImage(info.culturalAuctionLicense)" style="margin-right: 8px;" width="50" height="50" :src="info.culturalAuctionLicense"
                                    />
                                </template>
                    </van-field>
                    <van-field  readonly class="input_fild" label="品牌名" label-width="120px" :value="info.brand" />
                    <van-field readonly  class="input_fild" label="品牌介绍" autosize type="textarea" label-width="120px" :value="info.brandDesc" />
                    <van-field readonly  class="input_fild" label="品牌历史" autosize type="textarea" label-width="120px" :value="info.brandHistory" />
                    <van-field  readonly class="input_fild" label="是否有实体店" label-width="120px" :value="info.physicalStoreFlag == 0 ? '无' : '有'" />
                    <van-field v-if="info.physicalStoreAddress"  readonly class="input_fild" label="实体店地址" label-width="120px" :value="info.physicalStoreAddress" />
                    <van-field readonly  class="input_fild" label="品牌logo" label-width="120px" >
                        <template #input>
                        <van-image fit="cover" style="margin-right: 8px;" width="50" height="50" :src="info.brandLogo"
                                    @click="previewImage(info.brandLogo)" />
                                </template>
                    </van-field>
                    <van-field v-if="info.foodPermitImage" readonly  class="input_fild" label="食品许可证" label-width="120px" >
                        <template #input>
                        <van-image fit="cover" style="margin-right: 8px;" width="50" height="50" :src="info.foodPermitImage"
                                    @click="previewImage(info.foodPermitImage)" />
                                </template>
                    </van-field>
                    <van-field readonly  class="input_fild" label="宣传图片" label-width="120px">
                        <template #input>
                        <van-image fit="cover" v-for="item in info.publicityPicture" style="margin-right: 8px;" width="50" height="50" :src="item"
                                    @click="previewImage(info.publicityPicture)" />
                                </template>
                    </van-field>
                    <van-field readonly  class="input_fild" label="宣传视频" label-width="120px">
                        <template #input>
                            <!-- <van-image style="margin-right: 8px;" width="50" height="50" :src="info.videoResponse.image.url"/> -->
                            <video @click="playVideo" :poster="info.videoResponse.image.url" :src="info.videoResponse.videoUrl" style="width: 100px;height: 100px;"></video>
                        </template>
                    </van-field>
                    <van-button class="btn" @click="watchshow = false">返回</van-button>
                 </template>
             </template>
             <!-- 审核不通过 -->
             <template v-if="enterStatus == 3">
                 <div class="box1">
                    <van-image
                        width="160"
                        height="116"
                        :src="imgUrls"
                        style="margin-top: 50px;"
                    />
                 </div>
                 <div style="margin-top: 10px;text-align: center;">{{approveRemark}}</div>
                 <van-button class="btn aaaa" @click="enitinfo">重新编辑</van-button>
             </template>
             <!-- 审核通过 -->
             <template v-if="enterStatus == 2">
                 <template v-if="!watchshow">
                    <div class="box1">
                        <!-- <span @click="workfun">工作台</span>
                    <span @click="workfun1">工作台</span> -->
                        <van-image
                            width="100"
                            height="100"
                            :src="imgUrl"
                        />
                        <div style="margin-top: 20px;">审核通过</div>
                        <div  v-if="info.faceRecognition == 1" style="margin: 10px 0;">未实名认证，点击<span style="color: #475AD5;margin: 0 5px;">实名认证</span>去认证</div>
                        <div  v-if="info.faceRecognition == 3" style="margin: 10px 0;">实名认证失败，点击<span style="color: #475AD5;margin: 0 5px;">实名认证</span>去认证</div>
                        <van-button  class="btn"   v-if="info.faceRecognition == 1 || info.faceRecognition == 3" @click="shimfn">实名认证</van-button>
                    </div>
                    
                    <div class="watch" @click="watchshow = true">查看申请信息</div>
                 </template>
                 <template v-else>
                    <van-field readonly  class="input_fild" label="品牌主体" label-width="120px"  :value="info.type == 1 ? '个人' :info.type == 2 ? '个体工商户' : '企业/公司'" />
                    <van-field readonly  class="input_fild" label="经营类目" label-width="120px">
                        <template #input>
                            <el-cascader
                            class="iptql"
                                disabled
                                v-model="leimu_val"
                                :options="options"
                                :props="props"
                                :show-all-levels="false"
                                clearable>
                            </el-cascader>
                        </template>
                    </van-field>
                    <van-field readonly  class="input_fild" v-if="info.type != 3" label="姓名" label-width="120px" :value="info.name" />
                    <van-field readonly  class="input_fild" v-else label="公司/企业名称" label-width="120px" :value="info.name" />
                    <van-field readonly  class="input_fild" label="证件类型" label-width="120px" :value="info.idType == 0 ? '身份证' :info.idType == 1 ? '个体工商户营业执照' : '营业执照' "/>
                    <van-field readonly  class="input_fild" label="证件号" label-width="120px" :value="info.idNumber" />
                    <van-field readonly  class="input_fild" label="证件照片" label-width="120px" >
                    <template #input>
                        <van-image v-for="item in info.idUrl" style="margin-right: 8px;" width="50" height="50" :src="item"
                                    @click="previewImage(info.idUrl)" />
                                </template>
                    </van-field> 
                    <van-field class="input_fild" v-if="info.businessLicense" label="营业执照" label-width="120px" >
                        <template #input>
                        <van-image  style="margin-right: 8px;" width="50" height="50" :src="info.businessLicense"
                                    @click="previewImage(info.businessLicense)"  />
                                </template>
                    </van-field>
                    <van-field readonly  v-if="info.operateName" class="input_fild" label="运营联系人" label-width="120px" :value="info.operateName" />
                    <van-field readonly  v-if="info.operatePhone" class="input_fild" label="运营手机号" label-width="120px" :value="info.operatePhone" />
                    <van-field readonly  class="input_fild" label="支付宝账号" label-width="120px" :value="info.alipayNo" />
                    <!-- <van-field readonly  class="input_fild" label="微信账号" label-width="120px" :value="info.wechatNo" /> -->
                    <van-field readonly  class="input_fild" label="是否参与拍卖" label-width="120px" :value="info.auctionFlag == true ? '是': '否'" />
                    <van-field readonly  class="input_fild" label="拍卖许可证" label-width="120px" v-if="info.auctionLicense">
                        <template #input>
                        <van-image fit="cover" @click="previewImage(info.auctionLicense)" style="margin-right: 8px;" width="50" height="50" :src="info.auctionLicense"
                                    />
                                </template>
                    </van-field>
                    <van-field readonly  class="input_fild" label="是否参与文物拍卖"  label-width="120px" :value="info.culturalAuctionFlag == true ? '是': '否'" />
                    <van-field readonly  class="input_fild" label="文物拍卖许可证" label-width="120px" v-if="info.culturalAuctionLicense">
                        <template #input>
                        <van-image fit="cover" @click="previewImage(info.culturalAuctionLicense)" style="margin-right: 8px;" width="50" height="50" :src="info.culturalAuctionLicense"
                                    />
                                </template>
                    </van-field>
                    <van-field  readonly class="input_fild" label="品牌名" label-width="120px" :value="info.brand" />
                    <van-field readonly  class="input_fild" label="品牌介绍" autosize type="textarea" label-width="120px" :value="info.brandDesc" />
                    <van-field readonly  class="input_fild" label="品牌历史" autosize type="textarea" label-width="120px" :value="info.brandHistory" />
                    <van-field  readonly class="input_fild" label="是否有实体店" label-width="120px" :value="info.physicalStoreFlag == 0 ? '无' : '有'" />
                    <van-field v-if="info.physicalStoreAddress"  readonly class="input_fild" label="实体店地址" label-width="120px" :value="info.physicalStoreAddress" />
                    <van-field readonly  class="input_fild" label="品牌logo" label-width="120px" >
                        <template #input>
                        <van-image style="margin-right: 8px;" width="50" height="50" :src="info.brandLogo"
                                    @click="previewImage(info.brandLogo)" />
                                </template>
                    </van-field>
                    <van-field v-if="info.foodPermitImage" readonly  class="input_fild" label="食品许可证" label-width="120px" >
                        <template #input>
                        <van-image style="margin-right: 8px;" width="50" height="50" :src="info.foodPermitImage"
                                    @click="previewImage(info.foodPermitImage)" />
                                </template>
                    </van-field>
                    <van-field readonly  class="input_fild" label="宣传图片" label-width="120px">
                        <template #input>
                        <van-image v-for="item in info.publicityPicture" style="margin-right: 8px;" width="50" height="50" :src="item"
                                    @click="previewImage(info.publicityPicture)" />
                                </template>
                    </van-field>
                    <van-field readonly  class="input_fild" label="宣传视频" label-width="120px">
                        <template #input>
                            <!-- <van-image style="margin-right: 8px;" width="50" height="50" :src="info.videoResponse.image.url"/> -->
                            <video @click="playVideo" :poster="info.videoResponse.image.url" :src="info.videoResponse.videoUrl" style="width: 60px;height: 60px;"></video>
                        </template>
                    </van-field>
                    <van-button class="btn" @click="watchshow = false">返回</van-button>
                 </template>
             </template>
             <!-- 仅入住信息通过 -->
             <template v-if="enterStatus == 4">
                 <template>
                    <div class="box1">
                        <van-image
                            width="100"
                            height="100"
                            :src="imgUrl"
                        />
                        <div style="margin-top: 20px;">仅入住信息通过</div>
                        <van-button class="btn" @click="enitinfo">重新编辑</van-button>
                    </div>
                 </template>
             </template>
        </div> 
    </div>
</template>
<script>
import { fetchEnterInfo,updateFaceRecognition } from '@/api/enter';
import { findGoodCategoryList } from '@/api/goods';
import { ImagePreview, Toast } from 'vant';
export default {
    name: 'enter',
    data() {
        return {
            imgUrl_ing:require('/src/assets/time_pic.png'),
            imgUrl:require('/src/assets/pic_active.png'),
            imgUrls:require('/src/assets/pic_fail.png'),
            enterStatus:0,
            watchshow:false,
            approveRemark:'',
            props: { multiple: true,value:'id',label:'text' },
            options: [],
            leimu_val:[],
            mar_top:'',
            isactive:false,
            pic_active:require('/src/assets/pic_active.png'),
            pic_noactive:require('/src/assets/pic_noactive.png'),
        }
    },
    methods: {
        /* workfun1(){
            this.$router.push({name:'promotion',query:{brand_id:25}})
        },
        workfun(){
            this.$router.push({name:'add',query:{brand_id:25}})
        }, */
        toxylist(){
            this.$router.push({name:'xylist'})
        },
        playVideo(){
            this.$el.querySelector('video').play()
        },
        shimfn(){
            var a = {
                name:this.info.name,
                idCard:this.info.idNumber,
            }
            var data = {
                action:'Authentication',
                req:a,
                reqId:5,
                respFunction:'authentication'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        async authentication(e){
            console.log("实名认证",e);
            if(e.code == 200) {
                /* Toast('认证成功') */
                const result = await updateFaceRecognition({faceRecognition:2,enterId:this.info.id})
                console.log("实名认证更新",result);
                await this.getEnterInfo();
            }
        },
        previewImage(key) {
            if(Array.isArray(key)) {
            ImagePreview(key);

            } else {
                ImagePreview([key]);
            }
            console.log(key);
        },
        //开通店铺
        nextfun(){
            /* this.$router.push({name:'invoicecopy'}) */
            if(this.isactive) {
                this.$router.push({name:'enter',params:{info:{type : 100}}})
            } else {
                Toast('请勾选协议')
            }
        },
        back() {
            var data = {
                    action:'ClosePage',
                    req:{},
                    reqId:12,
                    respFunction:'closefn'
                }
            if(this.$Version() == 'iOS') {
                console.log("ios关闭当前页面");
                
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                console.log("Android关闭当前页面");
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        closefn(e){
            console.log("返回"),e;
            
        },
        enitinfo(){
            this.$router.push({name:'enter',params:{info:this.info}})
        },
        //入住信息
        async getEnterInfo() {
            // 获取入驻信息
            const res = await fetchEnterInfo({ });
            console.log("获取入驻信息112",res); 
            if (res.data) {
                this.enterStatus = res.data.status // 1提交审核 2成功 3 失败 4仅入住信息通过
                /* this.enterStatus = 0 */
                this.approveRemark = res.data.approveRemark; //  审核备注
                res.data.idUrl = res.data.idUrl.split('#####')
                res.data.publicityPicture = res.data.publicityPicture.split('#####')
                this.info = res.data
                this.leimu_val = JSON.parse(res.data.businessCategory)
            } else {
                this.enterStatus = 0;
            }
        },
        //三级分类
        async getThree(){
            const result =  await findGoodCategoryList({})
            console.log("三级分类",result);
            this.options = result.data
        },
       /*  UserInfofn(e){
            console.log("获取本地的token",localStorage.getItem('userToken'));
            console.log("获取用户信息beforenter111",e);
            localStorage.setItem('userToken',e.data.token)
            console.log("获取本地的token1111",localStorage.getItem('userToken'));
        }, */
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() { 
        /* window.videofn = this.videofn */
        window.closefn = this.closefn
        window.marginFun = this.marginFun
        //距离顶部距离  提交时打开
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }

        /* window.authentication = this.authentication */
        
       setTimeout(() => {
        this.getThree()
        this.getEnterInfo();
       }, 300);
    },
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 

.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 15px;
    .box {
        padding:40px 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 27px 0;
        position: relative;
        .title {
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translate(-50%,0);
            padding: 10px 30px;
            box-sizing: border-box;
            background-color: #876235;
            color: #E9D4BC ;
            border-radius: 8px;
            font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;
        }
        .t {
            color: #37261B ;
            font-weight: 600;
            margin-left: 5px;
        }
        .des {
            margin: 10px 0;
            padding: 10px;
            box-sizing: border-box;
            margin-left: 8px;
            border-left: 1px dashed #876235;
            font-size: 13px;
            color: rgba(55,38,27,0.33);
        }
        .b {
            padding: 3px 5px;
            box-sizing: border-box;
            background-color: rgba(71,90,213,0.1);
            color: #475AD5;
            margin-left: 5px;
            font-size: 12px;
            border-radius: 4px;
        }
    }
    .box1 {
        width: 100%;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .watch {
        position: fixed;
        bottom: 50px;
        left: 50%;
        transform: translate(-50%,-50%);
        text-decoration: underline;
        text-align: center;
        color: rgba(55,38,27,0.66);
    }
    .flex {
        display: flex;
        align-items: center;
        color: rgba(55,38,27,0.66);
    }
    .same {
        
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        font-size: 18px;
    }
    .a {
        background-color: #876235 ;
        color: #fff;
    }
    .b {
        background-color: rgba(174,133,81,0.15);
        color: #000;
    }
    .input_fild {
    padding:10px 15px;
    box-sizing: border-box;
    background: rgba(174,133,81,0.15);
    color: #000;
}
.btn {
        width: 100%;
        border-radius: 5px;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        margin-top: 20px;
        border: 1px solid #876235;
    }
}
/* /deep/.custom-cascader-bg .el-cascader-panel {
  background-color: red !important; 
} */

.iptql /deep/ .el-input__inner{
        background: rgba(174,133,81,0.15);  /* 背景色 */
        border-color: rgba(174,133,81,0.15); /* 边框色 */
        color: 000 /* 文字颜色 */ 
    }
    /deep/ .iptql .el-input__inner::placeholder,
    /deep/ .el-date-picker .el-input__inner::placeholder,
    /deep/ .el-input .el-input__inner::placeholder {
        color:#A8937C;
    }
    /deep/ .el-input.is-disabled .el-input__inner {
        background-color: rgba(0, 0, 0, 0);
        border: none;
    }
    /deep/ .el-cascader__tags .el-tag {
        background-color: rgba(174, 133, 81, 0.15);
        border: none;
        color: #000;
    }
    .van-cell::after {
    border-bottom: 1px solid #E6CDB2;
}
 //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
/deep/.el-cascader .el-input .el-icon-arrow-down {
    display: none !important;
}
.aaaa {
    width: 90% !important;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%,-50%);
}
.xy {
        width: 100%;
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .nextcss {
        width: 94%;
        position: fixed;
        bottom: 40px;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .fix {
        width: 94%;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,-50%);
    }
</style>
