<template>
  <div style="color: #FFFFFF; width: 95vw;
  height: auto; font-size: 11px ">
    <iframe class="iframe" style="height:40px; font-size: 11px;  margin-top: 4px;" :src="'/title.html?title=' + introduce"
            frameborder="0"
            width="100%"></iframe>
  </div>
</template>
<script>
export default {
  name: 'Spread',
  data() {
    return {
      isShowMore: false,
      isDescStatus: false, // 文字显示 false显示展开  true显示关闭
      introduce: "",
      originalIntroduce: '' //原始介绍
    };
  },
  props: {
    mes2: {
      type: String,
      default: ""
    }
  },
  mounted() {
    // 监听iframe传过来的值
    window.addEventListener('message', this.handleMessage, false)
  },
  methods: {
    // 监听iframe传过来的值 监听高度重新给iframe赋值
    handleMessage(event) {
      if (event.data) {
        this.$nextTick(() => {
          document.querySelector('iframe').style.height = event.data + 3 + 'px'
        })
      }
    },
    showmoreDesc() {
      this.isDescStatus = !this.isDescStatus
      if (this.isDescStatus) {
        this.introduce = this.originalIntroduce
      }

    }
    // showmoreDesc(e) {
    //   let el = e.currentTarget;
    //   el.previousElementSibling.classList[!this.isDescStatus ? 'add' : 'remove']('overflow-line');
    //   el.classList[this.isDescStatus ? 'add' : 'remove']('more-collapse');
    //   // el.innerHTML = !this.isDescStatus ? '展开' : '';
    //   this.isDescStatus = !this.isDescStatus;
    //   that.isShowMore = true;
    // }
  },
  watch: {
    mes2(val) {
      this.introduce = val
      // this.originalIntroduce = val
      // this.introduce = val.slice(0,57)+'...';
      // if (this.introduce.length > 57) {
      //   this.isShowMore = true;
      // }
    }
  }
};
</script>

<style lang="less" scoped>
.m-content {
  &.overflow-line {
    // line-height: .4rem;
    overflow: visible;
    white-space: pre-wrap;
    font-size: 11px !important;
    // height: 30px;
    // overflow: hidden; //超出隐藏
    // text-overflow: ellipsis; //溢出用省略号显示
    // display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
    // 控制行数
    -webkit-line-clamp: 2; //超出两行隐藏
    // -webkit-box-orient: vertical; // 从上到下垂直排列子元素


  }
}

.btn-more {
  display: inline-block;
  font-size: 13px;
  margin-left: 3px;
  color: #AAAAAA;
  text-align: right;
  // float: right;
  // position: relative;
  // margin-top: rc(5);
  // font-size: 13px;
  // color: #AAAAAA;
  // &.more-collapse {

  //   &::after,
  //   &::before {
  //     top: 2px;
  //     transform: rotate(180deg);
  //   }

  //   &::before {
  //     top: 4px;
  //   }
  // }

  // &::after,
  // &::before {
  //   width: 0;
  //   height: 0;
  //   content: '';
  //   position: absolute;
  //   right: 0;
  //   top: 7px;
  //   border: rc(12) solid transparent;
  // }

  // &::after {
  //   border-top-color: #5383E7;
  //   z-index: 1;
  // }

  // &::before {
  //   border-top-color: #1c2239;
  //   z-index: 2;
  //   top: 5px;
  // }

}
</style>
