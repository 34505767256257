<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="我的出价金" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <div class="box" style="display: flex;flex-direction: column;align-items: center;">
                <div style="font-size: 30px;color: #475AD5;">¥{{ allmoney }}</div>
                <div class="flex" style="margin-top: 20px;justify-content: space-around;width: 100%;">
                    <div class="same recive" style="padding: 10px 30px;" @click="showmode = true">充值</div>
                    <!-- <div class="same recive" style="padding: 10px 30px;">提现</div> -->
                </div>
            </div>
            <!-- 顶部导航 -->
            <van-tabs @click="navigationFun" v-model="active" color="#000000" background="#E9D4BC" line-width="20px"  title-inactive-color="rgba(55,38,27,0.66)">
                <van-tab v-for="item in top_navigation" :title="item.val">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="暂无内容"
                        @load="onLoad"
                        :immediate-check="false"
                    >
                        <div class="box" v-for="item in list">
                            <div v-if="item.type == 1" class="flex" style="justify-content: space-between;">
                                <div>出价</div>
                                <div style="color: #475AD5;font-size: 20px;">-¥{{ item.amount }}</div>
                            </div>
                            <div v-if="item.type == 0" class="flex" style="justify-content: space-between;">
                                <div>充值</div>
                                <div style="color: #475AD5;font-size: 20px;">+¥{{ item.amount }}</div>
                            </div>
                            <div v-if="item.type == 2" class="flex" style="justify-content: space-between;">
                                <div>提现</div>
                                <div style="color: #475AD5;font-size: 20px;">-¥{{ item.amount }}</div>
                            </div>
                            <div class="flex" style="justify-content: space-between;">
                                <div style="color: #475AD5;" v-if="item.status == 0 && item.type == 0">未支付</div>
                                <div style="color: #475AD5;" v-if="item.status == 1 && item.type == 0">取消</div>
                                <div style="color: #475AD5;" v-if="item.status == 2 && item.type == 0">支付成功</div>
                                <div style="color: #475AD5;" v-if="item.status == 3 && item.type == 0">支付失败</div>
                                <div style="margin-top: 20px;">{{ item.createTime }}</div>
                            </div>
                        </div>
                    </van-list>
                     
                </van-tab>
            </van-tabs>
        </div> 
        <!-- 充值出价金 -->
        <van-popup round v-model="showmode" position="bottom" >
                <div class="wuliu_box" style="height: 400px;">
                    <div style="font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;">充值</div>
                    <div>
                        <div style="margin-top: 20px;">充值方式</div> 
                        <van-radio-group v-model="paytype"  style="margin-top: 20px;" >
                            <van-radio label-position="left" name="0" style="display: flex;justify-content: space-between;">
                                <div style="display: flex;align-items: center;">
                                    <img style="width: 24px;height: 24px;" :src="require('/src/assets/pay_wx.png')" />
                                    <div style="margin-left: 10px;">微信</div>
                                </div>
                            </van-radio>
                            <van-radio label-position="left" name="1" style="margin-top: 20px;display: flex;justify-content: space-between;">
                                <div style="display: flex;align-items: center;">
                                    <img style="width: 24px;height: 24px;" :src="require('/src/assets/pay_zfb.png')" />
                                    <div style="margin-left: 10px;">支付宝</div>
                                </div>
                            </van-radio>
                        </van-radio-group>
                    </div>
                    <div>
                        <div style="margin-top: 20px;">充值金额</div> 
                        <van-field class="input_fild" type="number" v-model="money_"  autocomplete="off"  placeholder="请输入" maxlength="10"/> 
                    </div>
                    <div class="wuliu_btn" @click="payfn">充值</div>
                </div>
            </van-popup>
    </div>
</template>
<script>
import { Toast ,Dialog} from 'vant';
import {queryAuctionServiceRecord ,queryUserAuctionBalance,addServiceRecordMoney} from '@/api/auction';
export default {
    name: 'auction',
    data() {
        return {
            allmoney:'',//我的出价金余额
            loading: false,
            finished: false,
            page: 1,
            pageSize: 20,
           top_navigation:[
                {id:0,val:'充值'},
                {id:1,val:'消费'},
                {id:2,val:'提现'}
           ],
           list: [],
           money_:'',
           active: 0,
           paytype:'0',
           showmode:false,
           mar_top:'',
        }
    },
    methods: {
        onLoad() {       
            this.page++
            setTimeout(() => {
                this.getAuctionList()
            }, 1000);
        },
        async navigationFun(e,w){
            console.log("顶部导航标签点击",e,w);
            this.finished = false
            this.active = e
            this.page = 1
            this.list = []
            await this.getAuctionList()
        },
        //我的出价金充值
        async payfn(){
            if(this.money_ == '') {
                Toast.fail('请输入充值金额')
            } else {
                const res = await addServiceRecordMoney({
                    amount:this.money_,
                    payWay:this.paytype
                })
                console.log("充值出价金",res);
                
                var paydata = {}
                if(this.paytype == '0') { //微信支付
                    var a = {
                        payChannel:'wechat',
                        timeStamp:JSON.parse(res.data.orderStr).timeStamp,
                        sign:JSON.parse(res.data.orderStr).sign,
                        nonceStr:JSON.parse(res.data.orderStr).nonceStr,
                        appId:JSON.parse(res.data.orderStr).appId,
                        packageValue:JSON.parse(res.data.orderStr).packageValue,
                        prepayId:JSON.parse(res.data.orderStr).prepayId,
                        partnerId:JSON.parse(res.data.orderStr).partnerId,
                    }
                    paydata = a
                } else { //支付宝支付
                    var a = {
                        payChannel:'alipay',
                        orderInfo:res.data.orderStr,
                    }
                    paydata = a
                }
                if(res.code == 200) {
                    this.showmode = false
                    var data = {
                    action:'Play',
                    req:paydata,
                    reqId:4,
                    respFunction:'bridgepayfn'
                }
                    if(this.$Version() == 'iOS') {
                        window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
                    } else {
                        window.ssmAndroid.ssmMessage(JSON.stringify(data))
                    }
                }
                this.showmode = false
            }
        },
        //支付bridge回调
        async bridgepayfn(e){
            console.log("支付",e);
                this.page = 1
                this.list = []
                this.finished = false
                await this.getAuctionList();
                await this.getmoney();
        },
        back() {
            this.$router.go(-1);
        },
        //查询竞拍列表
        async getAuctionList(){
            const res = await queryAuctionServiceRecord({
                pageNum: this.page,
                pageSize: this.pageSize,
                type : this.active 
            });
            console.log("我的出价金",res);
            if(res.data) {
                this.list = [...this.list , ...res.data.records] 
                this.loading = false
                if (res.data.records.length === 0 || this.list.length == res.data.total) {
                    this.finished = true
                }
            }
        },
        //查询出价金余额
        async getmoney(){
            const res = await queryUserAuctionBalance({})
            console.log("查询出价金余额",res);
            this.allmoney = res.data
        },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    
    async mounted() {
        window.bridgepayfn = this.bridgepayfn   
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        this.page = 1
        this.list = []
        this.finished = false
        await this.getAuctionList();
        await this.getmoney();

    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .box {
        padding: 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 7px 0;
    }
    .flex {
        display: flex;
        align-items: center;
        .img {
           width: 24px;
           height: 24px;
           border-radius: 12px; 
        }
        .imgs {
            width: 60px;
            height: 60px;
            border-radius: 8px; 
        }
        .goodsn {
            width: 190px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .t {
            // width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .same {
            padding: 5px 15px;
            box-sizing: border-box;
            border-radius: 8px;
        }
        .cancel {
            border: 1px solid rgba(55,38,27,0.33);
            color: rgba(55,38,27,0.5);
        }
        .sub {
            background: #876235;
            color: #fff;
        }
        .recive {
            background-color: #876235;
            color: #fff;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tosat {
            width: 300px;
            height: 180px;
            border-radius: 8px;
            background-color: #E9D4BC;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }
    .lx {
            width: 160px !important;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background-color: rgba(255,255,255,1);
            color: rgba(16,16,16,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            border: 1px solid rgba(187,187,187,1);
        }
    }
    .wuliu_box {
    height: 300px;
    padding: 20px;
    box-sizing: border-box; 
    background-color: #E9D4BC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
}
.wuliu_btn {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    font-size: 18px;
    background-color: #876235 ;
    color: #fff;
    text-align: center;
    line-height: 48px;
    margin: 20px auto;
}
.input_fild {
   margin-top: 10px;
   padding:10px 15px;
   box-sizing: border-box;
   background: rgba(174,133,81,0.15);
   border-radius: 8px;
   color: #000;
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
    //fild输入框 placehouder样式
    :deep(.van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
