import { render, staticRenderFns } from "./applys.vue?vue&type=template&id=222d545c&scoped=true"
import script from "./applys.vue?vue&type=script&lang=js"
export * from "./applys.vue?vue&type=script&lang=js"
import style0 from "./applys.vue?vue&type=style&index=0&id=222d545c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "222d545c",
  null
  
)

export default component.exports