<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" left-arrow :title="top_text" @click-left="back" />
        </van-sticky>
        <div class="container">
            <van-form>
                <div style="margin-top: 20px;">商品名<span style="color: #B10808;">*</span></div>
                <van-field class="input_fild"  autocomplete="off" v-model="good_name" placeholder="请输入" maxlength="28" :rules="[{ required: true, message: '请输入商品名' }]"/> 
                <div class="flex" style="margin-top: 20px;">
                    <div>商品类型</div> 
                    <van-radio-group v-model="radio" direction="horizontal" style="margin-left: 40px;">
                        <van-radio name="1">全新</van-radio>
                        <van-radio name="2">老物件</van-radio>
                    </van-radio-group>
                </div>
                <template v-if="radio == 2">
                    <div style="margin-top: 20px;">几成新 </div>
                    <van-field readonly clickable  :value="newvalue" class="input_fild"
                    right-icon="arrow-down" @click="newshowPicker = true" placeholder="请选择" />
                    <van-popup v-model="newshowPicker" position="bottom">
                        <van-picker show-toolbar :columns="newlist" @confirm="onConfirmnew" value-key="val" @cancel="newshowPicker = false" />
                    </van-popup>
                </template>
                <div style="margin-top: 20px;">分类<span style="color: #B10808;">*</span> </div>
                <van-field readonly clickable  :value="resultstr" class="input_fild"
                right-icon="arrow-down" @click="cateshowPicker1 = true" placeholder="请选择" :rules="[{ required: true, message: '请选择分类' }]"  />
                <van-popup v-model="cateshowPicker1" round position="bottom" style="padding: 20px;box-sizing: border-box;">
                    <div style="margin-bottom: 20px;">选择分类(多选)</div>
                    <van-checkbox-group v-model="result_arr">
                        <van-checkbox checked-color="#475AD5" style="margin: 10px 0;" v-for="item in resultlist" :name="item">{{item}}</van-checkbox>
                    </van-checkbox-group>
                    <div class="row">
                        <div @click="lxfun" class="btns" >确认</div>
                    </div>
                </van-popup>
                <div style="margin-top: 20px;">品类<span style="color: #B10808;">*</span></div>
                <van-field readonly clickable  :value="pltext" class="input_fild" 
                :rules="[{ required: true, message: '请选择品类' }]" 
                right-icon="arrow-down" @click="cateshowPicker = true" placeholder="请选择" />
                <van-popup style="padding: 20px 0;box-sizing: border-box;" v-model="cateshowPicker" round position="bottom">
                <div style="margin-bottom: 20px;margin-left: 10px;">选择品类(单选)</div>
                <van-tree-select
                    :items="options"
                    :active-id.sync="activeId"
                    :main-active-index.sync="activeIndex"
                    @click-item="optionsfn"
                />
                </van-popup>
                <div style="margin-top: 20px;">商品价格<span style="color: #B10808;">*</span></div>
                <van-field class="input_fild"  autocomplete="off" type="number" @input="validateInput"  v-model="ssmPrice" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入价格'}]" /> 
               <!-- 默认规格 -->
                <div v-for="item in default_guige" class="flex" style="margin-top: 20px ;align-items: start;">
                    <div class="title">{{item.name}}：</div>
                    <div class="flex" style="flex-wrap: wrap;width: 275px;margin-bottom: 10px;">
                        <div @click="selectGuige(items)" v-for="items in item.itemList"  :class="`sc ${kucunclass.indexOf(items.id) != -1 ? 'active' : ''}`"  style="margin-top: 5px;margin-right: 5px;">
                            {{ items.name }} 
                            <van-image width="20" height="20" class="close" :src="require('/src/assets/pic_close_g.png')" @click.stop="sizeFun(items)"/>
                            <!-- <div @click.stop="sizeFun(items)" class="close">x</div> -->
                        </div>
                        <van-image style="background: rgba(174,133,81,0.15);" width="28" height="28" :src="require('/src/assets/pic_add_g.png')" @click.stop="addzigg(item)"/>
                        <!-- <van-button @click.stop="addzigg(item)" style="" icon="plus" size="mini" /> -->
                    </div>
                </div>
                <!-- 设置库存 -->
                <div v-if="this.result.length >=2" class="flex">
                    <div class="title">设置库存</div>
                </div>
                <div v-for="item in result" class="flex" style="margin: 10px 0;justify-content: space-between;align-items: center;">
                    <div>{{item.name}}：</div>
                    <div style="display: flex;align-items: center;">
                        <van-field class="input_fild" style="max-width: 100px;margin-top: 0;"  autocomplete="off" type="number" v-model="item.inventory" placeholder="请输入库存" maxlength="10"/> 
                    </div>

                </div>

                <div v-for="item in goods.customize" class="flex" style="margin-bottom: 10px;">
                    <div class="title">{{item.name}}：</div>
                    <div class="title" v-for="items in item.value">{{items}}</div>
                </div>

                <div v-if="default_guige.length < 3" class="flex" style="margin: 10px 0;">
                    <div class="guige" @click="zdyFun">自定义规格</div>
                </div>
                <div class="guige_box" v-if="showguige">
                    <div class="flex">
                        <div class="title">规格名称：</div>
                        <input maxlength="10" v-model="guige.name" :disabled="guige_name ? false : true" type="text" style="width: 80px;height: 40px;border: none;background-color: #876235;border-radius: 4px;color: #E9D4BC;text-align: center;">
                    </div>
                    <div class="flex" style="margin-top: 20px;align-items: start;">
                        <div class="title">规格选项：</div>
                        <div style="display: flex;align-items: center;flex-wrap: wrap;width: 240px;">
                            <input maxlength="10" v-for="(item,index) in guige.value"  @input="iptfun(index,$event)" :value="item" type="text" style="width: 70px;height: 40px;margin-right: 5px;margin-top: 4px;border: none;background-color: #876235;border-radius: 4px;color: #E9D4BC;text-align: center;">
                            <!-- <div @click="addFun" ><van-button icon="plus" size="mini" /></div> -->
                            <van-image style="background: rgba(174,133,81,0.15);" width="28" height="28" :src="require('/src/assets/pic_add_g.png')" @click="addFun"/>
                        </div>
                    </div>
                    <div class="flex" style="justify-content: space-around;margin-top: 10px;">
                        <div @click="showguige = false" style="color: #887C7C;">取消</div>
                        <div @click="addFun1">保存</div>
                    </div>
                </div>
                
                <div class="flex" style="margin-top: 20px;">
                    <div>发货时间</div>
                    <van-radio-group v-model="send_time" direction="horizontal" style="margin-left: 40px;max-width: 200px;">
                        <van-radio name="24小时">24小时</van-radio>
                        <van-radio name="48小时">48小时</van-radio>
                        <van-radio style="margin-top: 5px;" name="自定义">自定义</van-radio>
                    </van-radio-group>
                </div>
                <template v-if="send_time == '自定义'">
                    <div style="margin-top: 20px;">发货时间<span style="color: #B10808;">*</span></div>
                    <van-field class="input_fild"  autocomplete="off" v-model="zidingyi_time" placeholder="请输入" maxlength="10" :rules="[{ required: true, message: '请输入自定义时间' }]"/> 
                </template>
                <div class="flex" style="margin-top: 20px;">
                    <div>运费类型</div>
                    <van-radio-group v-model="yunfei" direction="horizontal" style="margin-left: 40px;">
                        <van-radio name="0">免运费</van-radio>
                        <van-radio name="1">动态运费</van-radio>
                    </van-radio-group>
                </div>
                <div class="flex" style="margin-top: 20px;">
                    <div>是否支持七天无理由退货</div>
                    <van-radio-group v-model="isseven" direction="horizontal" style="margin-left: 40px;">
                        <van-radio name="1">是</van-radio>
                        <van-radio name="0">否</van-radio>
                    </van-radio-group>
                </div>
                <div class="flex" style="align-items: self-start;margin-top: 20px;margin-bottom: 10px;">
                    <div>封面图(16:9) <span style="color: #B10808;">*</span></div>
                </div>
                <van-image width="140" height="78" fit='cover' :src="imgUrl" @click="coverfun"/>
                <div class="flex" style="align-items: self-start;margin-top: 20px;margin-bottom: 10px;">
                    <div>详情图(4:3) <span style="color: #B10808;">*</span></div>
                </div>
                <van-image width="105" height="70"  :src="imgUrl1" @click="shopdetailfun"/>
                <van-image width="105" height="70"  :src="imgUrl2" style="margin: 0 5px;" @click="shopdetailfuns"/>
                <van-image width="105" height="70"  :src="imgUrl3" @click="shopdetailfunss"/>
                <div class="flex" style="align-items: self-start;margin-top: 20px;margin-bottom: 10px;">
                    <div class="title">视频 <span style="color: #B10808;">*</span></div>
                    <!-- <video v-if="videoinfo.videoUrl"  @click="playVideo" :src="videoinfo.videoUrl" style="width: 80px;height: 80px;margin-left: 5px;"></video> -->
                </div>
                <van-image width="76" height="76" :src="videoinfo.image.url" @click="videofun"/>
                <div class="flex" style="align-items: self-start;margin-top: 20px;margin-bottom: 10px;">
                    <div>分享图(1:1) <span style="color: #B10808;">*</span></div>
                </div>
                <van-image width="111" height="111" fit='cover' :src="imgUrl4" @click="sharefun"/>
            </van-form>
            <div class="flex" style="justify-content: center;color: rgba(190,186,186,1);font-size: 14px;font-family: PingFangSC-regular;">
                最多只能上架两款商品
            </div> 
            <!-- <div class="flex" style="justify-content: space-between;">
                <div class="same b" @click="save">保存</div>
                <div class="same a" @click="submitFun">发布</div>
            </div>
            <div style="height: 80px;"></div> -->
            <div style="width:100%;height:90px"></div>
        </div> 
        <div  class="bottom_fixed">
            <div class="asd">
                <div class="bot" style="color: #37261B;font-weight: 500;user-select: none;" @click="save">保存</div>
                <div class="bot" style="background: #876235;color: #FFFFFF;user-select: none;" @click="submitFun">发布</div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import { findList ,addSpecification,addSpecificationItem,saveGoodsInfo,queryGoodsInfo,updateGoodsInfo,querySystemCategoryList,brandCategoryList,deleteSpecificationItem,queryGoodsCategoryByBrand} from '@/api/goods';
export default {
    name: 'goods',
    data() {
        return {
            mar_top:'',
        top_text:'',
        brand_id:'',//品牌ID
        ssmPrice:'',//普通商品价格 柴门价格
        good_name:"",//商品名
        resultlist:[],//所有分类数组
        result_arr:[],//选择商品分类数组
        resultstr:'',//选择商品分类字符串
        cateshowPicker1:false,//分类弹窗
        options:[],//所有品类数组
        pltext:'',//选择品类字符串
        activeId: '',//选择品类id
        activeIndex: 0,
        cateshowPicker:false,
        radio:'1',//1全新 2 老物件,
        newid:'9',
        newvalue:'九成新',//几成新
        newshowPicker:false,//几成新弹窗
        newlist:[
            {id:1,val:'一成新'},
            {id:2,val:'二成新'},
            {id:3,val:'三成新'},
            {id:4,val:'四成新'},
            {id:5,val:'五成新'},
            {id:6,val:'六成新'},
            {id:7,val:'七成新'},
            {id:8,val:'八成新'},
            {id:9,val:'九成新'}

        ],
        imgUrl:require('/src/assets/pic_goods_cover.png'),
        imgUrl1:require('/src/assets/pic_goods_z.png'),
        imgUrl2:require('/src/assets/pic_goods_c.png'),
        imgUrl3:require('/src/assets/pic_goods_f.png'),
        imgUrl4:require('/src/assets/pic_goods_share.png'),
        videoinfo:{
            image:{
                url:require('/src/assets/pic_video.png'),
            }
        },
        send_time:'24小时',//发货时间 
        zidingyi_time:'',//自定义发货时间
        yunfei:'0',//运费 0 免运费 1 动态运费
        isseven:'1',//七天无理由
        backvideo:{},//编辑返回视频信息

        resultnum:0,
        kucun :[],
        result:[],
        sentid:'',
        kucunclass:[],
            default_guige:[],
            goods_id:'',
            goods:{
                name:'',
                type:1,
                businessCategory: "一成新",
                businessCategoryid:1,
                goodsclass:[],
                goodsclassvalue:[],
                cate:1,
                sales:1,
                price:'',
                inventory:2,
                ship:0,
                shipval:'免运费',
                des:'',
                imageUrl: '',
                customize:[],
                size:['13厘米','15厘米','18厘米'],
                color:['红色','蓝色'],
                sendtime:''
            },
            guige:{
                name:"",
                value:[]
            },
            showguige:false,
            showPicker: false,
            showPickership: false,
            columns: [
                {id:1,val:'一成新'},
                {id:2,val:'二成新'},
                {id:3,val:'三成新'}
            ],
            type:[
                    {id:1,value:'全新'},
                    {id:2,value:'老物件'},
                ],
            shiplist:[
                {id:0,value:'免运费'},
                {id:1,value:'动态运费'},
            ],  
            sales:[
                {id:1,value:'秒杀'},
                {id:2,value:'分段计时'},
                {id:3,value:'减价售卖'},
                {id:4,value:'拍卖'}
            ],
            goodsclass:[
                    {id:1,value:'汉服'},
                    {id:2,value:'手工艺'},
                    {id:3,value:'非遗'},
                    {id:4,value:'茶道'},
                    {id:5,value:'字画'},
                    {id:6,value:'乐器'},
                    {id:7,value:'其他'}
                ],
            cate:[
                {id:1,value:'唐朝'},
                {id:2,value:'秦朝'},
            ],
            guige_name:'',
            guige_zi_id:'',
            kucun:[],
            ziid:[],
            inid:'',
            // 库存信息数据
            skuSpecification: [
                {
                    "itemIdList": [
                        1,
                        4
                    ],
                    "price": 568.45,
                    "inventory": 59
                },
                {
                    "itemIdList": [
                        2,
                        4
                    ],
                    "price": 812.19,
                    "inventory": 26
                }
            ],
            
        }
    },
    methods: {
        validateInput(val){
            if (val === '' || val > 0) {
                this.ssmPrice = val;
            } else {
                // 如果是负数，可以重置为空或上一个有效值
                this.ssmPrice = this.value; // 或者 this.value = '0'; // 如果想重置为0
            }
        },
        playVideo(){
            this.$el.querySelector('video').play()
        },
        //分类确认
        lxfun(){
            console.log(this.result_arr);
            if(this.result_arr.length < 1) {
                Toast('至少选择一种类型')
            } else {
                this.cateshowPicker1 = false
                this.resultstr = this.result_arr.join(',')
            }
        },
        //品类选择
        optionsfn(e){
            console.log("品类选择",e);
            this.activeId = e.id,
            this.pltext = e.text
            this.cateshowPicker = false
        },
        //几成新确定
        onConfirmnew(e){
            console.log("几成新",e);
            this.newid = e.id,
            this.newvalue = e.val
            this.newshowPicker = false
        },
        // arr 目标对象数组
        // filed 分组字段
        classifyArrayGroupBySameFieldAlpha(arr, filed) {
            let temObj = {}
            for (let i = 0; i < arr.length; i++) {
                let item = arr[i]
                if (!temObj[item[filed]]) {
                    temObj[item[filed]] = [item]
                } else {
                    temObj[item[filed]].push(item)
                }
            }
            let resArr = []
            Object.keys(temObj).forEach(key => {
                resArr.push({
                    key: key,
                    data: temObj[key],
                })
            })
            return resArr
        },

        // 子规格选中
        selectGuige(e){
            console.log('this.result',this.result,this.resultnum);
            console.log("eeee",e);
            if(this.kucunclass.indexOf(e.id) == -1) {
                    this.kucun.push(e)
                    this.kucunclass.push(e.id)
            } else {
                this.kucun=this.kucun.filter(item=>item.id != e.id)
                this.kucunclass=this.kucunclass.filter(item=>item != e.id)
                for (let i = 0; i < this.result.length; i++) {
                        this.result = this.result.filter(item=>item.itemIdList.indexOf(e.id) == -1)
                }
            }
            const res = this.classifyArrayGroupBySameFieldAlpha(this.kucun,'specificationId')
            console.log("kucun",this.kucun);
            console.log("res111",res);
            console.log("kucunclass",this.kucunclass);
            if(res.length == 1) {
                let result = this.result;
                console.log("单个规格",result);
                
            for (let i = 0; i < res[0].data.length; i++) {
                        let combined = {name:'',itemIdList:[],inventory:1};
                        combined.name = res[0].data[i].name
                        combined.itemIdList.push(res[0].data[i].id ) 
                        var a = result.some(item => item.name === combined.name)
                        if(!a) {
                            result.push(combined)
                        } 
                }
                /* result[0].itemIdList = result[0].itemIdList.split(",") */
                /* result[0].itemIdList = result[0].itemIdList.map(num => +num) */
                /* for (let i = 0; i < result.length; i++) {
                    if(!Array.isArray(result[i].itemIdList)) {
                        result[i].itemIdList = result[i].itemIdList.split(",")
                        result[i].itemIdList = result[i].itemIdList.map(num => +num)
                    }
                } */
            this.result = result
            console.log("result",result);
            }else if(res.length == 2) {
            /* let result = this.result; */
            let result = [];
            for (let i = 0; i < res[0].data.length; i++) {
                for (let j = 0; j < res[1].data.length; j++) {
                        let combined = {name:'',itemIdList:'',inventory:1};
                        combined.name = res[0].data[i].name + '/' + res[1].data[j].name
                        combined.itemIdList = res[0].data[i].id + ',' + res[1].data[j].id
                        var a = result.some(item => item.name === combined.name)
                        if(!a) {
                            result.push(combined)
                        } 
                    }
                }
                for (let i = 0; i < result.length; i++) {
                    if(!Array.isArray(result[i].itemIdList)) {
                        result[i].itemIdList = result[i].itemIdList.split(",")
                        result[i].itemIdList = result[i].itemIdList.map(num => +num)
                    }
                }
            this.result = result
            console.log("result",result);
            } else if(res.length == 3) {
            console.log(res,'三种规格');
            let result = [];
            for (let i = 0; i < res[0].data.length; i++) {
                for (let j = 0; j < res[1].data.length; j++) {
                    for (let k = 0; k < res[2].data.length; k++) {
                        let combined = {name:'',itemIdList:'',inventory:1};
                        combined.name = res[0].data[i].name + '/' + res[1].data[j].name+ '/' + res[2].data[k].name
                        combined.itemIdList = res[0].data[i].id + ',' + res[1].data[j].id+ ',' + res[2].data[k].id
                        result.push(combined)
                    }
                }
            }
            console.log("result333",result);
            for (let i = 0; i < result.length; i++) {
                result[i].itemIdList = result[i].itemIdList.split(",")
                result[i].itemIdList = result[i].itemIdList.map(num => +num)
            }
            this.result = result
            console.log("result",result);
            }
            return
            if(res.length == 1) {
                let result = this.result;
            for (let i = 0; i < res[0].data.length; i++) {
                /* for (let j = 0; j < res[1].data.length; j++) { */
                        let combined = {name:'',itemIdList:'',inventory:1};
                        combined.name = res[0].data[i].name
                        combined.itemIdList = res[0].data[i].id
                        var a = result.some(item => item.name === combined.name)
                        if(!a) {
                            result.push(combined)
                        } 
                    /* } */
                }
                result[0].itemIdList = result[0].itemIdList.split(",")
                result[0].itemIdList = result[0].itemIdList.map(num => +num)
                /* for (let i = 0; i < result.length; i++) {
                    if(!Array.isArray(result[i].itemIdList)) {
                        result[i].itemIdList = result[i].itemIdList.split(",")
                        result[i].itemIdList = result[i].itemIdList.map(num => +num)
                    }
                } */
            this.result = result
            console.log("result",result);
            }
        },
        // 新增子规格
        addzigg(e){
            console.log("新增zigg",e);
            this.showguige = true
            this.guige = {name:e.name,value:[]}
            this.guige_name = false
            this.guige_zi_id = e.id
            
        },
        // 新增规格
        zdyFun(){
            this.showguige = true
            this.guige = {name:"",value:[]}
            this.guige_name = true
            console.log(this.guige);
        },
        addFun(){
            this.guige.value.push('')
        },
        containsEmptyString(arr) {
            return arr.some(item => item === "");
        },
        async addFun1(){
            console.log(this.guige);
            if(this.guige_name) {
                console.log("自定义");
                if(this.guige.name == '' || this.containsEmptyString(this.guige.value)) {
                    Toast('请输入内容')
                } else {
                    var res = await addSpecification({
                        name:this.guige.name,
                        subNameList:this.guige.value
                    });
                    console.log("新增规格",res);
                    for (let i = 0; i < res.data.subItemList.length; i++) {
                        res.data.subItemList[i].specificationId = res.data.id
                    }
                    console.log("新增规格111",res);
                    
                    var data = {
                        id:res.data.id,
                        name:res.data.name,
                        itemList:res.data.subItemList
                    }
                    this.default_guige.push(data)
                }

            } else {
                console.log("no自定义",this.guige_zi_id,this.guige);
                console.log(this.default_guige);
                if(this.guige_zi_id != 1 && this.guige_zi_id != 2) {
                    if(this.containsEmptyString(this.guige.value)) {
                        Toast('请输入内容')
                    } else {
                        var res = await addSpecificationItem({
                            specificationId:this.guige_zi_id,
                            nameList:this.guige.value
                        })
                        console.log("新增自定义子规格",res);
                        for (let i = 0; i < res.data.length; i++) {
                            res.data[i].specificationId = this.guige_zi_id
                            for (let j = 0; j < this.default_guige.length; j++) {
                                if(this.default_guige[j].id == this.guige_zi_id) {
                                    this.default_guige[j].itemList.push(res.data[i])
                                }
                            }
                        }
                        console.log("新增自定义子规格",res);
                    }
                } else {
                    var res = await addSpecificationItem({
                        specificationId:this.guige_zi_id,
                        nameList:this.guige.value
                    })
                    console.log("新增子规格",res);
                    await this.getggList();
                }
            }
            this.showguige = false
            
        },
        async sizeFun(e){
            console.log(e);
            console.log(this.goods.size);
            console.log("this.default_guige",this.default_guige);
            const res = await deleteSpecificationItem({itemId:e.id})
            console.log("删除子规格",res);
            if(res.code == 200) {
                for (let i = 0; i < this.default_guige.length; i++) {
                    if(e.specificationId == this.default_guige[i].id) {
                        for (let j = 0; j < this.default_guige[i].itemList.length; j++) {
                            this.default_guige[i].itemList = this.default_guige[i].itemList.filter(item=>{
                                return item.id != e.id
                            })
                        }
                    }                
                }
            }
            return
            
            return
            this.goods.size = this.goods.size.filter(item => {
                return item != e
            })
        },
        iptfun(index,e){
            console.log(index,e);
            // 判断是否含有emoji表情
		let iconRule = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
		// 如果为true，字符串含有emoji表情 ，false不含
		if (iconRule.test(e.target.value)) {
			return e.target.value.replace(iconRule, '')
		}else{
			this.guige.value[index] = e.target.value
		}
        },
        /* addsize(){
            this.showguige = true
            this.guige = {name:"尺寸",value:this.goods.size}
            this.guige_name = false
        }, */
        /* addcolor(){
            this.showguige = true
            this.goods.color = this.goods.color.filter(item=> {
                  return item != ''
                }
            )
            this.guige = {name:"颜色",value:this.goods.color}
            this.guige_name = false
        }, */
        shop_price(e){
            this.goodsPriceInfo.price = parseFloat(e.target.value)
            console.log(this.goodsPriceInfo.price );

        },
        /* senrfun(e){
            this.goods.sendtime = e.target.value
        }, */
        // 类型
        /* typeFun(e){
            console.log(e);
            this.goods.type = e
        }, */
        // 运费
        /* ship(e){
            console.log(e);
            this.goods.ship = e
        }, */
        onConfirmship(e){
            this.goods.shipval = e.value;
            this.goods.ship = e.id;
            this.showPickership = false;
            console.log(this.goods);

        },
        onConfirm(value) {
            console.log(value);
            this.goods.businessCategory = value.val;
            this.goods.businessCategoryid = value.id;
            this.showPicker = false;
            
        },
        // 分类
        classFun(e){
            console.log(e);
            console.log(this.goods.goodsclassvalue);
            
            if (this.goods.goodsclassvalue.indexOf(e.value) != -1) {
            this.goods.goodsclass.splice(this.goods.goodsclass.indexOf(e.id), 1); //取消
            this.goods.goodsclassvalue = this.goods.goodsclassvalue.filter(item=>item!=e.value)
            } else {
            this.goods.goodsclass.push(e.id);//选中添加到数组里
            this.goods.goodsclassvalue.push(e.value);//选中添加到数组里
            }
            },
        // 品类
        cateFun(e){
            this.goods.cate = e.value
        },
        back() {
            this.$router.go(-1);
        },
        //查询字段是否为空
        allFieldsNotEmpty(obj){
            return Object.keys(obj).every(key => {
                const value = obj[key];
                return value !== null && value !== undefined && value !== '';
            });
        },
        //   发布
        async submitFun(){
            var pricedate = {
                ssmPrice: this.ssmPrice
            }
            var v_info = {
                url:this.videoinfo.image.url
            }
            if(this.allFieldsNotEmpty(pricedate) || Number(pricedate.ssmPrice)<0) {
                if(this.result.length == 0) {
                    Toast('请选择商品规格')
                    } else {
                        var data = {
                            brandId:this.brand_id,
                            name:this.good_name,
                            category:this.resultstr,
                            videoId:this.videoinfo.videoId,
                            videoImage:v_info,
                            coverImage:this.imgUrl,
                            shareImage:this.imgUrl4,
                            url:this.imgUrl1+'#####' +this.imgUrl2+'#####' +this.imgUrl3+'#####'  ,
                            status:1,
                            type:5,
                            brandCategory:this.pltext,
                            newType:this.radio == '1' ? 0 : this.newid,
                            skuSpecification:this.result,
                            goodsPriceInfo:pricedate,
                            deliveryTime:this.send_time == '自定义' ? this.zidingyi_time : this.send_time,
                            freightType:Number(this.yunfei),
                            sevenNoReason:Number(this.isseven)
                        }
                        var data1 = {
                            id:this.goods_id,
                            name:this.good_name,
                            category:this.resultstr,
                            videoId:this.videoinfo.videoId,
                            videoImage:v_info,
                            coverImage:this.imgUrl,
                            shareImage:this.imgUrl4,
                            url:this.imgUrl1+'#####' +this.imgUrl2+'#####' +this.imgUrl3+'#####'  ,
                            type:5,
                            brandCategory:this.pltext,
                            newType:this.radio == '1' ? 0 : this.newid,
                            skuSpecification:this.result,
                            goodsPriceInfo:pricedate,
                            deliveryTime:this.send_time == '自定义' ? this.zidingyi_time : this.send_time,
                            freightType:Number(this.yunfei),
                            sevenNoReason:Number(this.isseven)
                        }
                        if(this.goods_id == undefined || this.goods_id == '') {
                            if(this.allFieldsNotEmpty(data)) {
                               if(this.imgUrl.indexOf('pic_goods_cover') !== -1 || this.imgUrl1.indexOf('pic_goods_z') !== -1 || this.imgUrl2.indexOf('pic_goods_c') !== -1 || this.imgUrl3.indexOf('pic_goods_f') !== -1 || this.imgUrl4.indexOf('pic_goods_share') !== -1|| this.videoinfo.image.url.indexOf('pic_video') !== -1){
                                    Toast('请上传完整图片或视频')
                                } else {
                                    var res = await saveGoodsInfo(data);
                                    console.log("发布商品",res);
                                }
                                
                            } else {
                                Toast('请补充商品信息')
                            }
                        } else {
                            if(this.allFieldsNotEmpty(data1)) {
                                if(this.imgUrl.indexOf('pic_goods_cover') !== -1 || this.imgUrl1.indexOf('pic_goods_z') !== -1 || this.imgUrl2.indexOf('pic_goods_c') !== -1 || this.imgUrl3.indexOf('pic_goods_f') !== -1 || this.imgUrl4.indexOf('pic_goods_share') !== -1|| this.videoinfo.image.url.indexOf('pic_video') !== -1){
                                    Toast('请上传完整图片或视频')
                                } else {
                                    var res = await updateGoodsInfo(data1);
                                    console.log("编辑商品",res);
                                }
                               
                            } else {
                                Toast('请补充商品信息')
                            }
                        }
                        if(res.code == 200) {
                            Toast('操作成功'),
                            setTimeout(() => {
                                this.$router.go(-1);
                            }, 300);
                        } else {
                            Toast(res.msg)
                        }
                    }
            } else {
                Toast('请填写商品正确价格')
            }
        },
        //   保存商品
        async save(){
            var pricedate = {
                ssmPrice: this.ssmPrice
            }
            var v_info = {
                url:this.videoinfo.image.url
            }
            if(this.allFieldsNotEmpty(pricedate)) {
                if(this.result.length == 0) {
                    Toast('请选择商品规格')
                    } else {
                        var data = {
                            brandId:this.brand_id,
                            name:this.good_name,
                            category:this.resultstr,
                            videoId:this.videoinfo.videoId,
                            videoImage:v_info,
                            coverImage:this.imgUrl,
                            shareImage:this.imgUrl4,
                            url:this.imgUrl1+'#####' +this.imgUrl2+'#####' +this.imgUrl3 ,
                            status:0,
                            type:5,
                            brandCategory:this.pltext,
                            newType:this.radio == '1' ? 0 : this.newid,
                            skuSpecification:this.result,
                            goodsPriceInfo:pricedate,
                            deliveryTime:this.send_time == '自定义' ? this.zidingyi_time : this.send_time,
                            freightType:Number(this.yunfei),
                            sevenNoReason:Number(this.isseven)
                        }
                        var data1 = {
                            id:this.goods_id,
                            name:this.good_name,
                            category:this.resultstr,
                            videoId:this.videoinfo.videoId,
                            videoImage:v_info,
                            coverImage:this.imgUrl,
                            shareImage:this.imgUrl4,
                            url:this.imgUrl1+'#####' +this.imgUrl2+'#####' +this.imgUrl3 ,
                            type:5,
                            brandCategory:this.pltext,
                            newType:this.radio == '1' ? 0 : this.newid,
                            skuSpecification:this.result,
                            goodsPriceInfo:pricedate,
                            deliveryTime:this.send_time == '自定义' ? this.zidingyi_time : this.send_time,
                            freightType:Number(this.yunfei),
                            sevenNoReason:Number(this.isseven)
                        }
                        if(this.goods_id == undefined || this.goods_id == '') {
                            if(this.allFieldsNotEmpty(data)) {
                                if(this.imgUrl.indexOf('pic_goods') !== -1 || this.imgUrl1.indexOf('pic_goods') !== -1 || this.imgUrl2.indexOf('pic_goods') !== -1 || this.imgUrl3.indexOf('pic_goods') !== -1 || this.imgUrl4.indexOf('pic_goods') !== -1|| this.videoinfo.image.url.indexOf('pic_video') !== -1){
                                    Toast('请上传完整图片或视频')
                                } else {
                                    var res = await saveGoodsInfo(data);
                                    console.log("保存商品",res);
                                    if(res.code == 200) {
                                        Toast('操作成功'),
                                        setTimeout(() => {
                                            this.$router.go(-1);
                                        }, 300);
                                    } else {
                                        Toast(res.msg)
                                    }
                                }
                            } else {
                                Toast('请补充商品信息')
                            }
                        } else {
                            if(this.allFieldsNotEmpty(data1)) {
                                if(this.imgUrl.indexOf('pic_goods') !== -1 || this.imgUrl1.indexOf('pic_goods') !== -1 || this.imgUrl2.indexOf('pic_goods') !== -1 || this.imgUrl3.indexOf('pic_goods') !== -1 || this.imgUrl4.indexOf('pic_goods') !== -1 || this.videoinfo.image.url.indexOf('pic_video') !== -1){
                                    Toast('请上传完整图片或视频')
                                } else {
                                    var res = await updateGoodsInfo(data1);
                                    console.log("编辑商品",res);
                                    if(res.code == 200) {
                                    Toast('操作成功'),
                                    setTimeout(() => {
                                        this.$router.go(-1);
                                    }, 300);
                                } else {
                                    Toast(res.msg)
                                }
                                }
                            } else {
                                Toast('请补充商品信息')
                            }
                        }
                        
                    }
            } else {
                Toast('请填写商品价格')
            }

        },
        // 查询品牌下面的商品列表
        async getggList() {
                const res = await findList({});
                console.log("查询规格列表",res);
                if(res.data) {
                    this.default_guige = res.data
                }
        },
        //封面图
        coverfun(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'coverfn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        coverfn(e){
            this.imgUrl = e.data[0]
        },
        //详情图
        shopdetailfun(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'shopdetailfn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        shopdetailfn(e){
            this.imgUrl1 = e.data[0]
        },
        shopdetailfuns(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'shopdetailfns'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        shopdetailfns(e){
            this.imgUrl2 = e.data[0]
        },
        shopdetailfunss(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'shopdetailfnss'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        shopdetailfnss(e){
            this.imgUrl3 = e.data[0]
        },
        //分享图
        sharefun(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'sharefn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        sharefn(e){
            this.imgUrl4 = e.data[0]
        },
        //视频
        videofun(){
            var data = {
                action:'GetVideo',
                req:{
                    minute:180
                },
                reqId:3,
                respFunction:'videofn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        videofn(e){
            console.log('宣传视频111',e);
            this.videoinfo.image.url = e.data.videoCoverUrl
            this.videoinfo.videoId = e.data.videoId
        },
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    computed:{
        isObjectEmpty() {
            for (let key in this.$route.params) {
                if (this.$route.params.hasOwnProperty(key)) {
                return false;
                }
            }
            return true;
        }
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log(111,this.$route);
        this.brand_id = this.$route.query.brand_id
        this.top_text = this.$route.params.good_id ? '编辑商品' : '添加新商品'
        await this.getggList();
        // 查询商品详情
        if(!this.isObjectEmpty) {
            let goodsclass = []
            let kucun = []
            let result = [];
            this.goods_id = this.$route.params.good_id
            const res = await queryGoodsInfo(this.$route.params.good_id);
                console.log("查询商品信息",res);
                /* videoId:this.videoinfo.videoId,
                videoImage:v_info,
                coverImage:this.imgUrl,
                shareImage:this.imgUrl4,
                url:this.imgUrl1+',' +this.imgUrl2+',' +this.imgUrl3 , */
                this.imgUrl1 = res.data.url.split('#####')[0],
                this.imgUrl2 = res.data.url.split('#####')[1],
                this.imgUrl3 = res.data.url.split('#####')[2],
                this.imgUrl = res.data.coverImage,
                this.imgUrl4 = res.data.shareImage,
                this.videoinfo = res.data.videoResponse,
                /* this.backvideo = res.data.videoResponse,
                this.videoinfo.videoId = res.data.videoResponse.videoId */
                this.good_name = res.data.name,
                this.result_arr = res.data.category.split(','),
                this.resultstr = res.data.category
                this.pltext = res.data.brandCategory,
                this.radio = res.data.newType == 0 ? '1' : '2'
                this.newid = res.data.newType == 0 ? '' : res.data.newType
                this.newvalue = res.data.newType == 0 ? '' : this.newlist[res.data.newType - 1].val
                this.imgUrl = res.data.coverImage,
                this.yunfei = res.data.freightType.toString()
                this.ssmPrice = res.data.goodsPriceInfo.ssmPrice 
                this.send_time = res.data.deliveryTime
                this.zidingyi_time = res.data.deliveryTime == '自定义' ? '' : res.data.deliveryTime
                this.result = res.data.skuSpecification,
                this.isseven = res.data.sevenNoReason.toString()
                
                for (let i = 0; i < res.data.skuSpecification.length; i++) {
                    for (let j = 0; j < res.data.skuSpecification[i].items.length; j++) {
                        res.data.skuSpecification[i].name = res.data.skuSpecification[i].items.map(item => item.name).join('/'); 
                        res.data.skuSpecification[i].itemIdList = res.data.skuSpecification[i].items.map(item => item.itemId).join(','); 
                        res.data.skuSpecification[i].itemIdList = res.data.skuSpecification[i].itemIdList.split(',')
                        res.data.skuSpecification[i].itemIdList = res.data.skuSpecification[i].itemIdList.map(num => +num)
                    }
                    
                }
                for (let i = 0; i < res.data.skuSpecification.length; i++) {
                    for (let j = 0; j < res.data.skuSpecification[i].itemIdList.length; j++) {
                        goodsclass.push(res.data.skuSpecification[i].itemIdList[j])
                    }
                }
                this.kucunclass = Array.from(new Set(goodsclass))

                for (let i = 0; i < this.default_guige.length; i++) {
                    for (let j = 0; j < this.default_guige[i].itemList.length; j++) {
                        for (let k = 0; k < this.kucunclass.length; k++) {
                            if(this.default_guige[i].itemList[j].id == this.kucunclass[k])
                            kucun.push(this.default_guige[i].itemList[j])
                        }
                    }
                }

                this.kucun = kucun
                console.log("查询选中子规格对象",kucun);
                console.log("查询选中子规格",this.kucunclass);

                if(res.data.specificationInfoItemListResponses.length == 3) {
                    var zdy = {
                        id:res.data.specificationInfoItemListResponses[2].specificationId,
                        name:res.data.specificationInfoItemListResponses[2].specificationName,
                        itemList:res.data.specificationInfoItemListResponses[2].specificationItemList
                    }
                    this.default_guige.push(zdy)
                }
                this.result = res.data.skuSpecification
                this.resultnum = res.data.skuSpecification.length 


                console.log(this.goods);
                
        }

        //分类查询
        /* const res2 = await querySystemCategoryList({})
        console.log("分类多选",res2);
        this.resultlist = res2.data */
        //根据品牌id查询一级分类
        const res4 = await queryGoodsCategoryByBrand({brandId:this.brand_id})
        console.log("查询一级分类",res4);
        this.resultlist = res4.data
        //品类查询
        const res3 = await brandCategoryList({})
        console.log("品类2级",res3);
        this.options = res3.data

        window.coverfn = this.coverfn
        window.shopdetailfn = this.shopdetailfn
        window.shopdetailfns = this.shopdetailfns
        window.shopdetailfnss = this.shopdetailfnss
        window.sharefn = this.sharefn
        window.videofn = this.videofn
        
    }
}
</script>
<style lang="less" scoped>
.bottom_fixed {
    width: 100%;
    height: 90px;
    background: #E9D4BC;
    border-top: 1px solid rgba(223,192,156,0.66);
    padding: 0 15px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    .asd {
        display: flex;
        align-items: center;
        justify-content: space-between; 
        margin-top: 7px;
    }
    .bot {
        width: 49%;
        height: 48px;
        line-height: 48px;
        border-radius: 8px;
        background: rgba(174,133,81,0.15);
        color: #37261B;
        font-size: 18px;
        text-align: center;
        font-family: PingFangSC-regular;
    }
}
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .flex {
        display: flex;
        align-items: center;
        margin:2px 0;
        .send {
            border: 1px solid rgba(187,187,187,1);
            padding: 2px 4px;
            box-sizing: border-box;
        }
        .acsend {
            color: #fff;
            background-color: rgba(240,154,25,1);
            border: 1px solid rgba(240,154,25,1);
        }
        .sc {
            font-size: 14px;
            padding: 10px 15px;
            min-width: 50px;
            text-align: center;
            box-sizing: border-box;
            border-radius: 8px;
            background: rgba(174,133,81,0.15);
            color:rgba(55,38,27,0.5);
            margin-right: 4px;
            position: relative;
            .close {
                position: absolute;
                top: -5px;
                right: -5px;
                /* width: 16px;
                height: 16px;
                line-height: 12px;
                text-align: center;
                border-radius: 8px;
                border: 1px solid #aaa;
                color: #aaa;
                font-size: 12px; */
            }
        }
        .title {
            width: 80px;
        }
        .lx {
            width: 136px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background-color: rgba(255,255,255,1);
            color: rgba(16,16,16,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            border: 1px solid rgba(187,187,187,1);
        }
        .ipt {
            width: 275px;
            height: 28px;
            background-color: rgba(255,255,255,1);
            border: 1px solid rgba(187,187,187,1);
            padding-left: 10px;
            box-sizing: border-box;
        }
        .type_item {
            width: 44px;
            height: 23px;
            line-height: 23px;
            border-radius: 5px;
            background-color: rgba(247,241,241,1);
            color: rgba(136,124,124,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            margin-right: 4px;
            margin-top: 2px;
        }
        .vid {
            width: 92px;
            height: 34px;
            line-height: 34px;
            background-color: rgba(255,255,255,1);
            color: rgba(159,158,158,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            border: 1px solid rgba(187,187,187,1);
        }
        .save {
            width: 53px;
            height: 33px;
            line-height: 33px;
            border-radius: 5px;
            background-color: rgba(163,159,159,0.97);
            color: rgba(255,255,255,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
        }
        .sent {
            width: 280px;
            height: 33px;
            line-height: 33px;
            border-radius: 5px;
            background-color: rgba(174,133,81,0.97);
            color: rgba(255,255,255,1);
            font-size: 14px;
            margin-left: 6px;
            text-align: center;
            font-family: PingFangSC-regular;
        }
    }
    .guige_box {
        width: 100%;
        padding: 30px 20px;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15);
        border-radius: 8px;
        margin-bottom: 10px;
    }
    .active {
        background-color: #876235 !important;
        color: #E9D4BC !important;
    }
    .guige {
        width: 80px;
        height: 33px;
        line-height: 33px;
        border-radius: 8px;
        background-color: #876235;
        color: rgba(247,241,241,1);
        font-size: 14px;
        text-align: center;
        font-family: PingFangSC-regular;
    }
    table {
        border-spacing: 0;
    }
    tr > :first-child {
        width: 94px;
    }
     td {
        padding: 4px;
    }
}
.same {
        width: 48%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-size: 18px;
    }
    .a {
        background-color: #876235 ;
        color: #fff;
    }
    .b {
        background-color: rgba(174,133,81,0.15);
        color: #000;
    }
    .input_fild {
   margin-top: 10px;
   padding:10px 15px;
   box-sizing: border-box;
   background: rgba(174,133,81,0.15);
   border-radius: 8px;
   color: #000;
}
:deep(.van-field__control::-webkit-input-placeholder) {
   color:rgba(55,38,27,0.5);
   font-size: 14px;
}
.btns {
       width: 100%;
       height: 40px;
       border-radius: 5px;
       background-color: #876235;
       line-height: 40px;
       text-align: center;
       color: rgba(255, 255, 255, 1);
       margin-top: 20px;
   }
   .van-tree-select__item {
        font-weight: normal;
    }
   .van-sidebar-item--select, .van-sidebar-item--select:active{
        color:#475AD5;
    }
    .van-tree-select__item--active {
        color:#475AD5;
    }
    .van-sidebar-item--select::before {
        background-color: #475AD5;
    }
    .van-popup {
        background-color: #E9D4BC;
    }
    .van-sidebar-item--select, .van-sidebar-item--select:active {
        background-color: #E9D4BC;
    }
    .van-sidebar-item {
        background-color: #E9D4BC;
    }
    .van-tree-select__nav{
        background-color: rgba(174,133,81,0.15);
    }
    .van-tree-select__content{
        background-color: #E9D4BC;
    }
      //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
    .van-cell::after {
        border-bottom: 1px solid #E9D4BC !important;
    }
</style>
