import Vue from 'vue'
import VueRouter from 'vue-router'
import myvideo from "@/views/myvideo";
import image from "@/views/image";
import index from "@/views/index"
import UserAgreement from "@/views/UserAgreement";
import realNameAgreement from "@/views/realNameAgreement";
import app from "@/views/app";

Vue.use(VueRouter)

const routes = [{
    path: '/app', name: "app", component: app,
}, {
    path: '/', name: "index", component: index,
}, {
    path: '/images', name: 'images', component: image,
}, {
    path: '/myvideo', name: 'myvideo', meta: {
        keepAlive: true // 设置页面缓存
    }, component: myvideo
}, {
    path: "/DXAgreement", name: "DXAgreement", component: () => import("@/views/DXAgreement")
}, {
    path: "/LTAgreement", name: "LTAgreement", component: () => import("@/views/LTAgreement")
}, {
    path: "/privacyPolicy", name: "privacyPolicy", component: () => import("@/views/privacyPolicy")
}, {
    path: "/UserAgreement", name: "UserAgreement", component: UserAgreement
}, {
    path: "/YDAgreement", name: "YDAgreement", component: () => import("@/views/YDAgreement")
}, {
    path: "/realNameAgreement", name: "realNameAgreement", component: () => import("@/views/realNameAgreement")
}, {
    path: "/errorPage", name: "errorPage", component: () => import("@/views/errorPage")
}, {
    path: "/audit", name: "audit", component: () => import("@/views/audit")
}, {
    path: "/authentication", name: "authentication", component: () => import("@/views/authentication")
}, {
    path: "/personalInformation", name: "personalInformation", component: () => import("@/views/personalInformation")
}, {
    path: "/thirdParty", name: "thirdParty", component: () => import("@/views/thirdParty")
}, {
    path: "/androidApp", name: "androidApp", component: () => import("@/views/androidApp")  // 拉新ios和安卓下载app的引导页
}, {
    path: "/andOpenApp", name: "andOpenApp", component: () => import("@/views/andOpenApp")  //安卓环境浏览器打开app引导页
}, {
    path: "/activity",  //活动页
    name: "activity", component: () => import("@/views/activity")
}, {
    path: "/annotation",  //批注
    name: "annotation", component: () => import("@/views/annotation")
}, //history
    {
        path: "/history",  //历史的今天
        name: "history", component: () => import("@/views/history")
    },

//invite
    {
        path: "/invite",  //聚一聚邀请
        name: "invite", component: () => import("@/views/invite")
    }, {
        path: '/acquaintance', //熟人分享
        name: 'acquaintance', component: () => import("@/views/acquaintanceShare")
    }, {
        path: '/ownerShare', //大众人分享
        name: 'ownerShare', component: () => import("@/views/ownerShare")
    }, {
        path: '/inviteCluster', //聚一聚分享拉人
        name: 'InviteCluster', component: () => import("@/views/inviteCluster")
    },
    {
        path: '/testJsBridge',//测试jsbridge
        name: 'TestJsBridge', component: () => import("@/views/testJsBridge")
    }, 
    {
        path: "/jumpClassification",  //1112跳转页
        name: "jump", component: () => import("@/views/jumpClassification")
    },  
    {
        path: "/sharePage20241203",  //1203分享页
        name: "sharePage20241203", component: () => import("@/views/sharePage20241203")
    },
    {
        path: "/inviteActivity20241203",  //1203活动页
        name: "inviteActivity20241203", component: () => import("@/views/inviteActivity20241203")
    },
    {
        path: "/inviteActivity20241203test",  //1203活动页测试
        name: "inviteActivity20241203test", component: () => import("@/views/inviteActivity20241203test")
    },
    {
        path: "/leaveMessage20241203",  //1203留资页
        name: "leaveMessage20241203", component: () => import("@/views/leaveMessage20241203")
    },
    {
        path: "/submitPage20241203",  //1203投稿页
        name: "submitPage20241203", component: () => import("@/views/submitPage20241203")
    },
    {
        path: "/jumpMiniProgram",  //小程序跳转
        name: "jumpMiniProgram", component: () => import("@/views/jumpMiniProgram")
    },
    {
        path: "/togetherShare",  //共享
        name: "togetherShare", component: () => import("@/views/togetherShare")
    },
    {
        path: "/merchantCommon",  //榜单共享
        name: "merchantCommon", component: () => import("@/views/merchantCommon")
    },
    {
        path: "/merchantCommonwork",  //项目共享
        name: "merchantCommonwork", component: () => import("@/views/merchantCommonwork")
    },
    {
        path: "/merchantCommoncreat",  //创意
        name: "merchantCommoncreat", component: () => import("@/views/merchantCommoncreat")
    },
    {
        path: "/liveShare",  //app直播跳转
        name: "liveShare",component: () => import("@/views/liveShare")
    },
    {
        path: "/imageShare",  //图文跳转
        name: "imageShare",component: () => import("@/views/imageShare")
    },
    {
        //    配置故障路由404，必须配在最后一个
        path: "*", name: "errorPage", component: () => import("@/views/errorPage")
    }]


const router = new VueRouter({
    // mode: 'history',
    mode: 'hash', base: process.env.BASE_URL, routes
})

// router.beforeEach((to, from, next) => {
//     // window.entryUrl = location.href.split('#')[0]
//
// })
// router.afterEach((to, from,failure) =>{
// if (failure){
//     if (failure.code === 400){
//         this.$router.push("errorPage")
//     }
// }
// })

export default router
