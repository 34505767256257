<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" left-arrow title="设置物流" @click-left="back" />
        </van-sticky>
        <div class="container" v-if="shipshohw == 0">
            <!-- <div  class="flex box" style="justify-content: space-between;">
                <div>
                    <div style="margin-top: 20px;">其他价格(未添加收货地统一按其他价格)</div>
                    <van-field class="input_fild" name="brand" autocomplete="off" type="number" v-model="price_other" placeholder="请输入"
                    :rules="[{ required: true, message: '请填写其他价格' }]" maxlength="10"/> 
                </div>
                <div class="add" @click="otherprice">确定</div>
            </div> -->
            <div class="flex" style="justify-content: space-between;margin-top: 20px;">
                <div>运费列表</div>
                <div class="add" @click="(shipshohw = 1),(end = ''),(price = '')">添加</div>
            </div>
        </div>
        <!-- 添加物流 -->
        <div class="container">
            <template v-if="shipshohw == 0">

                <!-- //其他价格 -->
                <div class="box" style="margin: 5px 0;">
                    <div class="flex" style="justify-content: space-between;">
                        <div>
                            <div>其他价格(未添加收货地按其他价格)</div>
                            <div style="margin-top: 10px;">¥{{price_other}}</div>
                        </div>
                        <div style="text-decoration: underline;color:#475AD5" @click="showmodes = true">编辑</div>
                    </div>
                </div>



                <div v-for="item in shiplist" class="box" style="margin: 5px 0;">
                    <div class="flex" style="justify-content: space-between;">
                        <div>
                            <div>收货地 <span style="margin-left: 10px;">{{item.endProvince}}</span></div>
                            <div style="display: flex;align-items: center;margin: 10px 0;width: 240px;flex-wrap: wrap;">
                                <div style="margin-right: 5px;" v-for="items in item.endProvinceList">{{ items }}</div>
                            </div>
                            <div>运费<span style="margin-left: 10px;">¥{{item.amount}}</span></div>
                        </div>
                        <div style="text-decoration: underline;color:#475AD5" @click="editmoney(item)">编辑</div>
                        <div style="text-decoration: underline;color:rgba(55,38,27,0.5);" @click="deletefun(item)">删除</div>
                    </div>
                </div>
            </template>
            <template v-if="shipshohw == 1">
                <van-form @submit="onSubmit">
                    <div style="margin-top: 20px;">收货地(未添加收货地统一按其他价格) <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <el-select  multiple class="aaa"   v-model="end" style="width: 100%;margin-top: 10px;" @change="startfun1" filterable placeholder="请选择" >
                        <el-option
                            v-for="item in addressList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.name">
                        </el-option>
                    </el-select>
                    <div style="margin-top: 20px;">价格 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" name="brand" autocomplete="off" type="number" v-model="price" placeholder="请输入"
                        :rules="[{ required: true, message: '请填写价格' }]" maxlength="10"/> 
                    <van-button native-type="submit" class="btn">确定</van-button>
                </van-form>
            </template>
        </div>
        <van-popup round v-model="showmode" position="bottom" >
                <div class="wuliu_box" style="height: 320px;">
                    <div style="color:rgba(55,38,27,0.5);">编辑运费</div>
                    <div style="display: flex;align-items: center;flex-wrap: wrap;">
                        <div v-for="item in editinfo.endProvinceList" style="margin-right: 10px;">{{ item }}</div>
                    </div>
                    <div>
                        <div style="color:rgba(55,38,27,0.5);">运费</div>
                        <van-field class="input_fild" autocomplete="off" type="number" v-model="edit_mon" placeholder="请输入"
                        :rules="[{ required: true, message: '请填写运费' }]" maxlength="10"/> 
                    </div>
                    <van-button class="btn" @click="edityun">确认</van-button>
                </div>
        </van-popup>
        <!-- //其他价格 -->
        <van-popup round v-model="showmodes" position="bottom" >
                <div class="wuliu_box" style="height: 280px;">
                    <div style="color:rgba(55,38,27,0.5);">编辑其他价格</div>
                    <div>
                        <div style="color:rgba(55,38,27,0.5);">其他价格</div>
                        <van-field class="input_fild" autocomplete="off" type="number" v-model="price_other" placeholder="请输入"
                        :rules="[{ required: true, message: '请填写其他价格' }]" maxlength="10"/> 
                    </div>
                    <van-button class="btn" @click="otherprice">确认</van-button>
                </div>
        </van-popup>
    </div>
</template>
<script>
import { addSupplierTemplate,searchSupplierTemplateList,deleteSupplierTemplate,queryOtherSupplierTemplate,updateSupplierTemplate} from '@/api/goods';
import { Toast ,Dialog} from 'vant';
import addressList from '@/utils/BRCity'
export default {
    name: 'work',
    data() {
        return {
            mar_top:'',
            brand:"",
            options:[
                '北京','重庆','上海','天津','湖南','湖北','广东','广西','河南','河北',
            '山东','山西','江西','江苏','浙江','黑龙江','新疆','云南','贵州','福建','吉林','安徽','四川','西藏','宁夏','辽宁','青海','甘肃','陕西','海南','内蒙古'
        ],
            shipshohw:0,
            start:'',
            end:'',
            money:'',
            price:'',
            price_other:'',
            brandid:'',
            shiplist:[],
            showmode:false,
            showmodes:false,
            editinfo:{},
            edit_mon:'',

            addressList:addressList.provinces
        }
    },
    methods: {
        //添加物流确定
        async onSubmit(){
            if(this.end == '') {
                Toast('请选择收货地')
            } else {
                const res = await addSupplierTemplate({
                    endProvinceList:this.end,
                    brandId:this.brandid,
                    amount:this.price
                })
                console.log("添加运费",res);
                if(res.code == 200) {
                    Toast('操作成功')
                    this.shipshohw = 0
                    await this.getlist();
                }
            }
            
        },
        //其他价格确定
        async otherprice(){
            if(this.price_other == '') {
                Toast('请输入价格')
            } else {
                const res = await addSupplierTemplate({
                    endProvinceList:[-1],
                    brandId:this.brandid,
                    amount:this.price_other
                })
                console.log("添加其他运费",res);
                if(res.code == 200) {
                    Toast('操作成功')
                    this.showmodes = false
                    await this.getother()
                }
            }
        },
        //编辑运费价格
        editmoney(e){
            console.log("编辑价格",e);
            this.showmode = true
            this.editinfo = e
            this.edit_mon = e.amount
        },
        //编辑运费价格确认
        async edityun(){
            if(this.edit_mon == '') {
                Toast('运费不能为空')
            } else {
                const res = await updateSupplierTemplate({
                    id:this.editinfo.id,
                    amount:this.edit_mon
                })
                console.log("编辑运费",res);
                if(res.code == 200) {
                    Toast('操作成功')
                    this.showmode = false
                    await this.getlist();
                }
            }
            
        },


        // 删除
        deletefun(e){
            console.log(e);
            Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: `是否删除此条数据`,
                })
                .then(async () => {
                    const res =  await deleteSupplierTemplate({ids:[e.id]})
                    console.log("删除",res);
                    if(res.code == 200) {
                        Toast('操作成功')
                    }
                    setTimeout(() => {
                        this.getlist();
                    }, 300);
                })
                .catch(() => {
                    // on cancel
                });
        },
        startfun1(e){
            console.log(e);
        },
        back() {
            this.$router.go(-1);
        },
        async getlist(){
            const res = await searchSupplierTemplateList({ 
                brandId:this.brandid,
                pageNum:1,
                pageSize:999
            });
            console.log("查询运费模板列表",res);
            this.shiplist = res.data.records
        },
        async getother(){
            const res = await queryOtherSupplierTemplate({brandId:this.brandid})
            console.log("其他价格",res);
            if(res.data) {
                this.price_other = res.data.amount
            }
        },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log(this.$route.params);
        this.brandid = this.$route.params.id
        await this.getlist();
        await this.getother()
        console.log(this.addressList);

        
    }
}
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
    background: rgba(174,133,81,0.15);
    border: none;
}
/deep/ .el-input__inner::-webkit-input-placeholder {
    color:rgba(55,38,27,0.5);
}
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 
:deep(.van-field__control::-webkit-input-placeholder) {
    color:rgba(55,38,27,0.5);
    font-size: 14px;
}
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-size: 14px;
    .box {
        padding: 8px;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;

    }
   
}
.tosat {
    width: 300px;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 15px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.flex {
    display: flex;
    align-items: center;
}
.title {
    width: 60px;
}
.add {
        border-radius: 5px;
        padding: 8px 20px;
        box-sizing: border-box;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
}
.input_fild {
   margin-top: 10px;
   padding:10px 15px;
   box-sizing: border-box;
   background: rgba(174,133,81,0.15);
   border-radius: 8px;
   color: #000;
}
.wuliu_box {
    height: 300px;
    padding: 20px;
    box-sizing: border-box; 
    background-color: #E9D4BC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
}
.btn {
        width: 100%;
        border-radius: 5px;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        margin-top: 20px;
        border: 1px solid #876235;
    }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
    .van-cell::after {
        border-bottom: 1px solid #E9D4BC !important;
    }
</style>
<style>
.el-select-dropdown {
    background-color: #E9D4BC !important;
    border: none !important;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    background-color: rgba(147, 87, 8, 0.15) !important;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: #876235 !important;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
    background-color: #E9D4BC !important;
}
.el-select .el-tag {
    background-color: rgba(147, 87, 8, 0.15) !important;
    border: none !important;
    color: #876235 !important;
}
.el-tag.el-tag--info .el-tag__close {
    background-color: #E9D4BC !important;
}

</style>
