import { render, staticRenderFns } from "./image.vue?vue&type=template&id=8aa5fa78&scoped=true"
import script from "./image.vue?vue&type=script&lang=js"
export * from "./image.vue?vue&type=script&lang=js"
import style0 from "./image.vue?vue&type=style&index=0&id=8aa5fa78&prod&lang=less&scoped=true"
import style1 from "./image.vue?vue&type=style&index=1&id=8aa5fa78&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8aa5fa78",
  null
  
)

export default component.exports