<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" left-arrow title="发货信息" @click-left="back" />
        </van-sticky>
        <!-- 添加物流 -->
        <div class="container">
            <template>
                <van-form>
                    <div style="margin-top: 20px;">发货人 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" name="name" autocomplete="off"  v-model="name" placeholder="请输入姓名"
                        :rules="[{ required: true, message: '请填写发货人姓名' }]" maxlength="10"/> 

                    <div style="margin-top: 20px;">发货地 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" name="address" autocomplete="off"  v-model="address" placeholder="请输入发货地"
                        :rules="[{ required: true, message: '请填写发货地' }]" maxlength="20"/> 

                    <div style="margin-top: 20px;">发货人手机号 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" name="phone" autocomplete="off" type="tel" v-model="phone" placeholder="请输入发货人手机号"
                        :rules="[{ required: true, message: '请填写发货人手机号' }]" maxlength="11"/> 
                    <van-button v-if="edit == 0" @click="onSubmit" class="btn">确定</van-button>
                    <van-button v-if="edit == 1" @click="enitfun" class="btn">修改</van-button>
                </van-form>
            </template>
        </div>
    </div>
</template>
<script>
import { addBrandDeliveryInfo,queryBrandDeliveryInfo,updateBrandDeliveryInfo} from '@/api/goods';
import { Toast ,Dialog} from 'vant';
export default {
    name: 'work',
    data() {
        return {
            mar_top:'',
            info:{},
            edit:0,
            phone:'',
            name:'',
            address:'',
            brandid:'',
            id:''
        }
    },
    methods: {
        async onSubmit(){
            const res = await addBrandDeliveryInfo({
                brandId:this.brandid,
                userName:this.name,
                address:this.address,
                phone:this.phone
            })
            console.log("添加发货信息",res);
            if(res.code == 200) {
                Toast('操作成功')
                await this.getinfo()
                this.$router.go(-1);
            }
        },
        async enitfun(){
            const res = await updateBrandDeliveryInfo({
                id:this.id,
                userName:this.name,
                address:this.address,
                phone:this.phone
            })
            console.log("修改发货信息",res);
            if(res.code == 200) {
                Toast('操作成功')
                await this.getinfo()
                setTimeout(() => {
                    this.$router.go(-1);
                }, 300);
            }
        },
        async getinfo(){
            const res = await queryBrandDeliveryInfo({
                id:this.brandid
            })
            console.log('查询',res);
            if(res.data.length!=0) {
                this.edit = 1
                this.id = res.data[0].id
                this.name = res.data[0].userName
                this.phone = res.data[0].phone
                this.address = res.data[0].address
            } else {
                this.edit = 0
            }
            
        },
        back() {
            this.$router.go(-1);
        },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log(this.$route.query);
        this.brandid = this.$route.query.id
        await this.getinfo()
    }
}
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
    background: rgba(174,133,81,0.15);
    border: none;
}
/deep/ .el-input__inner::-webkit-input-placeholder {
    color:rgba(55,38,27,0.5);
}
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 
:deep(.van-field__control::-webkit-input-placeholder) {
    color:rgba(55,38,27,0.5);
    font-size: 14px;
}
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-size: 14px;
    .btn {
        width: 100%;
        border-radius: 5px;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        margin-top: 20px;
        border: 1px solid #876235;
    }
}
.tosat {
    width: 300px;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 15px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.flex {
    display: flex;
    align-items: center;
}
.title {
    width: 60px;
}
.add {
        border-radius: 5px;
        padding: 8px 20px;
        box-sizing: border-box;
        background-color: rgba(174, 133, 81, 0.97);
        color: rgba(255, 255, 255, 1);
}
.input_fild {
   margin-top: 10px;
   padding:10px 15px;
   box-sizing: border-box;
   background: rgba(174,133,81,0.15);
   border-radius: 8px;
   color: #000;
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
