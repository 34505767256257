<template>
  <div>
    <div style="display: flex;align-items: center;justify-content: center;">
      <van-button @click="tofun">商家入驻</van-button>
      <!-- <van-button @click="towork">工作台</van-button> -->
    </div>
  </div>

</template>
<script>
import {getList} from "@/api/post"

export default {
  name: "index",
  data() {
    return {
      getList: {
        postId: null, //帖子id
        type: "" //帖子类型
      },
      // inviteParmas: {  //用于聚一聚拉人
      //   inviteCode: "",
      //   shareType: 3,
      //   company: "cmxt",
      //   phoneType: "ios",
      //   invitePostId: ""
      // },
      type: ""  //这个type走老版本app逻辑
    }
  },
  created() {
    this.getQueryParams()
    const req = this.getQueryParams()
    this.getList.postId = req.postId
    this.getList.type = req.type
  },
  mounted() {
    // //判断走新老版本app逻辑
    if (this.getList.postId && this.getList.type) {
      this.isImage()
    } else if (this.getList.postId){
      this.getType()
    }
    //safari阻止缩放
    // window.addEventListener('gesturestart', this.preventZoom, false);
  },
  beforeDestroy() {
  // window.removeEventListener('gesturestart', this.preventZoom);
},
  methods: {
    tofun(){
      this.$router.push({ name: 'beforenter' });
    },
    towork(){
      this.$router.push({ name: 'work' });
    },

    // 防止放大缩小的事件处理函数
    preventZoom(e) {
      e.preventDefault();
    },
    // 获取URL查询参数的方法
    getQueryParams() {
      const queryParams = {};
      // 获取完整的URL查询字符串
      const searchParams = new URLSearchParams(window.location.search);
      // 遍历查询参数，并将其存储在对象中
      for (const [key, value] of searchParams) {
        queryParams[key] = value;
      }
      console.log(queryParams, "index页拿到的地址栏参数")
      return queryParams;
    },
    // 判断
    isImage() {
      if (this.getList.type === 'image') {
        this.$router.replace({path: "/images", query: {postId: this.getList.postId, type: this.getList.type}})
      } else if (this.getList.type === 'video') {
        this.$router.replace({path: "/myvideo", query: {postId: this.getList.postId, type: this.getList.type}})
      } else if (this.getList.type === 'history') {
        this.$router.replace({path: "/history", query: {postId: this.getList.postId, type: this.getList.type}})
      } else if (this.getList.type === 'annotation') {
        this.$router.replace({path: "/annotation", query: {postId: this.getList.postId, type: this.getList.type}})
      }
      // else if (this.getList.type === 'invite') {
      //   this.$router.replace({path: "/invite", query: {postId: this.getList.postId, type: this.getList.type}})
      // }
      else if (this.getList.type === 'acquaintance') { //分享拉人
        this.$router.replace({path: "/ownerShare", query: {postId: this.getList.postId, type: this.getList.type}})
      } else if (this.getList.type === 'ownerShare') {
        this.$router.replace({path: "/ownerShare", query: {postId: this.getList.postId, type: this.getList.type}})
      }
      // else if (this.getList.type === 'inviteCluster') {
      //   this.$router.replace({
      //     path: "/inviteCluster",
      //     query: {
      //       inviteCode: this.inviteParmas.inviteCode,
      //       // type: this.getList.type,
      //       shareType: this.inviteParmas.shareType,
      //       company: this.inviteParmas.company,
      //       phoneType: this.inviteParmas.phoneType,
      //       invitePostId: this.inviteParmas.invitePostId
      //     }
      //   })
      // }
    },

    //兼容老版本app
    oldisImage() {
      if (this.type === '图') {
        this.$router.replace({path: "/images", query: {postId: this.getList.postId}})
      } else if (this.type === '视频') {
        this.$router.replace({path: "/myvideo", query: {postId: this.getList.postId}})
      }
    },
    async getType() {
      try {
        const res = await getList(this.getList);
        if (res.code === 200) {
          this.type = res.data.type;
          this.oldisImage()
        } else {
          await this.$router.replace({path: "/errorPage", query: {postId: this.getList.postId}})
        }
      } catch (error) {
        if (error !== 200) {
          await this.$router.replace({path: "/errorPage", query: {postId: this.getList.postId}})
        }
      }
    }
  }
}

</script>

<style scoped>

</style>
