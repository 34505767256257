import request from "@/utils/request";

// 用户查询订单列表
export const queryUserOrderList = (data) => {
    return request({
        url: `/ssm-trade/api/trade/queryUserOrderList`,
        method: "POST",
        data
    })
}
// 用户确认收货
export const userConfirmReceive = (data) => {
    return request({
        url: `/ssm-trade/api/trade/userConfirmReceive`,
        method: "POST",
        data
    })
}
// 申请退款
export const addOrderRefund = (data) => {
    return request({
        url: `/ssm-trade/api/trade/addOrderRefund`,
        method: "POST",
        data
    })
}
// 重新提交退款
export const reSubmitRefund = (data) => {
    return request({
        url: `/ssm-trade/api/trade/reSubmitRefund`,
        method: "POST",
        data
    })
}
// 修改退款申请
export const updateRefund = (data) => {
    return request({
        url: `/ssm-trade/api/trade/updateRefund`,
        method: "POST",
        data
    })
}
// 申请开票
export const addInvoice = (data) => {
    return request({
        url: `/ssm-trade/api/trade/addInvoice`,
        method: "POST",
        data
    })
}
// 用户填写退货单号
export const deliverRefundGoods = (data) => {
    return request({
        url: `/ssm-trade/api/trade/deliverRefundGoods`,
        method: "POST",
        data
    })
}
// 用户取消退款
export const cancelOrderRefund = (data) => {
    return request({
        url: `/ssm-trade/api/trade/cancelOrderRefund`,
        method: "POST",
        data
    })
}
// 用户取消订单
export const userCancelOrder = (data) => {
    return request({
        url: `/ssm-trade/api/trade/userCancelOrder`,
        method: "POST",
        data
    })
}
// 删除订单
export const deleteOrder = (data) => {
    return request({
        url: `/ssm-trade/api/trade/deleteOrder`,
        method: "POST",
        data
    })
}
//用户查询订单详情
export const queryOrderInfoById = (data) => {
    return request({
        url: `/ssm-trade/api/trade/queryOrderInfoById`,
        method: "POST",
        data
    })
}
// 重新支付
export const getRePayOrderInfo = (data) => {
    return request({
        url: `/ssm-trade/api/trade/getRePayOrderInfo`,
        method: "POST",
        data
    })
}

