<template>
    <div class="page" >
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="订单详情" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <!-- <div class="flex" style="margin: 10px 0;justify-content: space-between;">
                <div>{{ company_name }} {{ info.logisticsNumber }}</div>
                <div class="flex">
                    <span>复制</span>
                    <span>｜</span>
                    <span>打电话</span>
                </div>
            </div> -->
            <!-- <van-steps v-if="!showmore" inactive-color="rgba(55,38,27,0.5)" active-color="#475AD5" direction="vertical" :active="-1" style="background: rgba(0, 0, 0, 0);">
                <van-step>
                    <div>{{wuliulist[0].context}}</div>
                    <div style="margin-top: 10px;">{{wuliulist[0].ftime}}</div>
                </van-step>
                <van-step>
                    <div class="flex" @click="showmore = true">
                        <div>查看更多物流信息</div>
                        <van-image width="20" height="20" :src="require('/src/assets/pic_down.png')"/>
                    </div>
                </van-step>
            </van-steps>
            <van-steps v-if="showmore" inactive-color="rgba(55,38,27,0.5)" active-color="#475AD5" direction="vertical" :active="-1" style="background: rgba(0, 0, 0, 0);">
                <van-step v-for="item in wuliulist">
                    <div>{{item.context}}</div>
                    <div style="margin-top: 10px;">{{item.ftime}}</div>
                </van-step>
                <van-step >
                    <div @click="showmore = false">收起物流信息</div>
                </van-step>
            </van-steps> -->
            <!-- <div style="height: 1px;width: 100%;background-color: rgba(223,192,156,0.66);margin: 10px 0;"></div> -->
            <div class="box">
                <!-- <div style="color: #475AD5;width: 100%;text-align: right;">交易成功</div> -->
                <div class="flex" style="justify-content: space-between;">
                            <div class="flex">
                                <van-image
                                    round
                                    width="30"
                                    height="30"
                                    :src="info.merchantsBrandInfoResponse.brandLogo"
                                    style="margin-right: 5px;"
                                    />
                                <div>{{info.merchantsBrandInfoResponse.brand}}</div>
                            </div>
                           <div style="color: #475AD5;" v-if="info.status == 0">待付款</div>
                           <div style="color: rgba(55,38,27,0.33);" v-if="info.status == 1">交易关闭</div>
                           <div style="color: #475AD5;" v-if="info.status == 2">待发货</div>
                           <div style="color: #475AD5;" v-if="info.status == 3">支付失败</div>
                           <div style="color: #475AD5;" v-if="info.status == 8">待收货</div>
                           <div style="color: #475AD5;" v-if="info.status == 10">交易成功</div>
                           <div style="color: #475AD5;" v-if="info.status == 11">已申请开票</div>
                           <div style="color: #475AD5;" v-if="info.status == 12">开票成功</div>

                       </div>
                <div style="height: 1px;width: 100%;background-color:#DFC09C;margin: 10px 0;"></div>
                <div v-for="items in info.orderItemList" class="flex" style="flex-direction: column;">
                    
                    <div class="flex" style="justify-content: space-between;">
                        <div class="flex">
                        <img :src="items.coverImage" class="imgs">
                        <div class="goodsn">
                            <div style="font-size: 15px;" class="t">{{items.goodsName}}</div>
                            <div style="font-size: 14px;color: rgba(55,38,27,0.33);display: flex;margin-right: 10px;">
                                <div  v-for="guige in items.specificationItemNameList">{{guige}}</div>
                            </div>
                        </div>
                        </div>
                        <div style="height: 60px;display: flex;flex-direction: column;justify-content: space-around;">
                            <div style="font-size: 18px;color: #475AD5;">¥{{ items.amount }}</div>
                            <div style="font-size: 14px;color: rgba(55,38,27,0.33);" >x{{items.num}}</div>
                        </div>
                    </div>
                    <div style="height: 1px;width: 100%;background-color:#DFC09C;margin: 10px 0;"></div>
                </div>
                <!-- 未支付显示 -->
                <div style="font-size: 12px;color: #B10808;" >
                    <template v-if="info.status == 0">
                        <div class="flex">
                            <span style="margin-right: 10px;">剩余支付时间</span>
                            <van-count-down @finish="finshfun" style="color: #B10808;" :time="info.residuePayMilliSecond" />
                        </div>
                    </template>
                </div> 
            </div>

            
            <div class="flex bt" style="margin: 10px 0;">
                <div>商品总价</div>
                <div style="margin-left: 30px;">¥{{info.amount}}</div>
            </div>
            <div class="flex bt" style="margin: 10px 0;">
                <div>运费</div>
                <div style="margin-left: 30px;">¥{{info.expressAmount}}</div>
            </div>
            <div class="flex bt" style="margin: 10px 0;">
                <div>实付款</div>
                <div style="margin-left: 30px;">¥{{info.payAmount}}</div>
            </div>
            <!-- <div class="flex bt" style="margin: 10px 0;">
                <div>实付款</div>
                <div style="margin-left: 30px;">¥{{info.amount}}</div>
            </div> -->

            <div style="height: 1px;width: 100%;background-color: rgba(223,192,156,0.66);margin: 10px 0;"></div>
            <!-- <div class="flex bt" style="margin: 10px 0;">
                <div>交易快照</div>
                <div style="margin-left: 30px;">¥{{info.amount}}</div>
            </div>
            <div class="flex bt" style="margin: 10px 0;">
                <div>微信交易号</div>
                <div style="margin-left: 30px;">¥{{info.amount}}</div>
            </div> -->

            <div class="flex bt" style="margin: 10px 0;">
                <div>创建时间</div>
                <div style="margin-left: 30px;">{{info.createTime}}</div>
            </div>
            <div v-if="info.payWay" class="flex bt" style="margin: 10px 0;">
                <div>付款方式</div>
                <div style="margin-left: 30px;">{{info.payWay == 0 ? '微信' :info.payWay == 1 ? '支付宝' : ''}}</div>
            </div>
            <div v-if="info.payTime" class="flex bt" style="margin: 10px 0;">
                <div>付款时间</div>
                <div style="margin-left: 30px;">{{info.payTime}}</div>
            </div>
            <div v-if="info.cancelTime" class="flex bt" style="margin: 10px 0;">
                <div>取消时间</div>
                <div style="margin-left: 30px;">{{info.cancelTime}}</div>
            </div>
            <div v-if="info.deliveryTime" class="flex bt" style="margin: 10px 0;">
                <div>发货时间</div>
                <div style="margin-left: 30px;">{{info.deliveryTime}}</div>
            </div>
            <div class="flex bt" style="margin: 10px 0;">
                <div>更新时间</div>
                <div style="margin-left: 30px;">{{info.updateTime}}</div>
            </div>
            <div class="flex p" style="justify-content: space-between;">
                <div v-if="info.status == 0 " class="same b" @click="cancelfun">取消</div>
                <div v-if="info.status == 0 " class="same a" @click="payfun">支付</div>
                <div v-if="info.status == 1 " style="width: 100%;" class="same b" @click="deletefun">删除</div>
                <div v-if="info.status == 2 " style="width: 100%;" class="same a" @click="applyfun">申请退款</div>
                <div v-if="info.status == 8 " class="same recive"  @click.stop="towuliudetail">查看物流</div>
                <div v-if="info.status == 8" class="same recive"  @click.stop="applyfun">申请退款</div>
                <div v-if="info.status == 8" class="same recive" @click.stop="recivefun">确认收货</div>
                <div v-if="info.status == 10" class="same recive"  @click.stop="towuliudetail">查看物流</div>
                <div v-if="info.status == 10" class="same recive"  @click.stop="invoice">申请发票</div>
                <div v-if="info.status == 12" style="width: 100%;" @click.stop="previewImage(info.invoice.invoiceUrl)" class="same recive">查看发票</div>
            </div>
        </div> 
    </div>
</template>
<script>
import { Toast ,ImagePreview,Dialog} from 'vant';
import {query } from '@/api/order';
import {userCancelOrder,deleteOrder,userConfirmReceive,queryOrderInfoById} from '@/api/myorder';
export default {
    name: 'myorder',
    data() {
        return {
            mar_top:'',
            options: [
                {value:'yuantong',label:'圆通速递'},
                {value:'yunda',label:'韵达快递'},
                {value:'zhongtong',label:'中通快递'},
                {value:'jtexpress',label:'极兔速递'},
                {value:'shentong',label:'申通快递'},
                {value:'youzhengguonei',label:'邮政快递包裹'},
                {value:'shunfeng',label:'顺丰速运'},
                {value:'jd',label:'京东物流'},
                {value:'ems',label:'EMS'},
                {value:'youzhengdsbk',label:'邮政电商标快'},
                {value:'youzhengbk',label:'邮政标准快递'},
                {value:'debangkuaidi',label:'德邦快递'},
                {value:'danniao',label:'菜鸟速递'},
                {value:'xinfengwuliu',label:'信丰物流'},
                {value:'debangwuliu',label:'德邦物流'},
                {value:'zhongtongkuaiyun',label:'中通快运'},
                {value:'rrs',label:'日日顺物流'},
                {value:'jingdongkuaiyun',label:'京东快运'},
                {value:'shunfengkuaiyun',label:'顺丰快运'},
                {value:'kuayue',label:'跨越速运'},
                {value:'annengwuliu',label:'安能快运'},
                {value:'baishiwuliu',label:'百世快运'},
                {value:'sxjdfreight',label:'顺心捷达'},
                {value:'jinguangsudikuaijian',label:'京广速递'},
                {value:'yimidida',label:'壹米滴答'},
                {value:'yundakuaiyun',label:'韵达快运'},
                {value:'subida',label:'速必达'},
                {value:'usps',label:'USPS'},
                {value:'youshuwuliu',label:'优速'},
                {value:'annto',label:'安得物流'},
                {value:'pingandatengfei',label:'平安达腾飞'},
                {value:'zhongtongguoji',label:'中通国际'},
                {value:'jiayunmeiwuliu',label:'加运美'},
                {value:'cainiaodajian',label:'菜鸟大件'},
                {value:'dhl',label:'DHL-中国件'},
                {value:'savor',label:'海信物流'},
                {value:'ups',label:'UPS'},
                {value:'zhaijisong',label:'宅急送'},
                {value:'upsen',label:'UPS-全球件'},
                {value:'ztky',label:'中铁快运'},
                {value:'suning',label:'苏宁物流'},
                {value:'suteng',label:'速腾快递'},
                {value:'fedexcn',label:'Fedex-国际件-中文'},
                {value:'ewe',label:'EWE全球快递'},
                {value:'stosolution',label:'申通国际'},
                {value:'huitongkuaidi',label:'百世快递'},
                {value:'shunfenglengyun',label:'顺丰冷链'},
                {value:'fedex',label:'FedEx-国际件'},
                {value:'huisenky',label:'汇森速运'},
                {value:'exfresh',label:'安鲜达'},
                {value:'disifang',label:'递四方'},
                {value:'quantong56',label:'全通速递'},
                {value:'youzhengguoji',label:'国际包裹'},
                {value:'flextock',label:'Flextock'},
                {value:'dhlen',label:'DHL-全球件'},
                {value:'zhongtiewuliu',label:'中铁飞豹'},
                {value:'longjun',label:'龙俊物流'},
                {value:'emswuliu',label:'EMS物流'},
                {value:'chinapost',label:'中国邮政（CHINA POST）'},
                {value:'ane66',label:'安能快递'},
                {value:'yuxinwuliu',label:'宇鑫物流'},
                {value:'shpost',label:'同城快寄'},
                {value:'emsbg',label:'EMS包裹'},
                {value:'yuantongguoji',label:'圆通国际'},
                {value:'emsguoji',label:'EMS-国际件'},
                {value:'ttsucha',label:'天天速查'},
                {value:'wooolink',label:'上海领链物流'},
            ],
            info:{},
            wuliulist:[],
            company_name:'',
            showmore:false,
            did:''
        }
    },
    methods: {
        // 取消订单
        cancelfun(){
            Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: '是否取消此订单',
                })
                .then(async() => {
                    const res =  await userCancelOrder({id:this.info.id})
                    console.log("取消订单",res);
                    Toast('操作成功')
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 300);
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 支付
        async payfun(){
            console.log(e);
            const res = await getRePayOrderInfo({
                orderNo:e.bizOrderNo,
                type:1
            })
            console.log("重新支付",res);
            var paydata = {}
            if(res.data.payWay == 0) { //微信支付
                var a = {
                        payChannel:'wechat',
                        timeStamp:JSON.parse(res.data.orderStr).timeStamp,
                        sign:JSON.parse(res.data.orderStr).sign,
                        nonceStr:JSON.parse(res.data.orderStr).nonceStr,
                        appId:JSON.parse(res.data.orderStr).appId,
                        packageValue:JSON.parse(res.data.orderStr).packageValue,
                        prepayId:JSON.parse(res.data.orderStr).prepayId,
                        partnerId:JSON.parse(res.data.orderStr).partnerId,
                    }
                    paydata = a
            } else { //支付宝支付
                var a = {
                        payChannel:'alipay',
                        orderInfo:res.data.orderStr,
                    }
                    paydata = a
            }
            var data = {
                action:'Play',
                req:paydata,
                reqId:4,
                respFunction:'bridgepayfn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
         //支付bridge回调
         async bridgepayfn(e){
            console.log("支付   ",e);
            if(e.code == 200) {
                Toast('操作成功')
                this.$router.go(-1);
            }
        },
        // 删除
        deletefun(){
            Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: '是否删除此订单',
                })
                .then( async() => {
                    const res =  await deleteOrder({orderId:this.info.id})
                    console.log("删除",res);
                        Toast('操作成功')
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 300);
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 申请退款
        applyfun(){
            this.$router.replace({ name: 'apply' ,params:{info:this.info,isfirst:1}})
        },
        //查看发票
        previewImage(e){
            ImagePreview([e])
        },
        // 查看物流
        towuliudetail(){
            if(this.info.logisticsCompany == null || this.info.logisticsNumber == null) {
                Toast('暂无物流信息')
            } else {
                this.$router.replace({ name: 'wuliudetaile' ,params:{info:this.info}});
            }
        },
        //确认收货
        recivefun(){
            Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: '是否确认收货',
                })
                .then(async () => {
                    const res = await userConfirmReceive({id:this.info.id})
                    console.log("确认收货",res);
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 300);
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 开发票
        invoice(){
            this.$router.push({ name: 'invoices' ,params:{info:this.info}});
        },
        async finshfun(){
            await this.getdetail()
        },



        // 复制文本
        copyfun(){
            try {
                const textToCopy = "这是要复制的文本内容";
                navigator.clipboard.writeText(textToCopy);
                console.log("文本已复制到剪贴板");
            } catch (error) {
                console.error("复制到剪贴板失败:", error);
        }
        },
        back() {
            this.$router.go(-1);
        },
         // 查询物流信息
         async getinfo() {
            const res = await query({ 
                bizOrderNo: this.bizOrderNo,
            });
            this.wuliulist = res.data.dataList.reverse()
            console.log("查询物流信息",this.wuliulist);

        },
        async getdetail(){
            const res = await queryOrderInfoById({ 
            orderId: this.did,
            });
            console.log("订单详情",res);
            
        this.info = res.data
        },
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    
    async mounted() {
        window.bridgepayfn = this.bridgepayfn
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log("this.$route.params",this.$route.params.info);
       this.did = this.$route.params.info.id
        await this.getdetail()
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.bt {
    justify-content: space-between;
}
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .box {
        padding: 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 7px 0;
    }
    .flex {
        display: flex;
        align-items: center;
        color: rgba(55,38,27,0.66);
        .img {
           width: 24px;
           height: 24px;
           border-radius: 12px; 
        }
        .imgs {
            width: 60px;
            height: 60px;
            border-radius: 8px; 
        }
        .goodsn {
            width: 190px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .t {
            // width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .p {
        width: 94%;
        position: fixed;
        left:50%;
        bottom: 50px;
        transform: translate(-50%,0);
    }
    .same {
        
        width: 48%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        font-size: 18px;
    }
    .a {
        background-color: #876235 ;
        color: #fff;
    }
    .b {
        background-color: #E0C8AC;
        color: #000;
    }
        .cancel {
            border: 1px solid rgba(55,38,27,0.33);
            color: rgba(55,38,27,0.5);
        }
        .sub {
            background: #876235;
            color: #fff;
        }
        .recive {
            background-color: #876235;
            color: #fff;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tosat {
            width: 300px;
            height: 180px;
            border-radius: 8px;
            background-color: #E9D4BC;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }
    .res {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        height: 52px;
        border-radius: 8px;
        background-color: rgba(174,133,81,0.15);
        margin-top: 10px;
    }
    .ipt {
        border: none;
        background: rgba(0, 0, 0, 0);
    }
    .te {
        border-radius: 8px;
        margin: 10px 0;
        background-color: rgba(174,133,81,0.15);
    }
    .p {
        width: 94%;
        position: fixed;
        left:50%;
        bottom: 50px;
        transform: translate(-50%,0);
    }
    .same {
        
        width: 48%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        font-size: 18px;
    }
    .a {
        background-color: #876235 ;
        color: #fff;
    }
    .b {
        background-color: rgba(174,133,81,0.15);
        color: #000;
    }
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
