import Vue from 'vue'
// 在这里引入你所需的组件
import {
    Button,
    Swipe,
    SwipeItem,
    Image as VanImage,
    Popup,
    Form,
    Field,
    Uploader,
    RadioGroup,
    Radio,
    CellGroup,
    Toast,
    Picker,
    Row,
    Col,
    NavBar,
    Sticky,
    NumberKeyboard,
    Checkbox, 
    CheckboxGroup,
    ImagePreview,
    Switch,
    Tag,
    Search,
    DatetimePicker,
    Icon,
    Tab,
    Tabs,
    Overlay,
    List,
    Popover,
    CountDown,
    Dialog,
    Step,
    Steps,
    NoticeBar,
    Cascader,
    TreeSelect,
    Cell,
    PullRefresh
} from 'vant'

// 按需引入UI组件
Vue.use(Button)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(VanImage)
Vue.use(Popup)
Vue.use(Form);
Vue.use(Field);
Vue.use(Uploader);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(CellGroup);
Vue.use(Toast);
Vue.use(Picker);
Vue.use(Row);
Vue.use(Col);
Vue.use(NavBar);
Vue.use(Sticky);
Vue.use(NumberKeyboard);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(ImagePreview);
Vue.use(Switch);
Vue.use(Tag);
Vue.use(Search);
Vue.use(DatetimePicker);
Vue.use(Icon);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Overlay);
Vue.use(List);
Vue.use(Popover);
Vue.use(CountDown);
Vue.use(Dialog);
Vue.use(Step);
Vue.use(Steps);
Vue.use(NoticeBar);
Vue.use(Cascader);
Vue.use(TreeSelect);
Vue.use(Cell);
Vue.use(PullRefresh);
