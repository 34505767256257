import Vue from 'vue'
import VueRouter from 'vue-router'
import myvideo from "@/views/myvideo";
import image from "@/views/image";
import index from "@/views/index"
import app from "@/views/app";
import enter from "@/views/enter/enter";
import beforenter from "@/views/enter/beforenter";
import personal from "@/views/enter/personal.vue";
import individual from "@/views/enter/individual.vue";
import company from "@/views/enter/company.vue";
import xylist from "@/views/enter/xylist.vue";

import work from "@/views/work/work";
import editbrand from "@/views/work/editbrand.vue";
import bond from "@/views/work/bond.vue";
import ship from "@/views/work/ship.vue";
import sendinfo from "@/views/work/sendinfo.vue";
import settled from "@/views/work/settled.vue";
import income from "@/views/work/income.vue";

import goods from "@/views/goods/my";
import add from "@/views/goods/add.vue";
import promotion from "@/views/goods/promotion.vue";
import anti from "@/views/goods/anti.vue";

import order from "@/views/order/order";
import logistics from "@/views/order/logistics";
import aftersales from "@/views/order/aftersales.vue";
import orderdetail from "@/views/order/orderdetail.vue";
import wuliu from "@/views/order/wuliu.vue";

import myorder from "@/views/myorder/myorder";
import apply from "@/views/myorder/apply.vue";
import applys from "@/views/myorder/applys.vue";
import detail from "@/views/myorder/detail.vue";
import shopdetail from "@/views/myorder/shopdetail.vue";
import wuliudetaile from "@/views/myorder/wuliudetaile.vue";

import invoice from "@/views/invoice/invoice";
import invoices from "@/views/invoice/invoices";
import invoicecopy from "@/views/invoice/invoicecopy";
import watch from "@/views/invoice/watch";

import auction from "@/views/auction/auction";
import bidding from "@/views/auction/bidding";
import onerecord from "@/views/auction/onerecord";
import bidmoney from "@/views/auction/bidmoney";
import ensure from "@/views/auction/ensure";
import credit from "@/views/auction/credit";


import xyone from "@/views/enter/xyone.vue";
import xytwo from "@/views/enter/xytwo.vue";
import xythree from "@/views/enter/xythree.vue";
import xyfour from "@/views/enter/xyfour.vue";
import xysix from "@/views/enter/xysix.vue";








Vue.use(VueRouter) 

const routes = [{
    path: '/merchans/watch', name: "watch", component: watch,
},{
    path: '/merchans/invoicecopy', name: "invoicecopy", component: invoicecopy,
},{
    path: '/merchans/auction', name: "auction", component: auction,
},{
    path: '/merchans/bidding', name: "bidding", component: bidding,
},{
    path: '/merchans/credit', name: "credit", component: credit,
},{
    path: '/merchans/onerecord', name: "onerecord", component: onerecord,
},{
    path: '/merchans/bidmoney', name: "bidmoney", component: bidmoney,
},{
    path: '/merchans/ensure', name: "ensure", component: ensure,
},{
    path: '/merchans/myorder', name: "myorder", component: myorder,
},{
    path: '/merchans/apply', name: "apply", component: apply,
},{
    path: '/merchans/applys', name: "applys", component: applys,
},{
    path: '/merchans/detail', name: "detail", component: detail,
},{
    path: '/merchans/shopdetail', name: "shopdetail", component: shopdetail,
},{
    path: '/merchans/wuliudetaile', name: "wuliudetaile", component: wuliudetaile,
},{
    path: '/merchans/order', name: "order", component: order,
},{
    path: '/merchans/logistics', name: "logistics", component: logistics,
},{
    path: '/merchans/orderdetail', name: "orderdetail", component: orderdetail,
},{
    path: '/merchans/wuliu', name: "wuliu", component: wuliu,
},{
    path: '/merchans/xyone', name: "xyone", component: xyone,
},{
    path: '/merchans/xytwo', name: "xytwo", component: xytwo,  
},{
    path: '/merchans/xythree', name: "xythree", component: xythree,
},{
    path: '/merchans/xysix', name: "xysix", component: xysix,
},{
    path: '/merchans/xyfour', name: "xyfour", component: xyfour,
},{
    path: '/merchans/aftersales', name: "aftersales", component: aftersales,
},{
    path: '/merchans/goods', name: "goods", component: goods,
},{
    path: '/merchans/invoice', name: "invoice", component: invoice,
},{
    path: '/merchans/invoicecopy', name: "invoicecopy", component: invoice,
},{
    path: '/merchans/invoices', name: "invoices", component: invoices,
},{
    path: '/merchans/add', name: "add", component: add,
},{
    path: '/merchans/promotion', name: "promotion", component: promotion,
},{
    path: '/merchans/anti', name: "anti", component: anti,
},{
    path: '/merchans/work', name: "work", component: work,
},{ 
    path: '/merchans/settled', name: "settled", component: settled,
},{
    path: '/merchans/income', name: "income", component: income,
},{
    path: '/merchans/sendinfo', name: "sendinfo", component: sendinfo,
},{
    path: '/merchans/editbrand', name: "editbrand", component: editbrand,
}, {
    path: '/merchans/ship', name: "ship", component: ship,
},{
    path: '/merchans/bond', name: "bond", component: bond,
},{
    path: '/merchans/beforenter', name: "beforenter", component: beforenter, 
}, {
    path: '/merchans/enter', name: "enter", component: enter,
},{
    path: '/merchans/personal', name: "personalenter", component: personal,
}, {
    path: '/merchans/xylist', name: "xylist", component: xylist,
},{
    path: '/merchans/individual', name: "individualenter", component: individual,
}, {
    path: '/merchans/company', name: "companyenter", component: company,
}, {
    path: '/app', name: "app", component: app,
}, {
    path: '/', name: "index", component: index,
}, {
    path: '/images', name: 'images', component: image,
}, {
    path: '/myvideo', name: 'myvideo', meta: {
        keepAlive: true // 设置页面缓存
    }, component: myvideo
}, {
    path: "/annotation",  //批注
    name: "annotation", component: () => import("@/views/annotation")
}, {
    path: "/history",  //历史的今天
    name: "history", component: () => import("@/views/history")
}, {
    path: '/ownerShare', //大众人分享
    name: 'ownerShare', component: () => import("@/views/ownerShare")
}, {
    path: "/errorPage", name: "errorPage", component: () => import("@/views/errorPage")
}, {
    path: '/merchants/home', name: 'merchantsHome', component: () => import("@/views/merchants/home")
}]


const router = new VueRouter({
    // mode: 'history',
    mode: 'hash', base: process.env.BASE_URL, routes
})

// router.beforeEach((to, from, next) => {
//     // window.entryUrl = location.href.split('#')[0]
//
// })
// router.afterEach((to, from,failure) =>{
// if (failure){
//     if (failure.code === 400){
//         this.$router.push("errorPage")
//     }
// }
// })

export default router
