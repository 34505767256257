<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" left-arrow title="商家入驻协议" @click-left="back" />
        </van-sticky>
        <div class="container" style="ont-size: 15px;padding: 0 10px;box-sizing: border-box;margin-top: 20px;">
        </div>
        <div style="color: rgba(55,38,27,0.5);position: relative;bottom: 20px;left: 0;font-size: 15px;padding: 10px 20px;box-sizing: border-box;margin-top: 20px;">
            <div>柴门小童商家入驻协议</div>

                <div>版本号：V1.0</div>
                    <div>发布日期：2025年1月1日</div>
                        <div>施行日期：2025年1月1日</div>


                            <div>本协议由缔约双方在自愿、平等、公平及诚实信用原则的基础上，根据《中华人民共和国民法典》、《消费者权益保护法》等相关法律、法规的规定，经友好协商于上海市闵行区缔结。</div>
                                <div>本协议由协议正文、附件及依据本协议公示于柴门小童平台（以下简称“柴门小童平台”）的各项规则所组成，协议附件及规则与本协议具有同等法律效力，如规则与本协议约定不一致的，以公布生效日期或签署日期在后的文件为准执行。</div>
                                    <div>本协议各条的标题仅为方便阅读而设，无意成为本协议的一部分，也不影响本协议的含义或解释。</div>
                                        <div>1 定义及解释</div>
                                            <div>1.1柴门小童：指上海声声曼网络科技有限公司运营的平台。本协议由甲方即“柴门小童”与乙方签署。</div>
                                                <div>1.2“柴门小童网站：指域名为https://www.serenetone.com的网站。如网站域名有调整，以柴门小童另行通知或公告。</div>
                                                    <div>1.3柴门小童平台：指运行于“柴门小童SERENETONE.COM”网站、柴门小童相关的APP、柴门小童相关微信小程序及柴门小童其它线上渠道（移动应用服务平台）的柴门小童平台，是柴门小童平台上为用户提供信息发布、交流，第三方经营者开设店铺经营，以及其他技术服务的电子商务交易服务平台。</div>
                                                        <div>1.4“柴门小童平台用户：指所有在“柴门小童平台”或网站上注册的法人、自然人及其他组织，本协议中统称为“用户”，“卖家”、“客户”或“买家”，除非另有说明，否则“用户”、“卖家”、“客户”或“买家”均指此含义。</div>
                                                            <div>1.5商家注册及入驻：商家注册指欲成为“柴门小童平台”第三方经营者的商家（本文或称“卖家”或“乙方”），依据“柴门小童平台”入驻流程和要求完成在线信息提交，经“柴门小童”审核同意后，商家可以使用设定的柴门小童平台用户名和密码登录“柴门小童平台”，以开立店铺开展经营；商家入驻，亦称“店铺入驻”指第三方经营者完成商家注册，通过资质审核且满足本协议第四条店铺服务开通条件后成为“柴门小童平台”第三方经营者的过程；本协议中的商家指本协议缔约方中的“乙方”。</div>
                                                                <div>1.6柴门小童平台用户名：亦称“店铺用户名”，是指商家完成商家注册后获取的与自设密码共同使用以登录“柴门小童平台”商家管理系统，使用“柴门小童平台”店铺服务的登录账户，每一个柴门小童平台用户名对应一个店铺。商家应妥善保管店铺用户名及密码信息，不得擅自转让或授权他人使用，并对其店铺用户名下的一切行为独立承担责任。</div>
                                                                    <div>1.7店铺：商家在完成商家注册及商家入驻流程后，为进行合法经营，依据协议约定和柴门小童平台规则由乙方申请，“柴门小童”审核通过的具有独立且唯一性ID（英文IDentity的缩写，意为“身份标识号码”,此处指“商家ID”）、特定名称（可依据相关平台规则进行调整）的网络化虚拟商铺。乙方通过柴门小童平台用户名可以实现对该店铺的管理，包括但不仅限于商品信息上传、修改、删除处理，交易跟踪、取消等。</div>
                                                                        <div>1.8柴门小童平台规则：指标示在“柴门小童平台”之上的，与商家经营有关的任何规范性文件，包括但不限于商家手册、商家后台公告、商家帮助中心等。商家知晓并同意，柴门小童有权单方更新、调整柴门小童平台规则，商家进一步知晓并同意，如不同意接受调整后的柴门小童平台规则时，应终止本协议，停止在柴门小童平台的店铺经营。</div>
                                                                            <div>1.9商品：亦称“产品”：指乙方生产、经销或提供的产品或服务，必须符合国家、行业及双方约定标准，并不侵犯任何第三方合法权益，包含产品附件、部件、随机文件资料、赠品、礼品、奖品、试用品和相关服务等。</div>
                                                                                <div>1.10假货：指乙方提供的与官方客观事实明显不符的产品，协议中也可称为“假冒商品”，“假冒伪劣商品”。包括但不限以下产品:</div>
                                                                                    <div>(1)三无产品（包含但不限于无中文厂名、中文厂址、许可证号、生产日期、产品标志、中文产品说明书、联系方式、商品名称）；</div>
                                                                                        <div>(2)冒用他人包装、商标、知识产权等信息的产品；</div>
                                                                                            <div>(3)宣称材质或功能、规格参数等各产品属性与实际不符的产品；</div>
                                                                                                <div>(4)除消费者原因、不能享受品牌正常售后服务的产品。</div>
                                                                                                    <div>1.11水货：指在中国内地没有经过原生产厂家所指定的销售代理而进行销售的产品。</div>
                                                                                                        <div>1.12保证金：指商家根据本服务条款、服务协议及“柴门小童平台”相关规则，向“柴门小童”缴纳的，在商家未履行消费者权益保障义务、违反服务协议或者“柴门小童平台”相关规则或流程时，用于对买家(指通过“柴门小童平台”购买商家商品的甲方其他用户，以下均称“买家”)进行赔付或对“柴门小童”和/或买家支付违约金的资金。</div>
                                                                                                            <div>1.13平台使用费：商家使用“柴门小童平台”各项服务时按照本协议约定缴纳的固定技术服务费用。</div>
                                                                                                                <div>1.14技术服务费：商家根据经营类目在达成每一单交易时按比例向“柴门小童”缴纳的技术服务费用。</div>
                                                                                                                    <div>1.15平台奖励:对用户在柴门小童平台购物、评价、晒单等情况下给予的优惠、折扣。</div>
                                                                                                                        <div>1.16平台奖励系统：柴门小童按照商家设定的规则向用户发放平台奖励的服务系统。</div>
                                                                                                                            <div>1.17优惠权益工具：柴门小童向商家提供的，可用于在商家店铺中享受优惠和折扣的系统工具，包括但不限于柴门小童优惠券及柴门小童后续提供的其他类型的优惠权益。</div>
                                                                                                                                <div>1.18商家关联店铺：是指与商家存在关联关系的店铺，包括但不限于：以关联人士名义创建的店铺；同一经营主体的店铺；不同经营主体但存在同一法人代表、投资关系等关系的店铺；或通过技术手段识别出由同一实际控制人经营的店铺。</div>
                                                                                                                                    <div>1.19关联方，本协议中亦称“关联公司”，对于一个非自然人的实体（包括但不限于企业、法人或其他实体）而言，指其通过股份、股权或任何其他安排直接或间接控制的、被直接或间接控制或与之同受第三方直接或间接控制的实体或人士；对于一个自然人而言，指其关系密切家庭成员（具体包括其配偶、父母及配偶的父母、兄弟姐妹及其配偶、年满18周岁的子女及其配偶、配偶的兄弟姐妹和子女配偶的父母），及该自然人或其关系密切家庭成员直接或间接控制或担任董事、监事、高级管理人员的企业、法人或其他实体。为本定义之目的，“控制”指在该实体中直接或间接拥有超过50%的股权、权益或表决权，或有权委派或指定该实体的多数管理层或多数董事、或通过协议或其他方式对该实体的运营具有实际决定权和控制权（包括通过VIE控制）。</div>
                                                                                                                                        <div>2 服务内容及乙方店铺经营方式</div>
                                                                                                                                            <div>2.1甲方依照本协议约定为乙方开通店铺服务后，乙方可使用“柴门小童平台”发布商品信息、服务信息，与愿意购买乙方商品的甲方其他用户进行在线交流，订立买卖合同，并向通过“柴门小童平台”购买其商品的用户提供售后服务，同时可参与“柴门小童平台”相关活动及使用“柴门小童平台”同意提供的其他有偿服务。</div>
                                                                                                                                                <div>2.2除上述服务内容外，乙方可使用本协议及附件(如有)列明的其他甲方提供的有偿服务，具体服务内容以本协议及附件约定或双方另行签署（含乙方同意相关有偿服务的电子协议等以数据电文形式签署）的文件约定为准。</div>
                                                                                                                                                    <div>2.3乙方店铺经营方式：乙方作为销售者，其店铺内商品，均以乙方自身名义进行商品信息上传、展示、咨询答复、商品销售、发票开具、物流配送服务及售后服务提供等；乙方销售及服务出现争议、纠纷、国家机关机构调查时，由乙方以销售者身份处理。“柴门小童”不参与乙方店铺的经营中，除依据本协议相关约定外，也不直接介入乙方与其他人间的争议和纠纷。</div>
                                                                                                                                                        <div>3 入驻条件及证明文件提交</div>
                                                                                                                                                            <div>3.1入驻条件</div>
                                                                                                                                                                <div>乙方申请成为“柴门小童平台”入驻商家，在“柴门小童平台”开展经营活动，须持续满足以下条件：</div>
                                                                                                                                                                    <div>（1）	乙方已依照中华人民共和国法律注册并领取合法有效的营业执照及其他经营许可，身份信息应为商家自身情况的客观表现，具备签署、履行本协议的合法资格；</div>
                                                                                                                                                                        <div>（2）	乙方申请经营的商品来源合法，资质齐全、有效，不侵犯任何第三方著作权、商标权、专利权等知识产权权利权益、及其他合法权益；</div>
                                                                                                                                                                            <div>（3）	商家提交的任何信息均真实、合法、有效，所使用的视频、图片、文字等不侵犯任何第三方著作权、商标权、专利权等知识产权权利权益、及其他合法权益；</div>
                                                                                                                                                                                <div>（4）	乙方同意本协议及柴门小童平台相关规则。</div>
                                                                                                                                                                                    <div>（5）	柴门小童依据法律法规等要求及经营需要可能设定的其他条件。</div>
                                                                                                                                                                                        <div>3.2证明文件提交</div>
                                                                                                                                                                                            <div>（1）乙方须根据柴门小童平台相关规则及要求向甲方提交证明文件或其他相关证明，包括但不限于行政许可文件、授权委托书、商标注册证、质检报告、报关单、检验检疫证书、产品来源地证明等。在发生客户投诉、行政机关机构调查、诉讼解决等事项时，商家还应向柴门小童提交加盖商家公章的纸质复印件或电子扫描件。</div>
                                                                                                                                                                                                <div>（2）乙方保证向甲方提供的上述证明文件或其他相关证明真实、合法、准确、有效，并保证上述证明文件或其他相关证明发生任何变更或更新时，立即通知甲方并在系统中提交变更或更新后的证明文件，若因乙方提交虚假、过期文件或未及时更新或通知证明文件导致纠纷或被相关国家机关处罚的，由乙方独立承担全部法律责任，如因此造成柴门小童（包括“柴门小童”合作方、代理人或职员）损失的，乙方应予以赔偿。若上述文件变更或更新导致乙方不符合本协议所规定入驻条件的，甲方有权单方全部或部分限制乙方经营，直至终止本协议。</div>
                                                                                                                                                                                                    <div>4 店铺服务开通及停止</div>
                                                                                                                                                                                                        <div>4.1对于乙方拟开展经营的本协议附件中所涉及的特定店铺，甲方在乙方提出开店申请并满足以下条件后7个工作日内为乙方开通服务，甲方将在服务正式开通前一个工作日以邮件方式通知乙方，服务期以柴门小童商家在线入驻系统显示为准。</div>
                                                                                                                                                                                                            <div>（1）	乙方已按照本协议及附件或者其他补充协议（若有）、规则中确定的金额和时间足额缴纳保证金,并足额缴纳特定店铺相应服务期的平台使用费；</div>
                                                                                                                                                                                                                <div>（2）	乙方已按照本协议及柴门小童平台相关规则提交证明文件或其他相关证明并已通过甲方审核；</div>
                                                                                                                                                                                                                    <div>（3）	乙方已注册成为柴门小童平台用户且本协议已签署生效。</div>
                                                                                                                                                                                                                        <div>4.2甲方为乙方开通服务后，乙方可利用柴门小童平台用户名及自设密码登录商家后台，根据柴门小童平台相关规则及流程向本协议附件中涉及的乙方特定店铺上传、发布商品信息，与用户交流达成交易，使用本协议约定的其他有偿服务等。</div>
                                                                                                                                                                                                                            <div>5 乙方店铺服务的停止：</div>
                                                                                                                                                                                                                                <div>5.1乙方需要停止店铺服务的，应至少提前七个工作日向甲方提出申请，经甲方审核同意后由甲方停止该店铺的服务功能；为弥补甲方已投入的人力、物力和技术支持，乙方同意甲方不退还该店铺未到期部分对应的平台使用费；</div>
<div>5.2出现以下任一情形时，甲方有权随时停止乙方该店铺的服务或单方解除本协议而无需承担任何责任：</div>
<div>（1）乙方不满足入驻条件的；</div>
<div>（2）乙方提供虚假资质文件的；</div>
<div>（3）乙方未完成承诺销售额的；</div>
<div>（4）乙方产品价格、规格等信息标示错误，导致行政处罚、争议和纠纷的；</div>
<div>（5）乙方产品质量不合格、标识不合格的，或者产品涉嫌走私、假冒伪劣、旧货、返修品、水货、侵犯第三方知识产权权益、违反法律禁止性规定或违反柴门小童平台规则的；</div>
<div>（6）乙方为假货进行宣传或经柴门小童自查发现商家有售假行为的；</div>
<div>（7）未经甲方事先审核产品类目、品牌，而上传某类目、品牌商品的；</div>
<div>（8）乙方连续30（三十）日或累计45（四十五）日未正常经营店铺的；</div>
<div>（9）乙方对用户进行恶意骚扰或者泄露用户隐私、个人信息的；</div>
<div>（10）应行政机关要求暂停服务的；</div>
<div>（11）其他违反法律法规、本协议约定或柴门小童平台规则的，或者其他甲方认为侵犯柴门小童或消费者权益的。</div>
<div>5.3乙方未经甲方同意，擅自停止店铺经营，或者乙方因为出现4.3.2情形被甲方停止店铺服务的，乙方已缴纳的本协议附件所涉及特定店铺的平台使用费未到期部分，作为乙方向甲方支付的违约金，该金额不足以冲抵乙方应付违约金和赔偿金时，乙方应另行支付差额。</div>
<div>6 双方权利及义务</div>
<div>6.4“柴门小童”根据本协议向乙方提供电子商务交易平台及相应技术支持服务，在现有技术可实现的基础上维护“柴门小童平台”的正常稳定运行，并努力提升和改进技术，对平台功能及服务进行更新、升级，不断提升平台性能和交易效率。该更新升级可能影响乙方店铺的全部或部分店铺功能，给乙方导致不便，乙方对此予以完全理解和接受，柴门小童对该更新升级不承担任何责任。</div>
<div>6.5“柴门小童”对乙方在使用“柴门小童平台”过程中遇到的问题及提出的建议和意见积极予以回复，可依乙方需求对其使用“柴门小童平台”提供合理的指导和培训。</div>
<div>6.6乙方同意“柴门小童”根据乙方营业执照所载明的经营范围及乙方申请经营的经营类目，核实及调整乙方在“柴门小童平台”经营的具体商品的种类、数量和类目范围。</div>
<div>6.7乙方同意“柴门小童”或“柴门小童”单方聘请第三方公司对乙方提供的相关资质文件等资料进行审核，保证向柴门小童提交的身份、地址、联系方式、行政许可等信息及所提交资料的真实性、合法性、准确性、及时性并对其承担相应的法律责任。</div>
<div>7 质量控制：</div>
<div>7.1为最大程度保障消费者权益，满足消费者对商品的质量要求，乙方有义务对其在“柴门小童平台”销售的每款商品按照国家标准，行业标准，企业标准及“柴门小童”发布的各品类商品要求进行质量控制（包括且不仅限于商品法律法规符合性，商品安全性，商品功能材质与描述符合性，商品标识标示，商品外观，商品包装等），并依照国家法律法规提供售后三包服务（包修、包换、包退）。“柴门小童”有权委托第三方质检机构进行不定期商品抽检，或要求乙方对“柴门小童”指定商品提供进货凭证，出厂检验报告、第三方质检机构报告证明文件。</div>
<div>7.2如果乙方所销售商品抽检不合格，所售商品存在旧货翻新货等不符合行业规则的情形，或无法向“柴门小童”提供相关商品及批次质量合格的证明文件，“柴门小童”有权根据所公示的商家规则，规范及标准，并且依据问题的严重程度对乙方进行处罚及提出相应的限期整改要求。乙方必须配合限期整改及根据“柴门小童”的要求进行指定商品的第三方检测，并完全承担因此产生的所有费用。</div>
<div>7.3如果因乙方商品质量问题而导致“柴门小童”产生损失（包括且不仅限于经济或声誉上的损失），“柴门小童”有权要求乙方赔偿因此而产生的所有费用，并保留进一步追究乙方相应责任的权利。</div>
<div>7.4乙方应对店铺负有管理义务，对其店铺中出现的违反国家有关法律、法规规定及柴门小童规则的信息予以立即删除。否则对乙方前述不当行为，“柴门小童”有权追究其违约、侵权责任并/或解除本协议</div>
<div>7.5“柴门小童”有权将经国家生效法律文书或行政文书确定的乙方违法违规事件，或乙方已确认的乙方违反本协议相关约定的事项，或柴门小童依据本协议及平台规则对乙方违反法律、法规的行为实施的警示、暂停或者终止服务等措施，在“柴门小童平台”上予以公示；乙方多次违规或者有严重违约、违规情形的，“柴门小童”有权对其采取暂停店铺运营、扣除保证金直至终止本协议等措施，上述措施不足以补偿柴门小童损失的，“柴门小童”有权继续向乙方追偿。</div>
<div>7.6如乙方的运营情况不能满足“柴门小童平台”要求，经限期整改调整后，仍无法满足平台运营条件的，“柴门小童”有权解除本协议，停止向乙方提供服务。</div>
<div>7.7“柴门小童”有权根据市场情况及“柴门小童平台”入驻商家经营情况组织相应的促销活动，乙方应根据自身经营情况及商品情况积极予以支持。一旦乙方以书面方式（包括但不限于电子邮件、传真等）确认选择参与上述促销活动的，乙方不得中途退出促销活动，或采取商品下架、恶意抬高售价等影响店铺正常运营或不利于消费者权益的方式变相退出促销活动。</div>
<div>7.8“柴门小童”有权要求乙方提供与乙方商品、售后服务等相关的信息，以便于客户直接向“柴门小童平台”客服中心进行咨询时予以回复，对于“柴门小童”无法回答或属乙方掌握的情况，“柴门小童”有权要求乙方在指定的时限内予以回复或给出相应方案，对乙方未及时解决的客户咨询及投诉，“柴门小童”有权对乙方采取相应处理措施。</div>
<div>7.9如因乙方销售的商品、发布的信息或提供的售后服务问题而引发第三人（包括但不限于客户、乙方经营行为的受侵害者等）对“柴门小童”及/或“柴门小童平台”的投诉、诉讼以及引发的国家监管部门调查的，由乙方处理，柴门小童有权将乙方的主体身份资料、注册资料、联系方式、纳税有关的信息及交易信息等资料提供给投诉方或其它相关部门，乙方应承担因此而产生的全部法律责任，如因此而给“柴门小童”及/或“柴门小童平台”造成损失的，“柴门小童”有权要求乙方赔偿“柴门小童”及“柴门小童平台”的全部损失，柴门小童有权从商家的保证金、结算货款中直接扣除。</div>
<div>7.10“柴门小童”依据相关法律法规的要求，制定并实施有关制止假冒或侵权行为的规则及流程，并依据规则流程采取相应措施，保障商家合法权益，维护平台秩序。</div>
<div>7.11因网上交易平台的特殊性，柴门小童没有义务对所有乙方的交易行为以及与交易有关的其它事项进行事先审查，但下列情况除外：</div>
<div>（1）	第三方通知柴门小童，认为某个具体商家或具体交易事项可能存在重大问题；</div>
<div>（2）	商家或第三方向柴门小童告知交易平台上有违法或不当行为的；</div>
<div>（3）	柴门小童发现某个具体商家或具体交易事项可能存在重大问题。</div>
<div>7.12柴门小童以普通非专业人员的知识水平标准对相关内容进行判别，可以认为这些内容或行为具有违法或不当性质的，柴门小童有权根据不同情况选择删除相关信息、对商家店铺采取限制性措施或停止对该商家提供服务。</div>
<div>7.13乙方同意并自愿遵守“柴门小童”根据国家相关法律法规、政策及平台运营情况，对公示于“柴门小童平台”的规则、流程、收费标准等进行更新、调整，并自愿遵守更新、调整后的内容。</div>
<div>7.14乙方同意“柴门小童”根据业务调整情况将本协议项下的权利和义务部分或全部转移给甲方相关关联公司，但须提前5日通知乙方。</div>
<div>7.15乙方应向柴门小童支付的所有应付款项，如未按照规定要求完成费用支付的，则柴门小童有权通知结算机构在线冻结商家的账户，并有权通知其自商家的账户中扣除，如未结算款项不足抵扣的，则柴门小童有权自甲方及其关联公司待向乙方结算的任意一笔款项或乙方交纳的任意一笔保证金中直接扣除。</div>
<div>7.16如“柴门小童”的损失通过上述方式仍无法弥补，则柴门小童有权单方终止向乙方提供的一切服务，且有权继续向乙方追偿。</div>
<div>7.17商家知悉并同意:商家在平台运营的相关系统需与柴门小童电子面单系统或与入驻柴门小童开放物流平台的快递公司系统进行对接，用于处理订单配送等事宜，以有效保护用户个人信息，否则产生的不利后果由商家承担。</div>
<div>7.18如乙方在甲方平台提供的产品引起任何投诉、举报、舆情事件、行政机关的处罚或诉讼等情况，乙方应及时配合甲方处理，提供监管部门、司法机关所需的全部资料信息，如因上述事项给甲方造成损失的，乙方需予以赔偿，并应自担费用采取下列措施，以保障甲方的利益不受损害。</div>
<div>（1）	及时响应：甲方发现危机舆情事件告知乙方后，乙方需在2小时内给予基本反馈，12小时内查明事件实际情况，24小时内与甲方协商解决方案，并按照双方确认的方案采取有效措施；</div>
<div>（2）	积极澄清：由于乙方原因导致的危机舆情事件，如甲方认为需对外界做出说明的，乙方需与甲方协商后在指定时间、渠道内向公众进行书面事实澄清；</div>
<div>（3）	统一口径：如乙方发现涉及所供产品或与甲方相关的危机舆情事件，需第一时间书面告知甲方，并与甲方协商处理。涉及甲方利益的，乙方相关言论、新闻稿件、网站信息及其他资料须经甲方审核通过后方可对外发布。</div>
<div>（4）甲方可根据舆情风险，下架、删除商品链接。</div>
<div>7.19乙方应保证与甲方合作期间的所有经营行为均完全遵守国家法律、法规及协议约定，保证按时、足额发放为乙方提供服务或与以乙方名义履行本协议的管理、支持人员（"乙方人员"）的劳动报酬，依法缴纳社会保险、住房公积金，诚实守信履行协议义务，避免因乙方人员或乙方的关联公司、分包商、合作伙伴、代理商及其人员的争议影响甲方的运营。</div>
<div>7.20乙方应识别并评估潜在的紧急情况，制定紧急预案和响应程序，并就潜在的紧急情况向甲方汇报处理预案，最大程度地降低对甲方造成的损害。紧急情况包括人为暴力破坏或非暴力妨碍行为等一切可能造成甲方利益受损的情况。</div>
<div>8 乙方声明及保证</div>
<div>8.1保证对获得的与自设密码共同使用的柴门小童平台用户名（店铺用户名）妥善保管，不得擅自转让或授权他人使用，并对利用该用户名和密码所进行的一切活动负全部责任。</div>
<div>8.2乙方应保证入驻时提供的联系人、联系地址、联系电话等信息真实、有效、完整，并对此承担法律责任。</div>
<div>8.3保证其有权利订立本协议，其代理人已获得充分授权，并对代理人的行为承担法律责任，同时乙方保证对其雇员、本协议约定的联系人及其他乙方委派的履行本协议的人员的行为承担法律责任。</div>
<div>8.4保证遵守本协议及附件的相关约定和柴门小童平台相关规则及流程，在使用“柴门小童平台”相关服务时严格按照上述约定及规则和流程使用，不从事任何有损甲方利益的行为。</div>
<div>8.5保证对在“柴门小童平台”经营的商品拥有合法销售权，商品质量合格，符合国家相关质量标准，不侵犯任何第三方的合法权益（包括但不限于对第三方知识产权、物权等构成侵害），并对其商品质量及商品合法性独立承担全部法律责任。</div>
<div>8.6保证履行“如实描述”义务，保证在“柴门小童平台”发布的商品信息真实、准确，符合柴门小童平台规则及国家相关法律法规的规定，与实际出售的商品一致，不含任何夸大或虚假内容。保证其提供给柴门小童或上传至柴门小童平台的图片、音频、视频等文件资料中涉及的相关知识产权及人物人身权享有合法权利或已获得合法授权，不侵犯任何第三方的合法权益。乙方对商品信息描述的准确、相符及上述文件资料的合法有效负有举证责任并承担独立的完全的法律责任。若发布的上述信息变更的，乙方应及时在“柴门小童平台”予以更新。若因信息未及时变更引起法律后果的，乙方独立承担全部责任。</div>
<div>8.7保证按照“柴门小童平台”审核通过的商品类目和品牌经营，未经“柴门小童”确认，不得擅自增加、变更商品类目和品牌。保证按照柴门小童平台规则及国家相关规定设置商品价格、售卖限制、库存数量、商品说明及其他商品信息，并对上述设置独立承担全部法律责任。乙方同时保证在经营过程中，按照“柴门小童平台”相关规则使用客服及时通讯工具，积极回复“柴门小童”用户的咨询，并保证在用户提交订单后按要求发货。</div>
<div>8.8保证向购买其商品的用户开具合法合规发票，并保证按照国家相关规定自行承担相应税费。如因乙方发票开具错误、未开具发票或者其他发票问题引起的纠纷，均由乙方独立承担相应的法律责任，如造成“柴门小童”损失的，乙方应予以赔偿。</div>
<div>8.9保证按照国家相关规定、自身售后服务承诺及附件自主售后的相关要求，提供商品“修理、更换、退货”等售后服务，同时保证按照本协议附件的相关规定履行消费者权益保障及相关售后服务义务。</div>
<div>8.10保证在使用“柴门小童平台”进行交易的过程中遵守诚实信用原则，不在交易中采取欺诈及虚假宣传、混淆等不正当竞争行为，不扰乱网上交易的正常秩序，不从事与网上交易无关的行为。</div>
<div>8.11保证不将从“柴门小童平台”获取的任何数据用于本协议约定以外的用途，同时保证未经“柴门小童”许可不得擅自获取、使用、传播“柴门小童平台”的任何资料，包括但不限于交易数据、用户信息、支付信息、“柴门小童”其他用户展示于“柴门小童平台”的信息等。</div>
<div>8.12同意授予“柴门小童”在全球范围内（在法律许可范围内）免费的许可使用权利(并有权对该权利进行再授权)，使“柴门小童”有权(全部或部份地)使用、复制、修订、改写、发布、翻译、分发、执行和展示乙方提供给“柴门小童”或公示于“柴门小童平台”网络店铺的图片、音频、视频等文件资料及各类信息或制作其派生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将上述图片、音频、视频等文件资料及各类信息纳入其它作品内。</div>
<div>8.13乙方不得在任何国家或地区抢先申请或注册甲方及其关联公司的任何知识产权（包括商标、专利、著作权、域名等）信息，如果乙方违反此条款，乙方承诺该违约注册的知识产权归甲方所有，承诺无偿将该知识产权转让给甲方，承诺配合签署转让文件并承担转让费用、办理管理权转移。乙方此等行为给甲方造成损失的（包括但不限于诉讼赔偿、诉讼费用、律师费用等），乙方应予以全部赔偿。</div>
<div>8.14为明确含义，抢注的情形包括但不限于：1）乙方知悉的甲方尚未申请注册的信息；2）甲方已申请区分表某类商品服务上的商标注册，但未在区分表其他商品或服务类别上申请商标注册的信息；3）甲方已申请一种知识产权，但尚未在其他种类知识产权权利上申请的信息；4）甲方已在一国申请，尚未在其他国家或地区申请注册的信息；5）以上信息包括抢注相同的信息，也包括抢注近似的信息。</div>
<div>8.15乙方保证不得对任何甲方系统或平台进行反向工程、反编译或反汇编，不得开发与甲方系统或平台有关的衍生产品、作品、服务、插件、外挂、兼容、互联等。非经甲方或甲方授权开发并正式发布的任何由甲方系统衍生的软件均属非法软件，由此产生的法律责任与纠纷与甲方无关，违反本条款的，甲方有权中止或终止本协议全部或部分内容。</div>
<div>8.16乙方不得在“柴门小童平台”发布或在商品本身及产品包装粘贴、夹带任何吸引“柴门小童”用户到其他平台或乙方自身网络销售平台或渠道进行交易的信息，也不得在配送包裹中夹带此类吸引“柴门小童”用户的信息，由此产生纠纷或造成柴门小童及柴门小童用户损失的，乙方需负责处理并承担全部责任。</div>
<div>8.17乙方不得隐瞒任何可能对“柴门小童平台”产生风险的事项，包括但不限于产品出现设计缺陷、质量瑕疵、权利纠纷、重大违约、上传资料包含病毒木马等，若乙方发生此类影响柴门小童平台商誉、正常经营、安全的事项而乙方未及时通知柴门小童的，“柴门小童”有权解除本协议并追究乙方违约、侵权责任。</div>
<div>8.18未经甲方另行书面许可，乙方不得将本协议项下权利义务全部或部分转移给第三方；乙方聘请第三方代运营公司代表乙方运营店铺的，第三方代运营公司的一切行为均视同为乙方亲自实施，由乙方承担全部法律后果；乙方与第三方代运营公司间的权利义务关系由乙方与第三方代运营公司单独解决，不得影响到“柴门小童”及“柴门小童”其他用户的权利。</div>
<div>8.19乙方（即商家）承诺尽最大努力保证用户/消费者的个人信息安全，并依据国家相应的法律法规及政策规定收集、处理和使用所获得的个人信息。商家同意并保证，商家在使用柴门小童服务过程中所获得或使用的个人信息仅为满足为用户/消费者提供商品/服务所必须，且均已经过该个人信息所有者明确授权，并严格依据向用户/消费者所公示的隐私政策或个人信息处理规则予以处理，不存在过度收集、使用或未经授权的转移个人信息等情况；不得泄露、出售或者非法向他人提供。在发生或者可能发生信息泄露、丢失的情况时，商家应当立即采取补救措施。未经用户/消费者同意或者请求，或者用户/消费者明确表示拒绝的，商家不得向用户/消费者发送任何性质的推荐、推广等商业性信息。商家同意并遵守柴门小童网站实时公示的隐私政策并依据其规定以及柴门小童的要求合法合规的处理其所获得的用户个人信息。商家违反本条约定义务的，柴门小童有权随时终止与商家的合作协议，并要求商家承担柴门小童及其关联公司或柴门小童用户/消费者由此遭受的一切损失；柴门小童并有权依据其它协议约定、法律法规范围内的权利继续追究商家相应责任。</div>
<div>8.20乙方保证乙方及其代表不得做出任何违反中国国家法律、法规及政策的行为，且可能给柴门小童带来重大损害（包括商誉的损害），否则视为乙方严重违约，甲方有权采取商品立即下架、暂停向乙方提供服务、暂时关闭乙方后台管理账户、通知网银在线冻结商家柴门小童钱包账户、终止合作等措施，且甲方无需承担任何责任，乙方因此给甲方造成损失的，乙方应承担赔偿责任。</div>
<div>8.21乙方尤其保证遵守《反恐怖主义法》、《网络安全法》、《广告法》、《互联网信息服务管理办法》、《计算机信息网络国际联网安全保护管理办法》、《枪支管理法》、《民用爆炸物品安全管理条例》、《危险化学品安全管理条例》和《互联网危险物品信息发布管理规定》等法律法规和政策文件的规定，遵守柴门小童平台相应的规则，不会在在柴门小童平台发布上述危险物品信息、交易上述物品。，否则视为乙方严重违约，甲方有权采取商品立即下架、暂停向乙方提供服务、暂时关闭乙方后台管理账户、通知网银在线冻结商家柴门小童钱包账户、终止合作等措施，且甲方无需承担任何责任，乙方因此给甲方造成损失的，乙方应承担赔偿责任。</div>
<div>8.22为本条之目的，“代表”指乙方的关联方、乙方及其关联方的董事、管理人员及雇员、乙方及其关联方聘用的外部顾问或代理。</div>
<div>9 贸易限制</div>
<div>乙方应关注并严格遵守可能影响乙方交易行为的进出口管制、贸易限制与经济制裁相关法律法规，以免对乙方或买家造成损失，并保证遵守适用于本平台相关软件、技术与服务的所有进出口相关法律法规限制。基于维护本平台交易秩序及交易安全的需要，如您违反前述承诺的，柴门小童可在发现上述情形时主动执行下架商品、关闭相关交易订单等操作</div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'enter',
    data() {
        return {
            mar_top:""
        }
    },
    methods: {
        back() {
        this.$router.go(-1)
    },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 

.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
/deep/ .van-step--horizontal .van-step__circle-container {
    background-color: rgba(0, 0, 0, 0);
}
.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-size: 14px;
    
    .box {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        .flex {
            display: flex;
            align-items: center;
        }
    }
    .box1 {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/deep/.van-step__circle  {
    width: 10px;
    height: 10px;
    background: rgba(97, 76, 47, 0.15); 
}
.wuliu_box {
    height: 300px;
    padding: 20px;
    box-sizing: border-box; 
    background-color: #E9D4BC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
}
.btn {
        width: 100%;
        border-radius: 5px;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        margin: 10px 0;
    }
    .paycss {
        width: 100px;
        height: 35px;
        border-radius: 8px;
        line-height: 35px;
        text-align: center;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        
    }
    .input_fild {
        margin-top: 10px;
        padding:10px 15px;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15);
        border-radius: 8px;
        color: #000;
    }
    :deep(.van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
