import Axios from "axios";
 //导入md5
 import md5 from 'js-md5'
 import dayjs from "dayjs"
import config from "@/config/index.js"
import {Toast} from 'vant';

const request = Axios.create({
    baseURL: config.serverUrl,
    timeout: 5000
})
request.interceptors.request.use(config => {
    const key = 'A34992KS945KS040W0087'
    const once = dayjs(new Date()).format("YYYYMMDDHHmmss")
    const sign = md5(key + once)
    config.headers.once = once   
    config.headers.sign = sign
    /* config.headers.token = 'd91cf3b6d55140beb1276896278c6d29' */
    return config
}, (error) => {
    return Promise.reject(error);
})


//请求后置拦截器   校验
request.interceptors.response.use( //用于处理判断请求成功失败
    response => {
        if (response.data.code === 200) {
            return response.data  // 表示放行
        } else {     //错误处理
            // 通过状态码，判断是什么错误
            // Message.error({
            //     message: response.data.msg,
            // })
            // Toast.fail(response.data.msg)
            return Promise.reject(response.data.code)
        }
    }, error => {
        // Message.error({
        //     message: "服务器异常，请稍后重试",
        // })
        Toast.fail("服务器异常，请稍后重试")
        return Promise.reject(error)
    }
)
export default request
