<template>
    <div class="page">
    <van-sticky :style="{'margin-top': mar_top + 'px'}">
        <van-nav-bar style="background-color: #E9D4BC;" title="商家入驻协议" left-arrow @click-left="back" />
    </van-sticky>
    <div class="container">
        <van-cell is-link title="柴门小童第三方商家收费及促销规则"  to='xyone'/>
        <van-cell is-link title="第三方商家商品售后规则"  to='xytwo'/>
        <van-cell is-link title="商家入驻协议"  to='xythree'/>
        <van-cell is-link title="柴门小童交易规则" to='xyfour'/>
        <van-cell is-link title="拍卖行入驻协议"  to='xysix'/>
    </div>
</div>
</template>
<script>
import { findByUser } from '@/api/goods';
export default {
name: 'work',
data() {
    return {
        mar_top:''
    }
},
methods: {
    back() {
        this.$router.go(-1)
    },
    //距离顶部px
    marginFun(e){
        console.log("距离顶部",e);
        this.mar_top = e.data.height
    },
},
async mounted() {
    window.marginFun = this.marginFun
    //距离顶部距离
    var data = {
            action:'StatusBarInfo',
            req:{},
            reqId:13,
            respFunction:'marginFun'
        }
    if(this.$Version() == 'iOS') {
        window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
    } else {
        window.ssmAndroid.ssmMessage(JSON.stringify(data))
    }
}
}
</script>
<style lang="less" scoped>
.van-cell {
display: flex;
align-items: center;
justify-content: center;
padding:20px 20px;
background-color: #E9D4BC;
color: #876235;
}
.van-cell::after {
border-bottom: 0.02667rem solid rgba(174,133,81,0.15);
}
.van-step__line {
display: none;
}
.van-hairline--bottom:after {
border-bottom-width: 0; 
} 
.page {
background-color: #E9D4BC;
&::before {
  content: ' ';
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #E9D4BC;
  background-size: 100% auto;
}
}
.container {
display: flex;
flex-direction: column;
height: 100vh;
padding: 16px;
font-size: 14px;
.flex {
    display: flex;
    align-items: center;
    .info {
        height: 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 5px;
    }
}
.tip {
    font-size: 14px;
    color: rgba(55,38,27,0.5);
    margin-top: 10px;
}
.edit {
    font-size: 12px;
    padding: 8px 10px;
    box-sizing: border-box;
    background-color: #475AD5;
    border-radius: 8px;
    color: #fff;
}
.a {
    font-size: 12px;
    color: red;
    text-decoration: underline;
}
.box {
    width: 100%;
    height: 140px;
    border: 1px solid #887C7C;
    border-radius: 10px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .mon {
        font-size: 28px;
        color: rgba(174,133,81,0.97);
    }
    .or {
        font-size: 16px;
        color: rgba(163, 159, 159, 0.97);
    }
}
}
.box_item {
width: 165px;
height: 121px;
padding: 15px;
box-sizing: border-box;
border-radius: 12px;
background: rgba(174,133,81,0.15);
margin: 20px 0;
}
.van-cell__right-icon {
color: #BCA07F;
font-size: 20px;
font-weight: bold;
}
//顶部导航样式
:deep.van-nav-bar .van-icon {
color: #000;
font-size: 22px;
font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
    color:rgba(55,38,27,0.5);
    font-size: 14px;
}
</style>
