<template>
<!--  用户协议-->
  <div v-html="list.value">
  </div>
</template>

<script>
import {getAgreement} from "@/api/agreement";
export default {
  name: "UserAgreement",
  data(){
    return {
      name:'user_protocol',
      group:"default",
      list:[], //协议内容
    }
  },
  methods:{
    async getUserAgreementList(){
      const res = await getAgreement(this.name,this.group)
      this.list = res.data
      console.log("参数",this.list);
    }
  },
  mounted() {
    this.getUserAgreementList()
  }
}
</script>

<style scoped>

</style>
