<template>
    <div class="box">
        <div class="logo">
            <img src="../assets/app/heng.png" alt="">
        </div>
        <!-- <div class="logo">

            <div class="font">
                <img src="../assets/app/logo.svg" alt="">
                <div>柴门小童</div>
                <div>Serene Tone</div>
            </div>

        </div> -->
        <div class="downLoad">
            <div class="title">
                <div class="big">慢生活尽在柴门小童</div>
                <div class="small">
                    <span>解压</span>
                    <span>陪伴</span>
                    <span>治愈</span>
                    <span>户外健康</span>
                </div>
            </div>
            <div class="btn">下载APP</div>
            <div class="up" @click="jump"></div>
        </div>

        <div class="video">
            <div class="videoContent">视频展位</div>
            <div class="about">
                <div class="name">上海声声曼网络科技有限公司</div>
                <div class="english">Shanghai Serene Time Network Technology Co., Ltd.</div>
                <div class="police">
                    <img
                        src="https://lf1-cdn-tos.bytescm.com/obj/static/ies/bytedance_official/_next/static/images/icp-9ca98112ae9d12370794027a9b22b221.png">
                    <span>沪ICP备2023012470号-1</span>
                </div>
                <div class="email">shengshengman@sonitalk.com</div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        jump() {
            setTimeout(() => {
                const targetElement = this.$el.querySelector(`.video`);
                if (targetElement) {
                    targetElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }, 100);
        }
    },
    mounted() {}
}
</script>
<style lang="less" scoped>
.box {
    .logo {
        position: fixed;
        z-index: 999;
        top: 10px;
        left: -20px;
        transform: scale(0.6);

        img {
            width: 194px;
            height: 64px;
            // width:60px;
            // height: 60px;

        }

        .font {
            color: #0a1674;
            font-size: 12px;
            font-weight: bold;
            width: 100px;
            text-align: center;
        }
    }

    .downLoad {
        position: relative;
        width: 100%;
        height: 100vh;
        // min-height: 737px;
        background: url(../assets/app/bg.png);
        background-position: 0 -2rem;
        background-repeat: no-repeat;
        background-size: cover;

        .title {
            padding-top: 4.15rem;

            .big {
                width: 270px;
                height: 42px;
                font-size: 30px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                color: #191919;
                line-height: 35px;
                margin: 0 auto;
            }

            .small {
                display: flex;
                width: 240px;
                margin: 0 auto;
                justify-content: space-between;

                span {
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #191919;
                }
            }
        }

        .btn {
            width: 140px;
            height: 35px;
            line-height: 0.93333rem;
            background: url(../assets/nav_bg.png) no-repeat;
            background-size: 100% 100%;
            margin: 0 auto;
            margin-top: 2.8rem;
            text-align: center;
            font-size: 14px;
            color: #fff;
        }

        .up {
            width: 24px;
            height: 32px;
            background: url(../assets/app/up.png) no-repeat;
            background-size: cover;
            position: absolute;
            bottom: 0.4rem;
            z-index: 999;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .video {
        width: 100vw;
        height: 100vh;
        background-color: #D9D9D9;
        position: relative;

        .videoContent {
            width: 180px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            font-size: 44px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #000000;
            height: 100px;
        }
    }

    .about {
        width:100%;
        background-color: #3b458f;
        font-size: 12px;
        text-align: center;
        padding: 1rem;
        color: #fff;
        position: absolute;
        bottom: 0;

        .name {
            margin-top: -4px;
        }

        .english {
            line-height: 12px;
        }

        .police {
            margin-top: 2px;

            img {
                width: 20px;
                vertical-align: middle;
                margin-right: 4px;
            }

            span {
                display: inline-block;
                vertical-align: middle;
            }

            .email {
                margin-top: 3px;
            }

        }
    }
}</style>
