<template>
  <div>
    <wx-open-launch-app v-if="isWeixin" :appid="config.appAppId" @error="error" @launch="launch" :extinfo="extinfo">
      <slot name="weixin" />
    </wx-open-launch-app>

    <div v-else>
      <slot name="unWeixin" />
    </div>
  </div>
</template>

<script>
import { tools, wxConfig } from '@/utils'
import config from "@/config"
export default {
  data: () => ({
    config,
    isWeixin: true
  }),
  props: {
    extinfo: {
      // type: String,
      // default: () => ({}),
      required: false
    }
  },
  created() {
    this.isWeixin = tools.getBrowserInfo().isWeixin
    // this.isWeixin = tools.getBrowserInfo()
    // if(this.isWeixin) {
    wxConfig({
      success() {
        // console.log('wxConfig success')
      }
    })
    // }
  },
  methods: {
    isIOS() {
      return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
    },
    redirectToAppStore() {
      const appStoreLink = 'https://itunes.apple.com/cn/app/id6464382860'; // 替换为目标App的App Store链接
      if (this.isIOS()) {
        window.location.href = appStoreLink
      } else {
        this.$router.push('/androidApp')
      }
    },
    error(e) {
      // 未安装软件
      if (e.detail.errMsg === 'launch:fail') {
        this.redirectToAppStore()

      } else if (e.detail.errMsg === 'launch:fail_check fail') {   // appid未绑定未关联到微信

      }
    },
    launch(e) { // 成功唤醒app执行的方法
    }
  },
  mounted() {
  }
}
</script>
<style lang="less" scoped>
.launch-app:active {
  background-color: transparent;
  text-decoration: none;
}
</style>
