<template>
        <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="工作台" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <div v-for="item in shoplist" >
                <div class="flex" style="justify-content: space-between;">
                        <div class="flex">
                            <img :src="item.brandLogo" style="width: 35px;height: 35px;border-radius: 50%;">
                            <div style="margin-left: 5px;">{{item.brand}}</div>
                        </div>
                        
                        <div v-if="item.status == 2" class="edit" @click="editBrand(item.id)">编辑品牌</div>
                        <div v-if="item.status == 1" class="a" style="color: rgb(224, 49, 49);" @click="editBrand(item.id)">资料正在审核中</div>
                        <div v-if="item.status == 3" class="a" @click="editBrand(item.id)" style="color: rgb(224, 49, 49);">资料审核不通过</div>
                </div>
                <div class="tip">{{item.brandDesc}}</div>
            </div>
            <div class="flex" style="justify-content: space-between;">
                <div class="box_item" @click="toshoporder">
                    <div>今日数据</div>
                    <div style="text-align: center;margin-top: 15px;">
                        <div style="color: #475AD5;font-size: 30px;"><span style="font-size: 18px;">¥</span>{{shop.orderAmount}}</div>
                        <div class="tip">{{shop.orderNums}}笔订单</div>
                    </div>
                </div>
                <div class="box_item" @click="togoodsfn">
                    <div>橱窗管理</div>
                    <div style="text-align: center;margin-top: 15px;">
                        <div><span style="color: #475AD5;font-size: 30px;">{{ shop.onlineGoodsNum }}</span>在售</div>
                        <div class="tip" v-if="shop.securityDepositLimit - shop.securityDepositBalance > 0">请先缴纳保证金</div>
                    </div>
                </div>
            </div>
            <van-cell is-link title="设置物流"  @click="toship"/>
            <van-cell is-link title="入驻信息"  @click="tosettled"/>
            <van-cell is-link title="保证金"  @click="tobond"/>
            <van-cell is-link title="发货信息" @click="tosendinfo" />
            <van-cell is-link title="我的收益" @click="toincome" />
            <div v-if="shop.securityDepositLimit - shop.securityDepositBalance > 0" style="font-size: 12px;color: #B10808 ;margin-top: 10px;text-align: center;">*保证金不足，请在30天之内补足保证金，否则店铺将被下线</div>
        </div>
    </div>
</template>
<script>
import { findByUser } from '@/api/goods';
export default {
    name: 'work',
    data() {
        return {
            shoplist:[],
            shop:{},
            msg:'',
            mar_top:''
        }
    },
    methods: {
        //订单数据 跳转商家订单
        toshoporder(){
            this.$router.push({name:'order',query:{brand_id:this.shop.id}})
        },
        //橱窗管理 跳转商品列表
        togoodsfn(){
            this.$router.push({name:'goods',query:{brand_id:this.shop.id}})
        },
        //跳转我的收益
        toincome(){
            this.$router.push({name:'income',query:{brand_id:this.shop.id}})
        },
        //发货信息
        tosendinfo(){
            this.$router.push({ name: 'sendinfo',query:{id:this.shop.id}});
        },
        //入住信息
        tosettled(){
            this.$router.push({ name: 'settled'});
        },
        //保证金
        tobond(){
            this.$router.push({ name: 'bond',query:{id:this.shop.id}});
        },
        editBrand(e) {
            console.log(e);
            this.$router.push({ name: 'editbrand',params:{id:e} });
        },
        toship(){this.$router.push({ name: 'ship',params:{id:this.shoplist[0].id} });},
        bondFun() {
            this.$router.push({ name: 'bond' });
        },
        back() {
            var data = {
                    action:'ClosePage',
                    req:{},
                    reqId:12,
                    respFunction:'closefn'
                }
            if(this.$Version() == 'iOS') {
                console.log("ios关闭当前页面");
                
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                console.log("Android关闭当前页面");
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        closefn(e){
            console.log("返回"),e;
            
        },
        async getEnterInfo() {
            const res = await findByUser({});
            console.log("res",res);
            this.msg = res.msg
            if(res.data) {
                this.shoplist = res.data
                this.shop = res.data[0]
            }

        },
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() {
        window.closefn = this.closefn
        window.marginFun = this.marginFun
        //距离顶部距离  提交时打开
        /* var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            } */
        setTimeout(() => {
            this.getEnterInfo();
        }, 300);
    }
}
</script>
<style lang="less" scoped>
.van-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:20px 20px;
    background-color: #E9D4BC;
    color: #876235;
}
.van-cell::after {
    border-bottom: 0.02667rem solid rgba(174,133,81,0.15);
}
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 16px;
    font-size: 14px;
    .flex {
        display: flex;
        align-items: center;
        .info {
            height: 35px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 5px;
        }
    }
    .tip {
        font-size: 14px;
        color: rgba(55,38,27,0.5);
        margin-top: 10px;
    }
    .edit {
        font-size: 12px;
        padding: 8px 10px;
        box-sizing: border-box;
        background-color: #876235;
        border-radius: 8px;
        color: #fff;
    }
    .a {
        font-size: 12px;
        color: red;
        text-decoration: underline;
    }
    .box {
        width: 100%;
        height: 140px;
        border: 1px solid #887C7C;
        border-radius: 10px;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .mon {
            font-size: 28px;
            color: rgba(174,133,81,0.97);
        }
        .or {
            font-size: 16px;
            color: rgba(163, 159, 159, 0.97);
        }
    }
}
.box_item {
    width: 165px;
    height: 121px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 12px;
    background: rgba(174,133,81,0.15);
    margin: 20px 0;
}
.van-cell__right-icon {
    color: #BCA07F;
    font-size: 20px;
    font-weight: bold;
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
