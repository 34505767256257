export function clip(
  realWidth = 40,
  realHeight = 43,
  rightTopRadius = 10,
  rightBottomRadius = 10,
  leftBottomRadius = 10,
  leftTopRadius = 10,
  src,
  id
) {
  var canvas = document.getElementById(id);
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.src = src;
  img.onload = function () {
    // 获取canvas元素和上下文
    var canvas = document.getElementById(id);
    var ctx = canvas.getContext("2d");

    // 创建一个Image对象并加载图片
    var img = new Image();
    img.src = src;

    // 等待图片加载完成后执行裁剪和缩放操作
    img.onload = function () {
      // 设置canvas的宽高和图片的宽高比一致
      let widthPx = img.width;
      let heightPx = img.height;
      if(img.width!==500){
        widthPx =500
        heightPx =500
      }
      if(img.height!==500){
        widthPx =500
        heightPx =500
      }
      canvas.width = widthPx;
      canvas.height = heightPx;

      var path = new Path2D();
      path.moveTo(leftTopRadius, 0);
      path.lineTo(realWidth - rightTopRadius, 0);
      path.quadraticCurveTo(
        realWidth - rightTopRadius / 2,
        0,
        realWidth - rightTopRadius / 2,
        rightTopRadius / 2
      );
      path.quadraticCurveTo(
        realWidth,
        rightTopRadius / 2,
        realWidth,
        rightTopRadius
      );
      path.lineTo(realWidth, realHeight - rightTopRadius);
      path.quadraticCurveTo(
        realWidth,
        realHeight - rightTopRadius / 2,
        realWidth - rightBottomRadius / 2,
        realHeight - rightTopRadius / 2
      );
      path.quadraticCurveTo(
        realWidth - rightBottomRadius / 2,
        realHeight,
        realWidth - rightBottomRadius,
        realHeight
      );
      path.lineTo(leftBottomRadius, realHeight);
      path.quadraticCurveTo(
        leftBottomRadius / 2,
        realHeight,
        leftBottomRadius / 2,
        realHeight - leftBottomRadius / 2
      );
      path.quadraticCurveTo(
        0,
        realHeight - leftBottomRadius / 2,
        0,
        realHeight - leftBottomRadius
      );
      path.lineTo(0, leftTopRadius);
      path.quadraticCurveTo(
        0,
        leftBottomRadius / 2,
        leftBottomRadius / 2,
        leftBottomRadius / 2
      );
      path.quadraticCurveTo(leftBottomRadius / 2, 0, leftBottomRadius, 0);
      path.closePath();
      // 裁剪路径
      ctx.clip(path);

      // 等比缩放图片
      var scale = Math.min(
        canvas.width / widthPx,
        canvas.height / heightPx
      );
      var scaledWidth = widthPx * scale;
      var scaledHeight = heightPx* scale;
      var x = (canvas.width - scaledWidth) / 2;
      var y = (canvas.height - scaledHeight) / 2;
      ctx.drawImage(img, x, y, scaledWidth, scaledHeight);
    };

    // 创建离屏canvas
    // var offscreenCanvas = document.createElement("canvas");
    // var offscreenCtx = offscreenCanvas.getContext("2d");
    // canvas.width = 28
    // canvas.height = 28
    // console.log(img.width)
    // console.log( img.height)
    // offscreenCanvas.width = img.width;
    // offscreenCanvas.height = img.height;
    // // 在离屏canvas上绘制图片
    // offscreenCtx.drawImage(img, 0, 0, 28, 28);
    // // 创建裁剪路径
    // var path = new Path2D();
    // path.moveTo(leftTopRadius, 0);
    // path.lineTo(realWidth - rightTopRadius, 0);
    // path.quadraticCurveTo(realWidth - rightTopRadius / 2,
    //   0,
    //   realWidth - rightTopRadius / 2,
    //   rightTopRadius / 2);
    // path.quadraticCurveTo(realWidth, rightTopRadius / 2, realWidth, rightTopRadius);
    // path.lineTo(realWidth, realHeight - rightTopRadius);
    // path.quadraticCurveTo(realWidth,
    //   realHeight - rightTopRadius / 2,
    //   realWidth - rightBottomRadius / 2,
    //   realHeight - rightTopRadius / 2);
    // path.quadraticCurveTo(realWidth - rightBottomRadius / 2,
    //   realHeight,
    //   realWidth - rightBottomRadius,
    //   realHeight);
    // path.lineTo(leftBottomRadius, realHeight);
    // path.quadraticCurveTo(leftBottomRadius / 2,
    //   realHeight,
    //   leftBottomRadius / 2,
    //   realHeight - leftBottomRadius / 2);
    // path.quadraticCurveTo(0, realHeight - leftBottomRadius / 2, 0, realHeight - leftBottomRadius);
    // path.lineTo(0, leftTopRadius);
    // path.quadraticCurveTo(0, leftBottomRadius / 2, leftBottomRadius / 2, leftBottomRadius / 2);
    // path.quadraticCurveTo(leftBottomRadius / 2, 0, leftBottomRadius, 0);
    // path.closePath();
    // // 裁剪路径
    // ctx.clip(path);

    // // 在主canvas上绘制裁剪后的图片
    // ctx.drawImage(offscreenCanvas, 0, 0);
  };
}
