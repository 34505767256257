<template>
    <div class="page">
        <!-- <van-sticky >
            <van-nav-bar style="background-color: #E9D4BC;" title="填写发票信息" left-arrow @click-left="back" />
        </van-sticky> -->
        <div class="container">
            <van-form @submit="onSubmit">
                <div class="flex">
                    <div>发票类型</div>
                    <van-field name="radio">
                        <template #input>
                            <van-radio-group @change="radiochange" v-model="radio" direction="horizontal" style="margin-left: 20px;">
                                <van-radio name="0" checked-color="#475AD5" icon-size="18px">电子普通发票</van-radio>
                                <van-radio name="1" checked-color="#475AD5" icon-size="18px">专用发票</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                </div>
                <template v-if="radio == 0">
                <div class="flex" style="margin: 20px 0;">
                    <div>发票内容</div>
                    <van-field name="radio1">
                        <template #input>
                            <van-radio-group @change="radiochange" v-model="radio1" direction="horizontal" style="margin-left: 20px;">
                                <van-radio name="0" checked-color="#475AD5" icon-size="18px">商品明细</van-radio>
                                <van-radio name="1" checked-color="#475AD5" icon-size="18px">商品类别</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                </div>
                <div class="flex">
                    <div>抬头类型</div>
                    <van-field name="radio2">
                        <template #input>
                            <van-radio-group @change="radiochange" v-model="radio2" direction="horizontal" style="margin-left: 20px;">
                                <van-radio name="0" checked-color="#475AD5" icon-size="18px">个人</van-radio>
                                <van-radio name="1" checked-color="#475AD5" icon-size="18px">单位</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                </div>
                </template>
                <div style="margin-top: 20px;">抬头名称 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                <van-field key="1" class="input_fild" name="titlename" autocomplete="off" v-model="titlename" placeholder="填写需要开具发票的姓名"
                    :rules="[{ required: true, message: '请填写需要开具发票的姓名' }]" maxlength="20"/> 
                <template v-if="radio == 0 && radio2 == 0">
                    <div style="margin-top: 20px;">收票邮箱<span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field key="2" autocomplete="off" class="input_fild" name="email" v-model="email" maxlength="30" placeholder="填写收票邮箱"
                    :rules="[{ required: true, message: '请填写收票邮箱' }]"/> 
                </template>
               <template v-if="radio2 == 1 || radio == 1">
                    <div style="margin-top: 20px;">税号<span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field key="3" autocomplete="off" class="input_fild" name="shuinum" v-model="shuinum" placeholder="税号人识别号"
                        :rules="[{ required: true, message: '请填写税号人识别号' }]" maxlength="20"/>
               </template> 
               <template v-if="radio2 == 1 || radio == 1">
                <div style="margin-top: 20px;">注册地址</div>
                <van-field key="4" autocomplete="off" class="input_fild" name="address" v-model="address" placeholder="注册地址" maxlength="20"/> 
                <div style="margin-top: 20px;">注册电话</div>
                <van-field key="5" autocomplete="off" class="input_fild" name="tel" type="tel" v-model="tel" placeholder="注册电话" maxlength="20"/> 
                <div style="margin-top: 20px;">开户银行</div>
                <van-field key="6" autocomplete="off" class="input_fild" name="bank" v-model="bank" placeholder="开户银行" maxlength="20"/> 
                <div style="margin-top: 20px;">银行账号</div>
                <van-field key="7" autocomplete="off" class="input_fild" name="banknum" v-model="banknum" placeholder="银行账号" maxlength="20"/> 
               </template>
                <div style="width: 100%;height: 100px;"></div>
                <van-button class="submit"  block type="info" native-type="submit">申请开票</van-button>
            </van-form>
        </div> 
    </div>
</template>
<script>
import { Toast } from 'vant';
import { addInvoice } from "@/api/order";
export default {
    name: 'invoice',
    data() {
        return {
            info:{},
            radio:'0',
            radio1:'0',
            radio2:'0',
            titlename:'',
            email:'',
            shuinum:'',
            address:'',
            tel:'',
            bank:'',
            banknum:'',
            targetId:''
        }
    },
    created() {
	 document.title = "申请开票";
},
    methods: {
        radiochange(){
            this.titlename = ''
            this.email = ''
            this.shuinum = ''
            this.address = ''
            this.tel = ''
            this.bank = ''
            this.banknum = ''
        },
        async onSubmit(values) {
            console.log('submit', values);
            const res = await addInvoice({
                targetId:this.targetId,
                targetType:1,
                invoiceType:Number(this.radio),
                invoiceContent:this.radio == 0 ? Number(this.radio1) : '',
                invoiceTitleType:this.radio == 0 ? Number(this.radio2) : '',
                invoiceTitleName:this.titlename,
                email:this.email,
                taxNo:this.shuinum,
                address:this.address,
                phone:this.tel,
                bankName:this.bank,
                bankAccount:this.banknum
            })
            console.log("申请开票",res);
            if(res.code == 200) {
                var data = {
                    action:'ClosePage',
                    req:{},
                    reqId:12,
                    respFunction:'closefn'
                }
            if(this.$Version() == 'iOS') {
                console.log("ios关闭当前页面");
                
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                console.log("Android关闭当前页面");
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
            }
            
        },
        closefn(e){
            console.log("返回"),e;
            
        },
        back() {
            this.$router.go(-1);
        },
    },
    
    async mounted() {
        window.closefn = this.closefn
      console.log("this.$route.query",this.$route.query);
      if(this.$route.query.targetId) {
        this.targetId = this.$route.query.targetId
      }
      
    }
}
</script>
<style lang="less" scoped>
:deep(.van-field__control::-webkit-input-placeholder) {
    color:rgba(55,38,27,0.5);
    font-size: 15px;
}
.van-cell {
  background: rgba(0, 0, 0, 0);
  max-width: 260px;
  padding: 0;
}
.input_fild {
    margin-top: 10px;
    padding:10px 15px;
    box-sizing: border-box;
    background: rgba(174,133,81,0.15);
    border-radius: 8px;
    color: #000;
}
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 15px;
    .flex{
        display: flex;
        align-items: center;
    }
    .submit {
        position: fixed;
        left:50%;
        bottom: 50px;
        transform: translate(-50%,0);
        width: 90%;
        height: 48px;
        line-height: 48px;
        text-align: center;
        border-radius: 12px;
        background-color: #876235 ;
        color: #fff;
        margin-top: 50px;
        font-size: 18px;
    }
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
    .van-cell::after {
        border-bottom: 1px solid #E9D4BC !important;
    }
</style>
