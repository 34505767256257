
<template>
  <div class="app">
    <div v-html="list.value"></div>
  </div>

</template>

<script>
import {getAgreement} from "@/api/agreement";
export default {
  name: "realNameAgreement",
  data(){
    return {
      name:'real_name_auth_protocol',
      group:"default",
      list:[], //协议内容
    }
  },
  methods:{
    async getAgreementList(){
      const res = await getAgreement(this.name,this.group)
      this.list = res.data
      console.log("参数",this.list);
    }

  },
  mounted() {
    this.getAgreementList()

  }
}
</script>

<style scoped>
.app {
  padding: 16px 14px;
}

</style>
