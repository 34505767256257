<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="我的拍卖" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <div class="flex" style="justify-content: space-between;color: rgba(55,38,27,0.5);font-size: 18px;margin-bottom: 10px;">
                <div style="text-decoration: underline;" @click="toensure">我的保证金</div>
                <div style="text-decoration: underline;" @click="tobidmon">我的出价金</div>
                <div style="text-decoration: underline;" @click="tocredit">我的信用值</div>
            </div>
            <!-- 顶部导航 -->
            <van-tabs @click="navigationFun" v-model="active" color="#000000" background="#E9D4BC" line-width="20px"  title-inactive-color="rgba(55,38,27,0.66)">
                <van-tab v-for="item in top_navigation" :title="item.val">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="暂无内容"
                        @load="onLoad"
                        :immediate-check="false"
                    >
                    <div class="box" v-for="(orderItem,index) in list">
                        <div class="flex" style="justify-content: space-between;">
                            <div class="flex"></div>
                           <div style="color: #876235;" v-if="orderItem.status == 3 && orderItem.orderStatus == 0">待支付</div>
                           <div style="color: rgba(55,38,27,0.33);" v-if="orderItem.status == 3 && orderItem.orderStatus == 1">已取消</div>
                       </div>
                       <div style="height: 1px;width: 100%;background-color:#DFC09C;margin: 10px 0;"></div>
                       <div class="flex" style="flex-direction: column;">
                           <div class="flex" style="justify-content: space-between;">
                                <div class="flex">
                                <img :src="orderItem.coverImage" class="imgs">
                                <div class="goodsn">
                                    <div style="font-size: 15px;" class="t">{{orderItem.goodsName}}</div>
                                    <div style="font-size: 14px;color: rgba(55,38,27,0.33);display: flex;margin-right: 10px;">
                                        <div  v-for="guige in orderItem.specificationItemNameList">{{guige}}</div>
                                    </div>
                                </div>
                                </div>
                                <div style="height: 60px;display: flex;flex-direction: column;justify-content: space-around;">
                                    <div style="font-size: 18px;color: #876235;">¥{{ orderItem.amount }}</div>
                                </div>
                           </div>
                           <div style="height: 1px;width: 100%;background-color:#DFC09C;margin: 10px 0;"></div>
                        </div>
                        <div class="flex" style="justify-content: space-between;">
                             <div style="font-size: 12px;color: #B10808;" >
                                <!-- <template v-if="orderItem.status == 0">
                                    <div class="flex">
                                        <span style="margin-right: 10px;">剩余支付时间</span>
                                        <van-count-down @finish="finishfun" style="color: #B10808;" :time="orderItem.residuePayMilliSecond" />
                                    </div>
                                </template> -->
                            </div> 
                            <div class="flex" style="justify-content: end;">
                                <div v-if="orderItem.status == 0 || orderItem.status == 1" class="same recive" @click.stop="mybid(orderItem)">我的出价</div>
                                <!-- <div class="same recive" @click.stop="payorder(orderItem)">支付</div> -->
                                <div v-if="orderItem.status == 3 && orderItem.orderStatus == 0" class="same recive" @click.stop="payorder(orderItem)">支付</div>
                                <!-- <div v-if="orderItem.status == 3" class="same recive" @click.stop="mybid(orderItem)">查看档案</div> -->
                            </div>
                        </div>
                    </div>
                    </van-list>
                </van-tab>
            </van-tabs>
        </div> 
         <!-- 拍卖商品支付 -->
         <van-popup round v-model="showmode" position="bottom" >
                <div class="wuliu_box" style="height: 400px;">
                    <div style="font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;">支付</div>
                    <div>
                        <div style="margin-top: 20px;">支付方式</div> 
                        <van-radio-group v-model="paytype" direction="horizontal" style="margin-top: 20px;">
                            <van-radio name="0">微信</van-radio>
                            <van-radio name="1">支付宝</van-radio>
                        </van-radio-group>
                    </div>
                    <div>
                        <div style="margin-top: 20px;">支付金额</div> 
                        <van-field readonly class="input_fild" type="number" v-model="amount"  autocomplete="off"  maxlength="10"/> 
                    </div>
                    <div class="wuliu_btn" @click="payfn">支付</div>
                </div>
            </van-popup>
    </div>
</template>
<script>
import { Toast ,Dialog} from 'vant';
import {queryAuctionListByType,submitAuctionOrder} from '@/api/auction';
import { getRePayOrderInfo} from '@/api/myorder';
export default {
    name: 'auction',
    data() {
        return {
            mar_top:'',
            loading: false,
            finished: false,
            page: 1,
            pageSize: 10,
           top_navigation:[
                {id:3,val:'全部'},
                {id:0,val:'竞拍中'},
                {id:1,val:'已拍下'},
                {id:2,val:'未拍下'}
           ],
           list: [],
           active: 0,
           showmode:false,
           paytype:'0',
           amount:'',
           bidRecordId:''
        }
    },
    methods: {
        onLoad() {       
            this.page++
            setTimeout(() => {
                this.getAuctionList()
            }, 1000);
        },
        async navigationFun(e,w){
            console.log("顶部导航标签点击",e,w);
            this.finished = false
            this.active = e
            this.page = 1
            this.list = []
            await this.getAuctionList()
        },
         //竞拍商品拍中确定支付
         async payfn(){
                const res = await submitAuctionOrder({
                    bidRecordId:this.bidRecordId,
                    payWay:this.paytype
                })
                console.log("拍卖第一次支付",res);
                
                var paydata = {}
                if(this.paytype == '0') { //微信支付
                    var a = {
                        payChannel:'wechat',
                        timeStamp:JSON.parse(res.data.orderStr).timeStamp,
                        sign:JSON.parse(res.data.orderStr).sign,
                        nonceStr:JSON.parse(res.data.orderStr).nonceStr,
                        appId:JSON.parse(res.data.orderStr).appId,
                        packageValue:JSON.parse(res.data.orderStr).packageValue,
                        prepayId:JSON.parse(res.data.orderStr).prepayId,
                        partnerId:JSON.parse(res.data.orderStr).partnerId,
                    }
                    paydata = a
                } else { //支付宝支付
                    var a = {
                        payChannel:'alipay',
                        orderInfo:res.data.orderStr,
                    }
                    paydata = a
                }
                if(res.code == 200) {
                    this.showmode = false
                    var data = {
                    action:'Play',
                    req:paydata,
                    reqId:4,
                    respFunction:'bridgepayfn'
                }
                    if(this.$Version() == 'iOS') {
                        window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
                    } else {
                        window.ssmAndroid.ssmMessage(JSON.stringify(data))
                    }
                this.showmode = false
            }
        },
        //支付按钮  
        async payorder(e){
            console.log(e);
            if(e.bizOrderNo == null) {
                console.log("第一次支付弹出框选择支付方式");
                this.showmode = true
                this.bidRecordId = e.id
            } else {
                console.log("取消支付再支付");
                console.log(e);
                const res = await getRePayOrderInfo({
                    orderNo:e.recordOrderNo,
                    type:1
                })
                console.log("重新支付",res);
                var paydata = {}
                if(res.data.payWay == 0) { //微信支付
                    var a = {
                            payChannel:'wechat',
                            timeStamp:JSON.parse(res.data.orderStr).timeStamp,
                            sign:JSON.parse(res.data.orderStr).sign,
                            nonceStr:JSON.parse(res.data.orderStr).nonceStr,
                            appId:JSON.parse(res.data.orderStr).appId,
                            packageValue:JSON.parse(res.data.orderStr).packageValue,
                            prepayId:JSON.parse(res.data.orderStr).prepayId,
                            partnerId:JSON.parse(res.data.orderStr).partnerId,
                        }
                        paydata = a
                } else { //支付宝支付
                    var a = {
                            payChannel:'alipay',
                            orderInfo:res.data.orderStr,
                        }
                        paydata = a
                }
                var data = {
                    action:'Play',
                    req:paydata,
                    reqId:4,
                    respFunction:'bridgepayfn'
                }
                if(this.$Version() == 'iOS') {
                    window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
                } else {
                    window.ssmAndroid.ssmMessage(JSON.stringify(data))
                }
            }
        },
        //支付bridge回调
        async bridgepayfn(e){
            console.log("支付   ",e);
            /* if(e.code == 200) { */
                /* Toast('操作成功') */
                this.page = 1
                this.list = []
                this.finished = false
                await this.getAuctionList();
            /* } */
        },
        //我的出价
        mybid(e){
            console.log("我的出价",e);
            this.$router.push({name:'onerecord',params:{goodsid:e.goodsId}})
        },
        // 跳转物流详情
       /*  towuliudetail(e){
            console.log("详情",e);
            if(e.logisticsCompany == null || e.logisticsNumber == null) {
                Toast('暂无物流信息')
            } else {
                this.$router.push({ name: 'wuliudetaile' ,params:{info:e}});
            }
        }, */
        // 倒计时结束
       /*  async finishfun(){
            console.log("倒计时结束");
            this.finished = false
            this.page = 1
            this.list = []
            await this.getOrderList()
        }, */

        back(){
            var data = {
                        action:'ClosePage',
                        req:{},
                        reqId:12,
                        respFunction:'closefn'
                    }
                if(this.$Version() == 'iOS') {
                    console.log("ios关闭当前页面");
                    
                    window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
                } else {
                    console.log("Android关闭当前页面");
                    window.ssmAndroid.ssmMessage(JSON.stringify(data))
                }
       },
       closefn(e){
            console.log("返回"),e;
        },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
        //跳转我的出价金
        tobidmon(){
            this.$router.push({name:'bidmoney'})
        },
        //跳转我的保证金
        toensure(){
            this.$router.push({name:'ensure'})
        },
        //跳转我的信用值
        tocredit(){
            this.$router.push({name:'credit'})
        },
        //查询竞拍列表
        async getAuctionList(){
            var data = {
                pageNum: this.page,
                pageSize: this.pageSize
            }
            var data1 = {
                pageNum: this.page,
                pageSize: this.pageSize,
                type : this.active -1
            }
            const res = await queryAuctionListByType(this.active == 0 ? data : data1);
            console.log("查询竞拍列表",res);
            if(res.data) {
                this.list = [...this.list , ...res.data.records] 
                this.loading = false
                if (res.data.records.length === 0 || this.list.length == res.data.total) {
                    this.finished = true
                }
            }
        },
    },
    
    async mounted() {
        window.bridgepayfn = this.bridgepayfn
        window.closefn = this.closefn
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        // console.log("this.$route.query",this.$route.query);
        
        this.page = 1
        this.list = []
        this.finished = false
        setTimeout(() => {
            this.getAuctionList();
        }, 300);
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .box {
        padding: 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 7px 0;
    }
    .flex {
        display: flex;
        align-items: center;
        .img {
           width: 24px;
           height: 24px;
           border-radius: 12px; 
        }
        .imgs {
            width: 60px;
            height: 60px;
            border-radius: 8px; 
        }
        .goodsn {
            width: 190px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .t {
            // width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .same {
            padding: 5px 15px;
            box-sizing: border-box;
            border-radius: 8px;
        }
        .sub {
            background: #876235;
            color: #fff;
        }
        .recive {
            background-color: #876235;
            color: #fff;
        }
    }
}
.wuliu_box {
    height: 300px;
    padding: 20px;
    box-sizing: border-box; 
    background-color: #E9D4BC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
}
.wuliu_btn {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    font-size: 18px;
    background-color: #876235 ;
    color: #fff;
    text-align: center;
    line-height: 48px;
    margin: 20px auto;
}
.input_fild {
   margin-top: 10px;
   padding:10px 15px;
   box-sizing: border-box;
   background: rgba(174,133,81,0.15);
   border-radius: 8px;
   color: #000;
}
     //顶部导航样式
     :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
