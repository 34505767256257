<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="订单" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container" style="padding-top: 0;">
            <!-- 顶部导航 -->
            <van-tabs :ellipsis="false" @click="navigationFun" v-model="active" color="#000000" background="#E9D4BC" line-width="20px"  title-inactive-color="rgba(55,38,27,0.66)">
                <van-tab v-for="item in top_navigation" :title="item.val">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="暂无内容"
                        @load="onLoad"
                        :immediate-check="false"
                    >
                    <div class="box" v-for="(orderItem,index) in list" @click="cancelback(orderItem)">
                    <!-- <div class="box" v-for="(orderItem,index) in list"> -->
                        <div class="flex" style="justify-content: space-between;">
                            <div class="flex">
                                <van-image
                                    round
                                    width="30"
                                    height="30"
                                    :src="orderItem.merchantsBrandInfoResponse.brandLogo"
                                    style="margin-right: 5px;"
                                    />
                                <div>{{orderItem.merchantsBrandInfoResponse.brand}}</div>
                            </div>
                           <div style="color: #475AD5;" v-if="orderItem.status == 0">待付款</div>
                           <div style="color: rgba(55,38,27,0.33);" v-if="orderItem.status == 1">交易关闭</div>
                           <div style="color: #475AD5;" v-if="orderItem.status == 2">待发货</div>
                           <div style="color: #475AD5;" v-if="orderItem.status == 3">支付失败</div>
                           <div style="color: #475AD5;" v-if="orderItem.status == 4">退款</div>
                           <div style="color: #475AD5;" v-if="orderItem.status == 5">退款成功</div>
                           <div style="color: #475AD5;" v-if="orderItem.status == 6">退款失败</div>
                           <div style="color: #475AD5;" v-if="orderItem.status == 8">待收货</div>
                           <div style="color: #475AD5;" v-if="orderItem.status == 10">交易成功 </div>
                           <div style="color: #475AD5;" v-if="orderItem.status == 11">已申请开票</div>
                           <div style="color: #475AD5;" v-if="orderItem.status == 12">开票成功</div>
                       </div>
                       <div style="height: 1px;width: 100%;background-color:#DFC09C;margin: 10px 0;"></div>
                       <div v-for="items in orderItem.orderItemList" class="flex" style="flex-direction: column;">
                           <div class="flex" style="justify-content: space-between;">
                                <div class="flex">
                                <img :src="items.coverImage" class="imgs">
                                <div class="goodsn">
                                    <div style="font-size: 15px;" class="t">{{items.goodsName}}</div>
                                    <div style="font-size: 14px;color: rgba(55,38,27,0.33);display: flex;margin-right: 10px;">
                                        <div  v-for="guige in items.specificationItemNameList">{{guige}}</div>
                                    </div>
                                </div>
                                </div>
                                <div style="height: 60px;display: flex;flex-direction: column;justify-content: space-around;">
                                    <div style="font-size: 18px;color: #475AD5;">¥{{ items.amount }}</div>
                                    <div style="font-size: 14px;color: rgba(55,38,27,0.33);" >x{{items.num}}</div>
                                </div>
                           </div>
                           <div style="height: 1px;width: 100%;background-color:#DFC09C;margin: 10px 0;"></div>
                        </div>
                        <div style="font-size: 12px;color: #B10808;" >
                                <template v-if="orderItem.status == 0">
                                    <div class="flex" style="margin-bottom: 10px;">
                                        <span style="margin-right: 10px;">剩余支付时间</span>
                                        <van-count-down @finish="finishfun" style="color: #B10808;" :time="orderItem.residuePayMilliSecond" />
                                    </div>
                                </template>
                            </div>
                        <div class="flex" style="justify-content: space-between;">
                             <div style="font-size: 12px;color: #B10808;" >
                              <!--   <template v-if="orderItem.status == 4">
                                    <div class="flex">
                                        <span style="margin-right: 10px;">剩余支付时间</span>
                                        <van-count-down @finish="finishfun" style="color: #B10808;" :time="orderItem.residuePayMilliSecond" />
                                    </div>
                                </template> -->
                            </div>  
                            <div v-if="orderItem.orderItemList.length >=2" style="color: rgba(55,38,27,0.33);">需付款<span style="color: #475AD5;font-size: 20px;margin-left: 10px;">¥</span><span style="font-size: 30px;color: #475AD5;">{{orderItem.amount}}</span></div>
                            <div v-if="orderItem.orderItemList.length == 1" class="flex" style="justify-content: end;">
                                <div v-if="orderItem.status == 0" class="same cancel" style="margin-right: 10px;" @click.stop="cancelorderfun(orderItem.id)">取消</div>
                                <div class="same recive" style="margin-right: 10px;" @click.stop="shareFun(orderItem)">分享</div>
                                <div v-if="orderItem.status == 0" class="same recive" @click.stop="payorder(orderItem)">支付</div>
                                <div v-if="orderItem.status == 1" class="same cancel"  @click.stop="deleteorders(orderItem)">删除</div>
                                <div v-if="orderItem.status == 2" class="same recive"  @click.stop="applyFun(orderItem)">申请退款</div> <!-- 2 -->
                                <div v-if="orderItem.status == 4" class="same recive" style="margin: 0 5px;" @click.stop="towuliudetail(orderItem)">查看物流</div>
                                <div v-if="orderItem.status == 4" class="same recive" @click.stop="cancelfun(orderItem.id)">撤销退款</div>
                                <div v-if="orderItem.status == 8" class="same recive" style="margin-right: 10px;" @click.stop="towuliudetail(orderItem)">查看物流</div>
                                <div v-if="orderItem.status == 8" class="same recive" style="margin-right: 10px;" @click.stop="applyFun(orderItem)">申请退款</div>
                                <div v-if="orderItem.status == 8" class="same recive" @click.stop="recivefun(orderItem.id)">确认收货</div>
                                <div v-if="orderItem.status == 10" class="same recive" style="margin-right: 10px;" @click.stop="towuliudetail(orderItem)">查看物流</div>
                                <div v-if="orderItem.status == 10" @click.stop="openinvoice(orderItem)" class="same recive">申请发票</div>
                                <div v-if="orderItem.status == 12" @click.stop="previewImage(orderItem.invoice.invoiceUrl)" class="same recive">查看发票</div>
                            </div>
                        </div>
                        <div v-if="orderItem.orderItemList.length >= 2" class="flex" style="justify-content: end;margin-top: 10px;">
                                <div v-if="orderItem.status == 0" class="same cancel" style="margin-right: 10px;" @click.stop="cancelorderfun(orderItem.id)">取消</div>
                                <div class="same recive" style="margin-right: 10px;" @click.stop="shareFun(orderItem)">分享</div>
                                <div v-if="orderItem.status == 0" class="same recive" @click.stop="payorder(orderItem)">支付</div>
                                <div v-if="orderItem.status == 1" class="same cancel"  @click.stop="deleteorders(orderItem)">删除</div>
                                <div v-if="orderItem.status == 12" class="same recive"  @click.stop="applyFun(orderItem)">申请退款</div>
                                <div v-if="orderItem.status == 4" class="same recive" style="margin: 0 5px;" @click.stop="towuliudetail(orderItem)">查看物流</div>
                                <div v-if="orderItem.status == 4" class="same recive" @click.stop="cancelfun(orderItem.id)">撤销退款</div>
                                <div v-if="orderItem.status == 8" class="same recive" style="margin-right: 10px;" @click.stop="towuliudetail(orderItem)">查看物流</div>
                                <div v-if="orderItem.status == 8" class="same recive" style="margin-right: 10px;" @click.stop="applyFun(orderItem)">申请退款</div>
                                <div v-if="orderItem.status == 8" class="same recive" @click.stop="recivefun(orderItem.id)">确认收货</div>
                                <div v-if="orderItem.status == 10" class="same recive" style="margin-right: 10px;" @click.stop="towuliudetail(orderItem)">查看物流</div>
                                <div v-if="orderItem.status == 10" @click.stop="openinvoice(orderItem)" class="same recive">申请发票</div>
                                <div v-if="orderItem.status == 12" @click.stop="previewImage(orderItem.invoice.invoiceUrl)" class="same recive">查看发票</div>
                            </div>
                    </div>
                    </van-list>
                </van-tab>
            </van-tabs>
        </div> 
    </div>
</template>
<script>
import { Toast ,Dialog,ImagePreview} from 'vant';
import {queryUserOrderList,cancelOrderRefund ,userConfirmReceive,deleteOrder,userCancelOrder,getRePayOrderInfo} from '@/api/myorder';
export default {
    name: 'myorder',
    data() {
        return {
            mar_top:'',
            loading: false,
            finished: false,
            page: 1,
            pageSize: 10,
           top_navigation:[
                {id:1,val:'全部'},
                {id:2,val:'待付款'},
                {id:3,val:'待发货'},
                {id:4,val:'待收货'},
                {id:5,val:'退款/售后'}
           ],
           list: [],
           active: 0,
        }
    },
    methods: {
        onLoad() {       
            this.page++
            setTimeout(() => {
                this.getOrderList()
            }, 1000);
        },
        //分享
        shareFun(e){
            console.log("iteminfo",e);
            var content = ''
            if(e.status <= 1) {
                content = '这家店铺有好物，快来看看吧'
            } else {
                content = `我${e.amount}买下了一件好物，快来看看吧`
            }
            var paydata = {
                imgUrl: e.merchantsBrandInfoResponse.brandLogo,
                title: e.orderItemList[0].goodsName,
                content: content,
                url: `https://${process.env.VUE_APP_ENV}-app-h5-merchant.serenetone.com/#/myorder?id=${e.id}`,
                platformType: 4,
                id: e.id
            }
            var data = {
                action:'Share',
                req:paydata,
                reqId:6,
                respFunction:'sharefun'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        sharefun(e){
            console.log("分享订单bridge",e);
        },
        //支付
        async payorder(e){
            console.log(e);
            const res = await getRePayOrderInfo({
                orderNo:e.bizOrderNo,
                type:1
            })
            console.log("重新支付",res);
            var paydata = {}
            if(res.data.payWay == 0) { //微信支付
                var a = {
                        payChannel:'wechat',
                        timeStamp:JSON.parse(res.data.orderStr).timeStamp,
                        sign:JSON.parse(res.data.orderStr).sign,
                        nonceStr:JSON.parse(res.data.orderStr).nonceStr,
                        appId:JSON.parse(res.data.orderStr).appId,
                        packageValue:JSON.parse(res.data.orderStr).packageValue,
                        prepayId:JSON.parse(res.data.orderStr).prepayId,
                        partnerId:JSON.parse(res.data.orderStr).partnerId,
                    }
                    paydata = a
            } else { //支付宝支付
                var a = {
                        payChannel:'alipay',
                        orderInfo:res.data.orderStr,
                    }
                    paydata = a
            }
            var data = {
                action:'Play',
                req:paydata,
                reqId:4,
                respFunction:'bridgepayfn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        //支付bridge回调
        async bridgepayfn(e){
            console.log("支付   ",e);
            if(e.code == 200) {
                /* Toast('操作成功') */
                this.page = 1
                this.list = []
                this.finished = false
                await this.getOrderList();
            }
        },
        //多件商品支付
        /* async payorders(e){
            console.log(e);
            const res = await getRePayOrderInfo({
                orderNo:e.bizOrderNo
            })
            console.log("重新支付",res);
            console.log(JSON.parse(res.data.orderStr));
            var paydata = {}
            if(JSON.parse(res.data.orderStr.appId)) { //微信支付
                var a = {
                        payChannel:'wechat',
                        timeStamp:JSON.parse(res.data.orderStr).timeStamp,
                        sign:JSON.parse(res.data.orderStr).sign,
                        nonceStr:JSON.parse(res.data.orderStr).nonceStr,
                        appId:JSON.parse(res.data.orderStr).appId,
                        packageValue:JSON.parse(res.data.orderStr).packageValue,
                        prepayId:JSON.parse(res.data.orderStr).prepayId,
                        partnerId:JSON.parse(res.data.orderStr).partnerId,
                    }
                    paydata = a
            } else { //支付宝支付
                var a = {
                        payChannel:'alipay',
                        orderInfo:res.data.orderStr,
                    }
                    paydata = a
            }
            var data = {
                action:'Play',
                req:paydata,
                reqId:4,
                respFunction:'bridgepayfn'
            }
            window.ssmAndroid.ssmMessage(JSON.stringify(data))
        }, */
        //查看发票
        previewImage(e){
            ImagePreview([e]);
        },
         // 取消退款
         cancelfun(e){
            console.log(e);
            Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: '是否确认取消退款',
                })
                .then(async () => {
                    const res =  await cancelOrderRefund({orderId:e})
                    console.log("取消退货/取消退款接口",res);
                    setTimeout(() => {
                        this.page = 1
                        this.list = []
                        this.getOrderList();
                    }, 300);
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 取消订单
        cancelorderfun(e){
            console.log(e);
            Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: '是否取消此订单',
                })
                .then(async() => {
                    const res =  await userCancelOrder({id:e})
                    console.log("取消订单",res);
                    Toast('操作成功')
                        setTimeout(() => {
                            this.page = 1
                            this.list = []
                            this.getOrderList();
                        }, 300);
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 售后详情
        cancelback(e){
            console.log(e);
            if(e.status == 4 ||e.status == 5 ||e.status == 6 ) {
                this.$router.push({ name: 'detail' ,params:{info:e}})
            } else {
                this.$router.push({ name: 'shopdetail' ,params:{info:e}})
            }
        },
        // 删除
        deleteorders(e){
            console.log(e);
             Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: '是否删除此订单',
                })
                .then( async() => {
                    const res =  await deleteOrder({orderId:e.id})
                    console.log("删除",res);
                        Toast('操作成功')
                        setTimeout(() => {
                            this.page = 1
                            this.list = []
                            this.getOrderList();
                        }, 300);
                })
                .catch(() => {
                    // on cancel
                });
        },
        //确认收货
        recivefun(e){
            console.log(e);
            Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: '是否确认收货',
                })
                .then(() => {
                    const res =  userConfirmReceive({id:e})
                    console.log("确认收货",res);
                    setTimeout(() => {
                        this.page = 1
                            this.list = []
                            this.getOrderList();
                    }, 300);
                })
                .catch(() => {
                    // on cancel
                });
        },
        async navigationFun(e,w){
            console.log("顶部导航标签点击",e,w);
            this.finished = false
            this.page = 1
            this.list = []
            await this.getOrderList()
        },
        // 跳转物流详情
        towuliudetail(e){
            console.log("详情",e);
            if(e.logisticsCompany == null || e.logisticsNumber == null) {
                Toast('暂无物流信息')
            } else {
                this.$router.push({ name: 'wuliudetaile' ,params:{info:e}});
            }
        },
        // 倒计时结束
        async finishfun(){
            console.log("倒计时结束");
            this.finished = false
            this.page = 1
            this.list = []
            await this.getOrderList()
        },
        //申请发票
        openinvoice(e){
            this.$router.push({ name: 'invoices' ,params:{info:e}});
        },
        // 申请退款
        applyFun(e){
            console.log(e);
            this.$router.push({ name: 'apply' ,params:{info:e,isfirst:1}})
        },
        back(){
        var data = {
                    action:'ClosePage',
                    req:{},
                    reqId:12,
                    respFunction:'closefn'
                }
            if(this.$Version() == 'iOS') {
                console.log("ios关闭当前页面");
                
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                console.log("Android关闭当前页面");
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
       },
       closefn(e){
            console.log("返回"),e;
        },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
        // 查询订单列表
        async getOrderList() {
            const res = await queryUserOrderList({ 
                pageNum: this.page,
                pageSize: this.pageSize,
                type:this.active
            });
            console.log("查询用户订单列表",res);
            if(res.data) {
                this.list = [...this.list , ...res.data.records] 
                this.loading = false
                if (res.data.records.length === 0) {
                    this.finished = true
                }
            }
        }
    },
    
    async mounted() {
        window.closefn = this.closefn
        window.marginFun = this.marginFun
        window.bridgepayfn = this.bridgepayfn
        //距离顶部距离
        var data = {
            action:'StatusBarInfo',
            req:{},
            reqId:13,
            respFunction:'marginFun'
        }
        if(this.$Version() == 'iOS') {
            window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
        } else {
            window.ssmAndroid.ssmMessage(JSON.stringify(data))
        }
        this.page = 1
        this.list = []
        this.finished = false
        setTimeout(() => {
            this.getOrderList();
        }, 300);
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .box {
        padding: 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 7px 0;
    }
    .flex {
        display: flex;
        align-items: center;
        .img {
           width: 24px;
           height: 24px;
           border-radius: 12px; 
        }
        .imgs {
            width: 60px;
            height: 60px;
            border-radius: 8px; 
        }
        .goodsn {
            width: 190px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .t {
            // width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .same {
            padding: 5px 15px;
            box-sizing: border-box;
            border-radius: 8px;
        }
        .cancel {
            border: 1px solid rgba(55,38,27,0.33);
            color: rgba(55,38,27,0.5);
        }
        .sub {
            background: #876235;
            color: #fff;
        }
        .recive {
            background-color: #876235;
            color: #fff;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tosat {
            width: 300px;
            height: 180px;
            border-radius: 8px;
            background-color: #E9D4BC;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }
    .lx {
            width: 160px !important;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background-color: rgba(255,255,255,1);
            color: rgba(16,16,16,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            border: 1px solid rgba(187,187,187,1);
        }
}
.wrappers {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 220px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #E9D4BC;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    position: relative;
  }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
