<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="售后详情" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
                <!-- 用户发起申请 -->
                 <div v-if="orderinfo.status == 0" class="flex" style="justify-content: space-between;">
                    <div class="flex" style="flex-direction: column;align-items: start;">
                        <div style="font-size: 18px;color: #000;">用户发起退货退款</div>
                        <div style="margin-top: 5px;color: rgba(55,38,27,0.5);">审核中，超时未处理，审核自动通过</div>
                    </div>
                    <div class="flex">
                        <div style="font-size: 13px;">还剩</div>
                        <van-count-down @finish="finishfun" style="color: #475AD5;margin-left: 5px;font-size: 13px;" :time="orderinfo.brandApproveRefundSecond*1000"/> 
                    </div>
                 </div>
                 <!-- 商家拒绝用户申请 -->
                 <van-steps v-if="orderinfo.status == 2" inactive-color="rgba(55,38,27,0.5)" active-color="#475AD5" direction="vertical" :active="1" style="background: rgba(0, 0, 0, 0);">
                    <van-step>
                        <div>用户提交申请</div>
                    </van-step>
                    <van-step>
                        <div>我已拒绝</div>
                        <div class="flex">
                            <div>原因:{{ orderinfo.approveRecordFailReason.remark}}</div>
                        </div>
                    </van-step>
                </van-steps>
                <!-- 商家同意用户申请 -->
                <van-steps v-if="orderinfo.status == 1" inactive-color="rgba(55,38,27,0.5)" active-color="#475AD5" direction="vertical" :active="1" style="background: rgba(0, 0, 0, 0);">
                    <van-step>
                        <div>我已审核通过</div>
                    </van-step>
                    <van-step>
                        <div>待用户退货并填写物流单号</div>
                        <div class="flex">
                        <div style="font-size: 13px;">还剩</div>
                        <van-count-down @finish="finishfun" style="color: #475AD5;margin-left: 5px;font-size: 13px;" :time="orderinfo.residueLogisticsSecond*1000"/> 
                    </div>
                        <div class="flex">
                            <div>超时未填写，用户申请自动关闭</div>
                        </div>
                    </van-step>
                    <van-step>
                        <div>待收货</div>
                    </van-step>
                </van-steps>
                 <!-- 退款申请关闭 -->
                 <div v-if="orderinfo.status == 5" class="flex">
                    <div class="flex" style="flex-direction: column;align-items: start;">
                        <div style="font-size: 18px;color: #000;">退款申请关闭</div>
                    </div>
                 </div>
                 <!-- 用户已填写单号 -->
                <van-steps v-if="orderinfo.status == 6" inactive-color="rgba(55,38,27,0.5)" active-color="#475AD5" direction="vertical" :active="1" style="background: rgba(0, 0, 0, 0);">
                    <van-step>
                        <div>我已审核通过</div>
                    </van-step>
                    <van-step>
                        <div>用户已填写单号</div>
                        <div class="flex">
                            <div>根据单号可以查物流状态</div>
                        </div>
                    </van-step>
                    <van-step>
                        <div>待收货</div>
                    </van-step>
                </van-steps>
                 <div style="height: 1px;width: 100%;background-color: rgba(223,192,156,0.66);margin: 10px 0;"></div>
                <!-- 商家同意用户申请 -->
                 <div class="box" v-if="orderinfo.status == 1">
                    <div class="flex" style="justify-content: space-between;">
                        <div style="color: #000;">收货地址</div>
                        <div class="flex">
                            <van-image width="20" height="20" :src="require('/src/assets/pic_copy.png')"/>
                            <div style="color: #475AD5;margin-left: 4px;" @click="copyfunadd">复制</div>
                        </div>
                    </div>
                    <div style="margin-top: 10px;color: rgba(55,38,27,0.5);">{{orderinfo.acceptaddress}}</div>
                </div>
                <!-- 商家已拒绝退款 -->
                <div v-if="orderinfo.status == 9" class="flex" style="justify-content: space-between;">
                    <div class="flex" style="flex-direction: column;align-items: start;">
                        <div style="font-size: 18px;color: #000;">我已拒绝退款</div>
                    </div>
                 </div>
                 <!-- 商家已同意退款 -->
                <div v-if="orderinfo.status == 8" class="flex" style="justify-content: space-between;">
                    <div class="flex" style="flex-direction: column;align-items: start;">
                        <div style="font-size: 18px;color: #000;">我已同意退款</div>
                    </div>
                 </div>
                 <!-- 用户已填写单号 -->
                 <div class="box" v-if="orderinfo.status == 6">
                    <div class="flex" style="justify-content: space-between;">
                        <div style="color: #000;">寄回单号</div>
                        <div class="flex">
                            <van-image width="20" height="20" :src="require('/src/assets/pic_copy.png')"/>
                            <div style="color: #475AD5;margin-left: 4px;" @click="copyfun">复制</div>
                        </div>
                    </div>
                    <div class="orderno" style="margin-top: 10px;color: rgba(55,38,27,0.5);">{{company_name}}  {{orderinfo.logisticsNumber}}</div>
                </div>

                 <div class="flex" style="margin: 10px 0;">
                <div>退货类型</div>
                    <div style="margin-left: 30px;">退货退款</div>
                </div>
                <div class="flex" style="margin: 10px 0;">
                    <div>退货原因</div>
                    <div style="margin-left: 30px;">{{orderinfo.reason == 0 ? '七天无理由' : ''}}</div>
                </div>
                <div v-if="orderinfo.refundDesc" class="flex" style="margin: 10px 0;">
                    <div>退货描述</div>
                    <div style="margin-left: 30px;width: 240px;">{{orderinfo.refundDesc}}</div>
                </div>
                <div class="flex" style="margin: 10px 0;align-items: start;">
                    <div>退货凭证</div>
                    <div style="margin-left: 30px;">
                        <van-image v-for="item in shouhouimglist" @click="previewImage(shouhouimglist)"  width="76" height="76" :src="item"/>
                        <video v-if="shouhouvideo.videoUrl" :poster="shouhouvideo.videoResponse.image.url" @click="playVideo" :src="vshouhouvideo.videoUrl" style="width: 76px;height: 76px;"></video>
                    </div>
                </div>
                <div style="height: 1px;width: 100%;background-color: rgba(223,192,156,0.66);margin: 10px 0;"></div>
                <div class="flex" style="margin: 10px 0;">
                    <div>订单编号</div>
                    <div style="margin-left: 30px;">{{orderinfo.bizOrderNo}}</div>
                </div>
                <div v-if="orderinfo.status == 0" class="flex" style="margin: 10px 0;">
                    <div>退货编号</div>
                    <div style="margin-left: 30px;">{{orderinfo.refundOrderNo}}</div>
                </div>
                <div class="flex" style="margin: 10px 0;">
                    <div>申请时间</div>
                    <div style="margin-left: 30px;">{{orderinfo.createTime}}</div>
                </div>
                <div class="flex" style="margin: 10px 0;">
                    <div>退货单号</div>
                    <div style="margin-left: 30px;">{{orderinfo.refundOrderNo}}</div>
                </div>
                <div v-if="orderinfo.status == 0" class="flex" style="margin: 10px 0;">
                    <div>返回方式</div>
                    <div style="margin-left: 30px;">原路返回</div>
                </div>
                <div v-if="orderinfo.status == 0" class="flex p" style="justify-content: space-between;">
                    <div class="same b" @click="unagreeshow = true">拒绝</div>
                    <div class="same a" @click="agreeback">通过</div>
                </div>
                <div v-if="orderinfo.status == 6" class="flex p" style="justify-content: space-between;">
                    <div class="same b" @click="unagreetui = true">商品有问题</div>
                    <div class="same a" @click="tuikuan">退款</div>
                </div>
                <!-- !-- 不同意退款申请弹窗  -->
            <van-popup round v-model="unagreeshow" position="bottom" >
                <div class="wuliu_box">
                    <div style="font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;">拒绝申请</div> 
                    <div class="flex" style="margin: 20px 0 ;font-size: 16px;color: #000;">
                        <div>理由</div>
                        <el-input  style="width: 60%;margin-left: 20px;" class="iptql" v-model="refuse_rea"></el-input>
                    </div>
                    <div class="wuliu_btn" @click="unagreefun">确认</div>
                </div>
            </van-popup>
            <!-- 不同意退款 -->
            <van-popup round v-model="unagreetui" position="bottom" >
                <div class="wuliu_box" style="height: 500px;">
                    <div style="font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;">商品有问题</div>
                    <div>
                        <div style="margin-top: 20px;">补充描述和凭证</div> 
                        <van-field v-model="message" rows="3" autosize type="textarea" maxlength="200" show-word-limit class="te" />
                    </div>
                    <div class="flex" style="flex-wrap: wrap;">
                        <van-image @click="upload_fp" width="76" height="76" :src="imgUrl" style="margin-right: 10px;"/>
                        <template v-if="imglist.length != 0">
                            <van-image v-for="item in imglist"  width="76" height="76" :src="item"/>
                        </template>
                    </div>
                    <div class="flex" style="flex-wrap: wrap;margin-top: 10px;">
                        <van-image width="76" height="76" :src="shouhou_video.videoCoverUrl" @click="upload_video"/>
                    </div>
                    <div class="wuliu_btn" @click="refusetui">拒收并拒绝退款</div>
                </div>
            </van-popup>
            <div style="height: 80px;" ></div>
        </div> 
    </div>
</template>
<script>
import { Toast ,ImagePreview} from 'vant';
import { approveBrandRefund,queryRefundListByOrder,approveRefundPay} from '@/api/order';
export default {
    name: 'order',
    data() {
        return {
            mar_top:'',
            orderinfo:{},
            info:{},
            refuextext:'',
            refuextext1:'',
            unagreeshow:false,
            unagreeshow1:false,
            unagreetui:false,
            order_id:'',
            imgUrl:require('/src/assets/pic_img.png'),
            imglist:[],
            video:require('/src/assets/pic_video.png'),
            shouhou_video:{
                videoCoverUrl:require('/src/assets/pic_img.png'),
                videoId:''
            },
            message:'',//拒绝退款描述
            company_name:'',
            refuse_rea:'',//拒绝理由
            options: [
                {value:'yuantong',label:'圆通速递'},
                {value:'yunda',label:'韵达快递'},
                {value:'zhongtong',label:'中通快递'},
                {value:'jtexpress',label:'极兔速递'},
                {value:'shentong',label:'申通快递'},
                {value:'youzhengguonei',label:'邮政快递包裹'},
                {value:'shunfeng',label:'顺丰速运'},
                {value:'jd',label:'京东物流'},
                {value:'ems',label:'EMS'},
                {value:'youzhengdsbk',label:'邮政电商标快'},
                {value:'youzhengbk',label:'邮政标准快递'},
                {value:'debangkuaidi',label:'德邦快递'},
                {value:'danniao',label:'菜鸟速递'},
                {value:'xinfengwuliu',label:'信丰物流'},
                {value:'debangwuliu',label:'德邦物流'},
                {value:'zhongtongkuaiyun',label:'中通快运'},
                {value:'rrs',label:'日日顺物流'},
                {value:'jingdongkuaiyun',label:'京东快运'},
                {value:'shunfengkuaiyun',label:'顺丰快运'},
                {value:'kuayue',label:'跨越速运'},
                {value:'annengwuliu',label:'安能快运'},
                {value:'baishiwuliu',label:'百世快运'},
                {value:'sxjdfreight',label:'顺心捷达'},
                {value:'jinguangsudikuaijian',label:'京广速递'},
                {value:'yimidida',label:'壹米滴答'},
                {value:'yundakuaiyun',label:'韵达快运'},
                {value:'subida',label:'速必达'},
                {value:'usps',label:'USPS'},
                {value:'youshuwuliu',label:'优速'},
                {value:'annto',label:'安得物流'},
                {value:'pingandatengfei',label:'平安达腾飞'},
                {value:'zhongtongguoji',label:'中通国际'},
                {value:'jiayunmeiwuliu',label:'加运美'},
                {value:'cainiaodajian',label:'菜鸟大件'},
                {value:'dhl',label:'DHL-中国件'},
                {value:'savor',label:'海信物流'},
                {value:'ups',label:'UPS'},
                {value:'zhaijisong',label:'宅急送'},
                {value:'upsen',label:'UPS-全球件'},
                {value:'ztky',label:'中铁快运'},
                {value:'suning',label:'苏宁物流'},
                {value:'suteng',label:'速腾快递'},
                {value:'fedexcn',label:'Fedex-国际件-中文'},
                {value:'ewe',label:'EWE全球快递'},
                {value:'stosolution',label:'申通国际'},
                {value:'huitongkuaidi',label:'百世快递'},
                {value:'shunfenglengyun',label:'顺丰冷链'},
                {value:'fedex',label:'FedEx-国际件'},
                {value:'huisenky',label:'汇森速运'},
                {value:'exfresh',label:'安鲜达'},
                {value:'disifang',label:'递四方'},
                {value:'quantong56',label:'全通速递'},
                {value:'youzhengguoji',label:'国际包裹'},
                {value:'flextock',label:'Flextock'},
                {value:'dhlen',label:'DHL-全球件'},
                {value:'zhongtiewuliu',label:'中铁飞豹'},
                {value:'longjun',label:'龙俊物流'},
                {value:'emswuliu',label:'EMS物流'},
                {value:'chinapost',label:'中国邮政（CHINA POST）'},
                {value:'ane66',label:'安能快递'},
                {value:'yuxinwuliu',label:'宇鑫物流'},
                {value:'shpost',label:'同城快寄'},
                {value:'emsbg',label:'EMS包裹'},
                {value:'yuantongguoji',label:'圆通国际'},
                {value:'emsguoji',label:'EMS-国际件'},
                {value:'ttsucha',label:'天天速查'},
                {value:'wooolink',label:'上海领链物流'},
            ],
            shouhouimglist:[],//用户申请退款图片
            shouhouvideo:{},//用户申请售后视频

        }
    },
    methods: {
        playVideo(){
            this.$el.querySelector('video').play()
        },
        previewImage(key) {
            if(Array.isArray(key)) {
                ImagePreview(key);
            } else {
                ImagePreview([key]);
            }
        },
        //商家收到退货拒绝退款 图片
        upload_fp(){
            var data = {
                action:'GetImage',
                req:{img_num:3},
                reqId:2,
                respFunction:'upload_fpfun'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
         },
         upload_fpfun(e){
            this.imglist = e.data
         },
         //视频
         upload_video(){
            var data = {
                action:'GetVideo',
                req:{ minute:180},
                reqId:3,
                respFunction:'upvideo'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
         },
         upvideo(e){
             console.log('视频',e);
             this.shouhou_video = e.data
         },
        finishfun(){

        },
        //复制收货地址
        async copyfunadd(){
            if (navigator.clipboard) {
                const textToCopy =this.orderinfo.acceptaddress;
                await navigator.clipboard.writeText(textToCopy);
                Toast('复制成功')
                console.log("文本已复制到剪贴板");
            } else {
                const textarea = document.createElement('textarea');
                textarea.value = this.orderinfo.acceptaddress;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
                Toast('复制成功')
                console.log('Text copied using fallback method');
            }
        },
        //复制单号
        async copyfun(){
            if (navigator.clipboard) {
                const textToCopy =this.orderinfo.logisticsNumber;
                await navigator.clipboard.writeText(textToCopy);
                Toast('复制成功')
                console.log("文本已复制到剪贴板");
            } else {
                const textarea = document.createElement('textarea');
                textarea.value = this.orderinfo.logisticsNumber;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
                Toast('复制成功')
                console.log('Text copied using fallback method');
            }
        },
        // 商家同意申请退款
        async agreeshowfun(e){
            var data = {
                refundId:e,
                status:1
            }
            var res = await approveBrandRefund(data);
            console.log("商家审核退款申请同意",res);
            await this.RefundListByOrder()
        },
        // 商家退款申请不同意
        unagreeshowfun(e){
            this.order_id = e
            this.unagreeshow = true
        },
        // 不同意退款申请弹窗确定
        async unagreefun(){
            if(this.refuse_rea == ''){
                Toast('请填写拒绝理由')
            } else {
                var data = {
                    refundId:this.orderinfo.id,
                    remark:this.refuse_rea,
                    status:0
                }
                var res = await approveBrandRefund(data);
                console.log("商家审核退款申请拒绝",res);
                this.unagreeshow = false
                await this.RefundListByOrder()
            }
        },
        // 同意退款申请
        async agreeback(){
            var data = {
                    refundId:this.orderinfo.id,
                    remark:'',
                    status:1
                }
                var res = await approveBrandRefund(data);
                console.log("商家审核退款申请同意",res);
                await this.RefundListByOrder()
        },
        // 商家退款
        async tuikuan(){
            const res = await approveRefundPay({
                refundId:this.orderinfo.id,
                status:1,
            });
            console.log("商家退款同意",res);
            if(res.code == 200) {
                Toast('操作成功')
                setTimeout(() => {
                    this.$router.go(-1);
                }, 300);
            }
        },
        // 商家不同意退款
        async refusetui(){
            const res = await approveRefundPay({
                refundId:this.orderinfo.id,
                status:0,
                remark:this.message,
                image:this.imglist.join('#####'),
                videoId: this.shouhou_video.videoId,
                videoImage: videoinfo,
            });
            console.log("商家退款拒绝",res);
            if(res.code == 200) {
                Toast('操作成功')
                setTimeout(() => {
                    this.$router.go(-1);
                }, 300);
            }
        },
         // 跳转物流信息页面
         toLogisitics(e,a){
            if(e.logisticsNumber == null || e.logisticsCompany == null) {
                Toast('未查询到物流信息')
            } else {
                this.$router.push({ name: 'logistics' ,params:{info:e,issales:a}});
            }
        },
        back() {
            this.$router.go(-1);
        },
        async RefundListByOrder(){
            const res = await queryRefundListByOrder({ 
                orderId:this.info.id
            });
            console.log("查询订单下面的退款信息",res);
            if(res.data.orderAddressDTO != null) {
                res.data.acceptaddress = res.data.orderAddressDTO.province+res.data.orderAddressDTO.city+res.data.orderAddressDTO.district+res.data.orderAddressDTO.address+res.data.orderAddressDTO.name
            }
            this.orderinfo = res.data
            //用户退款图片
            if(res.data.image) {
                this.shouhouimglist = res.data.image.split('#####')
            }
            if(res.data.videoResponse) {
                this.shouhouvideo = res.data.videoResponse
            }
            //用户退款视频
            /* if(res.data.approveRecordFailReason.videoResponse.videoId) {
                this.shouhouvideo = res.data.approveRecordFailReason.videoResponse
            } */
            /*  var xuan_video = {
                 videoCoverUrl:res.data.videoResponse.image.url,
                 videoId:res.data.videoResponse.videoId,
             }
             this.shouhou_video = xuan_video
             this.imglist = res.data.img.split(',') */ //返回图片 换字段
            if(res.data.logisticsCompany != null) {
                for (let i = 0; i < this.options.length; i++) {
                if(this.options[i].value == res.data.logisticsCompany) {
                    this.company_name = this.options[i].label
                }
            }
            }
        },
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    
    async mounted() {
        window.upload_fpfun = this.upload_fpfun
        window.upvideo = this.upvideo
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log("this.$router.parmars",this.$route.params);
        this.info = this.$route.params.info
        await this.RefundListByOrder();
     }
}
</script>
<style lang="less" scoped>
.van-step--vertical:not(:last-child)::after {
    border-bottom-width:0
}
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
    .page {
        background-color: #E9D4BC;
        &::before {
        content: ' ';
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #E9D4BC;
        background-size: 100% auto;
        }
    }
    .container {
        display: flex;
        flex-direction: column;
        padding: 16px;
        box-sizing: border-box;
        font-size: 14px;
        .box {
            padding: 15px;
            box-sizing: border-box;
            border-radius: 12px;
            background: rgba(174,133,81,0.15);
            margin: 7px 0;
        }
        .flex {
            display: flex;
            align-items: center;
            color: rgba(55,38,27,0.5);
            .img {
            width: 24px;
            height: 24px;
            border-radius: 12px; 
            }
            .imgs {
                width: 60px;
                height: 60px;
                border-radius: 8px; 
            }
            .goodsn {
                width: 190px;
                height: 60px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                margin: 0 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .t {
                // width: 190px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .same {
                padding: 5px 15px;
                box-sizing: border-box;
                border-radius: 8px;
            }
            .cancel {
                border: 1px solid rgba(55,38,27,0.33);
                color: rgba(55,38,27,0.5);
            }
            .sub {
                background: #876235;
                color: #fff;
            }
            .recive {
                border: 1px solid #876235;
                color: #475AD5;
            }
        }
        .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            .tosat {
                width: 300px;
                height: 180px;
                border-radius: 8px;
                background-color: #E9D4BC;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
            }
        }
        .lx {
                width: 160px !important;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                background-color: rgba(255,255,255,1);
                color: rgba(16,16,16,1);
                font-size: 14px;
                text-align: center;
                font-family: PingFangSC-regular;
                border: 1px solid rgba(187,187,187,1);
            }
            .p {
        width: 94%;
        position: fixed;
        left:50%;
        bottom: 50px;
        transform: translate(-50%,0);
    }
            .same {
        
        width: 48%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        font-size: 18px;
    }
    .a {
        background-color: #475AD5 ;
        color: #fff;
    }
    .b {
        background-color: rgba(174,133,81,0.15);
        color: #000;
    }
    .wuliu_box {
        height: 300px;
        padding: 20px;
        box-sizing: border-box; 
        background-color: #E9D4BC;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .el_selects {
        width: 60%;
        margin-left: 20px;
    }
    .iptql /deep/ .el-input__inner{
        background: rgba(174,133,81,0.15);  /* 背景色 */
        border-color: rgba(174,133,81,0.15); /* 边框色 */
        color: 000 /* 文字颜色 */ 
    }
    .wuliu_btn {
        width: 100%;
        height: 48px;
        border-radius: 12px;
        font-size: 18px;
        background-color: #876235 ;
        color: #fff;
        text-align: center;
        line-height: 48px;
        margin: 20px auto;
    }
    .te {
        border-radius: 8px;
        margin: 10px 0;
        background-color: rgba(174,133,81,0.15);
    }
    }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
    /deep/.van-step__circle  {
        background: #D5BA99;
    }
</style>
