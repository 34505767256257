<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="我的信用值" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <div class="box" style="display: flex;flex-direction: column;align-items: center;">
                <div style="font-size: 30px;color: #475AD5;">{{ mycredit }}</div>
            </div>
            <div class="box" v-for="item in list">
                <div>信用值：{{ item.creditScore }}</div>
                <div style="margin: 10px 0;width: 100%;">原因：{{ item.reason }}</div>
                <div>{{ item.createTime }}</div>
            </div>
                   
        </div> 
    </div>
</template>
<script>
import { auctionCreditScore,auctionCreditScoreDetail} from '@/api/auction';
export default {
    name: 'auction',
    data() {
        return {
            mycredit:'',//我的信用分
           list: [],
           mar_top:'',
        }
    },
    methods: {
        back() {
            this.$router.go(-1);
        },
        //查询竞拍列表
        async getAuctionList(){
            const res = await auctionCreditScoreDetail({});
            console.log("我的信用明细",res);
            if(res.data) {
                this.list = res.data
            }
        },
        //查询出价金余额
        async getmoney(){
            const res = await auctionCreditScore({})
            console.log("查询信用分",res);
            this.mycredit = res.data
        },
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        await this.getAuctionList();
        await this.getmoney();

    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .box {
        padding: 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 7px 0;
    }
    .flex {
        display: flex;
        align-items: center;
        .img {
           width: 24px;
           height: 24px;
           border-radius: 12px; 
        }
        .imgs {
            width: 60px;
            height: 60px;
            border-radius: 8px; 
        }
        .goodsn {
            width: 190px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .t {
            // width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .same {
            padding: 5px 15px;
            box-sizing: border-box;
            border-radius: 8px;
        }
        .cancel {
            border: 1px solid rgba(55,38,27,0.33);
            color: rgba(55,38,27,0.5);
        }
        .sub {
            background: #876235;
            color: #fff;
        }
        .recive {
            background-color: #876235;
            color: #fff;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tosat {
            width: 300px;
            height: 180px;
            border-radius: 8px;
            background-color: #E9D4BC;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }
    .lx {
            width: 160px !important;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background-color: rgba(255,255,255,1);
            color: rgba(16,16,16,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            border: 1px solid rgba(187,187,187,1);
        }
    }
    .wuliu_box {
    height: 300px;
    padding: 20px;
    box-sizing: border-box; 
    background-color: #E9D4BC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
}
.wuliu_btn {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    font-size: 18px;
    background-color: #876235 ;
    color: #fff;
    text-align: center;
    line-height: 48px;
    margin: 20px auto;
}
.input_fild {
   margin-top: 10px;
   padding:10px 15px;
   box-sizing: border-box;
   background: rgba(174,133,81,0.15);
   border-radius: 8px;
   color: #000;
}
:deep(.van-field__control::-webkit-input-placeholder) {
   color:rgba(55,38,27,0.5);
   font-size: 14px;
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
