
import Axios from "axios";
 //导入md5
 import md5 from 'js-md5'
 import dayjs from "dayjs"
import config from "@/config/index.js"
import {Toast} from 'vant';
getuserinfo()
const request = Axios.create({
    baseURL: config.serverUrl,
    timeout: 5000
})

request.interceptors.request.use(config => {
    const key = 'A34992KS945KS040W0087'
    const once = dayjs(new Date()).format("YYYYMMDDHHmmss")
    const sign = md5(key + once)
    config.headers.once = once
    config.headers.sign = sign
    config.headers.token = localStorage.getItem('userToken')
   /*  config.headers.token = '9a32af24589b4c91afced04c00a724ec' */
    return config
}, (error) => {
    return Promise.reject(error);
})


//请求后置拦截器   校验
request.interceptors.response.use( //用于处理判断请求成功失败
    response => {
        if (response.data.code === 200) {
            return response.data  // 表示放行
        } else {     //错误处理
            // 通过状态码，判断是什么错误
            // Message.error({
            //     message: response.data.msg,
            // })
            Toast.fail(response.data.msg)
            return Promise.reject(response)
        }
    }, error => {
        // Message.error({
        //     message: "服务器异常，请稍后重试",
        // })
        Toast.fail("服务器异常，请稍后重试")
        return Promise.reject(error)
    }
)
//判断安卓ios
function selectVersion(){
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
        return 'iOS';
    } else if (userAgent.match(/Android/i)) {
        return 'Android';
    } else {
        return 'unknown';
    }
  }
// 获取用户信息
function getuserinfo() {
    var data = {
      action: "UserInfo",
      req: {},
      reqId: 5,
      respFunction: "getuserFn",
    };
    if (selectVersion() == "iOS") {
      window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data));
    } else {
      window.ssmAndroid.ssmMessage(JSON.stringify(data));
    }
    window.getuserFn = getuserFn;
  }
// 获取用户信息的回调
function getuserFn(e){
    console.log("获取用户信息request.jsqqq1",e.data.token);
    localStorage.setItem('userToken',e.data.token)
    console.log("获取保存的token",localStorage.getItem('userToken'));
}

export default request
