<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" left-arrow title="商家入驻协议" @click-left="back" />
        </van-sticky>
        <div class="container" style="ont-size: 15px;padding: 0 10px;box-sizing: border-box;margin-top: 20px;">
        </div>
        <div style="color: rgba(55,38,27,0.5);position: relative;bottom: 20px;left: 0;font-size: 15px;padding: 10px 20px;box-sizing: border-box;margin-top: 20px;">
            <div>第三方商家商品售后规则</div>

            <div>公布、实施日期：2025年01月23日</div>

            <div>（一）第三方商家商品</div>
<div>第三方商家商品是指入驻柴门小童的商家以商家名义销售的商品，此等商品由该第三方商家向买家开具发票。</div>
<div>（二）退换货规则</div>
<div>1.商品的退换货规则以第三方商家的售后服务政策为准。</div>
<div>2．不适用七天无理由退货情形</div>
<div>（1）个人定作类商品；</div>
<div>（2）鲜活易腐类商品；</div>
<div>（3）在线下载或者您已拆封的音像制品，计算机软件等数字化商品；</div>
<div>（4）交付的报纸期刊类商品；</div>
<div>（5）根据商品性质不适宜退货，并经顾客购买时确认的商品：</div>
<div>a.拆封后易影响人身安全或者生命健康的商品，或者拆封后易导致商品品质发生改变的商品；</div>
<div>b.一经激活或者试用后价值贬损较大的商品；</div>
<div>.销售时已明示的临近保质期的商品、有瑕疵的商品；</div>
<div>d.其他根据商品性质不适宜退货的商品。</div>
<div>（6）无法保证退回商品完好（能够保持原有品质、功能，商品本身、配件、商标标识齐全的，视为商品完好）；</div>
<div>（7）柴门小童平台内相关页面公示明确不适用七天无理由退货的商品；</div>
<div>（8）出现依据法律法规或第三方商家售后服务政策规定的不支持退货情形的商品。</div>
<div>3．不予办理退换货情形</div>
<div>（1）任何非柴门小童的第三方商家出售的商品；</div>
<div>（2）超过退换货期限的商品；</div>
<div>（3）商品外观、功能不符合退换货标准；</div>
<div>（4）未经授权的维修、误用、碰撞、疏忽、滥用、进液、事故、改动、不正确的安装所造成的商品质量问题，或撕毁、涂改标贴、机器序号、防伪标记；</div>
<div>（5）商品的外包装、附件、赠品、说明书不完整；</div>
<div>（6）因意外因素或人为原因（包括浸水、震裂、摔坏、印刷线路板及功能器件烧坏等）导致商品故障或损坏；</div>
<div>（7）发票缺失或涂改三包凭证信息与商品不符及被涂改的；</div>
<div>（8）其他依法不予办理退换货的。</div>
<div>（三）第三方商家自主售后</div>
<div>第三方商家商品将由第三方商家直接为您提供售后服务。您可以通过柴门小童线上已购订单页面向商家申请售后，由商家审核确认，或者您直接联系商家客服解决。如对商家处理结果有异议，您可以拨打柴门小童客服热线进行反馈，柴门小童将协助为您解决。</div>
<div>（四）其他注意事项</div>
<div>1. 对于存储类商品，不提供数据保存、数据导出服务，请务必在返修前将商品内的数据自行导出，否则责任由用户承担。</div>
<div>2. 消费者应当确保退回商品和相关配（附）件（如吊牌、说明书、三包卡、入网卡等）齐全，并保持原有品质、功能，无受损、受污、刮开防伪、产生激活（授权）等情形，无难以恢复原状的外观类使用痕迹、不合理的个人数据使用痕迹。</div>
<div>3. 商品退货时，赠品（包括赠送的实物、积分、代金券、优惠券等形式）应一并退回，如赠品已丢失、使用、破损或未退回等，需按照事先标明的赠品价格支付赠品价款。退货成功后，您在购物时使用的优惠券不予退还。</div>
<div>4. 用户在退还商品的途中，有责任保证所退还商品的安全及完整，如退还途中出现丢失、损坏等情形，责任由用户承担。</div>
<div>5. 商品的外包装为商品不可分割的组成部分，退回时应当齐全、无严重破损、受污。“商品的外包装”是指：生产厂商原包装（最小销售单元），不含销售商或物流服务商自行二次封装的包装。</div>
<div>6. 商品退换货及维修请与第三方商家确认后办理，具体规则请以第三方商家的售后服务政策为准。</div>

<div>提示：本规则仅适用于中国地区（港澳台地区除外）的售后服务。</div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'enter',
    data() {
        return {
            mar_top:""
        }
    },
    methods: {
        back() {
        this.$router.go(-1)
    },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 

.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
/deep/ .van-step--horizontal .van-step__circle-container {
    background-color: rgba(0, 0, 0, 0);
}
.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-size: 14px;
    
    .box {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        .flex {
            display: flex;
            align-items: center;
        }
    }
    .box1 {
        padding:20px ;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15); 
        border-radius: 8px;
        color:rgba(55,38,27,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/deep/.van-step__circle  {
    width: 10px;
    height: 10px;
    background: rgba(97, 76, 47, 0.15); 
}
.wuliu_box {
    height: 300px;
    padding: 20px;
    box-sizing: border-box; 
    background-color: #E9D4BC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
}
.btn {
        width: 100%;
        border-radius: 5px;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        margin: 10px 0;
    }
    .paycss {
        width: 100px;
        height: 35px;
        border-radius: 8px;
        line-height: 35px;
        text-align: center;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        
    }
    .input_fild {
        margin-top: 10px;
        padding:10px 15px;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15);
        border-radius: 8px;
        color: #000;
    }
    :deep(.van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
