<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
                <van-nav-bar style="background-color: #E9D4BC;" title="橱窗管理" left-arrow @click-left="back" />
            </van-sticky>
        <div class="container">
            <!-- 顶部导航 -->
            <van-tabs @click="navigationFun" v-model="active" color="#000000" background="#E9D4BC" line-width="20px"  title-inactive-color="rgba(55,38,27,0.66)">
                <div style="height: 20px;"></div>
                <van-tab v-for="item in top_navigation" :title="item.val">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text=""
                        @load="onLoad"
                        :immediate-check="false"
                    >
                    <div class="box" v-for="(item,index) in list" style="position: relative;">
                        <div class="flex">
                            <van-image fit='cover' style="width:100%;" radius='12' height="200" :src="item.coverImage"
                                @click="previewImage(item.coverImage)" />
                        </div>
                        <div v-if="item.type == 1" class="rit_con" >
                            <van-image width="24" height="24"  :src="pic_fdjs"/>
                            <div>分段计时</div>
                        </div>
                        <div v-if="item.type == 0" class="rit_con" >
                            <van-image width="24" height="24"  :src="pic_msms"/>
                            <div>秒杀</div>
                        </div>
                        <div v-if="item.type == 2" class="rit_con" >
                            <van-image width="24" height="24"  :src="pic_hshs"/>
                            <div>荷式</div>
                        </div>
                        <div v-if="item.type == 4" class="rit_con" >
                            <div>薅羊毛</div>
                        </div>
                        <div v-if="item.type == 3" class="rit_con" >
                            <div>拍卖</div>
                        </div>
                        <div style="width:94%;margin:0 auto;color: rgba(55,38,27,0.66);padding: 1px 0;box-sizing: border-box;">
                            <div class="shop_name" style="margin:8px 0;font-size:14px">
                                {{item.name}}
                            </div>
                            <div style="height: 1px;width: 100%;background-color: rgba(55,38,27,0.05);margin:10px auto;"></div>
                            <div class="flex" style="margin: 12px 0;font-size: 14px;">
                                <div>上架时间：{{ item.onlineTime}}</div>
                            </div>
                            <div class="flex" style="margin: 12px 0;font-size: 14px;">
                                <div>已售{{ item.sales}}件</div>
                                <div style="text-decoration: underline;">
                                    <span @click="isupFun(item)">{{ item.status == 1 ? '下架' : '上架' }}</span>
                                    <span v-if="item.type == 5 && item.status == 0" @click="editShop(item)" style="margin-left: 25px;">编辑</span>
                                    <span v-if="item.type != 5 && item.status == 0" @click="editShops(item)" style="margin-left: 25px;">编辑</span>
                                    <span @click="deleteShop(item)" style="margin-left: 25px;">删除</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div style="width: 100%;height: 12px;"></div> -->
                    <!-- <div class="box" v-if="item.type != 5" v-for="(item,index) in list">
                        <div v-if="item.type == 0" class="flex" style="justify-content: flex-end;">
                            <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style="width: 24px;height: 24px;">
                            <div class="t">秒杀</div>
                        </div>
                        <div v-if="item.type == 1" class="flex" style="justify-content: flex-end;">
                            <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style="width: 24px;height: 24px;">
                            <div class="t">分段计时</div>
                        </div>
                        <div v-if="item.type == 2" class="flex" style="justify-content: flex-end;">
                            <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style="width: 24px;height: 24px;">
                            <div class="t">减价售卖</div>
                        </div>
                        <div v-if="item.type == 3" class="flex" style="justify-content: flex-end;">
                            <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style="width: 24px;height: 24px;">
                            <div class="t">拍卖</div>
                        </div>
                        <div v-if="item.type == 4" class="flex" style="justify-content: flex-end;">
                            <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style="width: 24px;height: 24px;">
                            <div class="t">薅羊毛</div>
                        </div>
                        <div class="flex" style="margin-top: 10px;">
                            <van-image  width="110" height="90" :src="item.coverImage" @click="previewImage(item.coverImage)"/>
                        </div>
                        <div class="flex" style="margin: 12px 0;font-size: 14px;">
                            <div>已售{{ item.sold}}件</div>
                            <div>
                                <span @click="isupFun1(item)">{{ item.status == 1 ? '下架' : '上架' }}</span>
                                <span @click="editcuShop(item)" style="margin:0 25px;">编辑</span>
                                <span>切换售卖模式</span>
                            </div>
                        </div>
                        <div class="flex" style="margin: 12px 0;font-size: 12px;color: rgb(174, 133, 81);">
                            <div>上架时间：{{ item.onlineTime}}</div>
                        </div>
                        <div style="font-size: 12px;color: rgb(174, 133, 81);">
                            <span>点赞：{{ item.like}}</span>
                            <span style="margin: 0 10px;">收藏：{{ item.collect}}</span>
                            <span>评价：{{ item.evaluate}}</span>
                            <span style="margin-left: 10px;">转发：{{ item.share}}</span>
                        </div>
                    </div> -->
                    </van-list>
                </van-tab>
            </van-tabs>
            <!-- <div style="width:100%;height:20px"></div> -->
            <div style="width:100%;height:90px"></div>
        </div>
        <div  class="bottom_fixed">
            <div class="asd">
                <div class="bot" @click="addGoods">添加新商品</div>
                <div class="bot" @click="addpromotion">添加促销商品</div>
            </div>
        </div>
    </div>
</template>
<script>
import { ImagePreview , Toast,Dialog } from 'vant';
import { queryListByBrandId ,updateGoodsStatus,deleteGoods} from '@/api/goods';
export default {
    name: 'goods',
    data() {
        return {
            mar_top:'',
            list :[
            ],
            pic_fdjs:require('/src/assets/pic_fdjs.png'),
            pic_hshs:require('/src/assets/pic_hshs.png'),
            pic_msms:require('/src/assets/pic_msms.png'),
            brand_id:'',
            loading: false,
            finished: false,
            page: 1,
            pageSize: 5,
            top_navigation:[
                {id:1,val:'下架'},
                {id:2,val:'上架'}
           ],
           active: 0,
        }
    },
    methods: {
        async navigationFun(){
            this.page = 1
            this.list = []
            /* this.finished = false */
            await this.getGoodsList();
        },
        onLoad() {       
            this.page++
            setTimeout(() => {
                this.getGoodsList()
            }, 1000);
        },

        async isupFun (e){
            console.log(e); //true 上架z状态 false 下架
            if(e.status == 1) {
                var res = await updateGoodsStatus({
                    id:e.id,
                    status:0
                })
                console.log('商品下架',res);
                this.page = 1
                this.list = []
                await this.getGoodsList();
            } else  {
                const list = this.list
                const num = list.filter(item => item.status === 1 && item.type == 5).length
                console.log(num);
                if(num >= 2) {
                    // 上架商品超过两个
                    Toast.fail('最多只能上架两个商品')
                } else {
                    var res = await updateGoodsStatus({
                    id:e.id,
                    status:1
                })
                console.log('商品上架',res);
                this.page = 1
                this.list = []
                await this.getGoodsList();
                }
            }
        },
        async isupFun1 (e){
            console.log('eeeee',e); //true 上架z状态 false 下架
            if(e.status == 1) {
                var res = await updateGoodsStatus({
                    id:e.id,
                    status:0
                })
                console.log('商品下架',res);
                await this.getGoodsList();
            } else {
                if(e.type == 4) {
                    var res = await updateGoodsStatus({
                    id:e.id,
                    status:1
                })
                console.log('商品上架',res);
                await this.getGoodsList();
                } else {
                    const list = this.list
                    const num = list.filter(item => item.status === 1 && item.type != 4 && item.type != 5).length
                    console.log(num);
                    if(num >= 1) {
                        // 上架商品超过两个
                        Toast.fail('最多只能上架一个促销商品')
                    } else {
                        var res = await updateGoodsStatus({
                        id:e.id,
                        status:1
                    })
                    console.log('商品上架',res);
                    await this.getGoodsList();
                    }
                }
            }
        },
        addGoods(){
            this.$router.push({ name: 'add' ,query:{brand_id:this.brand_id}});
        },
        addpromotion(){
            this.$router.push({ name: 'promotion' ,query:{brand_id:this.brand_id}});
        },
        addanti(){
            this.$router.push({ name: 'anti' });
        },
        previewImage(key) {
            ImagePreview([key]);
            return
            const value = this[key];
            console.log(key, value);
            if (value?.length > 0) {
                ImagePreview(value);
            };
        },
        // 编辑
        editShop(e){
            console.log(e);
            if(e.status == 1) {
                Toast('请先下架商品才可以编辑')
            } else {
                this.$router.push({ name: 'add' ,params:{good_id:e.id},query:{brand_id:this.brand_id}});
            }
        },
        //促销商品编辑
        editShops(e){
            if(e.status == 1) {
                Toast('请先下架商品才可以编辑')
            } else {
                this.$router.push({ name: 'promotion' ,params:{good_id:e.id},query:{brand_id:this.brand_id}});
            }
        },
        deleteShop(e){
            console.log(e);
            Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: `是否删除此商品`,
                })
                .then(async () => {
                    const res =  await deleteGoods({goodsId:e.id})
                    console.log("删除",res);
                    if(res.code == 200) {
                        Toast('操作成功')
                        setTimeout(() => {
                            this.page = 1
                            this.list = []
                            this.getGoodsList();
                        }, 300);
                    }
                })
                .catch(() => {
                    // on cancel
                });
        },
        editcuShop(e){
            if(e.status == 1) {
                Toast('请先下架商品才可以编辑')
            } else {
                this.$router.push({ name: 'promotion' ,params:{good_id:e.id}});
            }
        },
        back(){
            this.$router.go(-1)
        },
        // 查询品牌下面的商品列表
        async getGoodsList() {
            const res = await queryListByBrandId({ 
                sysOperator: "string",
                brandId: this.brand_id,
                pageNum: this.page,
                pageSize: this.pageSize,
                status:this.active
            });
            console.log("查询品牌下面的商品列表",res);
            if(res.data) {
                this.list = [...this.list , ...res.data.records] 
                this.loading = false
                if (res.data.records.length === 0 || this.list.length == res.data.total) {
                    this.finished = true
                }
            }
        },
         //距离顶部px
         marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        this.brand_id = this.$route.query.brand_id
        this.page = 1
        this.list = []
        this.finished = false
        await this.getGoodsList();
    }
}
</script>
<style lang="less" scoped>
.bottom_fixed {
    width: 100%;
    height: 90px;
    background: #E9D4BC;
    border-top: 1px solid rgba(223,192,156,0.66);
    padding: 0 15px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    .asd {
        display: flex;
        align-items: center;
        justify-content: space-between; 
        margin-top: 7px;
    }
    .bot {
        width: 49%;
        height: 48px;
        line-height: 48px;
        border-radius: 8px;
        background: rgba(174,133,81,0.15);
        color: #37261B;
        font-size: 18px;
        text-align: center;
        font-family: PingFangSC-regular;
    }
}
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding:10px 16px;
    font-size: 16px;
    .box {
        width: 100%;
        /* margin: 10px 0; */
        margin-bottom: 20px;
        background: rgba(174,133,81,0.15);
        border-radius: 12px;
        .flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .t {
                color: rgba(174,133,81,0.97);
                font-size: 16px;
                font-family: SourceHanSansSC-bold; 
                margin-left: 4px;
            }
        }
    }
    .line {
        width: 100%;
        height: 1px;
        background-color: rgba(255,255,255,1);
        border: 1px solid rgba(174,133,81,0.97);
    }
    .line1 {
        width: 100%;
        height: 1px;
        background-color: rgba(255,255,255,1);
        border: 1px dashed rgba(174,133,81,0.97);
        margin: 4px 0;
    }
    
}
.shop_name {
    white-space: nowrap; /* 保证文本在一行内显示 */
    overflow: hidden; /* 隐藏超出容器的内容 */
    text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
    width: 100%; /* 定义容器宽度 */
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
    .rit_con {
        position: absolute;
        top: 10px;
        right: 10px;
        background: rgba(55,38,27,0.66);
        border-radius: 4px;
        color: #E9D4BC;
        padding: 2px 4px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }
</style>
