import {request} from "@/utils";
// console.log(process.env.VUE_APP_ENV,'=====')
let url
if (process.env.VUE_APP_ENV === 'test') {
    url = `https://test-api.serenetone.com/ssm-h5/api/web/Statistics/webClick`
} else {
    url = ''
}
// 请求帖子详情接口
export const getList = (params) => {
    return request({
        url: "/api/app/post/detail",
        method: "get",
        params,
    });
};
// /api/app/comment/queryByPostId
// 查看帖子评论列表
export const queryByPostId = (data) => {
    return request({
        url: "/api/app/comment/queryByPostId",
        method: "get",
        params: data,
    });
};
// /api/app/inviteMoment/list
// 查看精彩瞬间列表
export const MomentByPostId = (data) => {
    return request({
        url: "/api/app/inviteMoment/list",
        method: "get",
        params: data,
    });
};
// /api/app/inviteMember/list
// 查看邀约费用全包的用户
export const getallPayedIf = (data) => {
    return request({
        url: "/api/app/inviteMember/list",
        method: "get",
        params: data,
    });
};
//根据videoId获取vod凭证
export const getPlayauth = (videoId) => {
    return request({
        url: "/api/cpt/player/getPlayAuth?videoId=" + videoId,
        method: "get",
    });
};
//查看层级帖子评论
export const getForLevel = (data) => {
    return request({
        url: '/api/app/comment/queryForLevel',
        method: "get",
        params: data,
    });
};
// 埋点
export const webClick = (data) => {
    return request({
        url,
        method: "post",
        data,
    });
};
//请求历史的今天详情数据
export const getHistoryNewsDetail = (data) => {
    return request({
        url: '/api/app/historynews/historyNewsDetail',
        method: "get",
        params: data,
    });
};
