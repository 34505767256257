export default {
    // 判断浏览器环境
    getBrowserInfo() {
        const ua = navigator.userAgent.toLowerCase()
        const isWeixin = ua.includes('micromessenger')
        const isWeibo = ua.includes('weibo')
        const isQQ = ua.includes('qq')
        const isIOS = /iphone|ipad|ipod/.test(ua)
        const isAndroid = ua.includes('android')
        const isBaidu = /baidubrowser|baiduboxapp/.test(ua);
        return {
            isWeixin,
            isWeibo,
            isQQ,
            isIOS,
            isAndroid,
            isBaidu
        }
    },

}
