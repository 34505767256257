export function getURLParameters() {
  var params = {};
  var queryString = window.location.search;
  var regex = /[\?&]([^&]+)=([^&]+)/g;
  var match;

  while ((match = regex.exec(queryString))) {
    var paramName = decodeURIComponent(match[1]);
    var paramValue = decodeURIComponent(match[2]);
    params[paramName] = paramValue;
  }

  return params;
}
