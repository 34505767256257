import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.css';  //引入vant样式
import VConsole from 'vconsole';
import '@/plugins/vant' //按需引入vant组件的文件

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//不是测试环境的话就有调试工具
const isDebugMode = process.env.NODE_ENV !== 'production';
if (isDebugMode) {
    new VConsole(); // 初始化vconsole
}
//移动适配
import 'amfe-flexible';
import Spread from "@/components/Spread";
import OpenTag from "@/components/OpenTag";
import {callNative,exposeH5Method} from "@/utils/JSBridge"; //引入JSBridge逻辑
import '@/utils/WebViewJavascriptBridge';
// import bridge from '@/utils/WebViewJavascriptBridge';
// Vue.prototype.$bridge = bridge;
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

Vue.config.ignoredElements = ['wx-open-launch-app']
Vue.config.productionTip = false
Vue.component('Spread', Spread)  //定义成全局组件
Vue.component('OpenTag', OpenTag)  //定义成全局组件
Vue.use(ElementUI);
new Vue({
    router, render: h => h(App)
}).$mount('#app')
