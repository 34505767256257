<template>
    <div class="page" >
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="售后详情" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <van-steps inactive-color="rgba(55,38,27,0.5)" active-color="#475AD5" direction="vertical" :active="1" style="background: rgba(0, 0, 0, 0);">
                <van-step>
                    <!-- 等待商家处理审核申请信息 -->
                    <div v-if="orderinfo.status == 0 || orderinfo.status == 2 || orderinfo.status == 9">提交申请</div>
                     <!-- 商家审核申请通过 -->
                    <div v-if="orderinfo.status == 1">商家审核通过 </div>
                     <!-- 用户单号已填写 -->
                     <div v-if="orderinfo.status == 6">单号已填写,快递已寄回 </div>
                      <!-- 商家同意退款 -->
                      <div v-if="orderinfo.status == 8">提交申请 </div>
                      <!-- 用户取消退款 -->
                      <div v-if="orderinfo.status == 5">提交申请 </div>
                      <!-- 退款到账失败 -->
                      <div v-if="orderinfo.status == 4">申请退款 </div>
                      <!-- 退款到账 -->
                      <div v-if="orderinfo.status == 3">申请退款 </div>
                </van-step>
                <van-step>
                    <!-- 等待商家处理申请审核信息 -->
                    <div v-if="orderinfo.status == 0">
                        <div class="flex">
                            <div>等待商家处理中 </div>
                            <span style="font-size: 13px;margin-left: 20px;">还剩</span>
                            <van-count-down @finish="finishfun" style="color: #475AD5;margin-left: 5px;font-size: 13px;" :time="orderinfo.brandApproveRefundSecond * 1000"/>    
                        </div>
                        <div style="font-size: 13px;">待商家审核中，商家超时未处理，审核自动通过</div>
                    </div>
                    <!-- 商家拒绝申请 -->
                    <div v-if="orderinfo.status == 2">
                        <div>商家已拒绝 </div>
                        <div class="flex">
                            <span style="font-size: 13px;">原因：</span>
                            <div style="font-size: 13px;">{{ orderinfo.approveRecordFailReason.remark }}</div>   
                        </div>
                    </div>
                    <!-- 商家通过申请 -->
                    <div v-if="orderinfo.status == 1">请尽快通过并填写物流单号 
                        <div class="flex">
                            <span style="font-size: 13px;">还剩</span>
                            <van-count-down @finish="finishfun" style="color: #475AD5;margin-left: 5px;font-size: 13px;" :time="orderinfo.residueLogisticsSecond*1000"/>
                        </div>
                        <div class="flex" style="font-size: 13px;">
                            请将商品完好退回并填写单号，超时未填写，申请关闭 
                        </div>
                    </div>
                    <!-- 用户单号已填写 -->
                    <div v-if="orderinfo.status == 6">待商家收货 
                        <div class="flex" style="font-size: 13px;">
                            待商家收货后,验收商品无问题,并通过退款 
                        </div>
                    </div>
                     <!-- 商家拒绝退款 -->
                     <div v-if="orderinfo.status == 9">商家已拒收 
                        <div class="flex" style="font-size: 13px;">
                            原因： {{ orderinfo.transferMoneyFailReason.remark}}
                        </div>
                        <div class="flex" style="margin-top: 5px;" >
                            <van-image @click="previewImage(shangjiaimg)" v-for="item in shangjiaimg" width="50" height="50" :src="item"/>
                            <video class="vid" v-if="shangjiavideo" @click="playVideos" :src="shangjiavideo.videoUrl" style="width: 50px;height: 50px;"></video>
                        </div>
                    </div>
                    <!-- 商家同意退款 -->
                    <div v-if="orderinfo.status == 8">退款成功 
                        <div class="flex" style="font-size: 13px;">
                            柴门价 <span style="margin-left: 10px;color: #475AD5;font-size: 20px;">¥{{ orderinfo.amount  }}</span>
                        </div>
                    </div>
                     <!-- 用户取消退款 -->
                     <div v-if="orderinfo.status == 5">退款申请关闭 </div>
                     <!-- 退款到账失败 -->
                     <div v-if="orderinfo.status == 4">退款到账失败 </div>
                       <!-- 退款到账 -->
                       <div v-if="orderinfo.status == 3">退款已到账 </div>
                </van-step>
                <van-step v-if="orderinfo.status != 9 && orderinfo.status != 8&& orderinfo.status != 5">
                    <!-- 等待商家处理审核申请信息 -->
                    <div v-if="orderinfo.status == 0">寄回商品</div>
                    <!-- 商家拒绝/同意申请 -->
                    <div v-if="orderinfo.status == 1 || orderinfo.status == 2">待商家收货</div>
                     <!-- 用户单号已填写 -->
                     <div v-if="orderinfo.status == 6">商家退款</div>
                </van-step>
            </van-steps>
            <div style="height: 1px;width: 100%;background-color: rgba(223,192,156,0.66);margin: 10px 0;"></div>
            <!-- 商家同意申请 -->
            <div class="box" v-if="orderinfo.status == 1">
                <div class="flex" style="justify-content: space-between;">
                    <div style="color: #000;">商家收货地址</div>
                    <div class="flex" @click="copyfunaddress">
                        <van-image width="20" height="20" :src="require('/src/assets/pic_copy.png')"/>
                        <div style="color: #475AD5;margin-left: 4px;" >复制</div>
                    </div>
                </div>
                <div style="margin-top: 10px;color: rgba(55,38,27,0.5);">{{orderinfo.acceptaddress}}</div>
            </div>
            <div class="flex" style="margin: 10px 0;">
                <div>退货类型</div>
                <div style="margin-left: 30px;">退货退款</div>
            </div>
            <div class="flex" style="margin: 10px 0;">
                <div>退货原因</div>
                <div style="margin-left: 30px;">{{orderinfo.reason == 0 ? '七天无理由' : ''}}</div>
            </div>
            <div v-if="orderinfo.refundDesc" class="flex" style="margin: 10px 0;">
                <div>退货描述</div>
                <div style="margin-left: 30px;width: 240px;">{{orderinfo.refundDesc}}</div>
            </div>
            <div class="flex" style="margin: 10px 0;align-items: start;">
                <div>退货凭证</div>
                <div style="margin-left: 30px;">
                    <van-image v-for="item in shouhouimglist" @click="previewImage(shouhouimglist)"  width="76" height="76" :src="item"/>
                    <video v-if="shouhouvideo.videoUrl" :poster="shouhouvideo.videoResponse.image.url" @click="playVideo" :src="shouhouvideo.videoUrl" style="width: 76px;height: 76px;"></video>
                </div>
            </div>
            <div style="height: 1px;width: 100%;background-color: rgba(223,192,156,0.66);margin: 10px 0;"></div>
            <div class="flex" style="margin: 10px 0;">
                <div>订单编号</div>
                <div style="margin-left: 30px;">{{orderinfo.bizOrderNo}}</div>
            </div>
            <div class="flex" style="margin: 10px 0;">
                <div>申请时间</div>
                <div style="margin-left: 30px;">{{orderinfo.createTime}}</div>
            </div>
            <div style="height: 100px;"></div>
            <div class="flex p" style="justify-content: space-between;">
                <div v-if="orderinfo.status != 5 && orderinfo.status != 3" class="same b" @click="cancelfun">取消退货</div>
                <!-- 等待商家处理审核申请信息 -->
                <div v-if="orderinfo.status == 0" class="same a" @click="editreason">修改原因</div>
                <!-- 商家拒绝申请 -->
                <div v-if="orderinfo.status == 2" class="same a" @click="againapply">重新申请</div>
                 <!-- 商家同意申请 -->
                <div v-if="orderinfo.status == 1" class="same a" @click="showordernum = true">填写单号</div>
                <!-- 用户单号已填写 -->
                <div v-if="orderinfo.status == 6" class="same a" @click="kefufun">联系客服</div>
                <!-- 商家拒绝退款 -->
                <div v-if="orderinfo.status == 9" class="same a" @click="kefufun">联系客服</div>
                 <!-- 退款到账失败 -->
                 <div v-if="orderinfo.status == 4" class="same a" @click="kefufun">联系客服</div>
                  <!-- 退款成功 -->
                  <!-- <div v-if="orderinfo.status == 3 " class="same b" @click="showapply = true">申请运费</div> -->
                  <div v-if="orderinfo.status == 3" class="same a" @click="kefufun">联系客服</div>
            </div>
            <!-- 退货物流弹出框 -->
            <van-popup round v-model="showordernum" position="bottom" >
                <div class="wuliu_box">
                    <div style="font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;">填写退货单号</div> 
                    <div class="flex" style="margin: 20px 0 ;font-size: 16px;color: #000;">
                        <div>物流公司<span style="margin-left: 4px;color: #B20A09;">*</span></div>
                        <el-select class="el_selects" @change="changefun" v-model="value" filterable placeholder="请选择快递">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex" style="margin: 20px 0 ;font-size: 16px;color: #000;">
                        <div>快递单号<span style="margin-left: 4px;color: #B20A09;">*</span></div>
                        <el-input  style="width: 60%;margin-left: 20px;" class="iptql" v-model="wuliu_num" placeholder="请输入内容"></el-input>
                    </div>
                    <div class="wuliu_btn" @click="wuliu_sub">确认</div>
                </div>
            </van-popup>
            <!-- 申请运费弹出框 -->
            <van-popup round v-model="showapply" position="bottom" >
                <div class="wuliu_box">
                    <div style="font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;">申请运费</div> 
                    <div class="flex" style="margin: 20px 0 ;font-size: 16px;color: #000;">
                        <div>运费金额<span style="margin-left: 4px;color: #B20A09;">*</span></div>
                        <el-input maxlength="10" style="width: 60%;margin-left: 20px;" class="iptql" type="number" v-model="yunfei" placeholder="请输入金额"></el-input>
                    </div>
                    <div class="flex" style="margin: 20px 0 ;font-size: 16px;color: #000;">
                        <div>支付截图<span style="margin-left: 4px;color: #B20A09;">*</span></div>
                        <van-uploader :after-read="afterRead" style="margin-left: 20px;">
                            <van-image width="76" height="76" :src="imgUrl"/>
                        </van-uploader >
                    </div>
                    <div class="wuliu_btn" @click="yunfei_sub">确认</div>
                </div>
            </van-popup>
        </div> 
    </div>
</template>
<script>
import { Toast ,ImagePreview,Dialog} from 'vant';
import {queryUserOrderList ,cancelOrderRefund,deliverRefundGoods} from '@/api/myorder';
import {queryRefundListByOrder,matchComAndNum} from '@/api/order';
export default {
    name: 'myorder',
    data() {
        return {
            mar_top:'',
            imgUrl:require('/src/assets/pic_img.png'),
            showordernum:false,
            options: [
                {value:'yuantong',label:'圆通速递'},
                {value:'yunda',label:'韵达快递'},
                {value:'zhongtong',label:'中通快递'},
                {value:'jtexpress',label:'极兔速递'},
                {value:'shentong',label:'申通快递'},
                {value:'youzhengguonei',label:'邮政快递包裹'},
                {value:'shunfeng',label:'顺丰速运'},
                {value:'jd',label:'京东物流'},
                {value:'ems',label:'EMS'},
                {value:'youzhengdsbk',label:'邮政电商标快'},
                {value:'youzhengbk',label:'邮政标准快递'},
                {value:'debangkuaidi',label:'德邦快递'},
                {value:'danniao',label:'菜鸟速递'},
                {value:'xinfengwuliu',label:'信丰物流'},
                {value:'debangwuliu',label:'德邦物流'},
                {value:'zhongtongkuaiyun',label:'中通快运'},
                {value:'rrs',label:'日日顺物流'},
                {value:'jingdongkuaiyun',label:'京东快运'},
                {value:'shunfengkuaiyun',label:'顺丰快运'},
                {value:'kuayue',label:'跨越速运'},
                {value:'annengwuliu',label:'安能快运'},
                {value:'baishiwuliu',label:'百世快运'},
                {value:'sxjdfreight',label:'顺心捷达'},
                {value:'jinguangsudikuaijian',label:'京广速递'},
                {value:'yimidida',label:'壹米滴答'},
                {value:'yundakuaiyun',label:'韵达快运'},
                {value:'subida',label:'速必达'},
                {value:'usps',label:'USPS'},
                {value:'youshuwuliu',label:'优速'},
                {value:'annto',label:'安得物流'},
                {value:'pingandatengfei',label:'平安达腾飞'},
                {value:'zhongtongguoji',label:'中通国际'},
                {value:'jiayunmeiwuliu',label:'加运美'},
                {value:'cainiaodajian',label:'菜鸟大件'},
                {value:'dhl',label:'DHL-中国件'},
                {value:'savor',label:'海信物流'},
                {value:'ups',label:'UPS'},
                {value:'zhaijisong',label:'宅急送'},
                {value:'upsen',label:'UPS-全球件'},
                {value:'ztky',label:'中铁快运'},
                {value:'suning',label:'苏宁物流'},
                {value:'suteng',label:'速腾快递'},
                {value:'fedexcn',label:'Fedex-国际件-中文'},
                {value:'ewe',label:'EWE全球快递'},
                {value:'stosolution',label:'申通国际'},
                {value:'huitongkuaidi',label:'百世快递'},
                {value:'shunfenglengyun',label:'顺丰冷链'},
                {value:'fedex',label:'FedEx-国际件'},
                {value:'huisenky',label:'汇森速运'},
                {value:'exfresh',label:'安鲜达'},
                {value:'disifang',label:'递四方'},
                {value:'quantong56',label:'全通速递'},
                {value:'youzhengguoji',label:'国际包裹'},
                {value:'flextock',label:'Flextock'},
                {value:'dhlen',label:'DHL-全球件'},
                {value:'zhongtiewuliu',label:'中铁飞豹'},
                {value:'longjun',label:'龙俊物流'},
                {value:'emswuliu',label:'EMS物流'},
                {value:'chinapost',label:'中国邮政（CHINA POST）'},
                {value:'ane66',label:'安能快递'},
                {value:'yuxinwuliu',label:'宇鑫物流'},
                {value:'shpost',label:'同城快寄'},
                {value:'emsbg',label:'EMS包裹'},
                {value:'yuantongguoji',label:'圆通国际'},
                {value:'emsguoji',label:'EMS-国际件'},
                {value:'ttsucha',label:'天天速查'},
                {value:'wooolink',label:'上海领链物流'},
            ],
            value: '',
            wuliu_name:'',
            wuliu_num:'',
            order_id:'',
            info:{},
            jjimg:[],
            wuliu_code:'',
            showapply:false,
            yunfei:'',
            orderinfo:'',
            shouhouimglist:[],
            shouhouvideo:{},
            shangjiaimg:[],
            shangjiavideo:{}
        }
    },
    methods: {
        afterRead(e){
            console.log('上传支付截图',e);
            
        },
        yunfei_sub(){
            console.log(this.imgUrl);
            
        },
        playVideo(){
            this.$el.querySelector('video').play()
        },
        playVideos(){
            this.$el.querySelector('.vid').play()
        },
        // 物流公司选择
        changefun(e){
            console.log(e);
            this.wuliu_code = e
            for (let i = 0; i < this.options.length; i++) {
                if(this.options[i].value == e) {
                    this.wuliu_name = this.options[i].label
                }
            }
            console.log("物流公司名称",this.wuliu_name);
        },
        // 退货物流确认
        async wuliu_sub (){
            if(this.wuliu_name == '') {
                Toast('请选择物流公司')
            } else if (this.wuliu_num == '') {
                Toast('请填写物流单号')
            } else {
                console.log(this.wuliu_name,this.wuliu_num,this.wuliu_code);
                
                const res = await matchComAndNum({ 
                    com: this.value,
                    num: this.wuliu_num,
                });
                console.log("检查物流单号和物流公司是否匹配",res);
                if(res.data) {
                    const res1 = await deliverRefundGoods({ 
                        refundId: this.orderinfo.id,
                        logisticsNumber: this.wuliu_num,
                        logisticsCompany: this.wuliu_code
                    });
                    console.log("用户退货物流提交",res1);
                    if(res1.code == 200) {
                        this.showordernum = false
                        Toast('操作成功')
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 300);
                    }
            } else {
                Toast('物流单号和快递公司不匹配')
            }
        }
        },
        // 复制文本
        async copyfunaddress(){
            if (navigator.clipboard) {
                const textToCopy =this.orderinfo.acceptaddress;
                await navigator.clipboard.writeText(textToCopy);
                Toast('复制成功')
                console.log("文本已复制到剪贴板");
            } else {
                const textarea = document.createElement('textarea');
                textarea.value = this.orderinfo.acceptaddress;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
                Toast('复制成功')
                console.log('Text copied using fallback method');
            }
        },
        // 联系客服
        kefufun(){

        },
        // 倒计时结束
        finishfun(){

        },
        // 取消退货
        cancelfun(){
            Dialog.confirm({
                title: '提示',
                confirmButtonColor:'#475AD5',
                message: '是否确认取消退货',
                })
                .then(() => {
                    const res =  cancelOrderRefund({orderId:this.orderinfo.id})
                    console.log("取消退货/取消退款接口",res);
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 300);
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 修改原因
        editreason(){
            this.$router.replace({ name: 'applys' ,params:{info:this.info}})
        },
        // 重新申请
        againapply(){
            this.$router.replace({ name: 'apply' ,params:{info:this.info,isfirst:2}})
        },
        previewImage(key) {
            if(Array.isArray(key)) {
                ImagePreview(key);
            } else {
                ImagePreview([key]);
            }
        },
        back() {
            this.$router.go(-1);
        },
        async RefundListByOrder(){
            const res = await queryRefundListByOrder({ 
                orderId:this.info.id
            });
            console.log("查询订单下面的退款信息111",res);
            //用户退款图片
            if(res.data.image) {
                this.shouhouimglist = res.data.image.split('#####')
            }
            if(res.data.videoResponse) {
                this.shouhouvideo = res.data.videoResponse
            }
            //商家拒绝退款图片
            if(res.data.transferMoneyFailReason) {
                if(res.data.transferMoneyFailReason.image) {
                    this.shangjiaimg = res.data.transferMoneyFailReason.image.split('#####')
                }
                if(res.data.transferMoneyFailReason.videoResponse) {
                    this.shangjiavideo = res.data.transferMoneyFailReason.videoResponse
                }
            }
            if(res.data.orderAddressDTO.province != null) {
                res.data.acceptaddress = res.data.orderAddressDTO.province+res.data.orderAddressDTO.city+res.data.orderAddressDTO.district+res.data.orderAddressDTO.address+res.data.orderAddressDTO.name
            }
            this.orderinfo = res.data
            /* this.jjimg = res.data.transferMoneyFailReason.image.split(',')
            console.log("jjimg",this.jjimg); */
            
        },
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log("this.$router.parmars",this.$route.params);
        this.info = this.$route.params.info
        await this.RefundListByOrder();
    }
}
</script>
<style lang="less" scoped>
.van-step--vertical:not(:last-child)::after {
    border-bottom-width:0
}
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .box {
        padding: 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 7px 0;
    }
    .flex {
        display: flex;
        align-items: center;
        color: rgba(55,38,27,0.66);
        .img {
           width: 24px;
           height: 24px;
           border-radius: 12px; 
        }
        .imgs {
            width: 60px;
            height: 60px;
            border-radius: 8px; 
        }
        .goodsn {
            width: 190px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .t {
            // width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .same {
            padding: 5px 15px;
            box-sizing: border-box;
            border-radius: 8px;
        }
        .cancel {
            border: 1px solid rgba(55,38,27,0.33);
            color: rgba(55,38,27,0.5);
        }
        .sub {
            background: #876235;
            color: #fff;
        }
        .recive {
            background-color: #876235;
            color: #fff;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tosat {
            width: 300px;
            height: 180px;
            border-radius: 8px;
            background-color: #E9D4BC;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }
    .res {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        height: 52px;
        border-radius: 8px;
        background-color: rgba(174,133,81,0.15);
        margin-top: 10px;
    }
    .ipt {
        border: none;
        background: rgba(0, 0, 0, 0);
    }
    .te {
        border-radius: 8px;
        margin: 10px 0;
        background-color: rgba(174,133,81,0.15);
    }
    .p {
        width: 94%;
        position: fixed;
        left:50%;
        bottom: 50px;
        transform: translate(-50%,0);
    }
    .same {
        
        width: 48%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        font-size: 18px;
    }
    .a {
        background-color: #876235 ;
        color: #fff;
    }
    .b {
        background-color: #E0C8AC;
        color: #000;
    }
    .wuliu_box {
        height: 300px;
        padding: 20px;
        box-sizing: border-box; 
        background-color: #E9D4BC;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .el_selects {
        width: 60%;
        margin-left: 20px;
    }
    .el_selects /deep/ .el-input__inner {
        background: rgba(174,133,81,0.15);  /* 背景色 */
        border-color: rgba(174,133,81,0.15); /* 边框色 */
        color: 000 /* 文字颜色 */ 
        /* 添加其他需要修改的样式 */
    }
    /deep/ .el-select .el-input__inner::placeholder,
    /deep/ .el-date-picker .el-input__inner::placeholder,
    /deep/ .el-input .el-input__inner::placeholder {
        color:#A8937C;
    }
    .iptql /deep/ .el-input__inner{
        background: rgba(174,133,81,0.15);  /* 背景色 */
        border-color: rgba(174,133,81,0.15); /* 边框色 */
        color: 000 /* 文字颜色 */ 
    }
    .wuliu_btn {
        width: 100%;
        height: 48px;
        border-radius: 12px;
        font-size: 18px;
        background-color: #876235 ;
        color: #fff;
        text-align: center;
        line-height: 48px;
        margin: 20px auto;
    }
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
