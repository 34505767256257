<template>
    <div>
        <van-sticky>
            <van-nav-bar left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <div class="flex">
                <div class="title">商品名：</div>
                <input class="ipt" type="text" v-model="goods.name">
            </div>
            <div class="flex">
                <div class="title">类型：</div>
                <div style="display: flex;">
                    <div @click="typeFun(item.id)" v-for="item in type" :key="item.id" :class="`type_item ${item.id == goods.type ? 'active' : ''}`">{{ item.value }}</div>
                </div>
                <van-field v-if="goods.type != 1"  readonly clickable class="lx" :value="goods.businessCategory" label="" right-icon="arrow-down" @click="showPicker = true" />
                <van-popup v-model="showPicker" position="bottom">
                    <van-picker show-toolbar :columns="columns" value-key="val" @confirm="onConfirm" @cancel="showPicker = false" />
                </van-popup>
            </div>
            <div class="flex">
                <div class="title">商品分类：</div>
                <div style="display: flex;width: 275px;flex-wrap: wrap;">
                    <div @click="classFun(item)" v-for="item in goodsclass" :key="item.id" :class="`type_item ${goods.goodsclassvalue.indexOf(item.value) != -1 ? 'active' : ''}`">{{ item.value }}</div>
                </div>
            </div>
            <div class="flex">
                <div class="title">商品品类：</div>
                <div style="display: flex;">
                    <div @click="cateFun(item)" v-for="item in cate" :key="item.id" :class="`type_item ${item.value == goods.cate ? 'active' : ''}`">{{ item.value }}</div>
                </div>
            </div>
            <div class="flex">
                <div class="title">商品价格：</div>
                <input class="ipt" @input="shop_price" :value="goodsPriceInfo.price"  type="number">
            </div>
            <!-- <div class="flex">
                <div class="title">库存：</div>
                <div style="display: flex;align-items: center;">
                    <div @click="goods.inventory > 1 ? goods.inventory-- : false">—</div>
                        <input type="number" v-model="goods.inventory" style="width: 76px;height: 31px;text-align: center;border: none;">
                    <div @click="goods.inventory++">+</div>
                </div>
            </div> -->
            <!-- <div class="flex" style="margin: 10px 0;">
                <div class="title">尺寸：</div>
                <div class="flex" style="flex-wrap: wrap;width: 275px;margin-bottom: 10px;">
                    <div v-for="item in goods.size" class="sc" style="margin-top: 5px;margin-right: 10px;">
                        {{ item }} 
                        <div @click="sizeFun(item)" class="close">x</div>
                    </div>
                    <van-button @click="addsize" style="" icon="plus" size="mini" />
                </div>
            </div>
            <div class="flex" style="margin-bottom: 10px;">
                <div class="title">颜色：</div>
                <div class="flex" style="margin-right: 10px;flex-wrap: wrap;width: 275px;">
                    <div v-for="item in goods.color" class="sc" style="margin-top: 5px;margin-right: 10px;">
                        {{ item }}
                        <div @click="colorFun(item)" class="close">x</div>
                    </div>
                    <van-button @click="addcolor" style="" icon="plus" size="mini" />
                </div>
            </div> -->

            <!-- 默认规格 -->
            <div v-for="item in default_guige" class="flex" style="margin: 10px 0;">
                <div class="title">{{item.name}}：</div>
                <div class="flex" style="flex-wrap: wrap;width: 275px;margin-bottom: 10px;">
                    <div @click="selectGuige(items)" v-for="items in item.itemList"  :class="`sc ${kucunclass.indexOf(items.id) != -1 ? 'active' : ''}`"  style="margin-top: 5px;margin-right: 10px;">
                        {{ items.name }} 
                        <!-- <div @click="sizeFun(items)" class="close">x</div> -->
                    </div>
                    <van-button @click.stop="addzigg(item)" style="" icon="plus" size="mini" />
                </div>
            </div>
            <!-- 设置库存 -->
            <div v-if="this.result.length >=2" class="flex">
                <div class="title">设置库存</div>
            </div>
            <div v-for="item in result" class="flex" style="margin: 10px 0;justify-content: space-between;">
                <div>{{item.name}}：</div>
                <div style="display: flex;align-items: center;">
                    <div @click="item.inventory > 1 ? item.inventory-- : false">—</div>
                        <input type="number" v-model="item.inventory" style="width: 76px;height: 31px;text-align: center;border: none;">
                    <div @click="item.inventory++">+</div>
                </div>

            </div>

            <div v-for="item in goods.customize" class="flex" style="margin-bottom: 10px;">
                <div class="title">{{item.name}}：</div>
                <div class="title" v-for="items in item.value">{{items}}</div>
            </div>

            <div v-if="default_guige.length < 3" class="flex" style="margin: 10px 0;">
                <div class="guige" @click="zdyFun">自定义规格</div>
            </div>
            <div class="guige_box" v-if="showguige">
                <div class="flex">
                    <div class="title">规格名称：</div>
                    <input v-model="guige.name" :disabled="guige_name ? false : true" type="text" style="width: 70px;height: 30px;">
                </div>
                <div class="flex" style="margin-top: 20px;align-items: start;">
                    <div class="title">规格选项：</div>
                    <div style="display: flex;align-items: center;flex-wrap: wrap;width: 240px;">
                        <input v-for="(item,index) in guige.value"  @input="iptfun(index,$event)" :value="item" type="text" style="width: 70px;height: 30px;margin-right: 5px;margin-top: 4px;">
                        <div @click="addFun" ><van-button icon="plus" size="mini" /></div>
                    </div>
                </div>
                <div class="flex" style="justify-content: space-around;margin-top: 10px;">
                    <div @click="showguige = false" style="color: #887C7C;">取消</div>
                    <div @click="addFun1">保存</div>
                </div>
            </div>
            <!-- <div class="flex" style="align-items: self-start;">
                <div class="title">产品参数：</div>
                <table border="1" style="border-collapse: collapse;width: 270px;">
                    <tr>
                        <td>品牌</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>适用年龄</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>厚薄</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>材质成分</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>适用性别</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>颜色分类</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>适用身高</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>产地</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>工艺</td>
                        <td></td>
                    </tr>
                </table>
            </div> -->
            <div class="flex">
                <div class="title">发货时间：</div>
                <div class="flex">
                    <div @click="sentid = 1" :class="`send ${sentid == 1 ? 'acsend' : ''}`">24小时内</div>
                    <div @click="sentid =2" :class="`send ${sentid == 2 ? 'acsend' : ''}`" style="margin:0 10px;">48小时内</div>
                    <input @focus="sentid = 3" @input="senrfun" type="text" :value="goods.sendtime" placeholder="自定义" style="width: 70px;height: 26px;line-height: 26px;text-align: center;border: 1px solid rgba(187,187,187,1);">
                </div>
            </div>
            <div class="flex">
                <div class="title">运费：</div>
                <van-field  readonly clickable class="lx" :value="goods.shipval" label="" right-icon="arrow-down" @click="showPickership = true" />
                <van-popup v-model="showPickership" position="bottom">
                    <van-picker show-toolbar :columns="shiplist" value-key="value" @confirm="onConfirmship" @cancel="showPickership = false" />
                </van-popup>
            </div>
            <div class="flex" style="margin: 10px 0;">
                <div class="title">产品介绍：</div>
                <input class="ipt" type="text" v-model="goods.des">
            </div>
            <div class="flex" style="align-items: self-start;">
                <div class="title">封面图：</div>
                <el-upload
                    class="avatar-uploader"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    >
                    <img v-if="goods.imageUrl" :src="goods.imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon">16:9封面</i>
                </el-upload>
            </div>
            <div class="flex" style="align-items: self-start;">
                <div class="title">详情图  ：</div>
                <el-upload
                    class="avatar-uploader"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    >
                    <img v-if="goods.imageUrl" :src="goods.imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon">4:3正面</i>
                </el-upload>
                <el-upload
                    style="margin: 0 6px;"
                    class="avatar-uploader"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    >
                    <img v-if="goods.imageUrl" :src="goods.imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon">4:3侧面</i>
                </el-upload>
                <el-upload
                    class="avatar-uploader"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    >
                    <img v-if="goods.imageUrl" :src="goods.imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon">4:3俯视</i>
                </el-upload>
            </div>
            <div class="flex" style="align-items: self-start;">
                <div class="title">分享图：</div>
                <el-upload
                    class="avatar-uploader"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    >
                    <img v-if="goods.imageUrl" :src="goods.imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon">1:1分享</i>
                </el-upload>
            </div>
            <div class="flex">
                <div class="title">视频</div>
                <div class="vid">视频</div>
                <!-- <video src="" ></video> -->
            </div>
            <div class="flex" style="justify-content: center;color: rgba(190,186,186,1);font-size: 14px;font-family: PingFangSC-regular;">
                最多只能上架两款商品
            </div> 
            <div class="flex">
                <div class="save" @click="save">保存</div>
                <div class="sent" @click="submitFun">发布</div>
            </div>
            <div style="height: 80px;"></div>
        </div> 
    </div>
</template>
<script>
import { Toast } from 'vant';
import { findList ,addSpecification,addSpecificationItem,saveGoodsInfo,queryGoodsInfo,updateGoodsInfo} from '@/api/goods';
export default {
    name: 'goods',
    data() {
        return {
        resultnum:0,
        kucun :[],
        result:[],
        sentid:'',
        kucunclass:[],
            default_guige:[],
            goods_id:'',
            goods:{
                name:'',
                type:1,
                businessCategory: "一成新",
                businessCategoryid:1,
                goodsclass:[],
                goodsclassvalue:[],
                cate:1,
                sales:1,
                price:'',
                inventory:2,
                ship:0,
                shipval:'免运费',
                des:'',
                imageUrl: '',
                customize:[],
                size:['13厘米','15厘米','18厘米'],
                color:['红色','蓝色'],
                sendtime:''
            },
            guige:{
                name:"",
                value:[]
            },
            showguige:false,
            showPicker: false,
            showPickership: false,
            columns: [
                {id:1,val:'一成新'},
                {id:2,val:'二成新'},
                {id:3,val:'三成新'}
            ],
            type:[
                    {id:1,value:'全新'},
                    {id:2,value:'老物件'},
                ],
            shiplist:[
                {id:0,value:'免运费'},
                {id:1,value:'动态运费'},
            ],  
            sales:[
                {id:1,value:'秒杀'},
                {id:2,value:'分段计时'},
                {id:3,value:'减价售卖'},
                {id:4,value:'拍卖'}
            ],
            goodsclass:[
                    {id:1,value:'汉服'},
                    {id:2,value:'手工艺'},
                    {id:3,value:'非遗'},
                    {id:4,value:'茶道'},
                    {id:5,value:'字画'},
                    {id:6,value:'乐器'},
                    {id:7,value:'其他'}
                ],
            cate:[
                {id:1,value:'唐朝'},
                {id:2,value:'秦朝'},
            ],
            guige_name:'',
            guige_zi_id:'',
            kucun:[],
            ziid:[],
            inid:'',
            // 库存信息数据
            skuSpecification: [
                {
                    "itemIdList": [
                        1,
                        4
                    ],
                    "price": 568.45,
                    "inventory": 59
                },
                {
                    "itemIdList": [
                        2,
                        4
                    ],
                    "price": 812.19,
                    "inventory": 26
                }
            ],
            goodsPriceInfo: {
                price: '',
                "countryPrice": 589.29,
                "ssmPrice": 115.49,
                "secKillPrice": 965.55,
                "startPrice": 454.59,
                "startTime": "",
                "endTime": "",
                "salePrice": 888.95,
                "reduceCyclePrice": 675.89,
                "reduceCycleType": 76,
                "goodsPiecewiseList": [
                    {
                        "price": 618.89,
                        "inventory": 55,
                        "startTime": "",
                        "endTime": ""
                    },
                    {
                        "price": 4.7,
                        "inventory": 51,
                        "startTime": "",
                        "endTime": ""
                    }
                ]
            },
        }
    },
    methods: {
        // arr 目标对象数组
        // filed 分组字段
        classifyArrayGroupBySameFieldAlpha(arr, filed) {
            let temObj = {}
            for (let i = 0; i < arr.length; i++) {
                let item = arr[i]
                if (!temObj[item[filed]]) {
                    temObj[item[filed]] = [item]
                } else {
                    temObj[item[filed]].push(item)
                }
            }
            let resArr = []
            Object.keys(temObj).forEach(key => {
                resArr.push({
                    key: key,
                    data: temObj[key],
                })
            })
            return resArr
        },

        // 子规格选中
        selectGuige(e){
            console.log('this.result',this.result,this.resultnum);
            console.log("eeee",e);
            if(this.kucunclass.indexOf(e.id) == -1) {
                    this.kucun.push(e)
                    this.kucunclass.push(e.id)
                } else {
                    this.kucun=this.kucun.filter(item=>item.id != e.id)
                    this.kucunclass=this.kucunclass.filter(item=>item != e.id)
                    for (let i = 0; i < this.result.length; i++) {
                            this.result = this.result.filter(item=>item.itemIdList.indexOf(e.id) == -1)
                    }
                }
             const res = this.classifyArrayGroupBySameFieldAlpha(this.kucun,'specificationId')
             console.log("kucun",this.kucun);
             console.log("res111",res);
             console.log("kucunclass",this.kucunclass);
             if(res.length == 2) {
                let result = this.result;
                for (let i = 0; i < res[0].data.length; i++) {
                    for (let j = 0; j < res[1].data.length; j++) {
                            let combined = {name:'',itemIdList:'',inventory:1};
                            combined.name = res[0].data[i].name + '/' + res[1].data[j].name
                            combined.itemIdList = res[0].data[i].id + ',' + res[1].data[j].id
                            var a = result.some(item => item.name === combined.name)
                            if(!a) {
                                result.push(combined)
                            } 
                        }
                    }
                // if(Array.isArray(result[0].itemIdList)) {
                    for (let i = 0; i < result.length; i++) {
                        if(!Array.isArray(result[i].itemIdList)) {
                            result[i].itemIdList = result[i].itemIdList.split(",")
                            result[i].itemIdList = result[i].itemIdList.map(num => +num)
                        }
                       
                    }
                // } else {
                //     for (let i = 0; i < result.length; i++) {
                //        result[i].itemIdList = result[i].itemIdList.split(",")
                //        result[i].itemIdList = result[i].itemIdList.map(num => +num)
                //     }
                // }
                this.result = result
                
                console.log("result",result);

             } else if(res.length == 3) {
                console.log(res,'三种规格');
                let result = [];
                for (let i = 0; i < res[0].data.length; i++) {
                    for (let j = 0; j < res[1].data.length; j++) {
                        for (let k = 0; k < res[2].data.length; k++) {
                            let combined = {name:'',itemIdList:'',inventory:1};
                            combined.name = res[0].data[i].name + '/' + res[1].data[j].name+ '/' + res[2].data[k].name
                            combined.itemIdList = res[0].data[i].id + ',' + res[1].data[j].id+ ',' + res[2].data[k].id
                            result.push(combined)
                        }
                    }
                }
                console.log("result333",result);
                for (let i = 0; i < result.length; i++) {
                   result[i].itemIdList = result[i].itemIdList.split(",")
                   result[i].itemIdList = result[i].itemIdList.map(num => +num)
                }
                this.result = result
                
                console.log("result",result);
                
             }
           
        // }
    },
        // 新增子规格
        addzigg(e){
            console.log("新增zigg",e);
            this.showguige = true
            this.guige = {name:e.name,value:[]}
            this.guige_name = false
            this.guige_zi_id = e.id
            
        },
        // 新增规格
        zdyFun(){
            this.showguige = true
            this.guige = {name:"",value:[]}
            this.guige_name = true
            console.log(this.guige);
        },
        addFun(){
            this.guige.value.push('')
        },
        async addFun1(){
            console.log(this.guige);
            if(this.guige_name) {
                console.log("自定义");
                var res = await addSpecification({
                    name:this.guige.name,
                    subNameList:this.guige.value
                });
                console.log("新增规格",res);
                for (let i = 0; i < res.data.subItemList.length; i++) {
                    res.data.subItemList[i].specificationId = res.data.id
                }
                console.log("新增规格111",res);
                
                var data = {
                    id:res.data.id,
                    name:res.data.name,
                    itemList:res.data.subItemList
                }
                this.default_guige.push(data)
                // await this.getggList();

            } else {
                console.log("no自定义",this.guige_zi_id,this.guige);
                console.log(this.default_guige);
                if(this.guige_zi_id != 1 && this.guige_zi_id != 2) {
                    var res = await addSpecificationItem({
                        specificationId:this.guige_zi_id,
                        nameList:this.guige.value
                    })
                    for (let i = 0; i < res.data.length; i++) {
                        res.data[i].specificationId = this.guige_zi_id
                        this.default_guige[2].itemList.push(res.data[i])
                    }
                    console.log("新增自定义子规格",res);
                } else {
                    var res = await addSpecificationItem({
                        specificationId:this.guige_zi_id,
                        nameList:this.guige.value
                    })
                    console.log("新增子规格",res);
                    await this.getggList();
                }
            }
            this.showguige = false
            
        },
        sizeFun(e){
            console.log(e);
            this.goods.size = this.goods.size.filter(item => {
                return item != e
            })
        },
        colorFun(e){
            console.log(e);
            this.goods.color = this.goods.color.filter(item => {
                return item != e
            })
        },
        iptfun(index,e){
            console.log(index,e);
            this.guige.value[index] = e.target.value
        },
        addsize(){
            this.showguige = true
            this.guige = {name:"尺寸",value:this.goods.size}
            this.guige_name = false
        },
        addcolor(){
            this.showguige = true
            this.goods.color = this.goods.color.filter(item=> {
                  return item != ''
                }
            )
            this.guige = {name:"颜色",value:this.goods.color}
            this.guige_name = false
        },
        shop_price(e){
            this.goodsPriceInfo.price = parseFloat(e.target.value)
            console.log(this.goodsPriceInfo.price );

        },
        senrfun(e){
            this.goods.sendtime = e.target.value
        },
        // 类型
        typeFun(e){
            console.log(e);
            this.goods.type = e
        },
        // 运费
        ship(e){
            console.log(e);
            this.goods.ship = e
        },
        onConfirmship(e){
            this.goods.shipval = e.value;
            this.goods.ship = e.id;
            this.showPickership = false;
            console.log(this.goods);

        },
        onConfirm(value) {
            console.log(value);
            this.goods.businessCategory = value.val;
            this.goods.businessCategoryid = value.id;
            this.showPicker = false;
            
        },
        // 分类
        classFun(e){
            console.log(e);
            console.log(this.goods.goodsclassvalue);
            
            if (this.goods.goodsclassvalue.indexOf(e.value) != -1) {
            this.goods.goodsclass.splice(this.goods.goodsclass.indexOf(e.id), 1); //取消
            this.goods.goodsclassvalue = this.goods.goodsclassvalue.filter(item=>item!=e.value)
            } else {
            this.goods.goodsclass.push(e.id);//选中添加到数组里
            this.goods.goodsclassvalue.push(e.value);//选中添加到数组里
            }
            },
        // 品类
        cateFun(e){
            this.goods.cate = e.value
        },
        // 售卖模式
        salesFun(e){
            this.goods.sales = e
        },
        back() {
            this.$router.go(-1);
        },
        handleAvatarSuccess(res, file) {
            console.log("res",res);
      },
    //   发布
    async submitFun(){
        console.log("this.goodsPriceInfo",this.goodsPriceInfo);
        // this.goodsPriceInfo.price = this.goodsPriceInfo.price.toFixed(2)
        console.log("this.good_id",this.goods_id);
        var a = this.goodsPriceInfo
        var pricedate = {
            price: a.price
        }
        var data = {
            brandId:2,
            name:this.goods.name,
            category:this.goods.goodsclassvalue.join(","),
            url:'https://img01.yzcdn.cn/vant/cat.jpeg',
            video:'https://img01.yzcdn.cn/vant/cat.jpeg',
            coverImage:"https://img01.yzcdn.cn/vant/cat.jpeg",
            status:1,
            type:4,
            brandCategory:this.goods.cate,
            introduce:this.goods.des,
            newType:this.goods.type == 1 ? 0 : this.goods.businessCategoryid,
            skuSpecification:this.result,
            goodsPriceInfo:pricedate,
            deliveryTime:this.sentid == 1 ? '24小时之内' : this.sentid == 2 ? '48小时之内' : this.goods.sendtime,
            freightType:this.goods.ship
        }
        var data1 = {
            id:this.goods_id,
            name:this.goods.name,
            category:this.goods.goodsclassvalue.join(","),
            url:'https://img01.yzcdn.cn/vant/cat.jpeg',
            video:'https://img01.yzcdn.cn/vant/cat.jpeg',
            coverImage:"https://img01.yzcdn.cn/vant/cat.jpeg",
            type:5,
            brandCategory:this.goods.cate,
            introduce:this.goods.des,
            newType:this.goods.type == 1 ? 0 : this.goods.businessCategoryid,
            skuSpecification:this.result,
            goodsPriceInfo:pricedate,
            deliveryTime:this.sentid == 1 ? '24小时之内' : this.sentid == 2 ? '48小时之内' : this.goods.sendtime,
            freightType:this.goods.ship
        }
        
        if(this.goods_id == undefined || this.goods_id == '') {
            var res = await saveGoodsInfo(data);
            console.log("保存商品",res);

        } else {
            var res = await updateGoodsInfo(data1);
            console.log("编辑商品",res);
        }
        if(res.code == 200) {
            Toast('保存成功'),
            setTimeout(() => {
                this.$router.go(-1);
            }, 500);
        } else {
            Toast(res.msg)
        }
    },
    //   保存商品
    async save(){
        console.log("this.goodsPriceInfo",this.goodsPriceInfo);
        // this.goodsPriceInfo.price = this.goodsPriceInfo.price.toFixed(2)
        console.log("this.good_id",this.goods_id);
        var a = this.goodsPriceInfo
        var pricedate = {
            price: a.price
        }
        var data = {
            brandId:2,
            name:this.goods.name,
            category:this.goods.goodsclassvalue.join(","),
            url:'https://img01.yzcdn.cn/vant/cat.jpeg',
            video:'https://img01.yzcdn.cn/vant/cat.jpeg',
            coverImage:"https://img01.yzcdn.cn/vant/cat.jpeg",
            status:0,
            type:4,
            brandCategory:this.goods.cate,
            introduce:this.goods.des,
            newType:this.goods.type == 1 ? 0 : this.goods.businessCategoryid,
            skuSpecification:this.result,
            goodsPriceInfo:pricedate,
            deliveryTime:this.sentid == 1 ? '24小时之内' : this.sentid == 2 ? '48小时之内' : this.goods.sendtime,
            freightType:this.goods.ship
        }
        var data1 = {
            id:this.goods_id,
            name:this.goods.name,
            category:this.goods.goodsclassvalue.join(","),
            url:'https://img01.yzcdn.cn/vant/cat.jpeg',
            video:'https://img01.yzcdn.cn/vant/cat.jpeg',
            coverImage:"https://img01.yzcdn.cn/vant/cat.jpeg",
            type:5,
            brandCategory:this.goods.cate,
            introduce:this.goods.des,
            newType:this.goods.type == 1 ? 0 : this.goods.businessCategoryid,
            skuSpecification:this.result,
            goodsPriceInfo:pricedate,
            deliveryTime:this.sentid == 1 ? '24小时之内' : this.sentid == 2 ? '48小时之内' : this.goods.sendtime,
            freightType:this.goods.ship
        }
        
        if(this.goods_id == undefined || this.goods_id == '') {
            var res = await saveGoodsInfo(data);
            console.log("保存商品",res);

        } else {
            var res = await updateGoodsInfo(data1);
            console.log("编辑商品",res);
        }
        if(res.code == 200) {
            Toast('保存成功'),
            setTimeout(() => {
                this.$router.go(-1);
            }, 500);
        } else {
            Toast(res.msg)
        }

    },
      // 查询品牌下面的商品列表
      async getggList() {
            const res = await findList({});
            console.log("查询规格列表",res);
            if(res.data) {
                this.default_guige = res.data
            }
        }
    },
    computed:{
        isObjectEmpty() {
            for (let key in this.$route.params) {
                if (this.$route.params.hasOwnProperty(key)) {
                return false;
                }
            }
            return true;
        }
    },
    async mounted() {
        console.log(111,this.$route);
        await this.getggList();

        // 查询商品详情
        if(!this.isObjectEmpty) {
            let goodsclass = []
            let kucun = []
            let result = [];
            this.goods_id = this.$route.params.good_id
            const res = await queryGoodsInfo(this.$route.params.good_id);
                console.log("查询商品信息",res);
                this.goods.name = res.data.name,
                this.goods.goodsclassvalue = res.data.category.split(','),
                this.goods.cate = res.data.brandCategory,
                this.goods.type = res.data.newType == 0 ? 1 : 2
                this.goods.des = res.data.introduce,
                this.goods.ship = res.data.freightType,
                this.sentid = res.data.deliveryTime == '24小时之内' ? 1 :res.data.deliveryTime == '48小时之内' ? 2 : ''
                this.goods.sendtime = res.data.deliveryTime != '24小时之内' && res.data.deliveryTime != '48小时之内' ? res.data.deliveryTime : '自定义'
                this.goods.shipval = this.shiplist[res.data.freightType].value
                this.goodsPriceInfo= res.data.goodsPriceInfo,
                this.goods.businessCategoryid = res.data.newType == 0 ? '' : res.data.newType
                this.goods.businessCategory = res.data.newType == 0 ? '' : this.columns[res.data.newType - 1].val
                this.goods.imageUrl = res.data.url,
                this.result = res.data.skuSpecification
                
                for (let i = 0; i < res.data.skuSpecification.length; i++) {
                    for (let j = 0; j < res.data.skuSpecification[i].items.length; j++) {
                        res.data.skuSpecification[i].name = res.data.skuSpecification[i].items.map(item => item.name).join('/'); 
                        res.data.skuSpecification[i].itemIdList = res.data.skuSpecification[i].items.map(item => item.itemId).join(','); 
                        res.data.skuSpecification[i].itemIdList = res.data.skuSpecification[i].itemIdList.split(',')
                        res.data.skuSpecification[i].itemIdList = res.data.skuSpecification[i].itemIdList.map(num => +num)
                    }
                    
                }
                for (let i = 0; i < res.data.skuSpecification.length; i++) {
                    for (let j = 0; j < res.data.skuSpecification[i].itemIdList.length; j++) {
                        goodsclass.push(res.data.skuSpecification[i].itemIdList[j])
                    }
                }
                this.kucunclass = Array.from(new Set(goodsclass))

                for (let i = 0; i < this.default_guige.length; i++) {
                    for (let j = 0; j < this.default_guige[i].itemList.length; j++) {
                        for (let k = 0; k < this.kucunclass.length; k++) {
                            if(this.default_guige[i].itemList[j].id == this.kucunclass[k])
                            kucun.push(this.default_guige[i].itemList[j])
                        }
                    }
                }

                this.kucun = kucun
                console.log("查询选中子规格对象",kucun);
                console.log("查询选中子规格",this.kucunclass);

                console.log("查询商品信息1",res);
                if(res.data.specificationInfoItemListResponses.length == 3) {
                    var zdy = {
                        id:res.data.specificationInfoItemListResponses[2].specificationId,
                        name:res.data.specificationInfoItemListResponses[2].specificationName,
                        itemList:res.data.specificationInfoItemListResponses[2].specificationItemList
                    }
                    this.default_guige.push(zdy)
                }
                this.result = res.data.skuSpecification
                this.resultnum = res.data.skuSpecification.length 

                console.log(this.goods);
                
        }
    }
}
</script>
<style lang="less" scoped>
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .flex {
        display: flex;
        align-items: center;
        margin:2px 0;
        .send {
            border: 1px solid rgba(187,187,187,1);
            padding: 2px 4px;
            box-sizing: border-box;
        }
        .acsend {
            color: #fff;
            background-color: rgba(240,154,25,1);
            border: 1px solid rgba(240,154,25,1);
        }
        .sc {
            font-size: 14px;
            padding: 4px 6px;
            min-width: 50px;
            text-align: center;
            box-sizing: border-box;
            border-radius: 8px;
            background-color: rgba(247,241,241,1);
            margin-right: 4px;
            position: relative;
            .close {
                position: absolute;
                top: -5px;
                right: -5px;
                width: 16px;
                height: 16px;
                line-height: 12px;
                text-align: center;
                border-radius: 8px;
                border: 1px solid #A39F9F;
                color: #A39F9F;
                font-size: 12px;
            }
        }
        .title {
            width: 75px;
        }
        .lx {
            width: 136px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background-color: rgba(255,255,255,1);
            color: rgba(16,16,16,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            border: 1px solid rgba(187,187,187,1);
        }
        .ipt {
            width: 275px;
            height: 28px;
            background-color: rgba(255,255,255,1);
            border: 1px solid rgba(187,187,187,1);
            padding-left: 10px;
            box-sizing: border-box;
        }
        .type_item {
            width: 44px;
            height: 23px;
            line-height: 23px;
            border-radius: 5px;
            background-color: rgba(247,241,241,1);
            color: rgba(136,124,124,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            margin-right: 4px;
            margin-top: 2px;
        }
        .vid {
            width: 92px;
            height: 34px;
            line-height: 34px;
            background-color: rgba(255,255,255,1);
            color: rgba(159,158,158,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            border: 1px solid rgba(187,187,187,1);
        }
        .save {
            width: 53px;
            height: 33px;
            line-height: 33px;
            border-radius: 5px;
            background-color: rgba(163,159,159,0.97);
            color: rgba(255,255,255,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
        }
        .sent {
            width: 280px;
            height: 33px;
            line-height: 33px;
            border-radius: 5px;
            background-color: rgba(174,133,81,0.97);
            color: rgba(255,255,255,1);
            font-size: 14px;
            margin-left: 6px;
            text-align: center;
            font-family: PingFangSC-regular;
        }
    }
    .guige_box {
        width: 100%;
        padding: 30px 20px;
        box-sizing: border-box;
        background-color: rgba(148,161,242,1);
        border-radius: 8px;
        margin-bottom: 10px;
    }
    .active {
        background-color: rgba(240,154,25,1) !important;
        color: white !important;
    }
    .guige {
        width: 80px;
        height: 33px;
        line-height: 33px;
        border-radius: 8px;
        background-color: rgba(148,161,242,1);
        color: rgba(247,241,241,1);
        font-size: 14px;
        text-align: center;
        font-family: PingFangSC-regular;
    }
    table {
        border-spacing: 0;
    }
    tr > :first-child {
        width: 94px;
    }
     td {
        padding: 4px;
    }
    .avatar-uploader,.el-upload {
        border: 1px solid rgba(187,187,187,1);
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 14px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
    }
    .avatar {
        width: 80px;
        height: 80px;
        display: block;
    }
}
</style>
