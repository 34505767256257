<template>
     <div class="page" >
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
                <van-nav-bar style="background-color: #E9D4BC;" :title="toptitle" left-arrow @click-left="back" />
            </van-sticky>
        <div class="container"> 
            <van-steps :active="step" :finish-icon="require('/src/assets/pic_active.png')" :active-icon="require('/src/assets/pic_now.png')" style="background-color: rgba(0, 0, 0, 0);" active-color="#475AD5" >
                <van-step>品牌主体</van-step>
                <van-step>填写资料</van-step>
                <van-step>品牌信息</van-step>
                <van-step>实人认证</van-step>
            </van-steps>
        </div>
            <van-notice-bar color="#6470D0" background="#D8C7BE" left-icon="info-o" >
                <template #left-icon>
                    <img :src="require('/src/assets/pic_info.png')" style="height: 18px;margin-right: 2px;">
                    </template>
                您的信息正在被保护，请放心填写
            </van-notice-bar>
        <div class="container">
            <!-- 品牌主题部分 -->
            <template v-if="step == 0">
                <div class="label">品牌主体:</div>
                <div class="row">
                    <span :class="`row-item ${type === 1 ? 'active' : ''}`">
                        <div class="row-item-name" @click="type = 1">个人</div>
                    </span>
                    <span :class="`row-item ${type === 2 ? 'active' : ''}`">
                        <div class="row-item-name" @click="type = 2">个体工商户</div>
                    </span>
                    <span :class="`row-item ${type === 3 ? 'active' : ''}`">
                        <div class="row-item-name" @click="type = 3">企业/公司</div>
                    </span>
                </div>
                <div class="label" style="margin-bottom: 10px;">经营类目:</div>
                    <el-cascader
                        class="iptqls"
                        @change="leimufun"
                        v-model="leimu_val"
                        :options="options"
                        :props="props"
                        :show-all-levels="false"
                        clearable>
                    </el-cascader>
                    <div v-if="havelm" style="font-size: 12px;color: #B10808 ;margin-top: 10px;">{{ haveval }}</div>
                <div class="row nextcss">
                    <van-button class="btn " @click="next">下一步</van-button>
                </div>
                
            </template>
        <!-- 填写资料部分 -->
            <template v-if="step == 1">
                <van-form @submit="onSubmit">
                    <template v-if="type == 3">
                        <div  style="margin-top: 20px;">公司/企业名称<span style="color: #B10808;margin-left: 5px;">*</span></div>
                        <van-field class="input_fild" name="companyName" autocomplete="off" v-model="companyName" placeholder="请输入"
                            :rules="[{ required: true, message: '请填写公司/企业名称' }]" maxlength="30"/> 
                    </template>

                    
                    <div style="margin-top: 20px;">{{ type == 3 ? '法人姓名' : '姓名' }} <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" name="name" autocomplete="off" v-model="name" placeholder="请输入"
                        :rules="[{ required: true, message: '请填写姓名',pattern:/^((?!\+|\.|\\|\/|:|\*|\?|<|>|\||'|%|@|#|&|\$|\^|&|\*).){1,50}$/, }]" maxlength="10"/> 
                    <div style="margin-top: 20px;">证件类型 </div>
                    <van-field readonly clickable name="picker" :value="lx" class="input_fild"
                    right-icon="arrow-down" @click="showPickers = true" />
                    <van-popup v-model="showPickers" position="bottom">
                        <van-picker show-toolbar :columns="columnss" @confirm="onConfirms" @cancel="showPickers = false" />
                    </van-popup>
                    <div style="margin-top: 20px;">{{ type == 3 ? '法人证件号' : '证件号码' }} <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field autocomplete="off" class="input_fild" name="idNumber" v-model="idNumber" placeholder="请输入"
                        :rules="[{ required: true, message: '请填写证件号码' }]" maxlength="18"/> 
                    <div style="margin-top: 20px;">个人证件图 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <div class="flex" style="margin-top: 10px;">
                            <van-image  width="167" height="106" :src="upimgUrl" @click="uploadImages"/>
                            <van-image  width="167" height="106" style="margin-left: 10px;" :src="doimgUrl" @click="uploadImagess"/>
                    </div>
                    <div style="font-size: 10px;color: #AE8551;margin-top: 10px;">图片不超过4M，不小于100K，格式支持 JPG、PNG、JPEG</div>
                    <template v-if="type == 2 || type == 3">
                        <div style="margin-top: 20px;">营业执照 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                        <div class="flex" style="margin-top: 10px;">
                            <van-image v-if="!yingyeimg"  width="167" height="106" @click="yingyefn"  :src="yineyeimg"/>
                            <van-image fit="cover" v-if="yingyeimg" :src="yingyeimg"  width="167" height="106" @click="yingyefn" style="margin-left: 10px;"/>
                            <!-- <img v-if="yingyeimg" :src="yingyeimg" alt="" style="width: 100px;height: 100px;"> -->
                        </div>
                        <div class="flex" style="margin-top: 20px;">
                            <div>是否参与拍卖 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                            <van-radio-group style="margin-left: 20px;" v-model="ispai" direction="horizontal">
                                <van-radio name="1">是</van-radio>
                                <van-radio name="0">否</van-radio>
                            </van-radio-group>
                        </div>
                        <div v-if="ispai == 1" style="margin-top: 20px;">拍卖许可证</div>
                        <div v-if="ispai == 1" class="flex" style="margin-top: 10px;">
                                <van-image v-if="!pmimg" width="167" height="106" :src="ppimg" @click="pmimgfn"/>
                                <van-image fit="cover" v-if="pmimg" width="167" height="106" :src="pmimg" @click="pmimgfn" style="margin-left: 10px;"/>
                        </div>
                        <div class="flex" style="margin-top: 20px;">
                            <div>是否文物拍卖 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                            <van-radio-group style="margin-left: 20px;" v-model="ispai_wen" direction="horizontal">
                                <van-radio name="1">是</van-radio>
                                <van-radio name="0">否</van-radio>
                            </van-radio-group>
                        </div>
                        <div v-if="ispai_wen == 1" style="margin-top: 20px;">文物拍卖许可证</div>
                        <div v-if="ispai_wen == 1" class="flex" style="margin-top: 10px;">
                                <van-image v-if="!pmimgwen" width="167" height="106" :src="wwppimg" @click="pmimgwenfn"/>
                                <van-image fit="cover" v-if="pmimgwen" width="167" height="106" :src="pmimgwen" @click="pmimgwenfn" style="margin-left: 10px;"/>
                        </div>
                        <div style="margin-top: 20px;">运营联系人 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                        <van-field class="input_fild" name="yun" autocomplete="off" v-model="yun" placeholder="请输入"
                            :rules="[{ required: true, message: '请填写运营联系人' }]" maxlength="10"/> 
                            <div style="margin-top: 20px;">运营联系人手机号 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                        <van-field class="input_fild" type="digit" name="yun_tel" autocomplete="off" v-model="yun_tel" placeholder="请输入"
                            :rules="[{ required: true, message: '请填写运营联系人手机号' }]" maxlength="11"/> 
                    </template>
                    <template v-if="issend">
                        <div style="margin-top: 20px;">食品许可证 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                        <div class="flex" style="margin-top: 10px;"> 
                            <van-image v-if="!foods_img" width="120" height="106" @click="foodfun"  :src="food_img"/>
                            <van-image fit="cover" v-if="foods_img" width="120" height="106" @click="foodfun"  :src="foods_img"/>
                        </div>
                    </template>

                    <div style="margin-top: 20px;">支付宝账号 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" type="digit" name="alipayNo" autocomplete="off" v-model="alipayNo" placeholder="请输入"
                        :rules="[{ pattern: /^1[34578]\d{9}$/, message: '支付宝账号格式不对' }]" maxlength="11"/> 
                        <!-- <div style="margin-top: 20px;">微信账号 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" name="wxNo" autocomplete="off" v-model="wxNo" placeholder="请输入"
                        :rules="[{ required: true, message: '请填写微信账号' }]" maxlength="20"/>  -->
                    <div class="row fix">
                        <van-button native-type="submit" class="btn">下一步</van-button>
                    </div>
                    <div style="height: 100px;"></div>
                </van-form>
                
            </template>
            <!-- 品牌信息部分 -->
            <template v-if="step == 2">
                <van-form @submit="onSubmits" >
                    <div style="margin-top: 20px;">品牌名 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" name="brand" autocomplete="off" v-model="brand" placeholder="请输入"
                        :rules="[{ required: true, message: '请填写品牌名' }]" maxlength="10"/> 
                        <div style="margin-top: 20px;">品牌介绍 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" name="brandDesc" autocomplete="off" v-model="brandDesc" placeholder="请输入"
                        :rules="[{ required: true, message: '请填写品牌介绍' }]" maxlength="20"/> 
                    <div style="margin-top: 20px;">品牌logo <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <div class="flex" style="margin-top: 10px;">
                        <van-image v-if="!imgUrlsss" width="76" height="76" :src="imgUrl" @click="pplogofn" style="margin-left: 10px;"/>
                        <van-image fit="cover" v-if="imgUrlsss" width="76" height="76" :src="imgUrlsss" @click="pplogofn" style="margin-left: 10px;"/>
                    </div>
                    <div class="flex" style="margin-top: 20px;">
                        <div >是否有实体店 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                        <van-radio-group v-model="ishave" direction="horizontal" style="margin-left: 40px;max-width: 200px;">
                            <van-radio name="0">无</van-radio>
                            <van-radio name="1">有</van-radio>
                        </van-radio-group>
                    </div>
                    <template v-if="ishave == 1">
                        <div style="margin-top: 20px;">实体店地址 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" name="address" autocomplete="off" v-model="address" placeholder="请输入"
                        :rules="[{ required: true, message: '请填写实体店地址' }]" maxlength="28"/> 
                    </template>
                    <div style="margin-top: 20px;">宣传图片 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <div class="flex" style="margin-top: 10px;">
                        <van-image v-if="xuan_img.length == 0" width="76" height="76" @click="xuanchuanimg" :src="imgsimgUrl" style="margin-left: 5px;"/>
                        <template v-if="xuan_img.length != 0">
                            <van-image v-for="item in xuan_img" :src="item" width="76" height="76" @click="xuanchuanimg" style="margin-left: 10px;"/>
                            <!-- <img v-for="item in xuan_img" :src="item" style="width: 76px;height: 76px;margin-left: 10px;"> -->
                        </template>
                    </div>
                    <div style="margin-top: 20px;">宣传视频 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <div class="flex" style="margin-top: 10px;">
                        <van-image @click="xuanchuanvideo" width="76" height="76" :src="xuan_video.videoCoverUrl" style="margin-left: 5px;"/>
                    </div>
                    <div style="margin-top: 20px;">品牌历史 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field v-model="brandhistory" name="brandhistory" rows="3" autosize type="textarea" maxlength="200" show-word-limit class="te" 
                    :rules="[{ required: true, message: '请填写品牌历史' }]"/>
                    <div style="height: 100px;"></div>
                    <div class="row fix">
                        <van-button native-type="submit" class="btn">下一步</van-button>
                    </div>
                    <!-- <div class="row" v-if="info.type == 100">
                        <van-button native-type="submit" class="btn" @click="onSubmits">下一步</van-button>
                    </div> -->
                    <!-- <div class="row" v-if="info.type != 100">
                        <van-button native-type="submit" @click="grnextfn" class="btn">下一步</van-button>
                    </div> -->
                </van-form>
            </template>
            <!-- 实名认证 -->
            <template v-if="step == 3">
                <van-form>
                    <div style="width: 100%;text-align: center;">
                        <van-image round style="margin-top: 120px;"  width="130" height="130" :src="pic_sm"/>
                    </div>
                    <div style="text-align: center;color: #906C41;font-size: 14px;margin-top: 20px;">
                        <div>点击<span style="color:#6470D0">'去认证'进入</span>认证系统</div>
                        <div style="margin: 10px 0;">按照提示进入人脸识别，扫脸通过即可完成认证</div>
                        <div>请确保扫脸信息与填写信息一致</div>
                    </div>
                     <div style="margin-top: 80px;" class="row aaabbb" v-if="info.type == 100">
                        <van-button  native-type="submit" @click="torenzheng" class="btn">去认证</van-button>
                    </div>
                    <div style="margin-top: 80px;" class="row aaabbb" v-if="info.type != 100">
                        <van-button  native-type="submit" @click="torenzhengs" class="btn">去认证</van-button>
                    </div>
                    <!-- <van-button  native-type="submit" @click="torenzheng" class="btn">去认证</van-button> -->
                </van-form>
            </template>
        </div>
    </div>
</template>
<script>
import { submitApproveFirst ,updateAndSubmit,updateFaceRecognition ,otherId2Verify} from '@/api/enter';
import { findGoodCategoryList } from '@/api/goods';
import { Toast } from 'vant';
export default {
    name: 'enter',
    data() {
        return {
            mar_top:'',
            isactive:false,//是否选中协议
            toptitle:'开通店铺',
            step:0,
            enterStatus: 0, // 0: 初始值 1: 未入驻 2: 已提交审核 3: 审核通过 4: 审核未通过
            type: 1,
            ispai:'0',//是否参与拍卖
            ispai_wen:'0',//是否文物拍卖
            yun_tel:'',
            yun:'',
            showPicker: false,
            businessCategory: "影音图书",
            columns: ['影音图书', '宁波', '温州', '嘉兴', '湖州'],
            info:{},
            showinfo:false,
            showPickers:false,
            showPickerss:false,
            columnss: ['身份证'],
            name:'',
            companyName:"",
            idNumber:'',
            lx:'身份证',
            pic_active:require('/src/assets/pic_active.png'),
            pic_sm:require('/src/assets/pic_smrz.png'),
            pic_noactive:require('/src/assets/pic_noactive.png'),
            imgUrl:require('/src/assets/pic_img.png'),//品牌logo
            imgUrlsss:'',
            videoimgUrl:require('/src/assets/pic_video.png'),
            imgsimgUrl:require('/src/assets/pic_img.png'),
            xuan_img:[],
            xuan_video:{
                videoCoverUrl:require('/src/assets/pic_video.png'),
                videoId:''
            },
            food_img:require('/src/assets/pic_img.png'),
            foods_img:'',//食品许可证
            yineyeimg:require('/src/assets/pic_img_yingye.png'),
            yingyeimg:'',//营业执照
            wwppimg:require('/src/assets/pic_img_wwpm.png'),//文物拍卖
            ppimg:require('/src/assets/pic_img_pm.png'),//拍卖
            pmimg:'',
            pmimgwen:'',
            upimgUrl:require('/src/assets/pic_img_head.png'),
            doimgUrl:require('/src/assets/pic_img_con.png'),
            brandLogo:"https://img01.yzcdn.cn/vant/cat.jpeg",
            alipayNo:'',
            wxNo:'111',
            brand:'',
            brandDesc:'',
            brandCategorys:[],
            brandCategoryss:'',
            props: { multiple: true,value:'id',label:'text' },
            options: [],
            leimu_val:[],
            enterid:"",
            brandhistory:'',
            issend:false,
            ishave:'0',//0 wu 1 you
            havelm:false,
            haveval:'必须选择类目',
            address:'',
            selectlist:[
    {
        "text": "汉服",
        "id": "category1",
        "children": [
            {
                "text": "民族服饰头饰",
                "id": "brand1",
                "children": [
                    {
                        "text": "汉服",
                        "id": "brand12",
                        "children": null
                    },
                    {
                        "text": "唐装/中式服装",
                        "id": "brand13",
                        "children": null
                    }
                ]
            },
            {
                "text": "西洋服饰",
                "id": "brand44",
                "children": []
            }
        ]
    },
    
]
        }
    },
    methods: {
        toxylist(){
            this.$router.push({name:'xylist'})
        },
        //食品许可证
        foodfun(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'foodfn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        foodfn(e){
            console.log("食品许可证",e);
            this.foods_img = e.data[0]
        },
        //营业执照
        yingyefn(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'yingyefun'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        yingyefun(e){
            console.log("营业执照",e);
            
            this.yingyeimg = e.data[0]
        },
        //拍卖许可证
        pmimgfn(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'pmimgfun'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        pmimgfun(e){
            console.log("拍卖许可证",e);
            
            this.pmimg = e.data[0]
        },
        //拍卖文物许可证
        pmimgwenfn(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'paimaiwenfn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        paimaiwenfn(e){
            console.log("拍卖文物许可证",e);
            
            this.pmimgwen = e.data[0]
        },
        //品牌logo
        pplogofn(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'pplogo'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        pplogo(e){
            console.log("品牌logo",e);
            
            this.imgUrlsss = e.data[0]
        },

        //宣传图片
        xuanchuanimg(){
            var data = {
                action:'GetImage',
                req:{img_num:2},
                reqId:2,
                respFunction:'xuanchuanimgs'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        xuanchuanimgs(e){
            console.log('宣传图片',e);
            
            this.xuan_img = e.data
        },
        //宣传视频
        xuanchuanvideo(){
            var data = {
                action:'GetVideo',
                req:{
                    minute:180
                },
                reqId:3,
                respFunction:'xuanchuanvideos'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        xuanchuanvideos(e){
            console.log('宣传视频',e);
            
            this.xuan_video = e.data
        },
        torenzheng(){
            var a = {
            name:this.name,
            idCard:this.idNumber,
            }
            var data = {
                action:'Authentication',
                req:a,
                reqId:5,
                respFunction:'authentication'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        async authentication(e){
            console.log("实名认证",e);
            if(e.code == 200) {
                /* const result = await updateFaceRecognition({faceRecognition:2,enterId:this.enterid})
                console.log("更新认证信息",result);
                setTimeout(() => {
                    this.$router.go(-1);
                }, 300); */
                var videoinfo = {
                    url:this.xuan_video.videoCoverUrl
                } 
                var data = {
                    type: this.type,
                    businessCategory:JSON.stringify(this.leimu_val),
                    name: this.name,
                    idType: this.lx == '身份证' ? 0 : 0,
                    idNumber: this.idNumber,
                    idUrl:`${this.upimgUrl}#####${this.doimgUrl}`,
                    alipayNo: this.alipayNo,
                    auctionFlag:Number(this.ispai),
                    culturalAuctionFlag:Number(this.ispai_wen),
                    operateName:this.yun,
                    operatePhone:this.yun_tel,
                    wechatNo:this.wxNo,
                    brand: this.brand,
                    foodPermitImage:this.issend ? this.foods_img :'',
                    brandDesc: this.brandDesc,
                    brandLogo: this.imgUrlsss,
                    physicalStoreFlag:Number(this.ishave),
                    physicalStoreAddress:this.ishave == '0' ? '' : this.address,
                    businessLicense:this.yingyeimg,
                    brandHistory:this.brandhistory,
                    publicityPicture: this.xuan_img.join('#####'),
                    videoId: this.xuan_video.videoId,
                    videoImage: videoinfo,
                    auctionLicense:this.pmimg,
                    culturalAuctionLicense:this.pmimgwen,
                    companyName:this.companyName
                }
                console.log(this.$data);
                    const res = await submitApproveFirst(data);
                    console.log("个人身份提交",res);
                    this.enterid = res.data
                    if(res.code == 200) {
                        this.$router.go(-1);
                    }
            }
        },
        torenzhengs(){
            var a = {
            name:this.name,
            idCard:this.idNumber,
            }
            var data = {
                action:'Authentication',
                req:a,
                reqId:5,
                respFunction:'authentications'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        async authentications(e){
            console.log("实名认证",e);
            if(e.code == 200) {
                var videoinfo = {
                    url:this.xuan_video.videoCoverUrl
                } 
                var data = {
                    id:this.info.id,
                    type: this.type,
                    businessCategory:JSON.stringify(this.leimu_val),
                    name: this.name,
                    idType: this.lx == '身份证' ? 0 : 0,
                    idNumber: this.idNumber,
                    idUrl:`${this.upimgUrl}#####${this.doimgUrl}`,
                    alipayNo: this.alipayNo,
                    auctionFlag:Number(this.ispai),
                    culturalAuctionFlag:Number(this.ispai_wen),
                    operateName:this.yun,
                    operatePhone:this.yun_tel,
                    wechatNo:this.wxNo,
                    brand: this.brand,
                    foodPermitImage:this.issend ? this.foods_img :'',
                    brandDesc: this.brandDesc,
                    brandLogo: this.imgUrlsss,
                    physicalStoreFlag:Number(this.ishave),
                    physicalStoreAddress:this.ishave == '0' ? '' : this.address,
                    businessLicense:this.yingyeimg,
                    brandHistory:this.brandhistory,
                    publicityPicture: this.xuan_img.join('#####'),
                    videoId: this.xuan_video.videoId,
                    videoImage: videoinfo,
                    auctionLicense:this.pmimg,
                    culturalAuctionLicense:this.pmimgwen,
                    companyName:this.companyName
                }
                console.log(this.$data);
                const res = await updateAndSubmit(data);
                console.log("个人身份编辑提交",res);
                this.enterid = res.data
                if(res.code == 200) {
                    this.$router.go(-1);
                    }
            }
        },
        leimufun(e){
            console.log(e);
            console.log(this.leimu_val);
        },
        showPickerssfun(){
            this.showPickerss = true
            console.log(this.brandCategorys);
            
        },
        onConfirm(value) {
            this.businessCategory = value;
            this.showPicker = false;
        },
        next() {
            console.log(this.leimu_val);
            if(this.leimu_val.length == 0) {
                this.havelm = true
                this.haveval = '*必须选择类目'
            } else if(this.leimu_val.length>5) {
                this.havelm = true
                this.haveval = '*最多可选5个类目'
            } else {
                this.havelm = false
                this.haveval = ''
                /* if(this.isactive) { */
                    this.havelm = false
                    this.haveval = ''
                    var a = []
                        for (let i = 0; i < this.leimu_val.length; i++) {
                            for (let j = 0; j < this.leimu_val[i].length; j++) {
                                a.push(this.leimu_val[i][j])
                            }
                        }
                        console.log("aaaaa",a);
                        if(a.includes('category10') || (a.includes('category4') && !a.includes('brand84'))) {
                            this.issend = true
                        } else {
                            this.issend = false
                        }
                    this.step = 1,
                    this.toptitle = this.type  == 1 ? '个人身份' : this.type  == 2 ? '个体工商户' : '企业/公司' 
                /* } else {
                    Toast('请勾选协议')
                } */
            }
             
        },
        onConfirms(e){
            console.log(e);
            this.lx = e
            this.showPickers = false
        },
        //证件图
        uploadImages(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'uploadimgs'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        uploadimgs(e){
            console.log("证件图1",e);
            
            this.upimgUrl = e.data[0]
        },
        uploadImagess(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'uploadimgss'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        uploadimgss(e){
            console.log("证件图2",e);
            
            this.doimgUrl = e.data[0]
        },
        //身份证校验
         sValidID(id) {
            if (!id || id.length !== 18) return false;
        
            const weight = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const checkCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
            let sum = 0;
        
            for (let i = 0; i < 17; i++) {
                sum += parseInt(id[i]) * weight[i];
            }
        
            const mod = sum % 11;
            const checkChar = checkCode[mod];
        
            return id[17].toUpperCase() === checkChar;
        },
        /* 个人资料提交下一步 */
        async onSubmit(e){
            console.log(this.upimgUrl);
            if(this.upimgUrl.indexOf('pic_img') !== -1 || this.doimgUrl.indexOf('pic_img') !== -1){
                Toast('请上传证件图')
            } else if((this.type == 2|| this.type == 3) && this.yingyeimg == '') {
                Toast('请上传营业执照')
            } else if(this.issend == true && this.foods_img == '') {
                Toast('请上传食品许可证')
            } else {
                const res = await otherId2Verify({
                    realName:this.name,
                    certNum:this.idNumber
                })
                if(res.code == 200) {
                    console.log("填写信息表单提交下一步",e);
                    this.step = 2
                } else {
                    Toast(res.msg)
                }
            }
        },
        //个人身份品牌信息下一步  新增入驻信息
        async onSubmits(e){
            if(this.imgUrlsss== '' || this.xuan_img.length == 0 || this.xuan_video.videoId == '') {
                Toast('请补充完整信息')
            }  else {
                this.step = 3
                /* var videoinfo = {
                    url:this.xuan_video.videoCoverUrl
                }  */
                /* var data = {
                    type: this.type,
                    businessCategory:JSON.stringify(this.leimu_val),
                    name: this.name,
                    idType: this.lx == '身份证' ? 0 : 0,
                    idNumber: this.idNumber,
                    idUrl:`${this.upimgUrl},${this.doimgUrl}`,
                    alipayNo: this.alipayNo,
                    auctionFlag:Number(this.ispai),
                    culturalAuctionFlag:Number(this.ispai_wen),
                    operateName:this.yun,
                    operatePhone:this.yun_tel,
                    wechatNo:this.wxNo,
                    brand: this.brand,
                    foodPermitImage:this.issend ? this.foods_img :'',
                    brandDesc: this.brandDesc,
                    brandLogo: this.imgUrlsss,

                    physicalStoreFlag:Number(this.ishave),
                    physicalStoreAddress:this.ishave == '0' ? '' : this.address,

                    businessLicense:this.yingyeimg,
                    foodPermitImage:this.foods_img,

                    brandHistory:this.brandhistory,
                    publicityPicture: this.xuan_img.join(','),
                    videoId: this.xuan_video.videoId,
                    videoImage: videoinfo
                }
                console.log(this.$data);
                    const res = await submitApproveFirst(data);
                    console.log("个人身份提交",res);
                    this.enterid = res.data
                    if(res.code == 200) {
                        this.step = 3
                    } */
            }
        },
        //编辑入住信息
        async grnextfn (){
            var videoinfo = {
                    url:this.xuan_video.videoCoverUrl
                } 
            var data = {
                id:this.info.id,
                type: this.type,
                    businessCategory:JSON.stringify(this.leimu_val),
                    name: this.name,
                    idType: this.lx == '身份证' ? 0 : 0,
                    idNumber: this.idNumber,
                    idUrl:`${this.upimgUrl}#####${this.doimgUrl}`,
                    alipayNo: this.alipayNo,
                    auctionFlag:Number(this.ispai),
                    culturalAuctionFlag:Number(this.ispai_wen),
                    operateName:this.yun,
                    operatePhone:this.yun_tel,
                    wechatNo:this.wxNo,
                    brand: this.brand,
                    foodPermitImage:this.issend ? this.foods_img :'',
                    brandDesc: this.brandDesc,
                    brandLogo: this.imgUrlsss,
                    physicalStoreFlag:Number(this.ishave),
                    physicalStoreAddress:this.ishave == '0' ? '' : this.address,
                    businessLicense:this.yingyeimg,
                    foodPermitImage:this.foods_img,
                    brandHistory:this.brandhistory,
                    publicityPicture: this.xuan_img.join('#####'),
                    videoId: this.xuan_video.videoId,
                    videoImage: videoinfo
            }
            console.log(this.$data);
                const res = await updateAndSubmit(data);
            console.log("编辑提交",res);
            if (res.data) {
                this.$toast('提交成功');
                setTimeout(() => {
                    this.$router.go(-1);
                }, 1000);
            } else {
                this.$toast('提交失败');
                return false;
            }
        },
        back(){
            this.$router.go(-1)
        },
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }

        window.uploadimgs = this.uploadimgs
        window.pplogo = this.pplogo
        window.uploadimgss = this.uploadimgss 
        window.authentication = this.authentication
        window.authentications = this.authentications
        window.xuanchuanimgs = this.xuanchuanimgs
        window.xuanchuanvideos = this.xuanchuanvideos
        window.yingyefun = this.yingyefun 
        window.pmimgfun = this.pmimgfun  
        window.paimaiwenfn = this.paimaiwenfn 
        window.foodfn = this.foodfn 
        
        console.log("this.$route.query",this.$route.params);
        this.info = this.$route.params.info
        if(this.$route.params.info.type == 100) {
            console.log("新增");
            
        } else {
            console.log("编辑");
            var xuan_video = {
                videoCoverUrl:this.$route.params.info.videoResponse.image.url,
                videoId:this.$route.params.info.videoResponse.videoId,
            }
            this.xuan_video = xuan_video
            this.type = this.$route.params.info.type
            this.yun = this.$route.params.info.operateName
            this.yun_tel = this.$route.params.info.operatePhone
            this.ispai = this.$route.params.info.auctionFlag.toString()
            this.ispai_wen = this.$route.params.info.culturalAuctionFlag.toString()
            this.leimu_val = JSON.parse(this.$route.params.info.businessCategory)
            this.xuan_img = this.$route.params.info.publicityPicture
            this.name = this.$route.params.info.name
            this.lx = this.$route.params.info.idType == 0 ? '身份证' : ''
            this.idNumber = this.$route.params.info.idNumber
            this.alipayNo = this.$route.params.info.alipayNo
            this.wxNo = this.$route.params.info.wechatNo
            this.brand = this.$route.params.info.brand
            this.brandDesc = this.$route.params.info.brandDesc
            this.imgUrlsss = this.$route.params.info.brandLogo
            this.brandhistory = this.$route.params.info.brandHistory
            this.upimgUrl = this.$route.params.info.idUrl[0]
            this.doimgUrl = this.$route.params.info.idUrl[1]
            this.yingyeimg = this.$route.params.info.businessLicense
            /* this.issend =  this.$route.params.info.foodPermitImage ? true:false */
            this.foods_img = this.$route.params.info.foodPermitImage
            this.ishave = this.$route.params.info.physicalStoreFlag.toString()
            this.address = this.$route.params.info.physicalStoreAddress ?this.$route.params.info.physicalStoreAddress : ''
            this.pmimgwen = this.$route.params.info.culturalAuctionLicense 
            this.pmimg = this.$route.params.info.auctionLicense 
            this.companyName = this.$route.params.info.companyName 
        }
        const result = await findGoodCategoryList({})
        console.log("三级分类",result);
        this.options = result.data
        /* await this.getEnterInfo(); */
    }
}
</script>
<style lang="less" scoped>


.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: auto;
    padding: 16px;
    font-size: 16px;
    .flex {
        display: flex;
    }
    .label {
        font-size: 14px;
    }
    .pop {
        padding: 20px;
        padding-bottom: 40px;
        box-sizing: border-box;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .row {
        margin: 10px 0;
        display: flex;
    }

    .category-right {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
    }

    .tip {
        font-size: 10px;
        color: rgba(163, 159, 159, 0.97);
    }

    .underline {
        text-decoration: underline;
    }

    .row-item {
        margin: 8px;
        background-color: #E1C9AD;
        display: inline-flex;
        border-radius: 5px;
        color: rgba(135,98,53,0.9);

        .row-item-name {
            font-size: 12px;
            padding:10px 8px;
            min-width: 90px;
            text-align: center;
        }
    }

    .btn {
        width: 100%;
        height: 48px;
        border-radius: 12px;
        background-color: #876235;
        border: none;
        color: rgba(255, 255, 255, 1);
        border: 1px solid #876235;
       /*  margin-top: 20px; */
    }

    .active {
        background-color: #876235 !important;
        color: #E9D4BC;
        font-family: AlimamaDongFangDaKai, AlimamaDongFangDaKai;
    }

    /deep/ .van-hairline--top-bottom::after,
    .van-hairline-unset--top-bottom::after {
        border-width: 1px;
    }

    .info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
            font-size: 12px;
        }
        .result-tip {
            color: rgba(163, 159, 159, 0.97);
        }
    }
}
    /deep/ .van-step--horizontal .van-step__circle-container {
        background-color: rgba(0, 0, 0, 0);
    }
    .te {
            border-radius: 8px;
            margin: 10px 0;
            background-color: rgba(174,133,81,0.15);
        }
    .input_fild {
        margin-top: 10px;
        padding:10px 15px;
        box-sizing: border-box;
        background: rgba(174,133,81,0.15);
        border-radius: 8px;
        color: #000;
    }
    /deep/.van-step--horizontal .van-step__icon  {
        font-size: 15px;
        background: #E9D4BC;
    }
    /deep/.van-step__circle  {
        width: 17px;
        height: 17px;
        background: #D5BA99;
    }
    /deep/.van-icon__image {
        width: 19px;
        height: 19px;
    }
    //步骤横线
    /deep/.van-step__line {
        background-color:#D5BA99;
    }
    //文字颜色
    /deep/.van-step__title {
        color: #C0AC97;
    }
    .iptqls /deep/ .el-input__inner{
        background: rgba(174,133,81,0.15);  /* 背景色 */
        border-color: rgba(174,133,81,0.15); /* 边框色 */
        color: #000; /* 文字颜色 */ 
    }
    /deep/ .iptqls .el-input__inner::placeholder,
    /deep/ .el-date-picker .el-input__inner::placeholder,
    /deep/ .el-input .el-input__inner::placeholder {
        color:#A8937C;
    }
    /deep/ .el-cascader__tags .el-tag {
        background-color: rgba(210, 133, 33, 0.15);
        border: none;
        color: #000;
    }
    /deep/ .el-cascader__tags .el-tag .el-icon-close  {
        background-color: #876235 !important;
    }
    /deep/ .el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path {
        color: #000;
    }
   /deep/ .el-cascader:not(.is-disabled):hover .el-input__inner {
    border-color: rgba(0, 0, 0, 0);
   }
    .te {
        border-radius: 8px;
        margin: 10px 0;
        background-color: rgba(174,133,81,0.15);
    }
    //fild输入框 placehouder样式
    :deep(.van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
    //提交校验placehouder样式
    :deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
    //顶部导航样式
    :deep.van-nav-bar .van-icon {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }
    .van-sticky {
  position: -webkit-sticky; /* 对于 Safari */
  position: sticky;
  top: 0; /* 或者根据需要调整 */
  z-index: 100; /* 确保有足够的层级 */
}
.van-cell::after {
        border-bottom: 1px solid #E9D4BC !important;
    }
    .xy {
        width: 100%;
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .nextcss {
        width: 94%;
        position: fixed;
        bottom: 40px;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .fix {
        width: 94%;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .aaabbb {
        width: 94%;
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%,-50%);
    }
</style>
<style>
.el-cascader-panel { 
        width: 360px !important;;
        overflow: auto !important;
        background-color: #E9D4BC !important;
        color: #000;
    }
    .el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path {
        color: #876235 !important;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #876235 !important;
        border-color: #876235 !important;
    } 
    .el-cascader__dropdown {
        border: none !important;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    .el-cascader-node:not(.is-disabled):focus, .el-cascader-node:not(.is-disabled):hover {
        background-color: rgba(147, 87, 8, 0.15) !important;
    }
    .el-cascader-menu {
        border-right: 1px solid rgba(147, 87, 8, 0.15) !important;
    }
    .el-checkbox__inner {
        background-color:rgba(124, 73, 6, 0.15) !important;
        border: none !important;
    }
    .el-cascader__tags .el-tag .el-icon-close  {
        background-color: #876235 !important;
    }
    .el-cascader .el-input .el-icon-arrow-down {
    color: #906C41 !important;
    font-weight: bold !important;
}
.el-cascader .el-input .el-input__inner:focus,.el-cascader .el-input.is-focus .el-input__inner {
    border-color: rgba(0, 0, 0, 0) !important;
}

</style>
