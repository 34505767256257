import request from "@/utils/request";

// 查询品牌列表
export const findByUser = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/findByUser/v2`,
        method: "POST",
        data
    })
}
// 查看最新的品牌信息
export const brand = (id) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/${id}`,
        method: "GET"
    })
}
// 编辑品牌信息提交审核
export const updateAndSubmit = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/updateAndSubmit`,
        method: "POST",
        data
    })
}
//店铺添加发货人信息
export const addBrandDeliveryInfo = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/addBrandDeliveryInfo`,
        method: "POST",
        data
    })
}
//修改发货人信息
export const updateBrandDeliveryInfo = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/updateBrandDeliveryInfo`,
        method: "POST",
        data
    })
}
//查询发货人信息
export const queryBrandDeliveryInfo = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/queryBrandDeliveryInfo`,
        method: "POST",
        data
    })
}
// 增加运费模板
export const addSupplierTemplate = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/addSupplierTemplate`,
        method: "POST",
        data
    })
}
// 查询运费模板列表
export const searchSupplierTemplateList = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/searchSupplierTemplateList`,
        method: "POST",
        data
    })
}
//查询其他运费模板
export const queryOtherSupplierTemplate = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/queryOtherSupplierTemplate`,
        method: "POST",
        data
    })
}
//编辑运费价格
export const updateSupplierTemplate = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/updateSupplierTemplate`,
        method: "POST",
        data
    })
}
// 删除运费模板
export const deleteSupplierTemplate = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/deleteSupplierTemplate`,
        method: "POST",
        data
    })
}
//根据品牌id查询一级类目
export const queryGoodsCategoryByBrand = (data) => {
    return request({
        url: `ssm-goods/api/goods/queryGoodsCategoryByBrand`,
        method: "POST",
        data
    })
}
// 新增商品信息
export const saveGoodsInfo = (data) => {
    return request({
        url: `ssm-goods/api/goods/saveGoodsInfo`,
        method: "POST",
        data
    })
}
//删除商品
export const deleteGoods = (data) => {
    return request({
        url: `ssm-goods/api/goods/deleteGoods`,
        method: "POST",
        data
    })
}
// 新增规格
export const addSpecification = (data) => {
    return request({
        url: `ssm-goods/api/goods/specification/addSpecification`,
        method: "POST",
        data
    })
}
// 新增子规格
export const addSpecificationItem = (data) => {
    return request({
        url: `ssm-goods/api/goods/specification/addSpecificationItem`,
        method: "POST",
        data
    })
}
//删除子规格 
export const deleteSpecificationItem = (data) => {
    return request({
        url: `ssm-goods/api/goods/specification/deleteSpecificationItem`,
        method: "POST",
        data
    })
}
// 查询规格列表
export const findList = (data) => {
    return request({
        url: `ssm-goods/api/goods/specification/findList`,
        method: "POST",
        data
    })
}
// 查询品牌下面的商品列表
export const queryListByBrandId = (data) => {
    return request({
        url: `ssm-goods/api/goods/queryListByBrandId`,
        method: "POST",
        data
    })
}

// 编辑商品信息
export const updateGoodsInfo = (data) => {
    return request({
        url: `ssm-goods/api/goods/updateGoodsInfo`,
        method: "POST",
        data
    })
}
// 查询商品详情
export const queryGoodsInfo = (id) => {
    return request({
        url: `ssm-goods/api/goods/queryGoodsInfo/${id}`,
        method: "GET"
    })
}
// 商品上下架
export const updateGoodsStatus = (data) => {
    return request({
        url: `ssm-goods/api/goods/updateGoodsStatus`,
        method: "POST",
        data
    })
}
//查询分类列表
export const querySystemCategoryList = (data) => {
    return request({
        url: `ssm-goods/api/goods/querySystemCategoryList`,
        method: "POST",
        data
    })
}
//查询品类列表(共两级)
export const brandCategoryList = (data) => {
    return request({
        url: `ssm-goods/api/goods/brandCategory/findList`,
        method: "POST",
        data
    })
}
//查询分类包括品类列表(共三级)
export const findGoodCategoryList = (data) => {
    return request({
        url: `ssm-goods/api/goods/findGoodCategoryList`,
        method: "POST",
        data
    })
}
//匹配薅羊毛商品
export const queryReverseBiddingGoods = (data) => {
    return request({
        url: `ssm-goods/api/goods/queryReverseBiddingGoods`,
        method: "POST",
        data
    })
}
//店铺查询保证金明细
export const searchSecurityDepositRecord = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/searchSecurityDepositRecord`,
        method: "POST",
        data
    })
}
//店铺充值保证金下单
export const createSecurityDepositRecordOrder = (data) => {
    return request({
        url: `ssm-merchants/api/merchants/brand/createSecurityDepositRecordOrder`,
        method: "POST",
        data
    })
}
