<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="物流信息" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
                    <!-- <div class="box" v-for="item in list" :key="item.id">
                       <div class="flex" style="justify-content: space-between;">
                           <div class="flex">
                               <img src="https://img01.yzcdn.cn/vant/cat.jpeg" class="imgs">
                               <div class="goodsn">
                                   <div style="font-size: 15px;" class="t">{{item.goodsName}}</div>
                                   <div style="font-size: 14px;color: rgba(55,38,27,0.33);" class="t">{{item.goodsId}}</div>
                               </div>
                           </div>
                           <div style="height: 60px;display: flex;flex-direction: column;justify-content: space-around;">
                               <div style="font-size: 18px;color: #475AD5;">¥{{ item.amount }}</div>
                               <div style="font-size: 14px;color: rgba(55,38,27,0.33);">x{{ item.num }}</div>
                           </div>
                       </div>
                    </div> -->
                    <div class="box" >
                        <div style="margin: 10px 0;">物流详情</div>
                        <div style="margin: 10px 0;">快递公司： {{ company_name }}</div>
                        <div v-for="(item,index) in wuliulist" :key="item.time" style="margin: 10px 0;">
                            <div>{{ item.time }}</div>
                            <div>{{ item.context }}</div>
                        </div>
                    </div>
            <div style="height: 80px;"></div>
        </div> 
    </div>
</template>
<script>
import { query} from '@/api/order';
export default {
    name: 'order',
    data() {
        return {
            mar_top:'',
            list:[],
            wuliulist:[],
            bizOrderNo:'',
            company_name:'',
            options: [
                {value:'yuantong',label:'圆通速递'},
                {value:'yunda',label:'韵达快递'},
                {value:'zhongtong',label:'中通快递'},
                {value:'jtexpress',label:'极兔速递'},
                {value:'shentong',label:'申通快递'},
                {value:'youzhengguonei',label:'邮政快递包裹'},
                {value:'shunfeng',label:'顺丰速运'},
                {value:'jd',label:'京东物流'},
                {value:'ems',label:'EMS'},
                {value:'youzhengdsbk',label:'邮政电商标快'},
                {value:'youzhengbk',label:'邮政标准快递'},
                {value:'debangkuaidi',label:'德邦快递'},
                {value:'danniao',label:'菜鸟速递'},
                {value:'xinfengwuliu',label:'信丰物流'},
                {value:'debangwuliu',label:'德邦物流'},
                {value:'zhongtongkuaiyun',label:'中通快运'},
                {value:'rrs',label:'日日顺物流'},
                {value:'jingdongkuaiyun',label:'京东快运'},
                {value:'shunfengkuaiyun',label:'顺丰快运'},
                {value:'kuayue',label:'跨越速运'},
                {value:'annengwuliu',label:'安能快运'},
                {value:'baishiwuliu',label:'百世快运'},
                {value:'sxjdfreight',label:'顺心捷达'},
                {value:'jinguangsudikuaijian',label:'京广速递'},
                {value:'yimidida',label:'壹米滴答'},
                {value:'yundakuaiyun',label:'韵达快运'},
                {value:'subida',label:'速必达'},
                {value:'usps',label:'USPS'},
                {value:'youshuwuliu',label:'优速'},
                {value:'annto',label:'安得物流'},
                {value:'pingandatengfei',label:'平安达腾飞'},
                {value:'zhongtongguoji',label:'中通国际'},
                {value:'jiayunmeiwuliu',label:'加运美'},
                {value:'cainiaodajian',label:'菜鸟大件'},
                {value:'dhl',label:'DHL-中国件'},
                {value:'savor',label:'海信物流'},
                {value:'ups',label:'UPS'},
                {value:'zhaijisong',label:'宅急送'},
                {value:'upsen',label:'UPS-全球件'},
                {value:'ztky',label:'中铁快运'},
                {value:'suning',label:'苏宁物流'},
                {value:'suteng',label:'速腾快递'},
                {value:'fedexcn',label:'Fedex-国际件-中文'},
                {value:'ewe',label:'EWE全球快递'},
                {value:'stosolution',label:'申通国际'},
                {value:'huitongkuaidi',label:'百世快递'},
                {value:'shunfenglengyun',label:'顺丰冷链'},
                {value:'fedex',label:'FedEx-国际件'},
                {value:'huisenky',label:'汇森速运'},
                {value:'exfresh',label:'安鲜达'},
                {value:'disifang',label:'递四方'},
                {value:'quantong56',label:'全通速递'},
                {value:'youzhengguoji',label:'国际包裹'},
                {value:'flextock',label:'Flextock'},
                {value:'dhlen',label:'DHL-全球件'},
                {value:'zhongtiewuliu',label:'中铁飞豹'},
                {value:'longjun',label:'龙俊物流'},
                {value:'emswuliu',label:'EMS物流'},
                {value:'chinapost',label:'中国邮政（CHINA POST）'},
                {value:'ane66',label:'安能快递'},
                {value:'yuxinwuliu',label:'宇鑫物流'},
                {value:'shpost',label:'同城快寄'},
                {value:'emsbg',label:'EMS包裹'},
                {value:'yuantongguoji',label:'圆通国际'},
                {value:'emsguoji',label:'EMS-国际件'},
                {value:'ttsucha',label:'天天速查'},
                {value:'wooolink',label:'上海领链物流'},
           ],
        }
    },
    methods: {
        back() {
            this.$router.go(-1);
        },
        // 查询物流信息
        async getinfo() {
            const res = await query({ 
                bizOrderNo: this.bizOrderNo,
            });
            console.log("查询物流信息",res);
            this.wuliulist = res.data.dataList
        },
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    
    async mounted() {
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log("this.$router.parmars",this.$route.params);
        if('reason' in this.$route.params.info && this.$route.params.issales == 1) {
            this.bizOrderNo = this.$route.params.info.bizOrderNo
            for (let i = 0; i < this.options.length; i++) {
                if(this.options[i].value == this.$route.params.info.logisticsCompany) {
                    this.company_name = this.options[i].label
                }
            }
            await this.getinfo()
        }else if('reason' in this.$route.params.info && this.$route.params.issales == 2) {
            this.bizOrderNo = this.$route.params.info.refundOrderNo
            for (let i = 0; i < this.options.length; i++) {
                if(this.options[i].value == this.$route.params.info.logisticsCompany) {
                    this.company_name = this.options[i].label
                }
            }
            await this.getinfo()

        } else {
            this.bizOrderNo = this.$route.params.info.bizOrderNo
            for (let i = 0; i < this.options.length; i++) {
                if(this.options[i].value == this.$route.params.info.logisticsCompany) {
                    this.company_name = this.options[i].label
                }
            }
            await this.getinfo()
        }
    }
}
</script>
<style lang="less" scoped>
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .box {
        padding: 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 7px 0;
    }
    .flex {
        display: flex;
        align-items: center;
        .img {
           width: 24px;
           height: 24px;
           border-radius: 12px; 
        }
        .imgs {
            width: 60px;
            height: 60px;
            border-radius: 8px; 
        }
        .goodsn {
            width: 190px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .t {
            // width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .same {
            padding: 5px 15px;
            box-sizing: border-box;
            border-radius: 8px;
        }
        .cancel {
            border: 1px solid rgba(55,38,27,0.33);
            color: rgba(55,38,27,0.5);
        }
        .sub {
            background: #876235;
            color: #fff;
        }
        .recive {
            border: 1px solid #475AD5;
            color: #475AD5;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tosat {
            width: 300px;
            height: 180px;
            border-radius: 8px;
            background-color: #E9D4BC;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }
    .lx {
            width: 160px !important;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background-color: rgba(255,255,255,1);
            color: rgba(16,16,16,1);
            font-size: 14px;
            text-align: center;
            font-family: PingFangSC-regular;
            border: 1px solid rgba(187,187,187,1);
        }
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
