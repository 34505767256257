import request from "@/utils/request";

// 查询我的竞拍列表
export const queryAuctionListByType = (data) => {
    return request({
        url: `/ssm-trade/api/trade/queryAuctionListByType`,
        method: "POST",
        data
    })
}
// 查询竞拍商品出价记录
export const queryAuctionGoodsBidRecord = (data) => {
    return request({
        url: `/ssm-trade/api/trade/queryAuctionGoodsBidRecord`,
        method: "POST",
        data
    })
}
//查询用户的拍卖服务费记录明细
export const queryAuctionServiceRecord = (data) => {
    return request({
        url: `/ssm-trade/api/trade/queryAuctionServiceRecord`,
        method: "POST",
        data
    })
}
//查询出价金余额
export const queryUserAuctionBalance = (data) => {
    return request({
        url: `/ssm-trade/api/trade/queryUserAuctionBalance`,
        method: "POST",
        data
    })
}
//保证金充值记录查询
export const queryAuctionDepositRecord = (data) => {
    return request({
        url: `/ssm-trade/api/trade/queryAuctionDepositRecord`,
        method: "POST",
        data
    })
}
//我的出价金充值
export const addServiceRecordMoney = (data) => {
    return request({
        url: `/ssm-trade/api/trade/addServiceRecordMoney`,
        method: "POST",
        data
    })
}
//用户拍卖信用分明细
export const auctionCreditScoreDetail = (data) => {
    return request({
        url: `/ssm-app/api/app/user/auctionCreditScoreDetail`,
        method: "GET",
        data
    })
}
//用户信用分
export const auctionCreditScore = (data) => {
    return request({
        url: `/ssm-app/api/app/user/auctionCreditScore`,
        method: "GET",
        data
    })
}
//用户拍卖商品下单
export const submitAuctionOrder = (data) => {
    return request({
        url: `/ssm-app/api/trade/submitAuctionOrder`,
        method: "GET",
        data
    })
}