<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar left-arrow title="编辑品牌信息" @click-left="back" style="background-color: #E9D4BC;"/>
        </van-sticky>
        <div class="container">
            <van-form @submit="submit">
                    <div style="margin-top: 20px;">品牌名 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" name="brand" autocomplete="off" v-model="brand" placeholder="请输入"
                        :rules="[{ required: true, message: '请填写品牌名' }]" maxlength="28"/> 
                        <div style="margin-top: 20px;">品牌介绍 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" name="brandDesc" autocomplete="off" v-model="brandDesc" placeholder="请输入"
                        :rules="[{ required: true, message: '请填写品牌介绍' }]" maxlength="38"/> 
                        <div style="margin-top: 20px;">品牌历史 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field v-model="brandhistory" rows="3" autosize type="textarea" maxlength="200" show-word-limit class="te" 
                    :rules="[{ required: true, message: '请填写品牌历史' }]"/>
                    <div class="flex" style="margin-top: 20px;">
                        <div >是否有实体店 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                        <van-radio-group v-model="ishave" direction="horizontal" style="margin-left: 40px;max-width: 200px;">
                            <van-radio name="0">无</van-radio>
                            <van-radio name="1">有</van-radio>
                        </van-radio-group>
                    </div>
                    <template v-if="ishave == 1">
                        <div style="margin-top: 20px;">实体店地址 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <van-field class="input_fild" name="address" autocomplete="off" v-model="address" placeholder="请输入"
                        :rules="[{ required: true, message: '请填写实体店地址' }]" maxlength="28"/> 
                    </template>
                    <div style="margin-top: 20px;">品牌logo <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <div class="flex" style="margin-top: 10px;">
                        <!-- <van-image width="76" height="76" :src="imgUrl" @click="uppplogo"/> -->
                        <!-- <van-image @click="previewImage(brandLogo)" style="margin-left: 10px;" width="76" height="76" :src="brandLogo"/> -->
                        <van-image fit="cover" @click="uppplogo" style="margin-left: 10px;" width="76" height="76" :src="brandLogo"/>
                    </div>
                        
                    <div style="margin-top: 20px;">宣传图片 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <div class="flex" style="margin-top: 10px;">
                            <!-- <van-image width="76" height="76" :src="imgUrl" @click="xuanchuanfun"/>
                            <van-image @click="previewImage(publicityPicture)" v-for="item in publicityPicture" width="76" height="76" :src="item" style="margin-left: 5px;"/> -->
                            <van-image fit="cover" @click="xuanchuanfun" v-for="item in publicityPicture" width="76" height="76" :src="item" style="margin-left: 5px;"/>
                        </div>
                    <div style="margin-top: 20px;">宣传视频 <span style="color: #B10808;margin-left: 5px;">*</span></div>
                    <div class="flex" style="margin-top: 10px;">
                            <!-- <van-image width="76" height="76" @click="xuanchuanvideo" :src="require('/src/assets/pic_video.png')"/> -->
                            <van-image fit="cover" width="76" height="76" :src="videoinfo.image.url" @click="xuanchuanvideo"/>
                           <!--  <video @click="playVideo" :src="videoinfo.videoUrl" style="width: 100px;height: 100px;"></video> -->
                    </div>
                    <div style="margin-top: 16px;">
                        <div v-if="status == 3 && info.failReason!= null" style="color: red;text-align: center;">失败原因:{{ info.failReason }}</div>
                        <van-button v-if="status == 2" class="btn" native-type="submit" >提交审核</van-button>
                        <van-button v-if="status == 1" class="btn" disabled>正在审核中</van-button>
                        <van-button v-if="status == 3" class="btn" native-type="submit" >重新提交</van-button>
                    </div>
                    <div style="height: 50px;"></div>
                </van-form>
        </div>
    </div>
</template>
<script>
import { brand ,updateAndSubmit} from '@/api/goods';
import { ImagePreview, Toast } from 'vant';
export default {
    name: 'work',
    data() {
        return {
            mar_top:'',
            status:'',
            showPickerss:false,
            imgUrl:require('/src/assets/pic_img.png'),
            brand: '',
            bradnid:'',
            brandDesc:'',
            brandLogo: require('/src/assets/pic_img.png'),
            brandhistory:'',
            publicityPicture: [require('/src/assets/pic_img.png')],
            publicityVideo: [],
            brandCategorys:[],
            videoinfo:{
                image:{
                    url:require('/src/assets/pic_video.png'),
                }
        },
            address:'',
            brandCategoryss:'',
            info:{},
            ishave:'0',//0 wu 1 you
        }
    },
    methods: {
        //宣传视频
        xuanchuanvideo(){
            var data = {
                action:'GetVideo',
                req:{ minute:180},
                reqId:3,
                respFunction:'xuanchuanvideos'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        xuanchuanvideos(e){
            console.log('宣传视频',e);
            
            this.videoinfo.image.url = e.data.videoCoverUrl
            this.videoinfo.videoId = e.data.videoId
        },
        //播放视频
        playVideo(){
            this.$el.querySelector('video').play()
        },
        uppplogo(){
            var data = {
                action:'GetImage',
                req:{img_num:1},
                reqId:2,
                respFunction:'uppplogofn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        uppplogofn(e){
            this.brandLogo = e.data[0]
        },
        xuanchuanfun(){
            var data = {
                action:'GetImage',
                req:{img_num:3},
                reqId:2,
                respFunction:'xuanchuanfn'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        },
        xuanchuanfn(e){
            console.log("宣传图片",e);
            
            this.publicityPicture = e.data
        },
        showPickerssfun(){
            this.showPickerss = true
            console.log(this.brandCategorys);
            
        },
        flfun(){
            this.showPickerss = false
            console.log(this.brandCategorys,111);
            this.brandCategoryss = this.brandCategorys.join(',')
            console.log(this.brandCategoryss,222);

        },

        previewImage(key) {
            if(Array.isArray(key)) {
            ImagePreview(key);

            } else {
                ImagePreview([key]);
            }
            console.log(key);
        },
        async submit() {
            console.log(this.$data);
            /* if (this.brandCategorys.length < 2) {
                this.$toast('至少选择两项品牌分类');
                return false;
            } */
            console.log(this.$data);
           if(this.brandLogo.indexOf('pic_img') !== -1) {
                Toast('请上传品牌logo')
           } else if(this.publicityPicture[0].indexOf('pic_img') !== -1) {
                Toast('请上传至少一张宣传图片')
           } else if(this.videoinfo.image.url.indexOf('pic_video') !== -1) {
                Toast('请上传宣传视频')
           } else {
               var v_info = {
                   url:this.videoinfo.image.url
               }
               const res = await updateAndSubmit({
                   id: this.bradnid,
                   brand: this.brand,
                   brandDesc: this.brandDesc,
                   brandLogo: this.brandLogo,
                   brandHistory: this.brandhistory,
                   /* brandCategory: this.brandCategorys.join(','), */
                   publicityPicture: this.publicityPicture.join('#####'),
                   physicalStoreFlag:Number(this.ishave),
                   physicalStoreAddress:this.ishave == '0' ? '' : this.address,
   
                   videoId:this.videoinfo.videoId,
                   videoImage:v_info,
                   /* videoId: this.videoinfo.videoId,
                   videoImage: videoinfo, */
               });
               console.log("编辑品牌提交",res);
               if (res.code == 200) {
                   this.$toast('提交成功');
                   this.$router.go(-1);
               } else {
                   this.$toast('提交失败');
                   return false;
               }
           }
        },
        
        back() {
            this.$router.go(-1);
        },
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
        
    },
    async mounted(e) {
        window.uppplogofn = this.uppplogofn
        window.xuanchuanfn = this.xuanchuanfn
        window.xuanchuanvideos = this.xuanchuanvideos
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log(this.$route.params);
        console.log(this.publicityPicture);
        
        const res = await brand(this.$route.params.id);
            console.log("查询最新品牌信息",res);
            this.info = res.data,
            this.status = res.data.status,
            this.bradnid = res.data.id,
            this.brand = res.data.brand,
            this.brandDesc = res.data.brandDesc,
            this.brandhistory = res.data.brandHistory,
            this.brandLogo = res.data.brandLogo,
            this.ishave = res.data.physicalStoreFlag.toString()
            this.address = res.data.physicalStoreAddress ?res.data.physicalStoreAddress : ''
            this.publicityPicture = res.data.publicityPicture.split('#####'),
            this.videoinfo = res.data.videoResponse
    }
}
</script>
<style lang="less" scoped>
:deep(.van-field__control::-webkit-input-placeholder) {
    color:rgba(55,38,27,0.5);
    font-size: 14px;
}
/deep/.el-input__inner {
    background: rgba(174,133,81,0.15);
    border: none;
}
/deep/ .el-input__inner::-webkit-input-placeholder {
    color:rgba(55,38,27,0.5);
}
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0; 
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-size: 16px;
    .btn {
        width: 100%;
        border-radius: 5px;
        background-color: #876235;
        color: rgba(255, 255, 255, 1);
        margin-top: 20px;
        border: 1px solid #876235;
    }
    .flex {
        display: flex;
        align-items: center;
    }
}
.te {
            border-radius: 8px;
            margin: 10px 0;
            background-color: rgba(174,133,81,0.15);
        }
.input_fild {
   margin-top: 10px;
   padding:10px 15px;
   box-sizing: border-box;
   background: rgba(174,133,81,0.15);
   border-radius: 8px;
   color: #000;
}
.pop {
        padding: 20px;
        padding-bottom: 40px;
        box-sizing: border-box;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
       //顶部导航样式
 :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
    .van-cell::after {
        border-bottom: 1px solid #E9D4BC !important;
    }
</style>
