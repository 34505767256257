<template>
    <div class="page">
        <van-sticky :style="{'margin-top': mar_top + 'px'}">
            <van-nav-bar style="background-color: #E9D4BC;" title="申请售后" left-arrow @click-left="back" />
        </van-sticky>
        <div class="container">
            <div class="box">
                <div class="flex" style="justify-content: space-between;">
                    <div class="flex">
                        <!-- <van-image round width="30" height="30" src="https://img01.yzcdn.cn/vant/cat.jpeg" style="margin-right: 5px;"/>
                        <div>品牌名称</div> -->
                    </div>
                </div>
                <div style="height: 1px;width: 100%;background-color:#DFC09C;margin: 10px 0;"></div>
                <div class="flex" style="flex-direction: column;">
                    <div v-for="item in info.orderItemList" class="flex" style="justify-content: space-between;">
                        <div class="flex">
                        <img :src="item.coverImage" class="imgs">
                        <div class="goodsn">
                                    <div style="font-size: 15px;" class="t">{{item.goodsName}}</div>
                                    <div style="font-size: 14px;color: rgba(55,38,27,0.33);display: flex;margin-right: 10px;align-items: center;">
                                        <div style="margin-right: 3px;" v-for="guige in item.specificationItemNameList">{{guige}} </div>
                                    </div> 
                                </div>
                        </div>
                        <div style="height: 60px;display: flex;flex-direction: column;justify-content: space-around;">
                            <div style="font-size: 18px;color: #475AD5;">¥{{item.amount}}</div>
                            <div style="font-size: 14px;color: rgba(55,38,27,0.33);" >x{{item.num}}</div>
                        </div>
                    </div>
                    <div style="height: 1px;width: 100%;background-color:#DFC09C;margin: 10px 0;"></div>
                </div>
            </div>
            <div class="flex" style="margin: 20px 0;">
                <div>售后类型</div>
                <div class="flex" style="margin-left: 35px;">
                    <!-- <van-image width="24" height="24" :src="require('/src/assets/pic_active.png')"/>
                    <div style="margin-left: 10px;">退货退款</div> -->
                    <van-radio-group v-model="radio" direction="horizontal">
                        <van-radio name="0">退货退款</van-radio>
                        <!-- <van-radio name="1">仅退款</van-radio> -->
                    </van-radio-group>
                </div>
            </div>
            <div>退货原因 <span style="color: red;">*</span></div>
            <div class="flex res" style="justify-content: space-between;" @click="showPicker = true">
                 <div style="font-size: 16px;color: rgba(55,38,27,0.33);">{{ resonval ? resonval : '请选择' }}</div>
                <van-image width="24" height="24" :src="require('/src/assets/pic_right.png')"/>
            </div>
            <!-- 退货原因弹出框 -->
            <van-popup v-model="showPicker" position="bottom">
                <van-picker show-toolbar :columns="columns" value-key="val" @confirm="onConfirm" @cancel="showPicker = false" />
            </van-popup>
            <div style="margin-top: 20px ;">补充描述和凭证</div>
            <van-field v-model="message" rows="3" autosize type="textarea" maxlength="200" show-word-limit class="te" />
            <div class="flex" style="flex-wrap: wrap;">
                    <van-image @click="upload_fp" width="76" height="76" :src="imgUrl" style="margin-right: 10px;"/>
                    <template v-if="imglist.length != 0">
                        <van-image v-for="item in imglist"  width="76" height="76" :src="item"/>
                    </template>
            </div>
            <div class="flex" style="flex-wrap: wrap;margin-top: 10px;">
                <van-image width="76" height="76" :src="shouhou_video.videoCoverUrl" @click="upload_video"/>
                <video v-if="videoinfoz.videoUrl" @click="playVideo" :src="videoinfoz.videoUrl" style="width: 76px;height: 76px;"></video>
            </div>
            <div class="submit" @click="submitFun">重新提交</div>
        </div> 
    </div>
</template>
<script>
import { Toast } from 'vant';
import {queryRefundListByOrder} from '@/api/order';
import {updateRefund,addOrderRefund } from '@/api/myorder';
export default {
    name: 'myorder',
    data() {
        return {
            mar_top:'',
            showPicker:false,
            radio:'0',
            columns: [
                {id:0,val:'七天无理由'}
            ],
            reason:'', // 退货原因
            resonval:'',
            message:'', //补充描述
            imgUrl:require('/src/assets/pic_img.png'),
            imglist:[],
            //bridge返回
            shouhou_video:{
                videoCoverUrl:require('/src/assets/pic_video.png'),
                videoId:''
            },
            videoinfoz:{},
            info:{},
            orderinfo:{}

        }
    },
    methods: {
        playVideo(){
            this.$el.querySelector('video').play()
        },
        //上传售后图片
        upload_fp(){
            var data = {
                action:'GetImage',
                req:{img_num:3},
                reqId:2,
                respFunction:'upload_fpfun'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
         },
         upload_fpfun(e){
            this.imglist = e.data
         },
         //上传售后视频
         upload_video(){
            var data = {
                action:'GetVideo',
                req:{ minute:180},
                reqId:3,
                respFunction:'upvideo'
            }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
         },
         upvideo(e){
             console.log('售后视频',e);
             this.shouhou_video = e.data
         },
        // 退货原因
        onConfirm(value) {
            console.log("value",value);
            this.reason = value.id
            this.resonval = value.val
            this.showPicker = false;
        },
        // 提交
        async submitFun(){
            var videoinfo = {
                url:this.shouhou_video.videoCoverUrl
            } 
            console.log(Number(this.radio));
            if(this.resonval == '') {
                Toast('请选择退货原因')
            } else {
                const res = await updateRefund({
                    refundId:this.orderinfo.id,
                    type:Number(this.radio),
                    reason:this.reason,
                    refundDesc:this.message,
                    image:this.imglist.join('#####'),
                    videoId: this.shouhou_video.videoId,
                    videoImage: videoinfo,
                })
                    console.log("修改提交退款申请",res);
                    if(res.code == 200) {
                        Toast('提交成功')
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 300);
                    }
            }
        },
        async RefundListByOrder(){
            const res = await queryRefundListByOrder({ 
                orderId:this.info.id
            });
            console.log("查询订单下面的退款信息",res);
            this.orderinfo = res.data
            this.radio = res.data.type.toString()
            this.resonval = res.data.reason== 0?'七天无理由':'',
            this.reason = res.data.reason,
            this.message = res.data.refundDesc
            if(res.data.image) {
                this.imglist = res.data.image.split('#####')
            }
            if(res.data.videoResponse) {
                this.videoinfoz = res.data.videoResponse
            }
        },
        back() {
            this.$router.go(-1);
        },
        //距离顶部px
        marginFun(e){
            console.log("距离顶部",e);
            this.mar_top = e.data.height
        },
    },
    
    async mounted() {
        window.upload_fpfun = this.upload_fpfun
        window.upvideo = this.upvideo
        window.marginFun = this.marginFun
        //距离顶部距离
        var data = {
                    action:'StatusBarInfo',
                    req:{},
                    reqId:13,
                    respFunction:'marginFun'
                }
            if(this.$Version() == 'iOS') {
                window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
            } else {
                window.ssmAndroid.ssmMessage(JSON.stringify(data))
            }
        console.log("this.$router.parmars",this.$route.params);
        this.info = this.$route.params.info
        await this.RefundListByOrder();
    }
}
</script>
<style lang="less" scoped>
.van-step__line {
  display: none;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
} 
.page {
    background-color: #E9D4BC;
    &::before {
      content: ' ';
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #E9D4BC;
      background-size: 100% auto;
    }
}
.container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;
    .box {
        padding: 15px;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(174,133,81,0.15);
        margin: 7px 0;
    }
    .flex {
        display: flex;
        align-items: center;
        .img {
           width: 24px;
           height: 24px;
           border-radius: 12px; 
        }
        .imgs {
            width: 60px;
            height: 60px;
            border-radius: 8px; 
        }
        .goodsn {
            width: 190px;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .t {
            // width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .same {
            padding: 5px 15px;
            box-sizing: border-box;
            border-radius: 8px;
        }
        .cancel {
            border: 1px solid rgba(55,38,27,0.33);
            color: rgba(55,38,27,0.5);
        }
        .sub {
            background: #876235;
            color: #fff;
        }
        .recive {
            background-color: #876235;
            color: #fff;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tosat {
            width: 300px;
            height: 180px;
            border-radius: 8px;
            background-color: #E9D4BC;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }
    .res {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        height: 52px;
        border-radius: 8px;
        background-color: rgba(174,133,81,0.15);
        margin-top: 10px;
    }
    .ipt {
        border: none;
        background: rgba(0, 0, 0, 0);
    }
    .te {
        border-radius: 8px;
        margin: 10px 0;
        background-color: rgba(174,133,81,0.15);
    }
    .submit {
        width: 100%;
        height: 48px;
        line-height: 48px;
        text-align: center;
        border-radius: 12px;
        background-color: #876235 ;
        color: #fff;
        margin-top: 50px;
        font-size: 18px;
    }
}
   //顶部导航样式
   :deep.van-nav-bar .van-icon {
    color: #000;
    font-size: 22px;
    font-weight: bold;
}
//提交校验placehouder样式
:deep(.van-field--error .van-field__control::-webkit-input-placeholder) {
        color:rgba(55,38,27,0.5);
        font-size: 14px;
    }
</style>
