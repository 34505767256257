import { request } from "@/utils";
//运行商服务协议

export const getAgreement = (name,group) =>{
    return request({
        url: "/api/cpt/dict/queryOne",
        method: "get",
        params:{
            name,
            group
        },
    })
}
