const env = process.env.VUE_APP_ENV
// console.log(env,"1231231")

const config = {
    // 开发环境
    development: {
        env: 'development', // 环境
        appId: 'wx9b3285dc724e9460', // 公众号的appId wx9b3285dc724e9460
        // domain: 'https://h5.sonitalk.com', //服务器域名
        // serverUrl: 'https://api.sonitalk.com/ssm-app',//接口域名
        domain: 'https://dev-h5.sonitalk.com', // 前端域名
        serverUrl: 'https://dev-api.sonitalk.com/ssm-app', // 请求接口的baseUrl https://dev-api.sonitalk.com/ssm-app
        appAppId: 'wx0a439cc190c512c5', // 跳转App的AppId wx0a439cc190c512c5
    },
    // 测试环境
    test: {
        env: 'test',
        appId: 'wx9b3285dc724e9460',// 公众号的appId
        // domain: 'https://test-h5.serenetone.com/', //服务器域名
        domain: 'test-app-h5-merchant.serenetone.com/', //服务器域名
        
        // serverUrl: 'https://test-api.serenetone.com/ssm-merchants',//接口域名
        serverUrl: 'https://test-api.serenetone.com/',//接口域名
        appAppId: 'wx0a439cc190c512c5',
    },
    // 生产环境1
    production: {
        env: 'production',
        appId: 'wx9b3285dc724e9460',
        domain: 'https://h5.serenetone.com', //服务器域名
        /* serverUrl: 'https://api.serenetone.com/ssm-app',//接口域名 */
        serverUrl: 'https://api.serenetone.com',//接口域名
        appAppId: 'wx0a439cc190c512c5', // 跳转App的AppId
    }
}
export default config[env]
